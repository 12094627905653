import { TextField } from "@mui/material";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../../components/UXDataTable";
import {
  NonChecmicalItemCols,
  Option,
  StyledAutocomplete,
  StyledDiv,
  StyledDivider,
  sampleGridFontSize,
} from "../../NewWorkRequests/constants";

export default function WorkRequestNonChemicalTable({
  nonChemicalShipping,
  unitOfMeasureLoading,
  unitOfMeasure,
  updateData_ShippingItem,
  ownerCanEdit,
}) {
  function AddNewNonChemicalItemsRow() {}

  return (
    <div>
      <StyledDivider />
      <StyledDiv>
        <p
          style={{
            fontFamily: "EMprint",
            fontWeight: "600",
            fontSize: "16px",
            color: "#545459",
            textTransform: "none",
            textAlign: "left",
            paddingTop: "20px",
          }}
        >
          Shipping Non-chemical Items
        </p>
      </StyledDiv>

      <StyledDiv style={{ flexDirection: "column" }}>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={NonChecmicalItemCols}
          blankFirstHeader={false}
          tableId={`ShippingItems`}
          rowLength={nonChemicalShipping.length}
          enablePaging={false}
          rowsPerPage={0}
          page={0}
          handleChangePage={null}
          handleChangeRowsPerPage={null}
          noDataFoundMessage={"No items added"}
          enableAddIcon={false}
          addFunction={() => AddNewNonChemicalItemsRow()}
          addToolTipText="Add new item"
          isDataLoading={false}
        >
          <StyledTableBody key={`WRNonChemicalTable`}>
            {nonChemicalShipping.map((item, index) => (
              <StyledTableRow key={`WRNonChemicalTable~Row${index}`}>
                <StyledTableCell component="th" scope="row">
                  <TextField
                    sx={{ width: "800px" }}
                    size="small"
                    disabled={!ownerCanEdit}
                    value={item.description ?? ""}
                    onChange={(e) => {
                      updateData_ShippingItem(
                        "description",
                        e.target.value,
                        item
                      );
                    }}
                  ></TextField>
                </StyledTableCell>

                <StyledTableCell align="left">
                  <TextField
                    style={{ width: "200px", marginTop: "5px" }}
                    disabled={!ownerCanEdit}
                    size="small"
                    margin="dense"
                    variant="outlined"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    inputProps={{ style: { fontSize: sampleGridFontSize } }}
                    InputLabelProps={{ shrink: true }}
                    value={item.quantity ? item.quantity < 0 ? 0 : item.quantity : ""}
                    onChange={(e) => {
                      updateData_ShippingItem("quantity", e.target.value, item);
                    }}
                  />
                </StyledTableCell>

                {/* UoM */}
                <StyledTableCell align="right">
                  <StyledAutocomplete
                    renderOption={(props2, option) => (
                      <Option {...props2}>{option}</Option>
                    )}
                    disabled={!ownerCanEdit}
                    disablePortal
                    noOptionsText={
                      unitOfMeasureLoading ? "Loading UoMs..." : "No UoMs Found"
                    }
                    options={unitOfMeasure?.map((x) => x.uoMName) ?? ""}
                    onChange={(e, value) => {
                      updateData_ShippingItem("quantityUoMName", value, item);
                    }}
                    value={item.quantityUoMName ?? null}
                    autoHighlight
                    style={{ marginBottom: "5px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "200px" }}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={false}
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: sampleGridFontSize },
                        }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </StyledDiv>
    </div>
  );
}
