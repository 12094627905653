import { useState, useContext, useEffect } from "react";
import { Box} from "@mui/material";
import TableMyStudies from "./TableMyStudies";
import TableAllStudies from "./TableAllStudies";
import TableBatchQueue from "./TableBatchQueue";
import TableRecentlyAccessed from "./TableRecentlyAccessed"
import TableMyOpenBatches from "./TableMyOpenBatches";
import FilterMenu from "../../components/FilterMenu"
import BlendStudy from "../../api/Formulations/BlendStudy";
import UserContext from "../../context/UserContext";
import BatchQueue from "../../api/Formulations/BatchQueue";
import BlendStatus from "../../api/Formulations/BlendStatus";
import BatchStatus from "../../api/Formulations/BatchStatus";
import BlendPriority from "../../api/Formulations/BlendPriority";
import UnitOfMeasure from "../../api/Admin/UnitOfMeasure";
import { Link } from "react-router-dom";
import { GlobalButton, GlobalSecondaryButton, GlobalTabCollection, StyledTab } from "../../pages/styles";
import BlendFacility from "../../api/Formulations/BlendFacility";
import BlendStudyRecentlyAccessed from "../../api/Formulations/BlendStudyRecentlyAccessed";

const DesignStudy = ({ ...props }) => {
  const [tabValue, setTabValue] = useState(0);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{name:null, displayName:null, operator:null, enumValues:[], value:''}])

  const [allStudies, setAllStudies] = useState([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  
  const [myStudies, setMyStudies] = useState([])
  const [isMyStudiesLoading, setIsMyStudiesLoading] = useState(true)
  const currentUser = useContext(UserContext) 
  
  let inputSearchCriteria = currentUser.username

  const [allBatches, setAllBatches] = useState([])
  const [myOpenBatches, setMyOpenBatches] = useState([])
  const [recentlyAccessed, setRecentlyAccessed] = useState([])

  const [isBatchesLoading, setBatchesLoading] = useState(true)
  const [isMyBatchesLoading, setIsMyBatchesLoading] = useState(true)
  const [isRecentlyAccessedLoading, setIsRecentlyAccessedLoading] = useState(true)

  const [blendStudyStatuses, setBlendStudyStatuses] = useState([])
  const [blendStudyPriorities, setBlendStudyPriorities] = useState([])
  const [batchStatuses, setBatchStatuses] = useState([])
  const [unitOfMeasures, setUnitOfMeasures] = useState([])
  const [blendFacilities, setBlendFacilities] = useState([])

  const [refreshData, setRefreshData] = useState(false);

  const filterOptions = [{name: "id", displayName: "Study ID", type: "integer", enumValues:[]}, 
    {name: "studyName",displayName: "Study Name", type: "string", enumValues:[]}, 
    {name: "studyPurpose",displayName: "Description", type: "string", enumValues:[]},
    {name: "ownerEmail",displayName: "Owner", type: "string", enumValues:[]},
    {name: "createdDate",displayName: "Date Created", type: "date", enumValues:[]},
    {name: "lastModifiedDate",displayName: "Last Modified", type: "date", enumValues:[]},
    {name: "blendStudyStatusName",displayName: "Status", type: "enum", enumValues: blendStudyStatuses}
  ]

  const batchFilterOptions = [{name: "studyName", displayName: "Study Name", type: "string", enumValues:[]}, 
    {name: "blendName",displayName: "Blend Name", type: "string", enumValues:[]}, 
    {name: "batchSequenceNumber",displayName: "Batch #", type: "integer", enumValues:[]},
    {name: "blendPriorityName",displayName: "Priority", type: "enum", enumValues: blendStudyPriorities},
    {name: "requestedCompletionDate",displayName: "Required Date", type: "date", enumValues:[]},
    {name: "requestedAmount",displayName: "Requested Amount", type: "integer", enumValues:[]},
    {name: "requestedAmountUoM",displayName: "UoM", type: "enum", enumValues: unitOfMeasures},
    {name: "batchCreatedByEmail",displayName: "Batch Creator", type: "string", enumValues:[]},
    {name: "preparedByEmail",displayName: "Assigned Blender", type: "string", enumValues:[]},
    {name: "batchStatusName",displayName: "Status", type: "enum", enumValues: batchStatuses},
    {name: "age", displayName: "Age", type: "integer", enumValues:[]},
    {name: "blendFacilityName", displayName: "Facility", type: "enum", enumValues: blendFacilities}
  ]

  //get all the API info
  useEffect(() => {
    let cancelPromise = false
      
      BlendFacility.getAllFacilities().then((res) => {
        if (cancelPromise) return
          setBlendFacilities(res.filter(result => result.isActive === true).map((item) => (item.blendFacilityName)).sort())
      })

      BlendStatus.getAllBlendStatuses().then((res) => {
        if (cancelPromise) return
          setBlendStudyStatuses(res.filter(result => result.isActive === true).map((item) => (item.blendStatusName)).sort())
      });

      BatchStatus.getAllBatchStatuses().then((res) => {
        if (cancelPromise) return
          setBatchStatuses(res.filter(result => result.isActive === true).map((item) => (item.batchStatusName)).sort())
      });

      BlendPriority.getAllBlendPriorities().then((res) => {
        if (cancelPromise) return
          setBlendStudyPriorities(res.filter(result => result.isActive === true).map((item) => (item.blendPriorityName)).sort())
      });

      UnitOfMeasure.getAll().then((res) => {
        if (cancelPromise) return
          setUnitOfMeasures(res.filter(result => (result.type === 'weight' || result.type ==='volume') && result.metricStandardConversion !== null && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
      });

      BatchQueue.getBatchActiveQueue().then((res) => {
        if (cancelPromise) return
          setAllBatches(res)
          setBatchesLoading(false)

          setMyOpenBatches(res.filter(res => res.batchCreatedByEmail === inputSearchCriteria))
          setIsMyBatchesLoading(false)
      });


      return () => {
        cancelPromise = true
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])  

  //get all the studies for owner
  useEffect(() => {
    let cancelPromise = false
      BlendStudy.getMyBlendStudies(inputSearchCriteria).then((res) => {
        if (cancelPromise) return
        setMyStudies(res)
        setIsMyStudiesLoading(false)
      });

      BlendStudyRecentlyAccessed.getRecentlyAccessedBlendStudies(inputSearchCriteria).then((res) => {
        if (cancelPromise) return
        setRecentlyAccessed(res)
        setIsRecentlyAccessedLoading(false)
      })
      
      BlendStudy.getAllBlendStudies().then((res) => {
        if (cancelPromise) return
          setAllStudies(res)
          setIsDataLoading(false)
      });

    return () => {
      cancelPromise = true
    }
  }, [inputSearchCriteria, refreshData])


  const handleChange = (event, newValue) => {
    clearFiltersClick()
    setTabValue(newValue);
  };

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{name:null, displayName:null, operator:null, enumValues:[], value:''}])  
  }

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <TableRecentlyAccessed
                  filters={filters} 
                  myStudies={recentlyAccessed} 
                  isDataLoading={isRecentlyAccessedLoading} 
                  userEmail={inputSearchCriteria}
                  refreshData={refreshData}
                  setRefreshData={setRefreshData}/>
      }
      case 1: {
        return <TableMyStudies 
                  filters={filters} 
                  myStudies={myStudies} 
                  isDataLoading={isMyStudiesLoading} 
                  userEmail={inputSearchCriteria}
                  refreshData={refreshData}
                  setRefreshData={setRefreshData}/>
      }
      case 2: {
        return <TableAllStudies 
                  filters={filters} 
                  allStudies={allStudies} 
                  isDataLoading={isDataLoading}
                  refreshData={refreshData}
                  setRefreshData={setRefreshData}/>
      }
      case 3: {
        return <TableMyOpenBatches 
                  filters={filters} 
                  allBatches={myOpenBatches} 
                  isDataLoading={isMyBatchesLoading}/>
      }
      case 4: {
        return <TableBatchQueue 
                  filters={filters} 
                  allBatches={allBatches} 
                  isDataLoading={isBatchesLoading}/>
      }
      default: {
        alert(tabValue);
      }
    }
  };

  const renderFilterMenu = () => {
    switch (tabValue) {
      case 0: {
        return  <FilterMenu 
                  open={filteringOpen} 
                  setOpen={setFilteringOpen} 
                  applyBtnAction={applyFilters} 
                  cancelButtonAction={closeFiltering} 
                  filteringInfo={filterOptions} 
                  appliedFilters={filters} 
                  setAppliedFilters={setFilters}/>
      }

      case 1: {
        return  <FilterMenu 
                  open={filteringOpen} 
                  setOpen={setFilteringOpen} 
                  applyBtnAction={applyFilters} 
                  cancelButtonAction={closeFiltering} 
                  filteringInfo={filterOptions} 
                  appliedFilters={filters} 
                  setAppliedFilters={setFilters}/>
      }
      case 2: {
        return  <FilterMenu 
                  open={filteringOpen} 
                  setOpen={setFilteringOpen} 
                  applyBtnAction={applyFilters} 
                  cancelButtonAction={closeFiltering} 
                  filteringInfo={filterOptions} 
                  appliedFilters={filters} 
                  setAppliedFilters={setFilters}/>
      }
      case 3: {
        return  <FilterMenu 
                  open={filteringOpen} 
                  setOpen={setFilteringOpen} 
                  applyBtnAction={applyFilters} 
                  cancelButtonAction={closeFiltering} 
                  filteringInfo={batchFilterOptions} 
                  appliedFilters={filters} 
                  setAppliedFilters={setFilters}/>
      }

      case 4: {
        return  <FilterMenu 
                  open={filteringOpen} 
                  setOpen={setFilteringOpen} 
                  applyBtnAction={applyFilters} 
                  cancelButtonAction={closeFiltering} 
                  filteringInfo={batchFilterOptions} 
                  appliedFilters={filters} 
                  setAppliedFilters={setFilters}/>
      }
      default: {
        alert(tabValue);
      }
    }
  };
  
  return (
       <div>
         <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }} display="flex">
          <GlobalTabCollection style={{marginRight:"1rem"}} scrollButtons="auto" variant="scrollable" value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='Recent Studies' />
            <StyledTab label='My Studies' />
            <StyledTab label='All Studies' />
            <StyledTab label='My Open Batches' /> 
            <StyledTab label='Batch Queue' /> 
          </GlobalTabCollection>

            <GlobalButton 
            style={{marginTop:"-.8rem"}}
            variant="contained" 
            component={Link}
            to='/blendstudy'>
              Create Blend Study
          </GlobalButton>

          <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
              <GlobalButton style={{marginRight:"1rem"}} variant="contained"
                onClick={() => filterClick()}>Filters</GlobalButton>

              {!(filters[0].name === null) && 
              <GlobalSecondaryButton  variant="contained" 
                onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
          </Box>
              
          <Box sx={{ p: 1 }} />
         </Box>
        {renderSearchTab()}
        {renderFilterMenu()}
       </div>
  );
};

export default DesignStudy;
