import { useState } from "react";
import { StyledTableBody, UXDataTableWithoutBody } from "../../../../../components/UXDataTable";
import React from "react";
import { sortAscendingByProperty, sortDescendingByProperty } from "../helpers";

type DataTableWrapperColumn = {
  id: string;
  label: string;
  notSorteable: boolean;
};

type DataTableWrapperProps<T> = {
  tableId: string;
  columns: DataTableWrapperColumn[];
  tableWidth: string;
  blankFirstHeader: boolean;
  noDataFoundMessage: string;
  isDataLoading: boolean;
  rowData: T[];
  rowComponent: (item: T, index: number) => React.ReactNode;
};

export const DataTableWrapper = <T,>({
  tableId,
  columns,
  tableWidth = "100%",
  blankFirstHeader = false,
  noDataFoundMessage,
  isDataLoading,
  rowData = [],
  rowComponent,
}: DataTableWrapperProps<T>) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState("name");

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    _event: React.MouseEvent<HTMLButtonElement> | undefined,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sort = (a: T, b: T) => {
    const propertyKey = orderBy as keyof typeof a;
    return order === "asc"
      ? sortAscendingByProperty(a, b, propertyKey)
      : sortDescendingByProperty(a, b, propertyKey);
  };

  const handleSelectAllClick = () => {};

  return (
    <UXDataTableWithoutBody
      tableId={tableId}
      cols={columns}
      tableWidth={tableWidth}
      blankFirstHeader={blankFirstHeader}
      rowLength={rowData.length}
      noDataFoundMessage={noDataFoundMessage}
      enableAddIcon={false}
      addFunction={null}
      addToolTipText={""}
      isDataLoading={isDataLoading}
      enableCheckbox={false}
      enableSorteable={true}
      order={order}
      orderBy={orderBy}
      handleRequestSort={handleRequestSort}
      handleSelectAllClick={handleSelectAllClick}
      enablePaging={true}
      page={page}
      handleChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}>
      <StyledTableBody>
        {(rowsPerPage > 0
          ? rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : rowData
        )
          .sort(sort)
          .map((row, index) => rowComponent(row, index))}
      </StyledTableBody>
    </UXDataTableWithoutBody>
  );
};
