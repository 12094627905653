import React, { cloneElement, ReactElement, useState } from "react";
import { Menu } from "@mui/material";
import MoreOptions from "@mui/icons-material/MoreVert";

type CustomMenuProps = {
  children: ReactElement;
  disabled?: boolean;
};

export const CustomMenu: React.FC<CustomMenuProps> = ({ children, disabled = false }) => {
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (child: ReactElement) => (event: React.MouseEvent) => {
    if (child?.props?.onClick) {
      child.props.onClick(event);
    }
    handleClose();
  };

  return (
    <>
      <MoreOptions
        color={disabled ? "disabled" : undefined}
        aria-haspopup="true"
        onClick={handleOpenMenu}
      />
      <Menu
        id="custom-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {cloneElement(children, { onClick: handleMenuItemClick(children) })}
      </Menu>
    </>
  );
};
