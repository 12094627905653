import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { ChemIDSearchField } from "../../../components/ChemIDSearch/index";
import { styled } from "@mui/material/styles";
import { UXDataTable } from "../../../components/UXDataTable";
import SubstanceSubcomponent from "../../../api/LIMS/SubstanceSubcomponent";
import Substance from "../../../api/LIMS/Substance";
import { convertToLocalTime, displayPHSValue } from "../../../global";

const subSubstance = [
  {
    field: "percentageCompositionUpper",
    headerName: "Quantity",
    type: "number",
  },
  { field: "subcomponentChemID", headerName: "ChemID", type: "label" },
  { field: "substanceName", headerName: "Description", type: "label" },
  { field: "nfpa", headerName: "NFPA", type: "label" },
  { field: "hmis", headerName: "HMIS", type: "label" },
  { field: "phs", headerName: "PHS", type: "label" },
];

const LineBox = styled(Box)({
  height: "30px",
  width: "50%",
  display: "flex",
  minWidth: "500px",
});
const SpanTitle = styled("span")({
  width: "30%",
  display: "flex",
  height: "30px",
  backgroundColor: "#2567c349",
  borderBottom: "1px solid white",
  alignItems: "center",
  paddingLeft: "10px",
});
const SpanValue = styled("span")({
  width: "70%",
  height: "30px",
  display: "flex",
  borderBottom: "1px solid #2567c349",
  alignItems: "center",
  paddingLeft: "10px",
});
const LineBoxTable = styled(Box)({
  width: "50%",
  display: "flex",
  minWidth: "500px",
});
const SpanTitleTable = styled("span")({
  width: "30%",
  display: "flex",
  backgroundColor: "#2567c349",
  borderBottom: "1px solid white",
  alignItems: "center",
  paddingLeft: "10px",
});
const SpanValueTable = styled("span")({
  width: "70%",
  display: "flex",
  borderBottom: "1px solid #2567c349",
  paddingLeft: "10px",
});
const NewFormBtn = styled(Box)({
  textDecoration: "underline",
  color: "blue",
  cursor: "pointer",
  width: "fit-content",
});
const ThStyled = styled("th")({
  textAlign: "center",
  padding: "0px 2px"
});
const TableStyled = styled("table")({
  margin: "5px 0px",
});
const TdStyled = styled("td")({
  textAlign: "center",
});
const PStyled = styled("p")({
  fontSize: "14px",
});

const RightToKnow = ({ setTabValue }) => {
  const [hasChemIDErrors, setHasChemIDErrors] = useState(false);
  const [substanceUsage, setSubstanceUsage] = useState(true);
  const [selectedChemID, setSelectedChemID] = useState(null);
  const [listSubcomponent, setListSubcomponent] = useState([]);

  const QuantityComposition = (substance) => {
    if (
      substance.percentageCompositionUpper ===
      substance.percentageCompositionLower
    )
      return substance.percentageCompositionUpper != null ? substance.percentageCompositionUpper.toFixed(5) : "";

    if (substance.percentageCompositionLower === 0)
      return `< ${substance.percentageCompositionUpper != null ? substance.percentageCompositionUpper.toFixed(5) : ""}`;

    if (substance.percentageCompositionUpper === 100)
      return `> ${substance.percentageCompositionLower != null ? substance.percentageCompositionLower.toFixed(5) : ""}`;
    else
      return `${substance.percentageCompositionLower != null ? substance.percentageCompositionLower.toFixed(5) : ""} - ${substance.percentageCompositionUpper != null ? substance.percentageCompositionUpper.toFixed(5) : ""}`;
  };

  useEffect(() => {
    if (!selectedChemID || !selectedChemID.chemID) return;
    SubstanceSubcomponent.get(selectedChemID.chemID,null,true).then((substances) => {
      let rows = [];
      let getAllSubSubstances = substances.map((element) => {
        return Substance.getByChemID(element.subcomponentChemID, false, false).then((res) => {
          return res && res.message === 'Success' ? res.result : null;
        });
      });
      Promise.all(getAllSubSubstances).then((result) => {
        result.forEach((element) => {
          let currentSubSubstance = substances.find(
            (x) => x.subcomponentChemID === element.chemID
          );
          rows.push({
            percentageCompositionUpper:
              QuantityComposition(currentSubSubstance),
            subcomponentChemID: element.chemID ?? "",
            substanceName: element.substanceName ?? "",
            nfpa: <>
              <PStyled>H {element.nfpah}</PStyled>
              <PStyled>F {element.nfpaf}</PStyled>
              <PStyled>R {element.nfpaR}</PStyled>
            </>,
            hmis: <>
            <PStyled>H {element.hmish}</PStyled>
            <PStyled>F {element.hmisf}</PStyled>
            <PStyled>R {element.hmisR}</PStyled>
            </>,
            phs: displayPHSValue(element),
            order: currentSubSubstance.percentageCompositionUpper,
          });
        });
        setListSubcomponent(rows);
      });
    });
  }, [selectedChemID]);

  async function usageCheck(chemID) {
    if (chemID) {
      const response = await Substance.usageCheck(chemID)
      setSubstanceUsage(response);
    }
  }


  return (
    <div>
      <Box>
        <ChemIDSearchField
          fontSize={14}
          fieldWidth="50%"
          selectedChemIDObject={selectedChemID}
          setSelectedChemIDObject={(e) => {
            setSelectedChemID(e)
            usageCheck(e.chemID)
          }}
          hasErrors={hasChemIDErrors}
          setHasErrors={setHasChemIDErrors}
          allowInactiveChemIDs={true}
          returnSubstanceStrucureData={false}
        />
      </Box>
      <br />
      <Box>
        <LineBox>
          <SpanTitle>Chem ID</SpanTitle>
          <SpanValue>{selectedChemID?.chemID ?? ""}</SpanValue>
        </LineBox>
        <LineBox>
          <SpanTitle>Chem ID Category</SpanTitle>
          <SpanValue>{selectedChemID?.chemIDCategoryName ?? ""}</SpanValue>
        </LineBox>
        <LineBox>
          <SpanTitle>Status</SpanTitle>
          <SpanValue>
            {selectedChemID
              ? selectedChemID?.isActive
                ? "Active"
                : "Inactive"
              : ""}
          </SpanValue>
        </LineBox>
        <LineBox>
          <SpanTitle>Description</SpanTitle>
          <SpanValue>{selectedChemID?.substanceName ?? ""}</SpanValue>
        </LineBox>
        <LineBoxTable>
          {selectedChemID ? <SpanTitleTable>HFR</SpanTitleTable> : <SpanTitle>HFR</SpanTitle>}
          <SpanValueTable>
          {selectedChemID &&<TableStyled>
            <thead>
              <tr>
                <ThStyled></ThStyled>
                <ThStyled>NFPA</ThStyled>
                <ThStyled>HMIS</ThStyled>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>H</td>
                <TdStyled>{selectedChemID?.nfpah ?? ""}</TdStyled>
                <TdStyled>{selectedChemID?.hmish ?? ""}</TdStyled>
              </tr>
              <tr>
                <td>F</td>
                <TdStyled>{selectedChemID?.nfpaf ?? ""}</TdStyled>
                <TdStyled>{selectedChemID?.hmisf ?? ""}</TdStyled>
              </tr>
              <tr>
                <td>R</td>
                <TdStyled>{selectedChemID?.nfpar ?? ""}</TdStyled>
                <TdStyled>{selectedChemID?.hmisr ?? ""}</TdStyled>
              </tr>
            </tbody>
          </TableStyled>}
          </SpanValueTable>
          {/* {selectedChemID ? displayNfpaHmisHFRValue(selectedChemID) : ""} */}
        </LineBoxTable>
        <LineBox>
          <SpanTitle>PHS</SpanTitle>
          <SpanValue>
            {selectedChemID ? displayPHSValue(selectedChemID) : ""}
          </SpanValue>
        </LineBox>
        <LineBox>
          <SpanTitle>Peroxidizable</SpanTitle>
          <SpanValue>
            {selectedChemID
              ? selectedChemID?.isPeroxidizable
                ? "Y"
                : "N"
              : ""}
          </SpanValue>
        </LineBox>
        <LineBox>
          <SpanTitle>Replacement Chem ID</SpanTitle>
          <SpanValue>{selectedChemID?.promotedChemID ?? ""}</SpanValue>
        </LineBox>
        <LineBox>
          <SpanTitle>Default Retention Period (Months)</SpanTitle>
          <SpanValue>
            {selectedChemID?.smgDefaultRetentionMonths ?? ""}
          </SpanValue>
        </LineBox>
        <LineBox>
          <SpanTitle>Safety Information Last Updated</SpanTitle>
          <SpanValue>
            {selectedChemID && selectedChemID?.lastSafetyModifiedByEmail && selectedChemID?.lastSafetyModifiedDate
              ? `${selectedChemID?.lastSafetyModifiedByEmail ?? "Unknown"
              } on ${convertToLocalTime(
                new Date(selectedChemID?.lastSafetyModifiedDate)
              )}`
              : ""}
          </SpanValue>
        </LineBox>
        <LineBox>
          <SpanTitle>Owner</SpanTitle>
          <SpanValue>{selectedChemID?.ownerEmail ?? ""}</SpanValue>
        </LineBox>
      </Box>
      <br />

      {!substanceUsage && <Box display={'flex'} gap={'10px'}>
        <NewFormBtn
          onClick={() => {
            setTabValue(2);
          }}
        >
          New Chemical Review Form
        </NewFormBtn>
        <span style={{ fontSize: 14, color: "red" }}>(This container has not been used in the last 3 years.  Please complete a new NCA form.)</span>
      </Box>}
      <br />
      {/* {selectedChemID && selectedChemID.psimsid && (
        <>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://apps.spheracloud.net/Pdf/3461510_1_615691557.pdf"
          >
            View SDS
          </a>
          <br />
          <br />
        </>
      )} */}

      <UXDataTable
        tableWidth="60%"
        cols={subSubstance}
        rows={
          selectedChemID
            ? listSubcomponent.sort((a, b) => a.order - b.order)
            : []
        }
        moreOptionsCell={false}
        enablePaging={true}
        noDataMessage={""}
        menuProps={null}
        defaultRowsPerPage={25}
        isDataLoading={false}
        tableName={"allStudies"}
        enableSorting={true}
      ></UXDataTable>
      <br />
    </div>
  );
};

export default RightToKnow;
