import API from "..";

export default class SpecialAnalysisFileAttachment {

  constructor({
    id,
    SpecialAnalysisID,
    SpecialAnalysis,
    fileName,
    storagePath,
  }) {
    this.id = id;
    this.SpecialAnalysisID = SpecialAnalysisID;
    this.SpecialAnalysis = SpecialAnalysis;
    this.fileName = fileName;
    this.storagePath = storagePath;
  }

  static async getAll(SpecialAnalysisId) {
    const api = await API();
    const { data } = await api.get(`/specialAnalysisFileAttachment/?SpecialAnalysisId=${SpecialAnalysisId}`);
    if (data.result) {
      return data.result.map((d) => new SpecialAnalysisFileAttachment(d));
    }
    else {
      return [];
    }
  }

  static async postAttachs(info) {
    const api = await API('multipart/form-data');
    const { data } = await api.post('/specialAnalysisFileAttachment', info);
    return new SpecialAnalysisFileAttachment(data.result);
  }

  static async updateSpecialAnalysis(info) {
    const api = await API();
    const { data } = await api.put('/specialAnalysisFileAttachment', info);
    return data;
  }
}
