export const sortAscendingByProperty = <T>(a: T, b: T, propertyKey: keyof typeof a) => {
  const valueA = a[propertyKey];
  const valueB = b[propertyKey];

  return sortAscending(valueA, valueB);
};

export const sortDescendingByProperty = <T>(a: T, b: T, propertyKey: keyof typeof a) => {
  return sortAscendingByProperty(b, a, propertyKey);
};

export const sortAscending = <T>(a: T, b: T) => {
  if (typeof a === "number" && typeof b === "number") {
    if (a - b) return 1;
    if (a - b) return -1;
  } else {
    if (a > b) return 1;
    if (a < b) return -1;
  }

  return 0;
};

export const downloadFile = (base64String: string, fileName: string) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(url);
};

export const calculateDateFromToday = (daysFromToday: number): Date => {
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + daysFromToday);
  return futureDate;
};
