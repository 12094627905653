import {
  Box, Checkbox, FormControlLabel, FormGroup,
} from "@mui/material";
import EmailAddressTextField from "../../../components/EmailAddressField";
import {
  defaultShippingItem,
  labelProp,
} from "./constants";
import { WorkRequestFormAutocomplete, WorkRequestFormDate, WorkRequestFormInput } from "./WorkRequestFormInput";
import BillingInfoField from "../../../components/BillingInfoField";

const WorkRequestInformation = ({
  requestFacilities,
  requestFacilitiesLoading,
  businessUnits,
  businessUnitsLoading,
  workRequestPriority,
  workRequestPriorityLoading,
  setMyWorkRequest,
  myWorkRequest,
  setErrors,
  errors,
  addCommentComponent,
  setNonChemicalShipping,
}) => {
  const commonProps = {
    setData: setMyWorkRequest,
    data: myWorkRequest,
    setErrors,
    errors
  }  

  return (
    <div>
      <Box style={{ width: "100%" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            gap: "1rem",
            height: 60
          }}
        >
          <WorkRequestFormAutocomplete
            {...commonProps}
            options={requestFacilities}
            property={'facilityName'}
            noOptionsText={
              requestFacilitiesLoading
                ? "Loading Facilities..."
                : "No Facilities Found"
            }
            label={labelProp['facilityName']}
          />

          <WorkRequestFormAutocomplete
            {...commonProps}
            options={businessUnits}
            property={'businessUnitName'}
            noOptionsText={
              businessUnitsLoading
                ? "Loading Business Units..."
                : "No Business Units Found"
            }
            label={labelProp['businessUnitName']}
            required
          />

          <WorkRequestFormDate
            {...commonProps}
            property={'requestedCompletionDate'}
            label={labelProp['requestedCompletionDate']}
            minDate={new Date()}
            maxDate={
              myWorkRequest.lastAcceptableCompletionDate
                ? new Date(myWorkRequest.lastAcceptableCompletionDate)
                : null
            }
            required
          />

          <WorkRequestFormDate
            {...commonProps}
            property={'lastAcceptableCompletionDate'}
            label={labelProp['lastAcceptableCompletionDate']}
            minDate={
              new Date(myWorkRequest.requestedCompletionDate || new Date())
            }
            required
          />
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "3fr 1fr 4fr",
            gap: "1rem",
            height: 60
          }}
        >
          <BillingInfoField
            billingValue={
              myWorkRequest.chargeCode === null ? "" : myWorkRequest.chargeCode
            }
            handleChange={(e) => {
              commonProps.setData(() => ({
                ...commonProps.data,
                chargeCode: e,
              }));
            }}
            margin="normal"
            fieldWidth="100%"
            marginLeft={null}
            marginRight={null}
          />
          <WorkRequestFormAutocomplete
            {...commonProps}
            setData={(data) => {
              if (data?.priorityName === 'Rush') {
                setErrors({
                  ...errors,
                  priorityReason: null
                })
              } else {
                delete errors.priorityReason;
              }
              commonProps.setData(data);
            }}
            options={workRequestPriority}
            property={'priorityName'}
            noOptionsText={
              workRequestPriorityLoading
                ? "Loading Priorities..."
                : "No Priorities Found"
            }
            label='Priority'
          />

          <WorkRequestFormInput
            {...commonProps}
            label={labelProp['priorityReason']}
            property="priorityReason"
            required={'priorityReason' in errors}
          />
        </div>

        <div style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
          <WorkRequestFormInput
            {...commonProps}
            label={labelProp['description']}
            property="description"
            required
          />

          <EmailAddressTextField
            fontSize={16}
            margin={"none"}
            fieldWidth={"30%"}
            validatedUserEmail={
              myWorkRequest.requestedByEmail === null
                ? ""
                : myWorkRequest.requestedByEmail
            }
            setValidatedUserEmail={(value) => {
              setMyWorkRequest(() => ({
                ...myWorkRequest,
                requestedByEmail: value,
              }));
            }}
            setHasErrors={(error) => {
              setErrors((prev) => ({
                ...prev,
                requestedByEmail: error,
              }));
            }}
            hasErrors={
              errors.requestedByEmail === null
                ? false
                : errors.requestedByEmail
            }
            isDisabled={false}
            labelText={`* ${labelProp['requestedByEmail']}`}
            placeholderText="* Requester Email"
            showPlusMeButton={true}
          />
        </div>
      </Box>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          checked={myWorkRequest.isNonChemical}
          onChange={(e) => {
            setMyWorkRequest(() => ({
              ...myWorkRequest,
              isNonChemical: e.target.checked,
            }));
            setNonChemicalShipping([defaultShippingItem]);
          }}
          label={<>Is non-chemical request?</>}
        />
      </FormGroup>
      {addCommentComponent()}
    </div>
  );
};

export default WorkRequestInformation;
