import {useState} from "react";
import { convertToLocalTime, formatMidasNumber, displayHFRValue, displayPHSValue } from "../../global";
import {Checkbox,IconButton,TableRow, MenuItem, Menu} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { UXDataTableWithoutBody, StyledTableCell, UXDataTableNested, StyledTableBody, StyledTableRow } from "../../components/UXDataTable";
import MoreOptions from "@mui/icons-material/MoreVert";
import {Link} from "react-router-dom";

const sampleCols = ['MIDAS Number', 'Containers', 'Sample Description', 'Additional Info','Chem ID','Parent Sample', 'HFR', 'PHS', 'Perox', 'Sample Owner', 'Project']
const containerCols = ['Container #', "Status", 'Size', '% Full', 'Location', 'Sub Location', 'Last Updated By','Owner','Created On']

const SearchSamples = ({inputSearchCriteria, sampleSearchArray, dataIsLoading}) => {
  const [containerRowOpen, setContainerRowOpen] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [anchorEl, setAnchorEl] = useState([]);

  const noDataMessage = `No samples found with midas number, chem id or sample owner = ${inputSearchCriteria}`

  const openMenu = (index) => {
    return anchorEl[index] != null
  }

  const handleClickMenu = (event, index) => {
    const newAnchorEl = [...anchorEl]

    newAnchorEl[index] = event.currentTarget
    
    setAnchorEl(newAnchorEl)
  }

  const handleCloseMenu = (e, reason, index) => {
    const newAnchorEl = [...anchorEl]

    newAnchorEl[index] = null
    
    setAnchorEl(newAnchorEl)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleContainerRowClick = (sampleName) => {
    const containerRowOpenIndex = containerRowOpen.indexOf(sampleName);
    let newContainerRowOpen = [];

    if (containerRowOpenIndex === -1) {
      newContainerRowOpen = newContainerRowOpen.concat(containerRowOpen, sampleName);
    } else if (containerRowOpenIndex === 0) {
      newContainerRowOpen = newContainerRowOpen.concat(containerRowOpen.slice(1));
    } else if (containerRowOpenIndex === containerRowOpen.length - 1) {
      newContainerRowOpen = newContainerRowOpen.concat(containerRowOpen.slice(0, -1));
    } else if (containerRowOpenIndex > 0) {
      newContainerRowOpen = newContainerRowOpen.concat(
        containerRowOpen.slice(0, containerRowOpenIndex),
        containerRowOpen.slice(containerRowOpenIndex + 1)
      );
    }
    setContainerRowOpen(newContainerRowOpen);
  };

  function PrepareSamplePayload (sample) 
  {
    const copySample = structuredClone(sample)

    copySample.containers.forEach(oContainer => {
      oContainer.tests = null;
    });

    return copySample;
  }

  const determineStartArray = () => {
    return (sampleSearchArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }
  
  const isContainerRowOpen = (sampleName) => containerRowOpen.indexOf(sampleName) !== -1;

  return (
    <>
      <UXDataTableWithoutBody 
        tableWidth='100%' 
        cols={sampleCols} 
        blankFirstHeader={true}
        tableId="mySamplesSearch"
        rowLength={sampleSearchArray ? sampleSearchArray.length : 0}
        enablePaging={true}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={noDataMessage}
        enableAddIcon={false}
        addFunction={null}
        addToolTipText={null}  
        isDataLoading={dataIsLoading}
      >
        {sampleSearchArray && sampleSearchArray.length > 0 ? (
          determineStartArray().map((sampleRow, index) => {
            return (
              <StyledTableBody key={"mySampleSearch" + index}>
                <StyledTableRow>

                  <StyledTableCell component='th' scope='row' style={{width:'2rem'}}>
                    <MoreOptions color='black' aria-haspopup="true" onClick={e => handleClickMenu(e, index)}/>
                      <Menu
                        key={`menu${index}`}
                        anchorEl={anchorEl[index]}
                        open={openMenu(index)}
                        onClose={(e, reason) => handleCloseMenu(e, reason, index)}
                      >
                        <MenuItem 
                          key={`menuItem${index}`}
                          component={Link} 
                          to={{pathname: '/newSample', state: {sampleObject: PrepareSamplePayload(sampleRow), draftSamples: [], draftObject:null}}}
                          disabled={false}
                        >{"View Sample"}</MenuItem>
                      </Menu>
                  </StyledTableCell>

                  <StyledTableCell component='th' scope='row' sx={{minWidth:"8rem"}}>
                    <b>{formatMidasNumber(sampleRow.sampleName)}</b>
                  </StyledTableCell>
                  <StyledTableCell>
                    {sampleRow.containers ? sampleRow.containers.length : ''}
                    <IconButton
                      aria-label='expand row'
                      size='small'
                      onClick={() => handleContainerRowClick(sampleRow.sampleName)}
                    >
                      {isContainerRowOpen(sampleRow.sampleName) ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </StyledTableCell>

                  <StyledTableCell>{sampleRow.description}</StyledTableCell>
                  <StyledTableCell>{sampleRow.additionalSampleInformation}</StyledTableCell>
                  <StyledTableCell>{sampleRow.chemID}</StyledTableCell>
                  <StyledTableCell>{sampleRow.parentSampleContainer ? formatMidasNumber(sampleRow.parentSampleContainer.sampleName) : ''}</StyledTableCell>
                  <StyledTableCell>
                    {sampleRow.substance ? displayHFRValue(sampleRow.substance): ('?-?-?')}
                  </StyledTableCell>
                  <StyledTableCell>{sampleRow.substance ? displayPHSValue(sampleRow.substance) : ('???')}</StyledTableCell>
                  <StyledTableCell>
                    <Checkbox color='primary' checked={sampleRow.substance && sampleRow.substance.isPeroxidizable ? true : false} disabled={true} />
                  </StyledTableCell>
                  <StyledTableCell>{sampleRow.sampleOwnerEmail}</StyledTableCell>
                  <StyledTableCell>{sampleRow.project ? sampleRow.project.name : sampleRow.projectID}</StyledTableCell>
                </StyledTableRow>

                {isContainerRowOpen(sampleRow.sampleName) && (
                  <UXDataTableNested 
                    tableWidth={'100%'} 
                    cols={containerCols} 
                    blankFirstHeader={false} 
                    colSpan={sampleCols.length} 
                    isOpenComparator={sampleRow.sampleName} 
                    isOpenArray={containerRowOpen}>

                    <StyledTableBody> 
                      {sampleRow && sampleRow.containers && sampleRow.containers.map((containerRow, index) => (
                        <StyledTableRow key={`myContainers${containerRow.id}-${index}`}>
                          <StyledTableCell>
                            {containerRow.containerNumber}
                          </StyledTableCell>
                          <StyledTableCell>{containerRow.containerStatusName}</StyledTableCell>
                          <StyledTableCell>
                            {containerRow.size} {containerRow.containerSizeUoM}
                          </StyledTableCell>
                          <StyledTableCell>
                            {containerRow.size > 0 ? ((containerRow.currentAmount / containerRow.size) * 100).toFixed(2) : 0}%
                          </StyledTableCell>
                          <StyledTableCell>
                            {containerRow.locationName}
                          </StyledTableCell>
                          <StyledTableCell>
                            {containerRow.subLocation}
                          </StyledTableCell>
                          <StyledTableCell>
                            {containerRow.lastModifiedByEmail === null ? containerRow.createdByEmail : containerRow.lastModifiedByEmail}
                          </StyledTableCell>
                          <StyledTableCell>
                            {containerRow.ownerEmail}
                          </StyledTableCell>
                          <StyledTableCell>
                            {convertToLocalTime(containerRow.createdDate)}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      </StyledTableBody>
                  </UXDataTableNested>
                )}
              </StyledTableBody>
            )
          })
        ):(
          <StyledTableBody key={"no samples found"}>
          <TableRow key={"nothing found"}>
          <StyledTableCell colSpan='14' key={"nothing found cells"}>
            <p>{noDataMessage}</p>
          </StyledTableCell>
        </TableRow>
        </StyledTableBody>          
        )}

      </UXDataTableWithoutBody>

    </>
  );
};

export default SearchSamples;
