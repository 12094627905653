import { useState, useEffect } from "react";
import { convertToLocalTime, applyFiltersToArray, formatMidasNumber, formatParentTestLabel } from "../../../global";
import { UXDataTableWithoutBody, StyledTableCell, StyledTableBody, StyledTableRow, StyledTableCellLink } from "../../../components/UXDataTable";
import {
  IconButton,
  TableRow,
  Dialog,
  Breadcrumbs,
  Typography,
  Link,
  DialogContent,
  Box
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ResultDisplayBox from "../../../components/ResultDisplay";
import Test from "../../../api/LIMS/Test";
import { GlobalSecondaryButton, GlobalButton } from "../../styles";
import "./index.css";
import FilterMenu from "../../../components/FilterMenu";

const myTestsCols = ['MIDAS Number', 'TSN', 'Container', 'Method', 'Method Description', 'Results', 'Facility', 'Child Test', 'Status', 'Date Completed'];

const ParentTests = ({ open, setClose, setParentTest, parentTest, parentTestLabel }) => {
  const [tests, setTests] = useState([]);
  const [resultRowOpen, setResultRowOpen] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [tree, setTree] = useState([parentTest]);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{ name: null, displayName: null, operator: null, enumValues: [], value: '' }])
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [facilityFilters, setFacilityFilters] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [methodFilters, setMethodFilters] = useState([]);
  const [parentTestLabels, setParentTestLabels] = useState();

  const noDataMessage = `No tests found with parent test = ${parentTest}`;

  const filterOptions = [
    { name: "container.sampleName", displayName: "MIDAS Number", type: "midasNumber", enumValues: [] },
    { name: "testSequenceNumber", displayName: "TSN", type: "integer", enumValues: [] },
    { name: "container.containerNumber", displayName: "Container Number", type: "integer", enumValues: [] },
    { name: "methodFacility.methodName", displayName: "Method", type: "enum", enumValues: methodFilters },
    { name: "methodFacility.method.description", displayName: "Method Description", type: "string", enumValues: [] },
    { name: "methodFacility.testFacilityAbv", displayName: "Facility", type: "enum", enumValues: facilityFilters },
    { name: "id", displayName: "Child Test", type: "string", enumValues: [] },
    { name: "testStatusName", displayName: "Status", type: "enum", enumValues: statusFilters },
    { name: "reportedDate", displayName: "Date Completed", type: "date", enumValues: [] },
  ];

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{ name: null, displayName: null, operator: null, enumValues: [], value: null }])
  }


  useEffect(() => {
    let cancelPromise = false

    setDataIsLoading(true)
    setTests([]);
    clearFiltersClick();

    Test.parentTest(parentTest).then((res) => {
      if (cancelPromise) return

      if (res.message === 'Success') {
        const list = res.result;
        setFacilityFilters([...new Set(list.map(test => (test?.methodFacility?.testFacilityAbv)))]);
        setMethodFilters([...new Set(list.map(test => (test?.methodFacility?.methodName)))]);
        setStatusFilters([...new Set(list.map(test => (test?.testStatusName)))]);
        setTests(list?.sort((a, b) => a.testSequenceNumber - b.testSequenceNumber))
      }

      setDataIsLoading(false)
    });

    return () => {
      cancelPromise = true
    }

  }, [parentTest]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleResultRowClick = (testId) => {
    const resultRowOpenIndex = resultRowOpen.indexOf(testId);
    let newResultRowOpen = [];

    if (resultRowOpenIndex === -1) {
      newResultRowOpen = newResultRowOpen.concat(resultRowOpen, testId);
    } else if (resultRowOpenIndex === 0) {
      newResultRowOpen = newResultRowOpen.concat(resultRowOpen.slice(1));
    } else if (resultRowOpenIndex === resultRowOpen.length - 1) {
      newResultRowOpen = newResultRowOpen.concat(resultRowOpen.slice(0, -1));
    } else if (resultRowOpenIndex > 0) {
      newResultRowOpen = newResultRowOpen.concat(
        resultRowOpen.slice(0, resultRowOpenIndex),
        resultRowOpen.slice(resultRowOpenIndex + 1)
      );
    }
    setResultRowOpen(newResultRowOpen);
  };

  const handleClose = () => {
    return setClose()
  };

  function handleChangeParent(parent, index) {
    if (index || index === 0) {
      const newTree = tree.slice(0, index + 1);
      setTree(newTree);
    } else {
      setTree([...tree, parent]);
    }
    setParentTest(parent);
  };

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, tests)
    setFilteredRequests(filteredArray)
  }, [filters, tests])

  const isResultRowOpen = (testId) => resultRowOpen.indexOf(testId) !== -1;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      className='dialog-parent-tests'
    >
      <Box className='header'>
        <div className='header-box'>
          <p>Test Relationship - </p>
          <Breadcrumbs separator=">" aria-label="breadcrumb">
            {tree.map((item, index) => {
              let label = index === 0 ? parentTestLabel : parentTestLabels[item] ?? item;
              if (tree.length === index + 1)
                return <Typography key={index} sx={{ color: 'text.primary' }}>{label}</Typography>
              else {
                return <Link
                  underline="hover"
                  key={index}
                  color="inherit"
                  onClick={() => handleChangeParent(item, index)}
                >{label}</Link>
              }
            })}
          </Breadcrumbs>
        </div>
        <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
          <GlobalButton style={{ marginRight: "1rem" }} variant="contained"
            onClick={() => filterClick()}>Filters</GlobalButton>

          {!(filters[0].name === null) &&
            <GlobalSecondaryButton variant="contained" style={{ marginRight: "1rem" }}
              onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
          <GlobalSecondaryButton
            variant='contained'
            onClick={handleClose}
          >Close</GlobalSecondaryButton>
        </Box>
      </Box>
      <DialogContent>
        <UXDataTableWithoutBody
          tableWidth='100%'
          cols={myTestsCols}
          blankFirstHeader={false}
          tableId="myTestsTable"
          rowLength={filteredRequests ? filteredRequests.length : 0}
          enablePaging={true}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={noDataMessage}
          enableAddIcon={false}
          addFunction={null}
          addToolTipText={null}
          isDataLoading={dataIsLoading}
        >


          {filteredRequests.map((testRow) => (
            <StyledTableBody key={`${testRow.container?.sampleName}${testRow.testSequenceNumber}_"testtablebody"`}>
              <StyledTableRow>
                <StyledTableCell style={{ width: '9rem' }}>{formatMidasNumber(testRow.container?.sampleName)}</StyledTableCell>
                <StyledTableCell style={{ width: '2rem' }}>{testRow.testSequenceNumber}</StyledTableCell>
                <StyledTableCell style={{ width: '2rem' }}>{testRow.container?.containerNumber}</StyledTableCell>
                <StyledTableCell>{testRow.methodFacility?.methodName}</StyledTableCell>
                <StyledTableCell>{testRow.methodFacility?.method?.description}</StyledTableCell>
                <StyledTableCell style={{ width: '4rem' }}>
                  {testRow.testStatusName !== 'Corrected' ? (<IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => handleResultRowClick(testRow.id)}>
                    {isResultRowOpen(testRow.id) ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>) : <></>}
                </StyledTableCell>
                <StyledTableCell>{testRow.methodFacility?.testFacilityAbv}</StyledTableCell>
                {testRow.countChildTest > 0 ? (<StyledTableCellLink onClick={() => {
                  handleChangeParent(testRow.id);
                  setParentTestLabels({
                    ...parentTestLabels,
                    [testRow.id]: formatParentTestLabel(testRow)
                  })
                }} style={{ width: '6rem' }}>{formatParentTestLabel(testRow)}</StyledTableCellLink>) : (
                  <StyledTableCell  style={{ width: '6rem' }}/>
                )}
                <StyledTableCell>{testRow.testStatusName}</StyledTableCell>
                <StyledTableCell>{convertToLocalTime(testRow.reportedDate)}</StyledTableCell>
              </StyledTableRow>
              <TableRow style={{ paddingBottom: "0px", paddingTop: "0px", marginTop: "0px", marginBottom: "0px" }}>
                <StyledTableCell colSpan={9} style={{ paddingBottom: "0px", paddingTop: "0px", marginTop: "0px", marginBottom: "0px" }}>{isResultRowOpen(testRow.id) ? <ResultDisplayBox testID={testRow.id}></ResultDisplayBox> : null}</StyledTableCell>
              </TableRow>

            </StyledTableBody>
          ))
            // ))
          }
        </UXDataTableWithoutBody>
      </DialogContent>
      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={applyFilters}
        cancelButtonAction={closeFiltering}
        filteringInfo={filterOptions}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
      />
    </Dialog>
  );
};

export default ParentTests;
