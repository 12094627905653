import { convertToLocalTime, formatMidasNumber} from "../../global";
import MoreOptions from "@mui/icons-material/MoreVert";
import {Link} from "react-router-dom";
import {useState} from "react";
import { UXDataTableWithoutBody, StyledTableCell, UXDataTableNested, StyledTableBody, StyledTableRow } from "../../components/UXDataTable";
import {IconButton,TableRow, Menu, MenuItem} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const myStudyCols = ['ID', 'Study Name', 'Blends', 'Study Type', 'Owner', 'Key Words', 'Project', 'Updated By', 'Last Updated'];
const myRequestCols = ['Blend', 'Batches', 'Components', 'Comments', 'Key Words', 'Updated By', 'Last Updated'];
const myBatchCols = ['MIDAS #', 'Status', 'Description', 'Target Amt.', 'Actual Amt.', 'Facility', 'Purpose', 'Product Group', 'Comments', 'Metals Free', 'ISO'];
const myComponentCols = ['Balance', 'Chem ID', 'MIDAS #', 'Alt. MIDAS #', 'Unit', 'Target', 'Add Order', 'Add Temp (C)'];


const SearchBlends = ({inputSearchCriteria, myBlends, dataIsLoading}) => {

  const [blendRowOpen, setBlendRowOpen] = useState([]);
  const [componentRowOpen, setComponentRowOpen] = useState([]);
  const [batchRowOpen, setBatchRowOpen] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [anchorEl, setAnchorEl] = useState([]);

  const noDataMessage = `No blend info found matching the quick search for = ${inputSearchCriteria}`

  const openMenu = (index) => {
    return anchorEl[index] != null
  }

  const handleClickMenu = (event, index) => {
    const newAnchorEl = [...anchorEl]

    newAnchorEl[index] = event.currentTarget
    
    setAnchorEl(newAnchorEl)
  }

  const handleCloseMenu = (e, reason, index) => {
    const newAnchorEl = [...anchorEl]

    newAnchorEl[index] = null
    
    setAnchorEl(newAnchorEl)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleBlendRowClick = (studyName) => {
    const blendRowOpenIndex = blendRowOpen.indexOf(studyName);
    let newBlendRowOpen = [];

    if (blendRowOpenIndex === -1) {
      newBlendRowOpen = newBlendRowOpen.concat(blendRowOpen, studyName);
    } else if (blendRowOpenIndex === 0) {
      newBlendRowOpen = newBlendRowOpen.concat(blendRowOpen.slice(1));
    } else if (blendRowOpenIndex === blendRowOpen.length - 1) {
      newBlendRowOpen = newBlendRowOpen.concat(blendRowOpen.slice(0, -1));
    } else if (blendRowOpenIndex > 0) {
      newBlendRowOpen = newBlendRowOpen.concat(
        blendRowOpen.slice(0, blendRowOpenIndex),
        blendRowOpen.slice(blendRowOpenIndex + 1)
      );
    }
    setBlendRowOpen(newBlendRowOpen);
  };

  const handleComponentRowClick = (studyName, blendLetter) => {
    const componentRowOpenIndex = componentRowOpen.indexOf(`${studyName}~${blendLetter}`);
    let newComponentRowOpen = [];

    if (componentRowOpenIndex === -1) {
      newComponentRowOpen = newComponentRowOpen.concat(componentRowOpen, `${studyName}~${blendLetter}`);
    } else if (componentRowOpenIndex === 0) {
      newComponentRowOpen = newComponentRowOpen.concat(componentRowOpen.slice(1));
    } else if (componentRowOpenIndex === componentRowOpen.length - 1) {
      newComponentRowOpen = newComponentRowOpen.concat(componentRowOpen.slice(0, -1));
    } else if (componentRowOpenIndex > 0) {
      newComponentRowOpen = newComponentRowOpen.concat(
        componentRowOpen.slice(0, componentRowOpenIndex),
        componentRowOpen.slice(componentRowOpenIndex + 1)
      );
    }
    setComponentRowOpen(newComponentRowOpen);
  };

  
  const handleBatchRowClick = (studyName, blendLetter) => {
    const batchRowOpenIndex = batchRowOpen.indexOf(`Batch${studyName}~${blendLetter}`);
    let newBatchRowOpen = [];

    if (batchRowOpenIndex === -1) {
      newBatchRowOpen = newBatchRowOpen.concat(componentRowOpen, `Batch${studyName}~${blendLetter}`);
    } else if (batchRowOpenIndex === 0) {
      newBatchRowOpen = newBatchRowOpen.concat(componentRowOpen.slice(1));
    } else if (batchRowOpenIndex === componentRowOpen.length - 1) {
      newBatchRowOpen = newBatchRowOpen.concat(componentRowOpen.slice(0, -1));
    } else if (batchRowOpenIndex > 0) {
      newBatchRowOpen = newBatchRowOpen.concat(
        componentRowOpen.slice(0, batchRowOpenIndex),
        componentRowOpen.slice(batchRowOpenIndex + 1)
      );
    }
    setBatchRowOpen(newBatchRowOpen);
  };

  const determineStartArray = () => {
    return (myBlends.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }

  const isBlendRowOpen = (studyName) => blendRowOpen.indexOf(studyName) !== -1;
  const isComponentRowOpen = (studyName, blendLetter) => componentRowOpen.indexOf(`${studyName}~${blendLetter}`) !== -1;
  const isBatchRowOpen = (studyName, blendLetter) => batchRowOpen.indexOf(`Batch${studyName}~${blendLetter}`) !== -1;

  return (
    <>
    <UXDataTableWithoutBody 
      tableWidth='100%' 
      cols={myStudyCols} 
      blankFirstHeader={false}
      tableId="myTestsTable"
      rowLength={myBlends ? myBlends.length : 0}
      enablePaging={true}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noDataFoundMessage={noDataMessage}
      enableAddIcon={false}
      addFunction={null}
      addToolTipText={null}  
      isDataLoading={dataIsLoading}
      >

        {myBlends && myBlends.length > 0 ? (
          determineStartArray().map((blendStudy, index) => {
          
            return (
              <StyledTableBody key={"myBlendStudyTablebody" + index}> 
                <StyledTableRow>
                  <StyledTableCell component='th' scope='row' style={{width:'2rem'}}>
                    <MoreOptions color='black' aria-haspopup="true" onClick={e => handleClickMenu(e, index)}/>
                      <Menu
                        key={`menu${index}`}
                        anchorEl={anchorEl[index]}
                        open={openMenu(index)}
                        onClose={(e, reason) => handleCloseMenu(e, reason, index)}
                      >
                        <MenuItem 
                          key={`menuItem${index}`}
                          component={Link} 
                          to={{pathname: '/blendStudy', state: {incomingStudyID: blendStudy.id}}}
                          disabled={false}
                        >{'Edit/View this Study'}</MenuItem>
                      </Menu>
                    </StyledTableCell>

                    <StyledTableCell component='th' scope='row'>{blendStudy.id}</StyledTableCell>
                    <StyledTableCell component='th' scope='row'>{blendStudy.studyName}</StyledTableCell>

                    <StyledTableCell align='right' style={{width:'5rem'}}>

                    {blendStudy.blendRequests.length}

                      <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => handleBlendRowClick(blendStudy.studyName)}>
                        {isBlendRowOpen(blendStudy.studyName) ? (
                          <KeyboardArrowUpIcon/>
                        ) : (
                          <KeyboardArrowDownIcon/>
                        )}
                      </IconButton>
                    </StyledTableCell>
                          
                    <StyledTableCell align='right'>{blendStudy.blendStudyTypeName}</StyledTableCell>
                    <StyledTableCell align='right'>{blendStudy.ownerEmail}</StyledTableCell>
                    <StyledTableCell align='right'>{blendStudy.keywords}</StyledTableCell>
                    <StyledTableCell align='right'>{blendStudy.project ? blendStudy.project.name : blendStudy.projectID}</StyledTableCell>
                    <StyledTableCell align='right'>{blendStudy.lastModifiedByEmail === null ? blendStudy.createdByEmail : blendStudy.lastModifiedByEmail}</StyledTableCell>
                    <StyledTableCell align='right'>{blendStudy.lastModifiedDate === null ? convertToLocalTime(blendStudy.createdDate) : convertToLocalTime(blendStudy.lastModifiedDate)}</StyledTableCell>
                  </StyledTableRow>

                {isBlendRowOpen(blendStudy.studyName) && (
                  <UXDataTableNested 
                  tableWidth={'100%'} 
                  cols={myRequestCols} 
                  blankFirstHeader={false} 
                  colSpan={myStudyCols.length + 1} 
                  isOpenComparator={blendStudy.studyName} 
                  isOpenArray={blendRowOpen}>

                    {blendStudy.blendRequests.map((blendRequest) => (
                    <StyledTableBody key={`${blendStudy.studyName}_${blendRequest.blendName}_requesttablebody`}>
                      <StyledTableRow>
                        {/* <StyledTableCell>{blendRequest.description}</StyledTableCell> */}
                        <StyledTableCell>{blendRequest.blendName}</StyledTableCell>

                        <StyledTableCell align='right' style={{width:'5rem'}}>
                          {blendRequest.batches.length}
                            <IconButton
                              aria-label='expand row'
                              size='small'
                              onClick={() => handleBatchRowClick(blendStudy.studyName, blendRequest.blendName)}>
                              {isBatchRowOpen(blendStudy.studyName, blendRequest.blendName) ? (
                                <KeyboardArrowUpIcon/>
                              ) : (
                                <KeyboardArrowDownIcon/>
                              )}
                            </IconButton>
                        </StyledTableCell>

                        <StyledTableCell align='right' style={{width:'5rem'}}>
                          {blendRequest.blendComponents.length}
                            <IconButton
                              aria-label='expand row'
                              size='small'
                              onClick={() => handleComponentRowClick(blendStudy.studyName, blendRequest.blendName)}>
                              {isComponentRowOpen(blendStudy.studyName, blendRequest.blendName) ? (
                                <KeyboardArrowUpIcon/>
                              ) : (
                                <KeyboardArrowDownIcon/>
                              )}
                            </IconButton>
                        </StyledTableCell>

                        {/* <StyledTableCell>{blendRequest.blendPurpose}</StyledTableCell>
                        <StyledTableCell>{blendRequest.blendProductGroupName}</StyledTableCell>
                        <StyledTableCell>{blendRequest.chargeCode}</StyledTableCell> */}
                        <StyledTableCell>{blendRequest.blendComments}</StyledTableCell>
                        <StyledTableCell>{blendRequest.keywords}</StyledTableCell>
                        {/* <StyledTableCell>{blendRequest.isMetalsFree ? "Yes" : "No"}</StyledTableCell>
                        <StyledTableCell>{blendRequest.isISOBlend ? "Yes" : "No"}</StyledTableCell> */}
                        <StyledTableCell align='right'>{blendRequest.lastModifiedByEmail === null ? blendRequest.createdByEmail : blendRequest.lastModifiedByEmail}</StyledTableCell>
                        <StyledTableCell align='right'>{blendRequest.lastModifiedDate === null ? convertToLocalTime(blendRequest.createdDate) : convertToLocalTime(blendRequest.lastModifiedDate)}</StyledTableCell>
                      </StyledTableRow>

                {isComponentRowOpen(blendStudy.studyName, blendRequest.blendName) && (
                  <UXDataTableNested 
                  tableWidth={'100%'} 
                  cols={myComponentCols} 
                  blankFirstHeader={false} 
                  colSpan={myRequestCols.length + 1} 
                  isOpenComparator={`${blendStudy.studyName}~${blendRequest.blendName}`} 
                  isOpenArray={componentRowOpen}>

                  {blendRequest.blendComponents.map((component) => (
                    <StyledTableBody key={`${blendStudy.studyName}_${blendRequest.blendName}_requesttablebody`}>
                      <StyledTableRow>
                        <StyledTableCell>{component.isBalance ? 'Yes' : 'No'}</StyledTableCell>
                        <StyledTableCell>{component.substanceName}</StyledTableCell>
                        <StyledTableCell sx={{minWidth:"8rem"}}>{formatMidasNumber(component.preferredSampleName)}</StyledTableCell>
                        <StyledTableCell sx={{minWidth:"8rem"}}>{formatMidasNumber(component.secondaryPreferredSampleName)}</StyledTableCell>
                        <StyledTableCell>{component.componentUoMName}</StyledTableCell>
                        <StyledTableCell>{component.targetAmountLower}</StyledTableCell>
                        <StyledTableCell>{component.addOrder}</StyledTableCell>
                        <StyledTableCell>{component.blendAdditionTemperatureC}</StyledTableCell>
                      </StyledTableRow>
                    </StyledTableBody>
                  ))}
                  </UXDataTableNested>
                )}  

                {isBatchRowOpen(blendStudy.studyName, blendRequest.blendName) && (
                  <UXDataTableNested 
                  tableWidth={'100%'} 
                  cols={myBatchCols} 
                  blankFirstHeader={false} 
                  colSpan={myBatchCols.length + 1} 
                  isOpenComparator={`Batch${blendStudy.studyName}~${blendRequest.blendName}`} 
                  isOpenArray={batchRowOpen}>

                  {blendRequest.batches.map((oBatch) => (
                    <StyledTableBody key={`${blendStudy.studyName}_${blendRequest.blendName}_requesttablebody_batch`}>
                      <StyledTableRow>
                        <StyledTableCell>{oBatch.preparedSampleName}</StyledTableCell>
                        <StyledTableCell>{oBatch.batchStatusName}</StyledTableCell>
                        <StyledTableCell>{oBatch.description ? oBatch.description : ""}</StyledTableCell>
                        <StyledTableCell>{(oBatch.requestedAmount ? `${oBatch.requestedAmount}${oBatch.requestedAmountUoM}` : "")}</StyledTableCell>
                        <StyledTableCell>{(oBatch.actualAmount ? `${oBatch.actualAmount}${oBatch.actualAmountUoM}` : "")}</StyledTableCell>
                        <StyledTableCell>{oBatch.blendFacilityName ? oBatch.blendFacilityName : ""}</StyledTableCell>
                        <StyledTableCell>{oBatch.batchPurpose ? oBatch.batchPurpose : ""}</StyledTableCell>
                        <StyledTableCell>{oBatch.batchProductGroupName ? oBatch.batchProductGroupName : ""}</StyledTableCell>
                        <StyledTableCell>{oBatch.batchComments ? oBatch.batchComments : ""}</StyledTableCell>
                        <StyledTableCell>{oBatch.isMetalsFree ? "Yes" : "No"}</StyledTableCell>
                        <StyledTableCell>{oBatch.isISOBlend ? "Yes" : "No"}</StyledTableCell>
                      </StyledTableRow>
                    </StyledTableBody>
                  ))}
                  </UXDataTableNested>
                )}  
                    </StyledTableBody>
                    ))}
                  </UXDataTableNested>
                )}                
                </StyledTableBody>
            );
          })
        ) : (
          <StyledTableBody key={"no blends found"}>
            <TableRow key={"nothing found blends"}>
            <StyledTableCell colSpan='14' key={"nothing found cells blends"}>
            <p>{noDataMessage}</p>
            </StyledTableCell>
          </TableRow>
          </StyledTableBody>
        )}
  </UXDataTableWithoutBody>
  </>
  );
};

export default SearchBlends;
