import { useEffect, useRef, useState } from "react";
import {
  SubSectionTitle,
  SubTitle,
  defaultBlindCoding,
} from "./constants";
import { SDSFormInput } from "./SDSFormInput";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { removeMatchingProperties } from "../../../../global";

const BlindCoding = ({ commonProps, disabled }) => {
  const { data, setData, setErrors, errors } = commonProps;
  const [blindCodeForCommericalProductDisabled, setBlindCodeForCommericalProductDisabled] = useState(false);
  const [blindCodeForExperimentalProductDisabled, setBlindCodeForExperimentalProductDisabled] = useState(false);
  const [blindCodeForNonEmDisabled, setBlindCodeForNonEmDisabled] = useState(false);

  const isInitialMount = useRef(true);

  useEffect(() => {
    return () => {
      setData((prevData) => (removeMatchingProperties(prevData, defaultBlindCoding)))
    };
  }, [setData]);

  const handleFormCheckOptions = (dataset) => {
    if (dataset.blindCodeForCommericalProduct) {
      setBlindCodeForExperimentalProductDisabled(true);
      dataset.blindCodeForExperimentalProduct = false;
      dataset.experimentalDetails = "";
      setBlindCodeForNonEmDisabled(true);
      dataset.blindCodeForNonEM = false;
    } else if (dataset.blindCodeForExperimentalProduct) {
      setBlindCodeForCommericalProductDisabled(true);
      dataset.blindCodeForCommericalProduct = false;
      dataset.commericalProductDetails = "";
      setBlindCodeForNonEmDisabled(true);
      dataset.blindCodeForNonEM = false;
    } else if (dataset.blindCodeForNonEM) {
      setBlindCodeForCommericalProductDisabled(true);
      dataset.blindCodeForCommericalProduct = false;
      dataset.commericalProductDetails = "";
      setBlindCodeForExperimentalProductDisabled(true);
      dataset.blindCodeForExperimentalProduct = false;
      dataset.experimentalDetails = "";
    } else {
      setBlindCodeForCommericalProductDisabled(false);
      setBlindCodeForExperimentalProductDisabled(false);
      setBlindCodeForNonEmDisabled(false);
      dataset.commericalProductDetails = "";
      dataset.experimentalDetails = "";
    }
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const newData = { ...data };
      removeMatchingProperties(newData, defaultBlindCoding);
      handleFormCheckOptions(newData);
      setData(newData);
    } else {
      handleFormCheckOptions(data);
    }
  }, [data, setData]);

  return (
    <div>
      <SubSectionTitle>SDS For Blind Coding</SubSectionTitle>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="Provide desired blind code name"
          property="sdsBlindCodeName"
        />
      </div>

      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled || blindCodeForCommericalProductDisabled}
          checked={data.blindCodeForCommericalProduct}
          onChange={(e) => {
            if (e.target.checked) {
              setErrors({
                ...errors,
                commericalProductDetails: null
              })
            } else {
              delete errors.commericalProductDetails;
            }
            setData((prevData) => ({
              ...prevData,
              blindCodeForCommericalProduct: e.target.checked
            }));
          }}
          label={<>Do you need a blind code created for an ExxonMobil Commercial Product (including all TDM)?</>}
        />
      </div>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled || !data.blindCodeForCommericalProduct}
          label="If Yes, please provide the ProMIS Technical Spec. Code to be blind coded"
          property="commericalProductDetails"
          required={!!data.commericalProductDetails}
        />
      </div>

      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled || blindCodeForExperimentalProductDisabled}
          checked={data.blindCodeForExperimentalProduct}
          onChange={(e) => {
            if (e.target.checked) {
              setErrors({
                ...errors,
                experimentalDetails: null
              })
            } else {
              delete errors.experimentalDetails;
            }
            setData((prevData) => ({
              ...prevData,
              blindCodeForExperimentalProduct: e.target.checked
            }));
          }}
          label={<>Do you need a blind code created for an ExxonMobil Experimental Product (including all TDM)?</>}
        />
      </div>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled || !data.blindCodeForExperimentalProduct}
          label="If Yes, please provide the name of the experimental SDS created by EMBSI"
          property="experimentalDetails"
          required={!!data.blindCodeForExperimentalProduct}
        />
      </div>

      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled || blindCodeForNonEmDisabled}
          checked={data.blindCodeForNonEM}
          onChange={(e) => {
            setData((prevData) => ({
              ...prevData,
              blindCodeForNonEM: e.target.checked
            }));
          }}
          label={<>Do you need a blind code for a non-ExxonMobil Product? If so, please attach the product's SDS in the file attachments section.</>}
        />
      </div>
      <SubTitle>Regions where product will be used:</SubTitle>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isNorthAmerica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isNorthAmerica: e.target.checked
          }))}
          label={<>North America</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isSouthAmerica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isSouthAmerica: e.target.checked
          }))}
          label={<>South America</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isEurope}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isEurope: e.target.checked
          }))}
          label={<>Europe</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isAfrica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isAfrica: e.target.checked
          }))}
          label={<>Africa/Middle East</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isAsiaPacific}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isAsiaPacific: e.target.checked
          }))}
          label={<>Asia Pacific</>}
        />
      </FormGroup>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="If country-specific SDSes are required, please specify"
          property="countrySpecificInfo"
        />
      </div>
    </div>
  );
};

export default BlindCoding;
