import API from "..";
import { checkAPIResponse } from "../helpers";

export default class ShippingAddress {

  constructor({
    id,
    etqid,
    recipientName,
    company,
    department,
    address1,
    address2,
    city,
    state,
    country,
    postalCode,
    phoneNumber1,
    emailAddress,
    comments,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,	
    lastModifiedDate
  }) 
    
  {
    this.id = id;
    this.etqid = etqid;
    this.recipientName = recipientName;
    this.company = company;
    this.department = department;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.country = country;
    this.postalCode = postalCode;
    this.phoneNumber1 = phoneNumber1;
    this.emailAddress = emailAddress;
    this.comments = comments;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;	
    this.lastModifiedDate = lastModifiedDate;
  }

  static allShippingAddresses = []; //cache all shipping addresses

  static async getAll() {
    if (ShippingAddress.allShippingAddresses.length > 0) {
      return ShippingAddress.allShippingAddresses;
    }

    const api = await API();
    const response = await api.get(`/shippingAddress`);
    checkAPIResponse(response);
    const result = response.data.result;

    ShippingAddress.allShippingAddresses = result ? result.map((d) => new ShippingAddress(d)) : [];
    return ShippingAddress.allShippingAddresses;
  }
  
}
