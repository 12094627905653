import { React, useState, useEffect} from "react";
import { UXDataTable } from "../../components/UXDataTable";
import { applyFiltersToArray} from "../../global";

 const MyBatchCols = [
    { field: 'studyName', headerName: 'Study Name', type: 'label'},
    { field: 'blendName', headerName: 'Blend Name', type: 'label' },
    { field: 'batchSequenceNumber', headerName: 'Batch #', type: 'number' },
    { field: 'blendPriorityName', headerName: 'Priority', type: 'label-blendPriority' },
    { field: 'ownerEmail', headerName: 'Study Owner', type: 'label' },
    { field: 'requestedCompletionDate', headerName: 'Required Date', type: 'date' },
    { field: 'requestedAmount', headerName: 'Requested Amount', type: 'number' },
    { field: 'requestedAmountUoM', headerName: 'UoM', type: 'label' },
    { field: 'preparedByEmail', headerName: 'Assigned Blender', type: 'label' },
    { field: 'batchStatusName', headerName: 'Status', type: 'label' },
    { field: 'age', headerName: 'Age (days)', type: 'number' },
    { field: 'blendFacilityName', headerName: 'Facility', type: 'label' }
  ];


const TableMyOpenBatches = ({ ...props }) => {
  const [filteredBatches, setFilteredBatches] = useState([])
  const noDataMessage = `No batches found in the queue`

  const myFilters = props.filters
  const batches = props.allBatches
  const isDataLoading = props.isDataLoading
    
  useEffect(() => {
    var filteredArray = applyFiltersToArray(myFilters, batches)
    setFilteredBatches(filteredArray)
  }, [myFilters, batches])

  function getStatusSortOrder(statusName){
    if(statusName === 'Submitted'){
      return 0
    } else if(statusName === 'InProgress'){
      return 1
    } else if(statusName === 'Assigned'){
      return 2
    } else if(statusName === 'OnHold'){
      return 3
    } else if(statusName === 'Needs Review'){
      return 4
    } else {
      return 5
    }
  }
 
  return (
       <div>
         <UXDataTable tableWidth='100%' cols={MyBatchCols} rows={filteredBatches.sort((a,b) => getStatusSortOrder(a.batchStatusName) - getStatusSortOrder(b.batchStatusName)).sort((a, b) => a.age - b.age)} moreOptionsCell={false} enablePaging={true} 
                      noDataMessage={noDataMessage} menuProps={null} defaultRowsPerPage={10} isDataLoading={isDataLoading} tableName={'myOpenBatches'} enableSorting={true}></UXDataTable>
       </div>
  );
};

export default TableMyOpenBatches;