import { Box, CircularProgress, Modal } from "@mui/material";

type ProgressSpinnerProps = {
  display: boolean;
};

export const ProgressSpinner = ({ display }: ProgressSpinnerProps) => {
  return (
    <Modal open={display}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}>
        <CircularProgress color="inherit" size="3rem" />
      </Box>
    </Modal>
  );
};
