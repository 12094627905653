import TrashIcon from "@mui/icons-material/Delete";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFormik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Location from "../../../api/Admin/Location";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import Draft from "../../../api/LIMS/Draft";
// import PrintLabel from "../../../api/LIMS/PrintLabel";
import Sample from "../../../api/LIMS/Sample";
import TSCAReviewApi from "../../../api/WorkRequest/TSCAReview";
import SpecialAnalysis from "../../../api/SpecialAnalysis/SpecialAnalysis";
import SpecialAnalysisBusinessUnit from "../../../api/SpecialAnalysis/SpecialAnalysisBusinessUnit";
import SpecialAnalysisFileAttachment from "../../../api/SpecialAnalysis/SpecialAnalysisFileAttachment";
import SpecialAnalysisPriority from "../../../api/SpecialAnalysis/SpecialAnalysisPriority";
import EmailAddressTextField from "../../../components/EmailAddressField";
import FileDragAndDrop from "../../../components/FileDragAndDrop";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../components/UXDataTable";
import UserContext from "../../../context/UserContext";
import {
  convertCelsiusToFahrenheit,
  getMessage,
  isEmailValid,
  convertToBase64,
  convertBase64ToFile,
  DatePickerKeyDownEvent,
  convertFlashPoint
} from "../../../global";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import AddressBook from "../AddressBook";
import NewSpecialAnalysisForm from "../NewSpecialAnalysisForm";

import BillingInfoField from "../../../components/BillingInfoField";
import MethodSelection from "../../../components/MethodSelection";
import PrintInformation from "../../../components/PrintInformation";

import SpecialAnalysisSampleInfoTable from "./components/SpecialAnalysisSampleInfoTable";
import {
  FILE_ATTACHMENT_TABLE_COLUMNS,
  StyledTextField,
  StyledAutocomplete,
  StyledDiv,
  StyledDivider,
  StyledShortTextField,
  SubTitle,
  WORK_REQUEST_PRIORITIES_ENUM,
  WORK_REQUEST_TYPES_ENUM,
  defaultErrorChecksWorkRequest,
  defaultShippingAddress,
  defaultShippingItem,
  defaultShippingRequest,
  defaultSpecialAnalysisObject,
  defaultWorkRequest,
  defaultWorkRequestSample,
  specialAnalysisErrors,
  subsectionTitle,
  copyWorkRequest,
  copyShippingRequest,
} from "./constants.ts";
import { CONTAINERING_METHOD_ENUM } from "../WorkRequestTypes/ContainerRegistrationWithTest";

const WorkRequest = ({ ...props }) => {
  const history = useHistory();
  const location = useLocation();

  let myWorkRequestToCopy = location.state && location.state.myWorkRequest ? location.state.myWorkRequest : null;
  let shippingRequestToCopy = myWorkRequestToCopy ? myWorkRequestToCopy.shippingRequest : null;
  let parsedMethodsToCopy = myWorkRequestToCopy ? JSON.parse(myWorkRequestToCopy.testSubmissionJSON) : null;
  if (myWorkRequestToCopy) {
    myWorkRequestToCopy = copyWorkRequest(myWorkRequestToCopy);
    shippingRequestToCopy = shippingRequestToCopy ? copyShippingRequest(shippingRequestToCopy, myWorkRequestToCopy.specialAnalysisSamples) : null;
  }

  const [testJson, setTestJson] = useState(parsedMethodsToCopy ?? {
    containeringMethod: CONTAINERING_METHOD_ENUM.MIDAS,
    methods: [],
  });
  const currentUser = useContext(UserContext);

  const [myWorkRequest, setMyWorkRequest] = useState(myWorkRequestToCopy ?? defaultWorkRequest);
  const [errorChecks, setErrorChecks] = useState(defaultErrorChecksWorkRequest);
  const [specialAnalysisErrorCheck, setSpecialAnalysisErrorCheck] = useState(
    specialAnalysisErrors
  );
  const [shippingAddress, setShippingAddress] = useState(
    shippingRequestToCopy && shippingRequestToCopy.shippingAddress ? shippingRequestToCopy.shippingAddress : defaultShippingAddress
  );
  const [shippingRequest, setShippingRequest] = useState(
    shippingRequestToCopy ?? defaultShippingRequest
  );

  const [requestBusinessUnits, setRequestBusinessUnits] = useState([]);
  const [requestBusinessUnitsLoading, setRequestBusinessUnitsLoading] =
    useState(true);

  const [unitOfMeasure, setUnitOfMeasure] = useState([]);
  const [unitOfMeasureLoading, setUnitOfMeasureLoading] = useState(true);

  const [locations, setLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(true);

  const [isTSCA, setIsTSCA] = useState(false);
  const [addressBookOpen, setAddressBookOpen] = useState(false);

  const [files, setFiles] = useState([]);

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const [modalMessagesText, setModalMessagesText] = useState("");
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState("Ok");
  const [redirect, setRedirect] = useState(false);

  const [modalDraftsOpen, setModalDraftsOpen] = useState(false);
  const [modalDraftsButton1Text, setModalDraftsButton1Text] = useState("");
  const [modalDraftsButton2Text, setModalDraftsButton2Text] = useState("");
  const [modalDraftsTitle, setModalDraftsTitle] = useState("");
  const [modalDraftsText, setModalDraftsText] = useState("");

  const [workRequestPriority, setWorkRequestPriority] = useState("");
  const [newComment, setNewComment] = useState("");

  const [containerRecommendation, setContainerRecommendation] = useState([]);
  const [
    containerRecommendationErrorCheck,
    setContainerRecommendationErrorCheck,
  ] = useState(false);

  // const [printLabelInfo, setPrintLabelInfo] = React.useState(null);
  const [printInfoOpen, setPrintInfoOpen] = React.useState(false);

  const [workRequestPriorityLoading, setWorkRequestPriorityLoading] =
    useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [draftSaveName, setDraftSaveName] = useState("");
  const formik = useFormik({
    initialValues: myWorkRequest.specialRequest ?? defaultSpecialAnalysisObject,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      setMyWorkRequest((prevState) => ({
        ...prevState,
        specialRequest: values,
      }));
      formik.resetForm();
    },
  });

  const [submitOnce, setSubmit] = useState(0);

  const validateSpecialAnalysis = () => {
    let values = formik.values;

    let errorCheck = { ...specialAnalysisErrorCheck };
    if (!values.requestDescription) {
      errorCheck.requestDescription = true;
    } else {
      errorCheck.requestDescription = false;
    }

    if (!values.requestReason) {
      errorCheck.requestReason = true;
    } else {
      errorCheck.requestReason = false;
    }
    if (!values.testingRequirements) {
      errorCheck.testingRequirements = true;
    } else {
      errorCheck.testingRequirements = false;
    }

    if (!values.groupLeaderEmail) {
      errorCheck.groupLeaderEmail = true;
    } else {
      errorCheck.groupLeaderEmail = false;
    }

    if (!values.dataClassificationName) {
      errorCheck.dataClassificationName = true;
    } else {
      errorCheck.dataClassificationName = false;
    }
    if (!values.reportFormatName) {
      errorCheck.reportFormatName = true;
    } else {
      errorCheck.reportFormatName = false;
    }

    setSpecialAnalysisErrorCheck(errorCheck);

    let hasError = false;
    for (const key in specialAnalysisErrorCheck) {
      if (specialAnalysisErrorCheck[key]) hasError = true;
    }

    return hasError;
  };
  const myDraftObj =
    location.state && location.state?.draftObject
      ? location.state?.draftObject
      : null;

  const usingSpecialAnalysis =
    myWorkRequest.typeName === WORK_REQUEST_TYPES_ENUM.SPECIAL_ANALYSIS;

  function AutoCompleteChangeAndValidate(property, value) {
    const copyMyWorkRequest = structuredClone(myWorkRequest);

    copyMyWorkRequest[property] = value;
    if (property === "typeName") {
      copyMyWorkRequest.facilityName = null;
    }

    setMyWorkRequest(copyMyWorkRequest);
  }
  const mountComments = (comments) => {
    let comment = "";
    comments.forEach((item) => {
      comment += item + "\n";
    });
    return comment;
  };

  function handleChangeFile(property, index) {
    const listFiles = files.map((file, idx) => {
      if (index === idx) {
        return {
          ...file,
          [property]: !file[property],
        };
      }
      return file;
    });

    setFiles([...listFiles]);
  }

  const loadDraft = useCallback(() => {
    if (myDraftObj && myDraftObj?.draftJSON) {
      setDraftSaveName(myDraftObj?.draftName);
      const draft = JSON.parse(myDraftObj?.draftJSON);
      const methodsDraft = JSON.parse(draft.methods);
      const filesDraft = JSON.parse(draft.files);

      if (draft?.specialAnalysisSamples && draft?.specialAnalysisSamples.length > 0) {
        var copyspecialAnalysisSamples = structuredClone(draft.specialAnalysisSamples);

        draft.specialAnalysisSamples.forEach((oSample, index) => {
          Sample.getSamplesWithContainers(oSample.sampleName).then((res) => {
            if (res && res.message === "Success") {
              copyspecialAnalysisSamples[index].sample = structuredClone(
                res.result
              );
            }

            if (index === draft.specialAnalysisSamples.length - 1) {
              setMyWorkRequest({
                ...draft,
                specialAnalysisSamples: copyspecialAnalysisSamples,
                shippingRequest: null,
              });
            }
          });
        });
      } else {
        setMyWorkRequest({
          ...draft,
          specialAnalysisSamples: [defaultWorkRequestSample],
          shippingRequest: null,
        });
      }

      if (draft.shippingRequest?.shippingAddress) {
        setShippingAddress(draft.shippingRequest?.shippingAddress);
      }
      if (draft.shippingRequest) {
        setShippingRequest({
          ...draft.shippingRequest,
          shippingItems: draft.shippingRequest?.shippingItems?.map(item => ({
            ...item,
            flashPointInC: item.flashPointInC ? item.flashPointInC : null
          }))
        });
      }
      if (draft.specialRequest) {
        formik.setValues(draft.specialRequest);
      }
      setTestJson(methodsDraft);
      setFiles(
        filesDraft && filesDraft.length > 0
          ? filesDraft.map((file) => {
            return {
              ...file,
              file: convertBase64ToFile(file.file),
            };
          })
          : []
      );
      setIsTSCA(draft.isTSCA ?? false)

      if (draft.containerRecommendation && draft.containerRecommendation?.length > 0) {
        setContainerRecommendation(draft.containerRecommendation);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myDraftObj]);

  useEffect(() => {
    let cancelPromise = false;
    Promise.all([
      SpecialAnalysisPriority.getAll()
        .then((res) => {
          setWorkRequestPriority(res);
          setWorkRequestPriorityLoading(false);
        })
        .catch(() => {
          setWorkRequestPriorityLoading(false);
        }),
      SpecialAnalysisBusinessUnit.getAllActive().then((res) => {
        if (cancelPromise) return;
        setRequestBusinessUnitsLoading(false);
        setRequestBusinessUnits(
          res.sort((a, b) =>
            a.businessUnitName.localeCompare(b.businessUnitName)
          )
        );
      }),

      UnitOfMeasure.getAllActive().then((res) => {
        if (cancelPromise) return;
        setUnitOfMeasureLoading(false);
        setUnitOfMeasure(
          res
            .filter(
              (result) =>
                (result.type === "weight" || result.type === "volume") &&
                result.metricStandardConversion !== null
            )
            .sort((a, b) => a.uoMName.localeCompare(b.uoMName))
        );
      }),

      Location.getAllActive().then((res) => {
        if (cancelPromise) return;
        setLocationsLoading(false);
        setLocations(
          res.sort((a, b) => a.locationName.localeCompare(b.locationName))
        );
      }),
    ]).then(() => {
      loadDraft();
    });

    return () => {
      cancelPromise = true;
    };
  }, [loadDraft]);

  function handleFiles(newFiles) {
    const listFiles = newFiles.map((item) => {
      return {
        file: item,
        sendWithShipment: false,
        isSDS: false,
      };
    });
    setFiles(files.concat(listFiles));
  }

  function deleteFile(file) {
    const newFiles = files.filter((f) => f !== file);

    setFiles(newFiles);
  }

  async function createTscaRequest(data, res) {
    if (isTSCA && data.shippingRequest !== null) {
      let response = await TSCAReviewApi.createTSCAReview({
        shippingRequestID: res?.shippingRequest?.id,
        tscaStatusName: "Submitted",
      })
        .then((res) => true)
        .catch((err) => false);

      return response;
    }

    return true;
  }

  async function submit() {
    const areFieldsInvalid = validateFields();
    if (areFieldsInvalid.hasError && areFieldsInvalid.fields.length > 0) {
      const fields = areFieldsInvalid.fields.join("\n");
      openModalMessages(
        "Missing information!",
        "There are required fields missing:\n\n" + fields
      );
      return;
    }

    if (files.length === 0 && !submitOnce) {
      openModalMessages(
        "Save Attachment",
        "Don't forget to add a attachment"
      );

      setSubmit(1);
      return;
    }

    setLoadingSubmit(true);

    const data = await prepareData(myWorkRequest);
    SpecialAnalysis.createSpecialAnalysis(data)
      .then(async (res) => {
        if (res?.message !== 'Success') {
          openModalMessages('Fail', `Something went wrong creating the Work Requests, please try again later. ${res?.message ? res?.message : ""}`, 'Ok');
          setLoadingSubmit(false);
        } else {
          const workRequest = res.result;
          await createTscaRequest(data, workRequest);
          let filesSent = await sendFiles(workRequest.id);

          if (filesSent) finallyCreate(true);
          
          setLoadingSubmit(false);
         // if (myDraftObj && myDraftObj.id) Draft.delete(myDraftObj.id).then((res) => { });
        }
      })
      .catch((err) => {
        setLoadingSubmit(false);
        openModalMessages('Fail', `Something went wrong creating the Work Requests, please try again later. ${err?.message ? err?.message : ""}`, 'Ok');
      });
  }

  // function isSampleValidToPrint(sample) {
  //   return (
  //     sample.sampleName &&
  //     sample.sampleName !== "" &&
  //     sample.containers &&
  //     sample.containers.length > 0
  //   );
  // }

  // function verifyHasLabelToPrint(message) {
  //   setModalMessagesText(message);
  //   const allSampleValidForPrint = myWorkRequest.specialAnalysisSamples.every(
  //     (workRequestSample) => isSampleValidToPrint(workRequestSample.sample)
  //   );

  //   if (allSampleValidForPrint) {
  //     openPrintInfo(myWorkRequest?.specialAnalysisSamples);
  //   }
  // }

  async function sendFiles(id) {
    if (files.length > 0) {
      let result = await Promise.all(
        files.map(async (file, index) => {
          const formData = new FormData();
          formData.append("file", file.file);
          formData.append("specialAnalysisID", id);
          formData.append("sendWithShipment", file.sendWithShipment);
          formData.append("isSDS", file.isSDS);

          let resp = await SpecialAnalysisFileAttachment.postAttachs(formData);

          return resp;
        })
      );
      if (!!result.length) return true;
      else return false;
    }
    return true;
  }

  function finallyCreate(redirect) {
    openModalMessages(
      "Work Request Create",
      "Work Request Created Successfully"
    );
    setModalMessagesButtonText("Ok");
    setRedirect(redirect);
  }

  function openModalDrafts(title, text, buttonText, button2Text) {
    setModalDraftsButton1Text(buttonText);
    setModalDraftsButton2Text(button2Text);
    setModalDraftsOpen(true);
    setModalDraftsTitle(title);
    setModalDraftsText(text);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
    setModalMessagesButtonText("Ok");
  }

  function closeModalDrafts() {
    setModalDraftsOpen(false);
  }

  const checkSampleErros = () => {
    let hasError = false;
    const selectedSamples = myWorkRequest.specialAnalysisSamples.filter((x) => x.sample);

    selectedSamples.forEach((x) => {
      if (
        !x.existingContainerID ||
        !x.requestedAmountUoM ||
        !x.requestedLocationName ||
        !x.requestedAmount
      ) {
        hasError = true;
      }

      let currentContainer = x.sample.containers.find(
        (y) => y.id === x.existingContainerID
      );
      hasError = x.requestedAmount > currentContainer.currentAmount;
    });

    return hasError;
  };

  const jadeRequired = true;

  const validateFields = () => {
    let fieldsInvalidList = [];
    let typeName = null;
    let facilityName = null;
    let businessUnitName = null;
    let chargeCode = null
    let requestedByEmail = null;
    let lastAcceptableCompletionDate = null;
    let description = null;
    let requestedCompletionDate = null;

    let fieldsSpecialAnalysis = validateSpecialAnalysis()
    let priorityError = false;
    let sampleHasError = checkSampleErros();

    if (!myWorkRequest.requestedCompletionDate) {
      fieldsInvalidList.push("Requested Completion Date");
      requestedCompletionDate = getMessage("REQUIRED_FIELD");
    }
    if (!myWorkRequest.lastAcceptableCompletionDate) {
      fieldsInvalidList.push("Last Acceptable Date");
      lastAcceptableCompletionDate = getMessage("REQUIRED_FIELD");
    }

    if (!myWorkRequest.description) {
      fieldsInvalidList.push("Description");
      description = getMessage("REQUIRED_FIELD");
    }

    if (!myWorkRequest.businessUnitName) {
      fieldsInvalidList.push("Business Unit");
      businessUnitName = getMessage("REQUIRED_FIELD");
    }
    if (!myWorkRequest.chargeCode && jadeRequired) {
      fieldsInvalidList.push("JADE Number");
      chargeCode = getMessage("REQUIRED_FIELD");
    }
    if (!myWorkRequest.requestedByEmail) {
      fieldsInvalidList.push("Requester Name");
      requestedByEmail = getMessage("REQUIRED_FIELD");
    } else if (!isEmailValid(myWorkRequest.requestedByEmail)) {
      fieldsInvalidList.push("Requester Name");
      requestedByEmail = "Invalid E-mail";
    }

    if (
      myWorkRequest.priorityName === WORK_REQUEST_PRIORITIES_ENUM.RUSH &&
      !myWorkRequest.priorityReason
    ) {
      fieldsInvalidList.push("Priority Reason");
      priorityError = true;
    }

    setErrorChecks({
      ...errorChecks,
      typeName,
      facilityName,
      businessUnitName,
      requestedByEmail,
      requestedCompletionDate,
      lastAcceptableCompletionDate,
      description,
      chargeCode,
    });
    return {
      hasError:
        typeName !== null ||
        facilityName !== null ||
        businessUnitName !== null ||
        requestedByEmail !== null ||
        fieldsSpecialAnalysis ||
        priorityError ||
        sampleHasError ||
        chargeCode !== null ||
        description !== null ||
        containerRecommendationErrorCheck,
      fields: fieldsInvalidList,
    };
  };

  const prepareData = async (workRequest, draft = false) => {
    const specialAnalysisSamples = workRequest.specialAnalysisSamples.filter(
      (wrs) => wrs.sample
    );

    const filesBase64 = await Promise.all(
      files.map(async (file) => {
        return {
          ...file,
          file: await convertToBase64(file.file),
        };
      })
    );

    return {
      ...workRequest,
      businessUnit: null,
      workRequestFacilityName: null,
      workRequestTypeName: null,
      testSubmissionJSON: JSON.stringify(testJson),
      specialAnalysisSamples:
        (specialAnalysisSamples
        ).map((sample) => {
          let currentContainer = sample.sample.containers.find(
            (x) => x.id === sample.existingContainerID
          );
          delete sample.newContainer;
          return {
            ...sample,
            requestedAmount:
              sample.requestedAmount ?? currentContainer.currentAmount,
            requestedAmountUoM:
              sample.requestedAmountUoM ?? currentContainer.containerSizeUoM,
            sample: draft ? sample.sample : null,
            sampleName: sample.sample?.sampleName,
            unitOfMeasure: null,
            location: null,
            container: draft ? sample.container : null,
          };
        }),
      methods: draft ? JSON.stringify(testJson) : null,
      files: draft ? JSON.stringify(filesBase64) : null,
      isTSCA: draft ? isTSCA : null,
      specialRequest: workRequest.specialRequest,
      containerRecommendation: draft ? containerRecommendation : null,
    };
  };

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText("");
    setModalMessagesButtonText("");
    setModalMessagesTitle("");

    if (redirect) {
      history.push("/workRequests");
    }
  }

  const SaveNewDraft = async (e) => {
    if (draftSaveName.trim() === "") {
      openModalMessages(
        "Draft Name Error",
        "You must enter a name to save a new draft!"
      );
      return;
    }

    const data = await prepareData(myWorkRequest, true);

    let newDraft = new Draft({
      draftOwnerEmail: currentUser.username,
      draftName: draftSaveName,
      draftJSON: JSON.stringify(data),
      draftType: "workRequest",
      createdDate: null,
      lastModifiedDate: null,
    });

    let api = Draft.add;

    if (myDraftObj?.id) {
      api = Draft.update;
      newDraft.id = myDraftObj?.id;
    }

    api(newDraft).then((res) => {
      if (res.message === "Success") {
        closeModalDrafts();
        closeModalMessages();
        setRedirect(true);
        setModalMessagesOpen(true);
        setModalMessagesText("Draft Successfully Saved!");
        setModalMessagesButtonText("Ok");
        setModalMessagesTitle("Save Successful");
      } else {
        openModalMessages(
          "Draft Save Failed",
          `${res.message}. Contact support if you feel this is an error.`
        );
      }
    });
  };

  function handleAddressBook(addressBook) {
    if (addressBook) {
      setShippingAddress(() => ({
        ...shippingAddress,
        city: addressBook.city,
        state: addressBook.state,
        postalCode: addressBook.postalCode,
        country: addressBook.country,
        phoneNumber1: addressBook.phoneNumber1,
        emailAddress: addressBook.emailAddress,
        recipientName: addressBook.recipientName,
        address1: addressBook.address1,
        company: addressBook.company,
        department: addressBook.department,
      }));
    }
  }

  // function openPrintInfo(sampleList) {
  //   let arrayPrintInfo = [];
  //   sampleList?.forEach((workRequestSample) => {
  //     if (
  //       workRequestSample.sample &&
  //       workRequestSample.sample.containers &&
  //       workRequestSample.sample.containers.length > 0
  //     ) {
  //       let currentContainer = workRequestSample.sample.containers.find(
  //         (x) => x.id === workRequestSample.existingContainerID
  //       ).containerNumber;

  //       let newPrintInfo = new PrintLabel({
  //         sampleName: workRequestSample.sample.sampleName,
  //         containerNumbers: [currentContainer],
  //         includeAdditionalSampleInformation: false,
  //         chemIDOnly: false,
  //         printLabelSizeType: null,
  //         isBlindCoded: false,
  //         blindDescription: null,
  //         blindSampleName: null,
  //         isShelfLabel: false,
  //         shelfLabelText: null,
  //         includeShelfBarcode: false,
  //       });

  //       arrayPrintInfo.push(newPrintInfo);
  //     }
  //   });

  //   setPrintLabelInfo(arrayPrintInfo);
  //   setPrintInfoOpen(true);
  // }

  function closePrintInformation() {
    setPrintInfoOpen(false);
    finallyCreate(true);
  }

  useEffect(() => {
    let chemicals = myWorkRequest.specialAnalysisSamples.filter(
      (i) => i.sample !== null
    );

    if (!chemicals.length) return;

    let chemicalShipping = [];
    chemicalShipping = chemicals.map((item) => {
      const currentShippingItemIfExist =
        shippingRequest?.shippingItems?.find(
          (x) => x.sampleName === item.sample.sampleName && x.containerID === item.existingContainerID
        ) ?? structuredClone(defaultShippingItem);
      return {
        ...currentShippingItemIfExist,
        sampleName: item.sample.sampleName,
        containerID: item.existingContainerID,
        flashPointInC: currentShippingItemIfExist.flashPointInC ? currentShippingItemIfExist.flashPointInC :
          item.sample?.substance?.flashPointC ?
            convertFlashPoint(convertCelsiusToFahrenheit, item.sample?.substance?.flashPointC) : null,
      };
    });
    setShippingRequest((prev) => ({
      ...prev,
      containsSamples: true,
      shippingItems: [
        ...chemicalShipping,
        ...prev.shippingItems.filter((x) => !x.sampleName),
      ],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myWorkRequest.specialAnalysisSamples]);

  return (
    <div>
      <span className="pageheader">Create Special Analysis</span>

      <Divider className="dividerbar" />

      <Box style={{ width: "100%", marginTop: "1rem" }}>
        <StyledDiv>
          {(<Tooltip title='If container is not already in the Special Analysis Lab, please submit an On-Site Transport Request or Container Registration Request with the "Final Location" as DBLE253' placement="right">
            <p style={subsectionTitle}>Work Request Info</p>
          </Tooltip>)}
        </StyledDiv>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
            gap: "1rem",
          }}
        >
          <StyledAutocomplete
            disablePortal
            noOptionsText={
              requestBusinessUnitsLoading
                ? "Loading Business Units..."
                : "No Business Units Found"
            }
            options={requestBusinessUnits}
            onChange={(e, value) =>
              AutoCompleteChangeAndValidate(
                "businessUnitName",
                value?.businessUnitName
              )
            }
            getOptionLabel={(option) =>
              option?.businessUnitName
                ? option.businessUnitName
                : requestBusinessUnits.length > 0
                  ? requestBusinessUnits.find(
                    (f) => f.businessUnitName === option
                  )?.businessUnitName
                  : ""
            }
            autoHighlight
            autoSelect
            value={myWorkRequest.businessUnitName}
            isOptionEqualToValue={(option, value) =>
              value?.businessUnitName === option?.businessUnitName
            }
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                margin="normal"
                label="* Business Unit"
                error={
                  errorChecks.businessUnitName === null
                    ? false
                    : errorChecks.businessUnitName &&
                    !myWorkRequest.businessUnitName
                }
                helperText={
                  errorChecks.businessUnitName &&
                    !myWorkRequest.businessUnitName
                    ? errorChecks.businessUnitName
                    : ""
                }
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 1fr 1fr",
            gap: "1rem",
          }}
        >
          <BillingInfoField
            billingValue={
              myWorkRequest.chargeCode === null ? "" : myWorkRequest.chargeCode
            }
            handleChange={(e) => {
              setMyWorkRequest(() => ({
                ...myWorkRequest,
                chargeCode: e,
              }));
            }}
            hasErrors={
              errorChecks.chargeCode === null ? false : errorChecks.chargeCode
            }
            margin="normal"
            fieldWidth="100%"
            helperText={errorChecks.chargeCode ? errorChecks.chargeCode : ""}
            marginLeft={null}
            marginRight={null}
            required={jadeRequired}

          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              size="small"
              inputFormat="MM/dd/yyyy"
              label="* Requested Completion Date"
              onChange={(e) =>
                setMyWorkRequest(() => ({
                  ...myWorkRequest,
                  requestedCompletionDate: e,
                }))
              }
              minDate={new Date()}
              maxDate={
                myWorkRequest.lastAcceptableCompletionDate
                  ? new Date(myWorkRequest.lastAcceptableCompletionDate)
                  : null
              }
              value={myWorkRequest.requestedCompletionDate}
              renderInput={(params) => (
                <StyledShortTextField
                  onKeyDown={DatePickerKeyDownEvent}
                  {...params}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  error={
                    !!errorChecks.requestedCompletionDate &&
                    !myWorkRequest.requestedCompletionDate
                  }
                  helperText={
                    errorChecks.requestedCompletionDate &&
                      !myWorkRequest.requestedCompletionDate
                      ? errorChecks.requestedCompletionDate
                      : ""
                  }
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ ...params.inputProps }}
                  InputProps={{ ...params.InputProps }}
                  style={{ width: "100%" }}
                />
              )}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              size="small"
              inputFormat="MM/dd/yyyy"
              label="* Last Acceptable Date"
              onChange={(e) =>
                setMyWorkRequest(() => ({
                  ...myWorkRequest,
                  lastAcceptableCompletionDate: e,
                }))
              }
              minDate={
                new Date(myWorkRequest.requestedCompletionDate || new Date())
              }
              value={myWorkRequest.lastAcceptableCompletionDate}
              renderInput={(params) => (
                <StyledShortTextField
                  onKeyDown={DatePickerKeyDownEvent}
                  {...params}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  error={
                    !!errorChecks.lastAcceptableCompletionDate &&
                    !myWorkRequest.lastAcceptableCompletionDate
                  }
                  helperText={
                    errorChecks.lastAcceptableCompletionDate &&
                      !myWorkRequest.lastAcceptableCompletionDate
                      ? errorChecks.lastAcceptableCompletionDate
                      : ""
                  }
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ ...params.inputProps }}
                  InputProps={{ ...params.InputProps }}
                  style={{ width: "100%" }}
                />
              )}
            />
          </LocalizationProvider>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 4fr",
            gap: "1rem",
          }}
        >
          <StyledAutocomplete
            disablePortal
            style={{ width: "100%" }}
            noOptionsText={
              workRequestPriorityLoading
                ? "Loading Priorities..."
                : "No Priorities Found"
            }
            options={workRequestPriority}
            onChange={(e, value) =>
              AutoCompleteChangeAndValidate("priorityName", value?.priorityName)
            }
            getOptionLabel={(option) =>
              option?.priorityName
                ? option.priorityName
                : workRequestPriority.length > 0
                  ? workRequestPriority.find((f) => f.priorityName === option)
                    ?.priorityName
                  : ""
            }
            autoHighlight
            autoSelect
            value={myWorkRequest.priorityName}
            isOptionEqualToValue={(option, value) =>
              value?.priorityName === option?.priorityName
            }
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                variant="outlined"
                size="small"
                margin="normal"
                label="* Priority"
                error={
                  errorChecks.priorityName === null
                    ? false
                    : errorChecks.priorityName
                }
                helperText={
                  errorChecks.priorityName ? errorChecks.priorityName : ""
                }
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps }}
              />
            )}
          />

          <TextField
            sx={{ width: "100%" }}
            size="small"
            margin="normal"
            fullWidth
            variant="outlined"
            label="Priority Reason"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setMyWorkRequest(() => ({
                ...myWorkRequest,
                priorityReason: e.target.value,
              }))
            }
            value={
              myWorkRequest.priorityReason === null
                ? ""
                : myWorkRequest.priorityReason
            }
            error={
              myWorkRequest.priorityName ===
              WORK_REQUEST_PRIORITIES_ENUM.RUSH &&
              !myWorkRequest.priorityReason
            }
            helperText={
              myWorkRequest.priorityName ===
                WORK_REQUEST_PRIORITIES_ENUM.RUSH &&
                !myWorkRequest.priorityReason
                ? getMessage("REQUIRED_FIELD")
                : ""
            }
          />
        </div>

        <div style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
          <TextField
            size="small"
            sx={{ flex: 1 }}
            margin="none"
            variant="outlined"
            label="* Description"
            InputLabelProps={{ shrink: true }}
            multiline
            error={!!errorChecks.description && !myWorkRequest.description}
            helperText={
              errorChecks.description && !myWorkRequest.description
                ? errorChecks.description
                : ""
            }
            onChange={(e) =>
              setMyWorkRequest(() => ({
                ...myWorkRequest,
                description: e.target.value,
              }))
            }
            value={
              myWorkRequest.description === null
                ? ""
                : myWorkRequest.description
            }
          />

          <EmailAddressTextField
            fontSize={16}
            margin={"none"}
            fieldWidth={"20%"}
            validatedUserEmail={
              myWorkRequest.requestedByEmail === null
                ? ""
                : myWorkRequest.requestedByEmail
            }
            setValidatedUserEmail={(value) => {
              setMyWorkRequest(() => ({
                ...myWorkRequest,
                requestedByEmail: value,
              }));
            }}
            setHasErrors={() => {
              setErrorChecks((prev) => ({
                ...prev,
                requestedByEmail: "Invalid E-mail",
              }));
            }}
            hasErrors={
              errorChecks.requestedByEmail === null
                ? false
                : errorChecks.requestedByEmail
            }
            isDisabled={false}
            labelText="* Requester Email"
            placeholderText="* Requester Email"
            showPlusMeButton={true}
          />
        </div>
      </Box>
      <SubTitle>Add Comment</SubTitle>
      <Grid container spacing={2}>
        <Grid item xs={8} md={8}>
          <StyledTextField
            size="small"
            margin="normal"
            variant="outlined"
            label="Comment"
            InputLabelProps={{ shrink: true }}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "6px",
          }}
        >
          <GlobalButton
            name="saveComment"
            variant="contained"
            onClick={() => {
              let timeStamp = new Date().toLocaleString();

              let commentWithDate = `${currentUser.username} (${timeStamp}):  ${newComment}`;
              let comment = myWorkRequest.comments
                ? commentWithDate + "|" + myWorkRequest.comments
                : commentWithDate;
              setMyWorkRequest(() => ({
                ...myWorkRequest,
                comments: comment,
              }));
              setNewComment("");
            }}
            disabled={newComment === "" || !newComment}
          >
            Save Comment
          </GlobalButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={10} md={8}>
          <StyledTextField
            size="small"
            margin="normal"
            variant="outlined"
            label="Comments History"
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 500 }}
            multiline
            rows={4}
            value={
              myWorkRequest.comments
                ? mountComments(myWorkRequest.comments.split("|"))
                : ""
            }
            disabled={true}
          />
        </Grid>
      </Grid>

      <StyledDivider />

      <StyledDiv>
        <p style={subsectionTitle}>MIDAS Sample Info</p>
      </StyledDiv>

      <StyledDiv>
        <SpecialAnalysisSampleInfoTable
          myWorkRequest={myWorkRequest}
          setMyWorkRequest={setMyWorkRequest}
          unitOfMeasureLoading={unitOfMeasureLoading}
          unitOfMeasure={unitOfMeasure}
          locationsLoading={locationsLoading}
          locations={locations}
          ownerCanEdit={true}
          testJson={testJson}
          setTestJson={setTestJson}
          isProcessing={false}
          containerRecommendation={containerRecommendation}
          setContainerRecommendation={setContainerRecommendation}
          containerRecommendationErrorCheck={containerRecommendationErrorCheck}
          setContainerRecommendationErrorCheck={
            setContainerRecommendationErrorCheck
          }
        />
      </StyledDiv>
      {!usingSpecialAnalysis &&
        (
          <Box style={{ marginTop: "1rem" }}>
            <MethodSelection
              width="100%"
              selectedMethods={testJson.methods}
              setSelectedMethods={(value) =>
                setTestJson((prev) => ({ ...prev, methods: value }))
              }
              showCompletionDate={false}
              showContainerGrouping={false}
              incomingTemplate={null}
              showSearchTemplateName={true}
              showEstimateAndPriority={true}
              maxMethodsAllowed={999}
            />
          </Box>
        )}

      <StyledDivider />

      <StyledDiv>
        <p style={subsectionTitle}>File Attachments</p>
      </StyledDiv>

      <FileDragAndDrop
        handleFiles={handleFiles}
        files={files.map((item) => item.file)}
      />
      <div
        style={{
          width: "50%",
          minWidth: "800px",
        }}
      >
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={FILE_ATTACHMENT_TABLE_COLUMNS}
          blankFirstHeader={true}
          tableId={`midasFileAttachments`}
          rowLength={files.length}
          enablePaging={false}
          rowsPerPage={files.length}
          page={0}
          handleChangePage={null}
          handleChangeRowsPerPage={null}
          noDataFoundMessage={"No Files Added"}
          enableAddIcon={false}
          addFunction={() => null}
          addToolTipText=""
          isDataLoading={false}
        >
          <StyledTableBody key={"myTablebody"}>
            {files.map((file, rowIndex) => {
              return (
                <StyledTableRow hover key={`custom-row-${rowIndex}`}>
                  <StyledTableCell component="th" scope="row">
                    <Tooltip title="Delete File" placement="right">
                      <TrashIcon onClick={() => deleteFile(file)}></TrashIcon>
                    </Tooltip>
                    {/* <Tooltip title="Download File" placement="right">
                      <DownloadIcon onClick={() => downloadFileBlob(file.file)}>
                      </DownloadIcon>
                    </Tooltip> */}
                  </StyledTableCell>
                  <StyledTableCell>{file.file.name}</StyledTableCell>
                  <StyledTableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={file.sendWithShipment}
                          onChange={(e) =>
                            handleChangeFile("sendWithShipment", rowIndex)
                          }
                        />
                      }
                      label={"Yes"}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={file.isSDS}
                          onChange={(e) => handleChangeFile("isSDS", rowIndex)}
                        />
                      }
                      label={"Yes"}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>

      {(
        <NewSpecialAnalysisForm
          myWorkRequest={myWorkRequest}
          specialAnalysisErrorCheck={specialAnalysisErrorCheck}
          formik={formik}
        />
      )}
      <Box display="flex" paddingTop={6} paddingBottom={10} gap={2}>
        <GlobalButton name="btnSave" variant="contained" onClick={submit} disabled={loadingSubmit ? true : false}>
          Submit Special Analysis
        </GlobalButton>
        {loadingSubmit && <CircularProgress style={{ textTransform: "none", marginLeft: "15px" }}></CircularProgress>}

        <GlobalSecondaryButton
          name="btnSaveDraft"
          variant="contained"
          onClick={(e) => {
            openModalDrafts(
              "Save Draft",
              "Enter a name for your new draft.",
              "Save",
              "Cancel"
            );
          }}
        >
          Save As Draft
        </GlobalSecondaryButton>
      </Box>
      {/* </Box> */}

      {/* Informational Messages */}
      <ModalSimpleButton
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}
      >
        <label style={{ whiteSpace: "break-spaces" }}>
          {modalMessagesText}
        </label>
      </ModalSimpleButton>

      <ModalTwoButtons
        title={modalDraftsTitle}
        button1Text={modalDraftsButton1Text}
        button1Action={SaveNewDraft}
        button2Text={modalDraftsButton2Text}
        button2Action={closeModalDrafts}
        open={modalDraftsOpen}
        setOpen={setModalDraftsOpen}
      >
        <label>{modalDraftsText}</label>

        <div />

        <TextField
          size="small"
          label="Draft Name"
          value={draftSaveName}
          onChange={(e) => setDraftSaveName(e.target.value)}
          margin="normal"
          style={{ width: 250, marginTop: 40 }}
          inputProps={{ maxLength: 250 }}
        />
      </ModalTwoButtons>
      {addressBookOpen && (
        <AddressBook
          open={addressBookOpen}
          setOpen={setAddressBookOpen}
          handleApply={handleAddressBook}
        />
      )}

      <PrintInformation
        open={printInfoOpen}
        setOpen={setPrintInfoOpen}
        button2Action={closePrintInformation}
        button2Text="Close"
        printLabelInfo={'printLabelInfo'}
        isShelfLabel={false}
      />
    </div>
  );
};

export default WorkRequest;
