import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../context/UserContext";
import { styled, useTheme } from "@mui/material/styles";
import { Autocomplete, TextField, Box } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";
import User from "../../api/Admin/User";
import { GlobalSecondaryButton, GlobalButton } from "../../pages/styles";
import ClearIcon from "@mui/icons-material/Clear";

const autoCompleteFontSize = 14;

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
    fontSize: autoCompleteFontSize,
  },
});

const Option = styled("li")({
  fontSize: autoCompleteFontSize,
});

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 100,
});

const validatedTextVerbiage = "Valid";
const invalidTextVerbiage = "Invalid";

const EmailAddressTextField = ({ fontSize, fieldWidth, validatedUserEmail, setValidatedUserEmail, hasErrors, setHasErrors, isDisabled, labelText, placeholderText, showPlusMeButton, size, margin, bypassValidation = false, isRequired = true, style = {} }) => {
  const currentUser = useContext(UserContext);
  const theme = useTheme();
  const [avaliableUsers, setAvaliableUsers] = useState([]);
  const [firstNameToSearch, setFirstNameToSearch] = useState(null);
  const [lastNameToSearch, setLastNameToSearch] = useState(null);

  const [selectedUser, setSelectedUser] = useState(null);
  const [userSearchValue, setUserSearchValue] = useState(""); //substance search value
  const [tempSearchValue, setTempSearchValue] = useState(""); //chemIDSearchValue

  const [coloring, setColoring] = useState(theme.palette.primary);
  const [label, setLabel] = useState(labelText);

  const [modalUserSearchOpen, setModalUserSearchOpen] = useState(false);
  const [modalUserSearchButton1Text, setModalUserSearchButton1Text] = useState("");
  const [modalUserSearchButton2Text, setModalUserSearchButton2Text] = useState("");
  const [modalUserSearchTitle, setModalUserSearchTitle] = useState("");
  const [modalUserSearchText, setModalUserSearchText] = useState("");

  const handleUserSearch = () => {
    setUserSearchValue(tempSearchValue);
  };

  function closeModalUserSearch() 
  {
    if (validatedUserEmail === null || validatedUserEmail === "") 
    {
      setColoring(theme.palette.primary);
    }
    setModalUserSearchOpen(false);
  }

  function openModalUserSearch(title, text, buttonText, button2Text) 
  {
    setModalUserSearchButton1Text(buttonText);
    setModalUserSearchButton2Text(button2Text);
    setModalUserSearchOpen(true);
    setModalUserSearchTitle(title);
    setModalUserSearchText(text);
  }

  const confirmUserSelection = () => 
  {
    if (selectedUser !== null) 
    {
      setValidatedUserEmail(selectedUser.mail);
      setSelectedUser(null);

      setLabel(`${validatedTextVerbiage} ${labelText.replace('*', '')}`);
      setColoring("green");
      setHasErrors(false);
      closeModalUserSearch();
    }
  };

  const handleKeyPress = (event) => 
  {
    if (event.key === "Enter" || event.key === "Tab") 
    {
      setUserSearchValue(event.target.value);
    }
  };

  const fieldChange = (value) => 
  {
    if (validatedUserEmail === null || validatedUserEmail === "") 
    {
      setTempSearchValue(value);
    } else {
      setTempSearchValue(value);
      setValidatedUserEmail(null);
      setLabel(`${invalidTextVerbiage} ${labelText.replace('*', '')}`);
      setColoring(theme.palette.primary);
    }

    if (value === "") 
    {
      setLabel(`${labelText}`);
    }
  };

  //get all the Users
  useEffect(() => {
    let cancelPromise = false;

    if (userSearchValue && (userSearchValue !== null) & (userSearchValue !== "")) 
    {
      if (userSearchValue.includes("@")) {
        if (bypassValidation)
        {
          setLabel(`${validatedTextVerbiage} ${labelText.replace('*', '')}`);
          setColoring("green");
          setHasErrors(false);
          setValidatedUserEmail(userSearchValue);
          setModalUserSearchOpen(false);

          setTempSearchValue('');
        } else {
          User.validateEmailAddress(userSearchValue).then((res) => {
            if (cancelPromise) return;

            if (res) 
            {
              setLabel(`${validatedTextVerbiage} ${labelText.replace('*', '')}`);
              setColoring("green");
              setHasErrors(false);
              setValidatedUserEmail(res.mail);
              setModalUserSearchOpen(false);

              setTempSearchValue('');
            } else {
              setValidatedUserEmail(null);
              setLabel(`${invalidTextVerbiage} ${labelText.replace('*', '')}`);
              setColoring(theme.palette.primary);
              setHasErrors(true);
            }
          });
        }
      } else {
        openModalUserSearch(`Search for User`, "Please select your desired user from the list below, or modify the search criteria.", "OK", "Cancel");

        if (firstNameToSearch !== null && lastNameToSearch !== null && firstNameToSearch !== '' && lastNameToSearch !== '') 
        {
          User.searchByFirstAndLastName(firstNameToSearch, lastNameToSearch).then((res) => 
          {
            if (cancelPromise) return;
            setAvaliableUsers(res);
          });
        } else if ((firstNameToSearch === null || firstNameToSearch === '') && (lastNameToSearch !== null && lastNameToSearch !== '')) 
        {
          User.searchByLastName(lastNameToSearch).then((res) => {
            if (cancelPromise) return;
            setAvaliableUsers(res);
          });
        } else if ((firstNameToSearch !== null && firstNameToSearch !== '') && (lastNameToSearch === null || lastNameToSearch === '')) 
        {
          User.searchByFirstName(firstNameToSearch).then((res) => {
            if (cancelPromise) return;
            setAvaliableUsers(res);
          });
        }
      }
    }

    return () => {
      cancelPromise = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSearchValue]);

  useEffect(() => {
    if (validatedUserEmail !== null && validatedUserEmail !== "") 
    {
      setLabel(`${validatedTextVerbiage} ${labelText.replace('*', '')}`);
      setColoring("green");
    } else {
      setColoring(theme.palette.primary);
      setUserSearchValue("");
    }
  }, [theme.palette.primary, validatedUserEmail, labelText]);

  useEffect(() => {
    if (tempSearchValue === null) 
    {
      setFirstNameToSearch(null);
      setLastNameToSearch(null);
    } else {
      if (tempSearchValue.includes("@")) 
      {
        setFirstNameToSearch(null);
        setLastNameToSearch(tempSearchValue.trim());
      } else if (tempSearchValue.includes(",")) 
      {
        const split = tempSearchValue.split(",");

        setFirstNameToSearch(split[1].trim());
        setLastNameToSearch(split[0].trim());
      } else {
        setFirstNameToSearch(null);
        setLastNameToSearch(tempSearchValue.trim());
      }
    }
  }, [tempSearchValue]);

  function SearchAgainClick() {
    setTempSearchValue(`${lastNameToSearch === null ? "" : lastNameToSearch},${firstNameToSearch === null ? "" : firstNameToSearch}`);
    setUserSearchValue(`${lastNameToSearch === null ? "" : lastNameToSearch},${firstNameToSearch === null ? "" : firstNameToSearch}`);
  }

  function PlusMeButtonClick() {
    setTempSearchValue(currentUser.username);
    setUserSearchValue(currentUser.username);
  }

  function clearField() {
    setTempSearchValue("");
    setUserSearchValue("");
    setValidatedUserEmail("");
    setLabel(` ${labelText.replace('*', '')}`);
    setHasErrors(isRequired ? true : false)
  }

  function determineIfErrors() {
    if (isRequired)
    {
      return (validatedUserEmail === null || validatedUserEmail === "")
    } else {
      if (tempSearchValue === null || tempSearchValue === '')
      {
        return false
      } else {
        return true
      }
    }
  }

  return (
    <>
      <Box
        display="flex"
        gap={2}
        width={`calc(${fieldWidth} + ${showPlusMeButton ? "64px" : "0px"})`}
        alignItems="center"
        style={style}
      >
        <TextField
          sx={{
            [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
              {
                borderColor: coloring,
              },

            [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
              {
                borderColor: coloring,
              },
            [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
              {
                borderColor: coloring,
              },
            [`& .${outlinedInputClasses.input}`]: {
              color: coloring,
            },

            [`&:hover .${outlinedInputClasses.input}`]: {
              color: coloring,
            },
            [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
              {
                color: coloring,
              },
            [`& .${inputLabelClasses.outlined}`]: {
              color: coloring,
            },

            [`&:hover .${inputLabelClasses.outlined}`]: {
              color: coloring,
            },
            [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
              color: coloring,
            },
          }}
          fullWidth
          disabled={isDisabled}
          size={size ? size : "small"}
          margin={margin ? margin : "normal"}
          variant="outlined"
          label={label}
          InputLabelProps={{ shrink: true }}
          onKeyDown={handleKeyPress}
          onChange={(e) => fieldChange(e.target.value)}
          value={validatedUserEmail !== null && validatedUserEmail !== "" ? validatedUserEmail : tempSearchValue}
          placeholder={placeholderText}
          error={hasErrors}
          onBlur={() => setHasErrors(determineIfErrors())}
          //onBlur={() => setHasErrors(validatedUserEmail === null || validatedUserEmail === "")}
          inputProps={{ style: { fontSize: fontSize }, maxLength: 100 }}
          InputProps={{
            endAdornment: (
              <>
                {(!!validatedUserEmail || !!tempSearchValue) && (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={isDisabled}
                      edge="end"
                      sx={{ p: "4px" }}
                      type="button"
                      aria-label="search"
                      onClick={clearField}
                    >
                      <ClearIcon
                        sx={{ width: "20px" }}
                        style={{ fill: "rgba(0, 0, 0, 0.54)" }}
                      />
                    </IconButton>
                  </InputAdornment>
                )}
                <InputAdornment position="end">
                  <IconButton
                    
                    sx={{ p: "2px" }}
                   
                    type="button"
                    aria-label="search"
                    onClick={handleUserSearch}
                  >
                    <SearchIcon style={{ fill: "blue" }} />
                  </IconButton>
                </InputAdornment>
              </>
            ),
          }}
        ></TextField>

        {showPlusMeButton && (
          <GlobalButton
            name="btnMe"
            disabled={isDisabled}
            variant="contained"
            onClick={(e) => PlusMeButtonClick()}
            style={{ marginTop: margin === "none" ? "0px" : "8px" }}
          >
            +Me
          </GlobalButton>
        )}
      </Box>

      <ModalTwoButtons
        title={modalUserSearchTitle}
        button1Text={modalUserSearchButton1Text}
        button1Action={confirmUserSelection}
        button2Text={modalUserSearchButton2Text}
        button2Action={closeModalUserSearch}
        open={modalUserSearchOpen}
        setOpen={setModalUserSearchOpen}
      >
        <label>{modalUserSearchText}</label>

        <div style={{ display: "flex" }}>
          <TextField
            style={{ id: "outlined-normal", width: "40%", marginRight: "1rem" }}
            size="small"
            margin="normal"
            variant="outlined"
            label="First Name"
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 50 }}
            multiline
            onChange={(e) => setFirstNameToSearch(e.target.value)}
            value={firstNameToSearch === null ? "" : firstNameToSearch}
          ></TextField>

          <TextField
            style={{ id: "outlined-normal", width: "40%", marginRight: "1rem" }}
            size="small"
            margin="normal"
            variant="outlined"
            label="Last Name"
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 50 }}
            multiline
            onChange={(e) => setLastNameToSearch(e.target.value)}
            value={lastNameToSearch === null ? "" : lastNameToSearch}
          ></TextField>

          <GlobalSecondaryButton
            style={{ marginTop: "1.1rem", width: "8rem" }}
            onClick={(e) => SearchAgainClick()}
          >
            {"Search Again"}
          </GlobalSecondaryButton>
        </div>

        <div style={{ display: "flex" }}>
          <StyledAutocomplete
            renderOption={(props2, option) => (
              <Option {...props2}>{`${option.displayName}`}</Option>
            )}
            noOptionsText={"No Users Found..."}
            options={avaliableUsers.filter((e) => e.mail !== null)}
            getOptionLabel={(option) => `${option.displayName}`}
            onChange={(e, value) => setSelectedUser(value)}
            autoHighlight
            filterOptions={filterOptions}
            value={selectedUser}
            isOptionEqualToValue={(option, value) =>value.displayName === option.displayName}
            style={{ width: "70%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                margin="normal"
                label="Select User"
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
          <label style={{ marginLeft: "1rem", marginTop: "1rem" }}>{`***${avaliableUsers.filter((e) => e.mail !== null).length} ${avaliableUsers.filter((e) => e.mail !== null).length > 1 ? "users" : "user"} found!***`}</label>
        </div>
      </ModalTwoButtons>
    </>
  );
};
export default EmailAddressTextField;
