
import { useEffect, useRef, useState } from "react";
import { removeMatchingProperties } from "../../../../global";
import {
  defaultShipment,
  SubSectionTitle,
  SubTitle,
} from "./constants";
import { SDSFormInput } from "./SDSFormInput";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const Shipment = ({ commonProps, disabled }) => {
  const { data, setData } = commonProps;

  const [componentsExistDisabled, setComponentsExistDisabled] = useState(false);
  const [containsNewOrExperimentalComponentsDisabled, setContainsNewOrExperimentalComponentsDisabled] = useState(false);

  const isInitialMount = useRef(true);

  const handleCheckedOptions = (dataset) => {
    if (dataset.componentsExist) {
      setContainsNewOrExperimentalComponentsDisabled(true);
      dataset.containsNewOrExperimentalComponents = false;
    } else if (dataset.containsNewOrExperimentalComponents) {
      setComponentsExistDisabled(true);
      dataset.componentsExist = false;
    } else {
      setContainsNewOrExperimentalComponentsDisabled(false);
      setComponentsExistDisabled(false);
      dataset.containsNewOrExperimentalComponents = false;
      dataset.componentsExist = false;
    }
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const newData = { ...data };
      removeMatchingProperties(newData, defaultShipment);
      handleCheckedOptions(newData);
      setData(newData);
    }
    else {
      handleCheckedOptions(data);
    }
  }, [data, setData]);

  return (
    <div>
      <SubSectionTitle>SDS For Shipment</SubSectionTitle>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="List desired name for SDS for shipment"
          property="sdsShipmentName"
        />
      </div>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="Provide Closest ProMIS Technical Spec (if known)"
          property="existingTechnicalSpec"
        />
      </div>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled || componentsExistDisabled}
          checked={data.componentsExist}
          onChange={(e) => {
            setData((prevData) => ({
              ...prevData,
              componentsExist: e.target.checked
            }))
          }}
          label={<>All components exist in ProMIS?</>}
        />
      </div>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled || containsNewOrExperimentalComponentsDisabled}
          checked={data.containsNewOrExperimentalComponents}
          onChange={(e) => {
            setData((prevData) => ({
              ...prevData,
              containsNewOrExperimentalComponents: e.target.checked
            }))
          }}
          label={<>Contains some new/experimental components? (Request SIF or attach SDS for new/experimental components)</>}
        />
      </div>
      <SubTitle>Regions where product will be shipped:</SubTitle>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isNorthAmerica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isNorthAmerica: e.target.checked
          }))}
          label={<>North America</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isSouthAmerica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isSouthAmerica: e.target.checked
          }))}
          label={<>South America</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isEurope}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isEurope: e.target.checked
          }))}
          label={<>Europe</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isAfrica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isAfrica: e.target.checked
          }))}
          label={<>Africa/Middle East</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isAsiaPacific}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isAsiaPacific: e.target.checked
          }))}
          label={<>Asia Pacific</>}
        />
      </FormGroup>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="If country-specific SDSes are required, please specify"
          property="countrySpecificInfo"
        />
      </div>
    </div>
  );
};

export default Shipment;
