import { Box } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { GlobalTabCollection, StyledTab } from "../../styles";
import { GlobalButton, GlobalSecondaryButton } from "../../../pages/styles";
import FilterMenu from "../../../components/FilterMenu";
import UserContext from "../../../context/UserContext";
import ShippingAddressApi from "../../../api/WorkRequest/ShippingAddress"
import UserShippingAddressApi from "../../../api/WorkRequest/UserShippingAddress";
import AddressTable from "./AddressTable";

const filterOptions = [
    { name: "recipientName", displayName: "Recipient Name", type: "string", enumValues: [] },
    { name: "emailAddress", displayName: "Recipient's E-mail", type: "string", enumValues: [] },
    { name: "phoneNumber1", displayName: "Phone", type: "string", enumValues: [] },
    { name: "country", displayName: "Country", type: "string", enumValues: [] },
    { name: "postalCode", displayName: "Zip Code", type: "string", enumValues: [] },
    { name: "state", displayName: "State", type: "string", enumValues: [] },
    { name: "city", displayName: "City", type: "string", enumValues: [] },
    { name: "address1", displayName: "Street Address", type: "string", enumValues: [] },
    { name: "company", displayName: "Company", type: "string", enumValues: [] },
    { name: "department", displayName: "Department/ Floor No.", type: "string", enumValues: [] },
]

const AddressBook = ({ open, setOpen, handleApply }) => {
  const [tabValue, setTabValue] = useState(0);
  const [globalAddresses, setGlobalAddresses] = useState([]);
  const [favoriteAddresses, setFavoriteAddresses] = useState([]);
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);
  const [isFavoriteLoading, setFavoriteLoading] = useState(true);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{ name: null, displayName: null, operator: null, enumValues: [], value: '' }])
  const [selectedAddress, setSelectedAddress] = useState();

  const currentUser = useContext(UserContext);
  const userEmail = currentUser.username;

  const canAddToFavorites = favoriteAddresses.find((x) => x.id === selectedAddress?.id) ? false : true;
  
  const handleTabChange = (event, newValue) => setTabValue(newValue);
  
  const handleClose = () => setOpen(false);    
  
  const closeFiltering = () => setFilteringOpen(false);
  const applyFilters = () => setFilteringOpen(false);
  const filterClick = (event) => setFilteringOpen(true);
  
  const clearFiltersClick = (event) => {
    setFilters([{ name: null, displayName: null, operator: null, enumValues: [], value: null }])
  }

  useEffect(() => {
    setSelectedAddress(null);
    clearFiltersClick(null);
  },[tabValue])

  useEffect(() => {
    let cancelPromise = false;
    
    UserShippingAddressApi.getByUser(userEmail).then((userData) => {
      if (cancelPromise) return;
      
      setFavoriteAddresses(userData)      
      setFavoriteLoading(false);
      if (cancelPromise) return;

      ShippingAddressApi.getAll().then((data) => {
        if (cancelPromise) return;

        setGlobalAddresses(data);        
        setIsGlobalLoading(false);
      });
    });

    return () => {
      cancelPromise = true;
    };
  },[userEmail])

  function handleAddToFavorite() {
    if (!selectedAddress) return;

    UserShippingAddressApi.addByUser(userEmail, [selectedAddress.id]).then((response) => {

      UserShippingAddressApi.getByUser(userEmail).then((userData) => {
        setFavoriteAddresses(userData);
      });
    });
  }

  function handleRemoveFromFavorite() {
    if (!selectedAddress) return;

    UserShippingAddressApi.deleteByUser(userEmail, [selectedAddress.id]).then((response) => {

      UserShippingAddressApi.getByUser(userEmail).then((userData) => {
        setFavoriteAddresses(userData);
      });
    });
    
    setSelectedAddress(null);
  }

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0:  {
        return (
          <AddressTable
            key='favorite'
            listAddress={favoriteAddresses}
            isDataLoading={isFavoriteLoading}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            removeFromFavorites={handleRemoveFromFavorite}
            filters={filters}
          />
        );
      }
      case 1: {
        return (
          <AddressTable
            key='global'
            listAddress={globalAddresses}
            isDataLoading={isGlobalLoading || isFavoriteLoading}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            canAddToFavorites={canAddToFavorites}
            addToFavorites={handleAddToFavorite}
            filters={filters}
          />
        );
      }
      default: {
        alert(tabValue);
      }
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Saved Address</DialogTitle>
      <DialogContent>
        <div>
          <label> To select one of the previous address, just click on the desired row. </label>
          <Box sx={{ bgcolor: "#fff", pt: 3, pb: 1 }} display="flex">
            <GlobalTabCollection
              value={tabValue}
              onChange={handleTabChange}
              aria-label="ant example"
            >
              <StyledTab label="Favorites" />
              <StyledTab label="Global" />
            </GlobalTabCollection>

            <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
              <GlobalButton style={{ marginRight: "1rem" }} variant="contained"
                onClick={() => filterClick()}>Filters</GlobalButton>
              {!(filters[0].name === null) &&
                <GlobalSecondaryButton variant="contained"
                  onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
            </Box>

            <Box sx={{ p: 1 }} />
          </Box>

          {renderSearchTab()}

          <div style={{
            display: "flex",
            justifyContent: "center",
          }}>
            <GlobalButton
              style={{
                marginTop: "35px",
                marginLeft: "6px"
              }}
              variant='contained'
              onClick={() => {
                handleApply(selectedAddress);
                handleClose();
              }}
              disabled={!selectedAddress}
            >
              Apply Address
            </GlobalButton>

            <GlobalSecondaryButton
              style={{
                marginTop: "35px",
                marginLeft: "20px"
              }}
              variant='contained'
              onClick={handleClose}
            >
              Close
            </GlobalSecondaryButton>
          </div>

          <FilterMenu
            open={filteringOpen}
            setOpen={setFilteringOpen}
            applyBtnAction={applyFilters}
            cancelButtonAction={closeFiltering}
            filteringInfo={filterOptions}
            appliedFilters={filters}
            setAppliedFilters={setFilters}
            />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddressBook;
