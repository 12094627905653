import React from "react";
import { MenuItem, Menu, TableRow, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import { StyledTableBody, UXDataTableWithoutBody, StyledTableCell } from "../../../../components/UXDataTable";
import { SDSRequestContextProvider } from "../../Context/ProcessSDS";
import { convertDateFormat } from "../../../../global";
import ModalSimpleButton from "../../../../components/Modal/ModalSimpleButton";
import SubmitSDS from "../SubmitSDS";
import { viewFormOrigin } from "./constants";
import { requestStatusOptions } from "../constants";
import MoreOptions from "@mui/icons-material/MoreVert";
import toast from "react-hot-toast";
import Requests from "../../../../api/SDSRequest/SDSRequest";

const StyledTableCellClickable = styled(StyledTableCell)(() => ({
  cursor: 'pointer'
}));

export function GridRender(props) {
  const { showCompletedRequests, showCancelledRequests, requests, loading, gridColumns, currentUser } = props;
  const filteredEvents = [];

  requests && requests.forEach((row) => {
    filteredEvents.push(row);
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [modalMessagesOpen, setModalMessagesOpen] = React.useState(false);
  const [modalMessageText, setModalMessageText] = React.useState('');
  const [modalMessagesTitle, setModalMessagesTitle] = React.useState('');
  const [modalMessagesButtonText, setModalMessagesButtonText] = React.useState('Ok');
  const [selectedRequest, setSelectedRequest] = React.useState(null);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('requestStatusName');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = React.useState(null);
  const [menuLoading, setMenuLoading] = React.useState(false);

  React.useEffect(() => {
    setSelectedRequest()
  }, [showCompletedRequests, showCancelledRequests]);

  React.useEffect(() => {
    if (requests?.length) {
      setOrderBy('requestStatusName');
      setOrder('desc');
    }
  }, [requests]);

  function reloadData() {
    props.reloadList();
    setSelectedRequest(null);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const determineStartArray = () => {
    return stableSort(filteredEvents, getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'requestStatusName') {
      const orderMap = {
        'Draft': 1,
        'InProgress': 2,
        'Assigned': 3,
        'Submitted': 4,
        'OnHold': 5
      };
      const aOrder = orderMap[a[orderBy]] || 6;
      const bOrder = orderMap[b[orderBy]] || 6;
      return aOrder - bOrder;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function handleOpenMoreOptions(e, workRequest) {
    setMoreOptionsSelected(workRequest)
    setAnchorEl(e.currentTarget)
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null)
    setAnchorEl(null)
  }

  async function updateRequestStatus(request, status) {
    if (request.requestStatusName === status) {
      toast.success(`Request Id ${request.id} is already set as ${status}`);
    }

    setMenuLoading(true);
    handleCloseMoreOptions(null);
    const data = await Requests.updateSDSRequestStatus(request.id, status);
    if (data?.result) {
      toast.success(`Request Id ${request.id} set as ${status} successfuly`);
      reloadData();
    } else {
      toast.error("An error ocurred while trying to update the request");
      openModalMessages(
        "Error",
        "An error ocurred while trying to update the request. \n" + data?.message 
      )
    }
    setMenuLoading(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessageText(text);
    setModalMessagesButtonText("Ok");
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessageText('')
    setModalMessagesButtonText('Ok')
    setModalMessagesTitle('')
  }

  function canChangeStatus(request) {
    const validStatuses = [
      requestStatusOptions.draft,
      requestStatusOptions.submitted,
      requestStatusOptions.onHold
    ];

    return (
      request?.requesterByEmail === currentUser.username &&
      validStatuses.includes(request?.requestStatusName)) ||
      (request?.requestStatusName === requestStatusOptions.draft &&
        request?.createdByEmail === currentUser.username);
  }

  function setSelectedRow(request) {
    setSelectedRequest(null);
    setTimeout(() => setSelectedRequest(request), 100);
  }

  return (
    <SDSRequestContextProvider>
      <UXDataTableWithoutBody
        tableWidth="100%"
        cols={gridColumns}
        tableId="gridSdsRequests"
        rowLength={filteredEvents.length}
        enablePaging={true}
        enableSorteable={true}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handlePageChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={"No data found"}
        isDataLoading={loading}
      >
        <StyledTableBody>
          {determineStartArray().map((row, index) => {
            return (
              <TableRow hover key={index}>
                <StyledTableCell component="th" scope="row" style={{ width: "10px" }}>
                  <MoreOptions onClick={(e) => handleOpenMoreOptions(e, row)} color="black" />
                </StyledTableCell>
                <StyledTableCellClickable onClick={() => { setSelectedRow(row) }}>
                  {row?.id}
                </StyledTableCellClickable>

                <StyledTableCellClickable onClick={() => { setSelectedRow(row) }}>
                  {row?.requesterByEmail}
                </StyledTableCellClickable>

                <StyledTableCellClickable onClick={() => { setSelectedRow(row) }}>
                  {row?.sdsRequestType?.description}
                </StyledTableCellClickable>

                <StyledTableCellClickable onClick={() => { setSelectedRow(row) }}>
                  {convertDateFormat(row?.createdDate)}
                </StyledTableCellClickable>

                <StyledTableCellClickable onClick={() => { setSelectedRow(row) }}>
                  {convertDateFormat(row?.requestedCompletionDate)}
                </StyledTableCellClickable>

                <StyledTableCellClickable onClick={() => { setSelectedRow(row) }}>
                  {convertDateFormat(row?.lastAcceptableCompletionDate)}
                </StyledTableCellClickable>

                <StyledTableCellClickable onClick={() => { setSelectedRow(row) }}>
                  {row?.requestStatusName}
                </StyledTableCellClickable>

                <StyledTableCellClickable onClick={() => { setSelectedRow(row) }}>
                  {row?.project?.name}
                </StyledTableCellClickable>

                <StyledTableCellClickable onClick={() => { setSelectedRow(row) }}>
                  {row?.description}
                </StyledTableCellClickable>
              </TableRow>
            )
          })}
        </StyledTableBody>
      </UXDataTableWithoutBody>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl && moreOptionsSelected ? true : false}
        onClose={() => handleCloseMoreOptions(null)}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem
          key="mnuCancel"
          onClick={() => { updateRequestStatus(moreOptionsSelected) }}
          disabled={!canChangeStatus(moreOptionsSelected) || menuLoading}
        >
          Cancel {menuLoading && <span>. Please wait...</span>}
        </MenuItem>

        <MenuItem
          key="mnuSetDraf"
          onClick={() => { updateRequestStatus(moreOptionsSelected, requestStatusOptions.draft) }}
          disabled={!canChangeStatus(moreOptionsSelected) || menuLoading}
        >
          Set as Draft {menuLoading && <span>. Please wait...</span>}
        </MenuItem>

        <MenuItem
          key="mnuSetHold"
          onClick={() => { updateRequestStatus(moreOptionsSelected, requestStatusOptions.onHold) }}
          disabled={!canChangeStatus(moreOptionsSelected) || menuLoading}
        >
          Set as On Hold {menuLoading && <span>. Please wait...</span>}
        </MenuItem>

        <MenuItem
          key="mnuSetSubmitted"
          onClick={() => { updateRequestStatus(moreOptionsSelected, requestStatusOptions.submitted) }}
          disabled={!canChangeStatus(moreOptionsSelected) || menuLoading}
        >
          Set as Submitted {menuLoading && <span>. Please wait...</span>}
        </MenuItem>
      </Menu>

      <ModalSimpleButton
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}
      >
        <label style={{ whiteSpace: "break-spaces" }}>
          {modalMessageText}
        </label>
      </ModalSimpleButton>

      {selectedRequest &&
        <Card variant="elevation" style={{ padding: "1rem", marginTop: "2rem" }}>
          <SubmitSDS
            origin={viewFormOrigin.backlog}
            reloadList={reloadData}
            request={selectedRequest}
          />
        </Card>
      }

    </SDSRequestContextProvider>
  );

}