export const backlogViewType = {
  myRequests: 0,  
  allRequests: 1
}

export const viewFormOrigin = {
  newRequest: 0,
  backlog: 1,
  process: 2
}
