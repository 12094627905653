import API from "..";

export default class SpecialAnalysisStatus {

  constructor({
    statusName,
    isActive,
    displayOrder
  }) 
    
  {
    this.statusName = statusName;
    this.isActive = isActive;
    this.displayOrder = displayOrder
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/specialAnalysisStatus`);
    if (data.result) {
        return data.result.map((d) => new SpecialAnalysisStatus(d));
    }
    else {
        return [];
    }
  }
}
