import { useState, useEffect } from "react";
import MoreOptions from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon } from "@mui/material";
import { Star, StarBorder } from "@mui/icons-material";
import { UXDataTableWithoutBody,
     StyledTableBody,
     StyledTableCell, 
     StyledTableRow } from "../../../components/UXDataTable";
import { applyFiltersToArray } from '../../../global';

const Columns = [
  { id: 'recipientName', label: 'Recipient Name' },
  { id: 'emailAddress', label: 'Recipient\'s E-mail' },
  { id: 'phoneNumber1', label: 'Phone' },
  { id: 'country', label: 'Country' },
  { id: 'postalCode', label: 'Zip Code' },
  { id: 'state', label: 'State' },
  { id: 'city', label: 'City' },
  { id: 'address1', label: 'Street Address' },
  { id: 'company', label: 'Company' },
  { id: 'department', label: 'Department/ Floor No.' }
];

const AddressTable = ({ 
  listAddress = [],
  isDataLoading, 
  selectedAddress, 
  setSelectedAddress,
  canAddToFavorites = false,
  addToFavorites, 
  removeFromFavorites, 
  filters 
  }
) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = useState(null);

  const [filteredTableData, setFilteredTableData] = useState([])

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, listAddress)
    setFilteredTableData(filteredArray)
  }, [filters, listAddress])

  useEffect(() => {
    setPage(0);
  }, [filters])

 const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
    return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
  }

  const handleClick = (event, row) => {
    setSelectedAddress(row);
  };

  function handleOpenMoreOptions(e, address) {
    setMoreOptionsSelected(address);

    setAnchorEl(e.currentTarget);
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null);

    setAnchorEl(null);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <UXDataTableWithoutBody
        tableWidth='100%'
        cols={Columns}
        blankFirstHeader={true}
        tableId={`addressBookTable`}
        rowLength={filteredTableData.length}
        enablePaging={true}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={'No Addresses Found'}
        enableSorteable={true}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        isDataLoading={isDataLoading}
      >
        <StyledTableBody key={`AddressBookTable`}>

          {stableSort(filteredTableData, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((address, index) => {

              const isItemSelected = address.id === selectedAddress?.id;
              return (
                <StyledTableRow
                  key={`AddressBookTable~Row${index}`}
                  hover
                  onClick={(event) => handleClick(event, address)}
                  selected={isItemSelected}
                  style={isItemSelected ? { backgroundColor: 'rgba(25, 118, 210, 0.08)' } : {}}
                >
                  <StyledTableCell style={{ width: "10px" }} component='th' scope='row'>
                    <MoreOptions
                      onClick={(e) => {
                        handleClick(e, address);
                        handleOpenMoreOptions(e, address);
                      }}
                    color="black"
                    />
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {address?.recipientName}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {address?.emailAddress}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {address?.phoneNumber1}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {address?.country}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {address?.postalCode}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {address?.state}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {address?.city}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {address?.address1}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {address?.company}
                  </StyledTableCell>
                  <StyledTableCell component='th' scope='row'>
                    {address?.department}
                  </StyledTableCell>
                </StyledTableRow>
              );
            }
          )}
        </StyledTableBody>
      </UXDataTableWithoutBody>
      <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={anchorEl && moreOptionsSelected ? true : false}
          onClose={() => handleCloseMoreOptions()}
          MenuListProps={{
          "aria-labelledby": "basic-button",
          }}
      >
        { addToFavorites &&
        <MenuItem
          onClick={() => {
            addToFavorites();
            handleCloseMoreOptions();
          }}
          disabled={!canAddToFavorites}
        >
          <ListItemIcon>
            <Star></Star>
          </ListItemIcon>
          Add to Favorites
        </MenuItem>
        }
        { removeFromFavorites &&
        <MenuItem
          onClick={() => {
            removeFromFavorites();
            handleCloseMoreOptions();
          }}
        >
          <ListItemIcon>
            <StarBorder></StarBorder>
          </ListItemIcon>
          Remove from Favorites
        </MenuItem>
        }
      </Menu>

    </div>
  );
};

export default AddressTable;
