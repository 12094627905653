import API from "../index";
import type {
  STRequestProcessResult as STRequestProcessResultType,
  STProcessComponent,
} from "../../pages/StationaryTesting/types";

export default class STRequestProcessResult {
  static async getByStRequestId(requestId: number): Promise<STRequestProcessResultType[]> {
    const api = await API();
    if (api) {
      const { data } = await api.get<{
        result: STRequestProcessResultType[] | null;
        message: string;
      }>(`/strequestprocessresult/strequest/${requestId}`);

      if (data.message === "Success" && data.result) {
        return data.result;
      }

      return [];
    }

    return [];
  }

  static async getActiveStProcessComponentNames(): Promise<string[]> {
    const api = await API();
    if (api) {
      const { data } = await api.get<{ result: STProcessComponent[] | null; message: string }>(
        "/stprocesscomponent",
      );

      if (data.message === "Success" && data.result) {
        const actives = data.result.filter((r) => r.isActive);

        return actives.map((a) => a.processComponentName);
      }

      return [];
    }

    return [];
  }
}
