import API from "..";
import ShippingAddress from "./ShippingAddress";
import { checkAPIResponse } from "../helpers";

export default class UserShippingAddress {
 
  static async getByUser(userEmail) {
    const api = await API();
    const response = await api.get(`/userShippingAddress/${userEmail}`);
    checkAPIResponse(response);
    const result = response.data.result;
    return result ? result.map((d) => new ShippingAddress(d)) : [];
  }

  static async addByUser(userEmail, shippingAddressIDs) {
    const api = await API();
    const response = await api.post(`/userShippingAddress/${userEmail}`, shippingAddressIDs);
    checkAPIResponse(response);
    return response.data;
  }

  static async deleteByUser(userEmail, shippingAddressIDs) {
    const api = await API();
    const response = await api.post(`/userShippingAddress/${userEmail}/delete`, shippingAddressIDs);
    checkAPIResponse(response);
    return response.data;
  }
  
}
