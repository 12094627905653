interface ResultContainer {
  resultValue?: number;
  resultText?: string;
}

interface SynchronizedResult {
  resultValue: number | undefined;
  resultText: string | undefined;
}

export const synchronizeResultValues = <T extends ResultContainer>(v: T): SynchronizedResult => {
  const resultValue =
    v.resultValue !== undefined && v.resultValue !== null
      ? v.resultValue
      : v.resultText
      ? parseFloat(v.resultText)
      : undefined;

  const resultText =
    v.resultText ||
    (v.resultValue !== undefined && v.resultValue !== null ? v.resultValue.toString() : undefined);

  return {
    resultValue: isNaN(resultValue as number) ? undefined : resultValue,
    resultText,
  };
};

export const deriveResultValue = <T extends ResultContainer>(v: T): number | undefined => {
  const resultValue = v.resultText ? parseFloat(v.resultText) : undefined;

  return isNaN(resultValue as number) ? undefined : resultValue;
};
