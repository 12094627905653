import { array, number, object, string } from "yup";
import type {
  ContainerType,
  Facility,
  FuelSamples,
  InformationForProcessRequest,
  Interval,
  IntervalToRegister,
  LubricantSamples,
  RetainContainer,
  STIntervalProcessResult,
  STRequestProcessResult,
  StRequestIntervalsToSampleRegistration,
  StationaryTest,
  TestStand,
  UoM,
} from "./types";
import { generateUniqueID } from "../../global";

export const createRequestMenuItems = [
  {
    menuType: "link-viewExperiment",
    redirectPath: "/newStationaryRequest",
    text: "View Request",
    onClickFunction: null,
  },
];

export const FILTER_INITIAL_STATE = [
  {
    name: "",
    displayName: "",
    operator: "",
    enumValues: [],
    value: "",
  },
];

export const filterOptions = [
  { name: "id", displayName: "ID", type: "integer", enumValues: [] },
  {
    name: "cardNumber",
    displayName: "Card Number",
    type: "integer",
    enumValues: [],
  },
  {
    name: "testDisplayName",
    displayName: "Test Name",
    type: "string",
    enumValues: [],
  },
  {
    name: "runNumber",
    displayName: "Run Number",
    type: "string",
    enumValues: [],
  },
  {
    name: "submittedSampleName",
    displayName: "MIDAS Number",
    type: "midasNumber",
    enumValues: [],
  },
  {
    name: "submittedContainerNumber",
    displayName: "Container Number",
    type: "string",
    enumValues: [],
  },
  {
    name: "requestedByEmail",
    displayName: "Requester",
    type: "string",
    enumValues: [],
  },
  {
    name: "stationaryTestFacilityName",
    displayName: "Test Facility",
    type: "string",
    enumValues: [],
  },
];

export const NOTIFICATION_INITIAL_VALUES = {
  notificationContent: "",
};

export const notificationSchema = object({
  notificationContent: string()
    .required("Notification message is required")
    .test(
      "empty-check",
      "Notification must be at least 1 character",
      (message) => message.length > 0,
    ),
});

export const OFICIAL_REGISTERED_ENUM = {
  Yes: "Yes",
  No: "No",
  NA: "N/A",
};

export const TEST_CLASSIFICATION_ENUM = {
  Lubricant: "Lubricant",
  Fuel: "Fuels",
};

export const lubricantSampleSchema = object({
  sampleName: string(),
  containerId: string(),
  containerNumber: number(),
  unusedReturnLocation: string().nullable(),
  amountUsed: number().nullable(),
  amountUsedUoMName: string().nullable(),
  usedReturnLocation: string().nullable(),
  viscosityGrade: string().nullable(),
});

export const LUBRICANT_SAMPLE_INITIAL_VALUES: LubricantSamples = {
  sampleName: "",
  containerNumber: undefined,
  unusedReturnLocation: "DISCARD",
  amountUsed: undefined,
  amountUsedUoMName: "",
  usedReturnLocation: "DISCARD",
  containerId: "",
  viscosityGrade: "",
};

export const fuelSampleSchema = object({
  sampleName: string(),
  containerId: string(),
  containerNumber: number(),
  returnLocationName: string().nullable(),
  amountUsed: number().nullable(),
  amountUsedUoMName: string().nullable(),
});

export const FUEL_SAMPLE_INITIAL_VALUES: FuelSamples = {
  sampleName: "",
  containerNumber: undefined,
  returnLocationName: "DISCARD",
  amountUsed: undefined,
  amountUsedUoMName: "",
  containerId: "",
};

export const TEST_SELECTION_INITIAL_VALUES = {
  requestParameters: [],
  procedureTestTestDisplayName: "",
  testClassificationType: "",
  procedureLabel: "",
  testLabel: "",
  leadEngineerEmail: "",
  backupEngineerEmails: "",
  workInstructionName: "N/A",
  parameterColumns: [],
  procedureTests: [],
  testDescription: "",
  minimumSampleSizeUoMName: "N/A",
  minimumSampleSize: "N/A",
  showViscosity: false,
  availableTestStands: [],
};

export const STATIONARY_TESTING_INITIAL_VALUES: Partial<StationaryTest> = {
  id: 0,
  lubricantSamples: [],
  fuelSamples: [],
  testSelection: TEST_SELECTION_INITIAL_VALUES,
  requestedByEmail: "",
  billingReference: "",
  submittedSampleName: "",
  reportAlias: "",
  accOilCode: "",
  formulationCode: "",
  submitterComments: "",
  safetyAlert: false,
  isISO9001: false,
  officialRegistered: OFICIAL_REGISTERED_ENUM.NA,
  testTypeName: TEST_CLASSIFICATION_ENUM.Lubricant,
  sampleExpectedUse: "",
  sampleExpectedUseUoMName: "",
  sampleUsedReturnLocationName: "DISCARD",
  sampleUnusedReturnLocationName: "DISCARD",
  sampleViscosityGrade: "",
  isCandidate: true,
  testStandName: "",
  isLocked: false,
  deletedFileAttachmentNames: [],
  fileAttachments: [],
};

export const stRequestSelectTestSchema = object({
  testClassificationType: string().required(),
  procedureNumber: string().required(),
});

export const stationaryTestingSchema = object({
  requestedByEmail: string().required("Required").email(),
  billingReference: string()
    .required()
    .test("empty-check", "Billing reference must be at least 1 character", (b) => b.length > 0),
  lubricantSamples: array(lubricantSampleSchema),
});

export const ADDITIONAL_SAMPLE_NAME_ENUM = {
  Lubricant: "Lubricant",
  Fuel: "Fuel",
};

export const PROCEDURE_INITIAL_STATE = {
  procedureNumber: "",
  procedureName: "",
  isActive: true,
  createdByEmail: "",
  createdDate: new Date(),
  lastModifiedByEmail: "",
  lastModifiedDate: new Date(),
  workInstructionName: "",
  description: "",
  classificationName: "",
  procedureClassification: null,
  intervalResultOptionName: "",
  resultOption: null,
  allowMultipleLubricants: false,
  requireFuelSample: false,
  showViscosity: false,
  allowMultipleFuelSamples: false,
  allowTestPreparationStep: false,
  availableEngineers: [],
  procedureTests: [],
};

export const TEST_INITIAL_STATE = {
  testDisplayName: "",
  procedureNumber: "",
  procedure: null,
  derivativeNumber: 0,
  isModified: false,
  modificationNumber: 0,
  testDescription: "",
  userMessage: "",
  minimumSampleSize: "",
  minimumSampleSizeUoMName: "g",
  minimumSampleSizeUoM: null,
  isActive: true,
  testName: "",
  testIntervals: [],
  availableFacilities: [],
  availableTestStands: [],
  availableParameters: [],
};

export function getContainerNumberByProps(props: any) {
  const existInitialProps =
    props && props.location && props.location.state && props.location.state.stationaryTesting;

  return existInitialProps ? props.location.state.stationaryTesting.submittedContainerNumber : "";
}

export function getRequestInitialValue(props: any) {
  let initialValues: any = STATIONARY_TESTING_INITIAL_VALUES;

  const existInitialProps =
    props && props.location && props.location.state && props.location.state.stationaryTesting;

  if (existInitialProps) {
    initialValues = {
      ...initialValues,
      id: props.location.state.stationaryTesting.id,
      accOilCode: props.location.state.stationaryTesting.accOilCode ?? "",
      billingReference: props.location.state.stationaryTesting.billingReference ?? "",
      formulationCode: props.location.state.stationaryTesting.formulationCode ?? "",
      isISO9001: props.location.state.stationaryTesting.isISO9001 ?? false,
      requestedByEmail: props.location.state.stationaryTesting.requestedByEmail ?? "",
      safetyAlert: props.location.state.stationaryTesting.safetyAlert ?? false,
      submittedContainerNumber:
        props.location.state.stationaryTesting.submittedContainerNumber ?? "",
      submittedSampleName: props.location.state.stationaryTesting.submittedSampleName ?? "",
      submitterComments: props.location.state.stationaryTesting.submitterComments ?? "",
      testDisplayName: props.location.state.stationaryTesting.testDisplayName ?? "",
      testTypeName: props.location.state.stationaryTesting.testTypeName ?? "",
      lubricantSamples: getLubricantInitialSamples(props.location.state.stationaryTesting),
      fuelSamples: getFuelInitialSamples(props.location.state.stationaryTesting),
      testSelection: getTestSelectionInitialValue(props.location.state.stationaryTesting),
    };
  }

  return initialValues;
}

function getTestSelectionInitialValue(initialValues: any) {
  const testSelection = { ...TEST_SELECTION_INITIAL_VALUES };

  if (initialValues && initialValues.procedureLabel) {
    testSelection.procedureLabel = initialValues.procedureLabel;
  }

  if (initialValues && initialValues.testDisplayName) {
    testSelection.procedureTestTestDisplayName = initialValues.testDisplayName;
  }

  if (initialValues && initialValues.testClassificationType) {
    testSelection.testClassificationType = initialValues.testClassificationType;
  }

  if (initialValues && initialValues.leadEngineerEmail) {
    testSelection.leadEngineerEmail = initialValues.leadEngineerEmail;
  }

  if (initialValues && initialValues.backupEngineerEmails) {
    testSelection.backupEngineerEmails = initialValues.backupEngineerEmails;
  }

  if (initialValues && initialValues.workInstructionName) {
    testSelection.workInstructionName = initialValues.workInstructionName;
  }

  if (initialValues && initialValues.testDescription) {
    testSelection.testDescription = initialValues.testDescription;
  }

  if (initialValues && initialValues.minimumSampleSizeUoMName) {
    testSelection.minimumSampleSizeUoMName = initialValues.minimumSampleSizeUoMName;
  }

  if (initialValues && initialValues.minimumSampleSize) {
    testSelection.minimumSampleSize = initialValues.minimumSampleSize;
  }

  if (initialValues && initialValues.procedureTests) {
    testSelection.procedureTests = initialValues.procedureTests;
  }

  return testSelection;
}

function getLubricantInitialSamples(stationaryTesting: any) {
  const lubricantSamples: any = [];

  if (stationaryTesting.requestLubricants && stationaryTesting.requestLubricants.length > 0) {
    stationaryTesting.requestLubricants.forEach((rl: any) => {
      const lubricantSample = {
        sampleName: rl.sampleName ?? "",
        containerNumber: rl.containerNumber ?? "",
        unusedReturnLocation: rl.unusedReturnLocation ?? "DISCARD",
        amountUsed: rl.amountUsed ?? "",
        amountUsedUoMName: rl.amountUsed ?? "",
        usedReturnLocation: rl.usedReturnLocation ?? "DISCARD",
        containerId: rl.containerId ?? "",
      };

      lubricantSamples.push(lubricantSample);
    });
  }

  return lubricantSamples;
}

function getFuelInitialSamples(stationaryTesting: any) {
  const fuelSamples: any = [];

  if (stationaryTesting.requestFuels && stationaryTesting.requestFuels.length > 0) {
    stationaryTesting.requestFuels.forEach((rf: any) => {
      const fuelSample = {
        sampleName: rf.sampleName ?? "", // mising?
        containerNumber: rf.containerNumber ?? "", // missing?
        returnLocationName: rf.returnLocationName ?? "DISCARD",
        amountUsed: rf.amountUsed ?? "",
        amountUsedUoMName: rf.amountUsedUoMName ?? "",
        containerId: rf.containerId ?? "",
      };

      fuelSamples.push(fuelSample);
    });
  }

  return fuelSamples;
}

// TODO: Move to API
export function getParameterColumnsByProcedure(procedure: any) {
  const parameterColumns = ["Test #"];

  if (procedure && procedure.procedureTests && procedure.procedureTests.length > 0) {
    procedure.procedureTests.forEach((pt: any) => {
      if (pt.availableParameters && pt.availableParameters.length > 0) {
        pt.availableParameters.forEach((ptAv: any) => {
          const alreadyAddedColumn = parameterColumns.find((pc) => pc === ptAv.testParameterName);

          if (!alreadyAddedColumn) {
            parameterColumns.push(ptAv.testParameterName);
          }
        });
      }
    });
  }

  return parameterColumns;
}

export function getLabelForProcedureByNumberAndName(
  procedureNumber: number,
  procedureName: string,
) {
  return `${procedureNumber}: ${procedureName}`;
}

export enum REQUEST_INFORMATION_ENUM {
  GENERAL = "General",
  PROCEDURE_INFORMATION = "Procedure Information",
  TEST_SPECIFICATIONS = "Testing Specifications",
  AUTHORIZATION = "Authorization/Validation",
  STATIONARY_TEST_SAMPLE = "Stationary Test Sample",
  STATIONARY_TEST_LUBE_SAMPLES = "Stationary Test Lube Samples",
  STATIONARY_TEST_FUEL_SAMPLES = "Stationary Test Fuel Samples",
  SAMPLE_SCHEDULE = "Sample Schedule",
  EXPERIMENT_PROCESS_DETAILS = "Experiment Process Details",
}

export const ST_STATUS_ENUM = {
  Cancelled: "Cancelled",
  Completed: "Completed",
};

export const REQUEST_INFORMATION_OPTIONS: {
  label: REQUEST_INFORMATION_ENUM;
}[] = [
  {
    label: REQUEST_INFORMATION_ENUM.GENERAL,
  },
  {
    label: REQUEST_INFORMATION_ENUM.PROCEDURE_INFORMATION,
  },
  {
    label: REQUEST_INFORMATION_ENUM.TEST_SPECIFICATIONS,
  },
  {
    label: REQUEST_INFORMATION_ENUM.AUTHORIZATION,
  },
  {
    label: REQUEST_INFORMATION_ENUM.STATIONARY_TEST_SAMPLE,
  },
  {
    label: REQUEST_INFORMATION_ENUM.STATIONARY_TEST_LUBE_SAMPLES,
  },
  {
    label: REQUEST_INFORMATION_ENUM.STATIONARY_TEST_FUEL_SAMPLES,
  },
  {
    label: REQUEST_INFORMATION_ENUM.SAMPLE_SCHEDULE,
  },
  {
    label: REQUEST_INFORMATION_ENUM.EXPERIMENT_PROCESS_DETAILS,
  },
];

export const TEST_PARAMETER_INITIAL_VALUES = {
  testParameterName: "",
  parameterValue: "",
};

export const TEST_STAND_INITIAL_VALUES: TestStand = {
  standName: "",
};

export const FACILITY_INITIAL_VALUES: Partial<Facility> = {
  stationaryTestFacilityName: "",
};

export const testParameterSchema = object({
  testParameterName: string().required(),
  parameterValue: string().required(),
});

export const testStandSchema = object({
  standName: string().required(),
});

export const facilitySchema = object({
  stationaryTestFacilityName: string().required(),
});

export const CONTAINERING_METHOD_ENUM = {
  MIDAS: "midas",
  NON_SPLIT: "nonsplit",
};

export const INTERVAL_INITIAL_STATE: Interval = {
  intervalName: "",
  testJSON: "",
  retainContainerJSON: "",
  chemID: "",
  initialLocationName: "",
  returnLocationName: "DISCARD",
  intervalSample: null,
  psimsId: "",
  containeringMethod: CONTAINERING_METHOD_ENUM.MIDAS,
};

export const RETAIN_CONTAINER_INITIAL_STATE: RetainContainer = {
  numberOfContainers: "1",
  containerSize: "1",
  sizeUoM: "g",
  retainAmount: "1",
  retainUoM: "g",
  containerType: "Unknown",
  storageLocation: "DISCARD",
};

function getValueIfNotNullOrEmpty(value: string | Date | number | undefined) {
  if (value && value !== "") return value;

  return false;
}

export function getPayloadForSaveAction(values: Partial<StationaryTest>): Partial<StationaryTest> {
  const payload: Partial<StationaryTest> = { id: values.id };

  if (getValueIfNotNullOrEmpty(values.testEngineerEmail)) {
    payload.testEngineerEmail = values.testEngineerEmail;
  }

  if (getValueIfNotNullOrEmpty(values.testStandName)) {
    payload.testStandName = values.testStandName;
  }

  if (getValueIfNotNullOrEmpty(values.calibrationExpiration)) {
    payload.calibrationExpiration = values.calibrationExpiration;
  }

  if (getValueIfNotNullOrEmpty(values.testStartedByEmail)) {
    payload.testStartedByEmail = values.testStartedByEmail;
  }

  if (getValueIfNotNullOrEmpty(values.testStartDate)) {
    payload.testStartDate = values.testStartDate;
  }

  if (getValueIfNotNullOrEmpty(values.cellCalibrationExpiration)) {
    payload.cellCalibrationExpiration = values.cellCalibrationExpiration;
  }

  if (getValueIfNotNullOrEmpty(values.testEndedByEmail)) {
    payload.testEndedByEmail = values.testEndedByEmail;
  }

  if (getValueIfNotNullOrEmpty(values.testEngineerEmail)) {
    payload.testEngineerEmail = values.testEngineerEmail;
  }

  if (getValueIfNotNullOrEmpty(values.testEndDate)) {
    payload.testEndDate = values.testEndDate;
  }

  if (getValueIfNotNullOrEmpty(values.supportInformation)) {
    payload.supportInformation = values.supportInformation;
  }

  if (getValueIfNotNullOrEmpty(values.sequenceNumber)) {
    payload.sequenceNumber = values.sequenceNumber;
  }

  if (getValueIfNotNullOrEmpty(values.validationStatusName)) {
    payload.validationStatusName = values.validationStatusName;
  }

  if (getValueIfNotNullOrEmpty(values.validationDate)) {
    payload.validationDate = values.validationDate;
  }

  if (getValueIfNotNullOrEmpty(values.validatedByEmail)) {
    payload.validatedByEmail = values.validatedByEmail;
  }

  if (getValueIfNotNullOrEmpty(values.eotCellHourmeter)) {
    payload.eotCellHourmeter = values.eotCellHourmeter;
  }

  if (getValueIfNotNullOrEmpty(values.validationComments)) {
    payload.validationComments = values.validationComments;
  }

  if (getValueIfNotNullOrEmpty(values.ionNumber)) {
    payload.ionNumber = values.ionNumber;
  }

  if (getValueIfNotNullOrEmpty(values.fuelType)) {
    payload.fuelType = values.fuelType;
  }

  if (getValueIfNotNullOrEmpty(values.fuelTankLine)) {
    payload.fuelTankLine = values.fuelTankLine;
  }

  if (getValueIfNotNullOrEmpty(values.fuelBatch)) {
    payload.fuelBatch = values.fuelBatch;
  }

  if (getValueIfNotNullOrEmpty(values.statusName)) {
    payload.statusName = values.statusName;
  }

  if (getValueIfNotNullOrEmpty(values.workGroupName)) {
    payload.workGroupName = values.workGroupName;
  }

  if (typeof values.isISO9001 !== "undefined") {
    payload.isISO9001 = values.isISO9001;
  }

  if (typeof values.safetyAlert !== "undefined") {
    payload.safetyAlert = values.safetyAlert;
  }

  if (typeof values.officialRegistered !== "undefined") {
    payload.officialRegistered = values.officialRegistered;
  }

  if (getValueIfNotNullOrEmpty(values.reportAlias)) {
    payload.reportAlias = values.reportAlias;
  }

  if (getValueIfNotNullOrEmpty(values.accOilCode)) {
    payload.accOilCode = values.accOilCode;
  }

  if (getValueIfNotNullOrEmpty(values.formulationCode)) {
    payload.formulationCode = values.formulationCode;
  }

  if (getValueIfNotNullOrEmpty(values.submitterComments)) {
    payload.submitterComments = values.submitterComments;
  }

  if (getValueIfNotNullOrEmpty(values.technicianComments)) {
    payload.technicianComments = values.technicianComments;
  }

  if (getValueIfNotNullOrEmpty(values.deletedFileAttachmentNames)) {
    payload.deletedFileAttachmentNames = values.deletedFileAttachmentNames;
  }

  payload.requestIntervals = values.requestIntervals;

  if (getValueIfNotNullOrEmpty(values.sampleScheduleType)) {
    payload.sampleScheduleType = values.sampleScheduleType;
  }

  if (getValueIfNotNullOrEmpty(values.billingReference)) {
    payload.billingReference = values.billingReference;
  }

  if (values.stRequestProcessResults && values.stRequestProcessResults.length > 0) {
    payload.stRequestProcessResults = values.stRequestProcessResults;
  }

  if (
    values.stIntervalsWithIntervalProcessResults &&
    values.stIntervalsWithIntervalProcessResults.length > 0
  ) {
    payload.stIntervalsWithIntervalProcessResults = values.stIntervalsWithIntervalProcessResults;
  }

  return payload;
}

export function getPayloadForRegisterIntervals(
  activeProcessRequest: StationaryTest,
  intervalSamplesToRegister: Interval[],
  requestIntervals: Interval[],
): StRequestIntervalsToSampleRegistration {
  const intervalsToRegister: IntervalToRegister[] = [];

  intervalSamplesToRegister.forEach((isr) => {
    const interval = requestIntervals.find((r) => {
      return r.id === isr.id;
    });

    if (interval) {
      intervalsToRegister.push({
        containeringMethod: interval.containeringMethod,
        intervalName: interval.intervalName,
        psimsId: interval.psimsId,
        containers: interval.intervalSample?.containers,
        sortOrder: interval.sortOrder,
      });
    }
  });

  const payload: StRequestIntervalsToSampleRegistration = {
    sTRequestId: activeProcessRequest.id,
    stRequestIntervals: requestIntervals ?? [],
    intervalsToRegister: intervalsToRegister,
  };

  return payload;
}

const AUTHORIZATION_VALIDATION_INITIAL_VALUES = {
  testEngineerEmail: "",
  calibrationExpiration: new Date(),
  testStartedByEmail: "",
  testStartDate: new Date(),
  cellCalibrationExpiration: new Date(),
  testEndedByEmail: "",
  testEndDate: new Date(),
  supportInformation: "",
  sequenceNumber: 0,
  validationStatusName: "",
  validationDate: new Date(),
  validatedByEmail: "",
  validationComments: "",
  fileAttachments: [],
};

export const PROCESS_REQUESTS_INITIAL_VALUES: Partial<StationaryTest> = {
  ...AUTHORIZATION_VALIDATION_INITIAL_VALUES,
};

export function areTwoIntervalsTheSame(firstInterval: Interval, secondInterval: Interval) {
  return firstInterval.id === secondInterval.id;
}
const localeDateTimezone = (date: Date | undefined) => {
  if (date) {
    return date.toString().endsWith("Z") ? new Date(date) : new Date(date + "Z");
  } else {
    return null;
  }
};

export function getFormikInitialValuesForProcessRequestByApiResponse(
  response: InformationForProcessRequest,
  stRequestProcessResults: STIntervalProcessResult[] | STRequestProcessResult[],
  intervalsWithIntervalProcessResults: any,
) {
  const reportAlias = response?.testSpecification?.reportAlias ?? "";
  const accOilCode = response?.testSpecification?.accOilCode ?? "";
  const formulationCode = response?.testSpecification?.formulationCode ?? "";
  const validationComments = response?.authorizationValidation?.validationComments ?? "";
  const testEngineerEmail = response?.authorizationValidation?.testEngineerEmail ?? "";
  const testStandName = response?.authorizationValidation?.testStandName ?? "";
  const supportInformation = response?.authorizationValidation?.supportInformation ?? "";
  const testStartedByEmail = response?.authorizationValidation?.testStartedByEmail ?? "";
  const sequenceNumber = response?.authorizationValidation?.sequenceNumber ?? "";
  const testEndedByEmail = response?.authorizationValidation?.testEndedByEmail ?? "";
  const eotCellHourmeter = response?.authorizationValidation?.eotCellHourmeter ?? "";
  const testEndDate = localeDateTimezone(response?.authorizationValidation?.testEndDate);
  const testStartDate = localeDateTimezone(response?.authorizationValidation?.testStartDate);
  const fuelType = response?.fuelType ?? "";
  const fuelTankLine = response?.fuelTankLine ?? "";
  const fuelBatch = response?.fuelBatch ?? "";
  const ionNumber = response?.ionNumber ?? "";
  const runNumber = response.generalInformation.runNumber ?? "";
  const cardNumber = response.generalInformation.cardNumber ?? "";
  const workGroupName = response.generalInformation.workGroupName ?? "";
  const containerReturnLocation = "DISCARD";

  const formikValues = {
    id: response.generalInformation.id,
    ...response,
    deletedFileAttachmentNames: [],
    fuelBatch: fuelBatch,
    fuelTankLine: fuelTankLine,
    fuelType: fuelType,
    ionNumber: ionNumber,
    isCandidate: response.isCandidate,
    sampleScheduleType: response.isCandidate ? "Candidate" : "Reference",
    ...response.authorizationValidation,
    ...response.testSpecification,
    reportAlias: reportAlias,
    accOilCode: accOilCode,
    formulationCode: formulationCode,
    validationComments: validationComments,
    testEngineerEmail: testEngineerEmail,
    testStandName: testStandName,
    supportInformation: supportInformation,
    testStartedByEmail: testStartedByEmail,
    sequenceNumber: sequenceNumber,
    testEndedByEmail: testEndedByEmail,
    eotCellHourmeter: eotCellHourmeter,
    testEndDate: testEndDate,
    testStartDate: testStartDate,
    runNumber: runNumber,
    cardNumber: cardNumber,
    workGroupName: workGroupName,
    intervalSamplesToRegister: [],
    containerReturnLocation,
    statusName: response.generalInformation.statusName,
    billingReference: response.generalInformation.billingReference,
    stRequestProcessResults: stRequestProcessResults ?? [],
    stIntervalsWithIntervalProcessResults: intervalsWithIntervalProcessResults ?? [],
  };
  return formikValues;
}

export const INITIAL_ERROR_CHECKS_FOR_CONTAINER = {
  containerType: null,
  containerStatus: false,
  size: null,
  currentAmount: null,
  uom: null,
  location: null,
  ownerEmail: null,
};

export function getContainersForValidation(
  locations: any[],
  containers: any[],
  ownerEmail: string | null,
) {
  const updatedContainers: any[] = [];
  containers.forEach((container) => {
    const location = locations.find((l) => l.locationName === container.locationName);

    updatedContainers.push({
      ...container,
      ownerEmail,
      _originalAmount: container.currentAmount,
      _originalUoM: container.uom,
      _isRetainContainer: false,
      location,
    });
  });

  return updatedContainers;
}

export function getRetainContainersForValidation(
  locations: any[],
  retainContainers: string | undefined,
  uoms: UoM[],
  containerTypes: ContainerType[],
  ownerEmail: string | null,
) {
  const updatedContainers: any[] = [];

  if (retainContainers) {
    const retainContainersParsed:
      | {
          containerSize: string;
          containerType: string;
          numberOfContainers: string;
          retainAmount: string;
          retainUoM: string;
          sizeUoM: string;
          storageLocation: string;
        }[]
      | undefined = JSON.parse(retainContainers);

    if (retainContainersParsed && retainContainersParsed.length > 0) {
      retainContainersParsed.forEach((rc) => {
        const location = locations.find((l) => l.locationName === rc.storageLocation);

        const uom = uoms.find((u) => u.uoMName === rc.sizeUoM);

        const containerType = containerTypes.find((c) => c.name === rc.containerType);

        if (rc.numberOfContainers && Number(rc.numberOfContainers) > 0) {
          const amountOfContainersToCreate = Number(rc.numberOfContainers);

          const numberOfContainers = Array(amountOfContainersToCreate).fill("");

          numberOfContainers.forEach(() => {
            updatedContainers.push({
              size: rc.containerSize,
              _originalAmount: rc.containerSize,
              currentAmount: rc.retainAmount,
              _isRetainContainer: true,
              uom,
              location,
              containerType,
              tests: [],
              containerStatusName: "Confirmed",
              containerStatus: {
                name: "Confirmed",
                isActive: true,
                displayColor: null,
                displayOrder: null,
              },
              ownerEmail,
            });
          });
        }
      });
    }
  }

  return updatedContainers;
}

export const REQUEST_PROCESS_RESULT_INITIAL_VALUES: STRequestProcessResult = {
  id: 0,
  sTRequestID: 0,
  processComponentName: "",
  resultUoMName: "",
  resultText: "",
};

export const stRequestProcessResultSchema = object({
  resultText: string().required("Value is required"),
  processComponentName: string()
    .required("Process component name is required")
    .max(200, "Exceed component name length")
    .test("empty-check", "Process component name must not be empty", (value) => value.length > 0),
});

export const INTERVAL_PROCESS_RESULT_INITIAL_VALUES: STIntervalProcessResult = {
  id: 0,
  sTRequestID: 0,
  processComponentName: "",
  resultUoMName: "",
  resultValue: "",
};

export const stIntervalProcessResultSchema = object({
  resultValue: string().required("Value is required"),
  processComponentName: string()
    .required("Process component name is required")
    .max(200, "Exceed component name length")
    .test("empty-check", "Process component name must not be empty", (value) => value.length > 0),
});

export function getUpdatedIntervalsByIntervalToBeCopied(
  intervals: Interval[],
  intervalToBeCopied: Interval,
) {
  const copiedInterval = {
    ...intervalToBeCopied,
    intervalName: `copy from ${intervalToBeCopied.intervalName}`,
    id: generateUniqueID(),
  };

  if (intervals && intervals.length > 0) {
    const foundIntervalIndex = intervals.findIndex((int) =>
      areTwoIntervalsTheSame(int, intervalToBeCopied),
    );

    if (foundIntervalIndex !== -1) {
      const updatedArray: Interval[] = [];

      intervals.forEach((inter, index) => {
        if (index !== foundIntervalIndex && index < foundIntervalIndex) {
          updatedArray.push(inter);
        } else if (index === foundIntervalIndex) {
          updatedArray.push(copiedInterval, { ...inter });
        } else {
          updatedArray.push({ ...inter });
        }
      });

      return updatedArray;
    }
  }

  return [];
}
