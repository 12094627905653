import { useState } from "react";
import ModalTwoButtons from '../../../../components/Modal/ModalTwoButtons'
import "./styles.css";
import { Box } from "@mui/system";
import { WorkRequestFormAutocomplete, WorkRequestFormInput } from "../WorkRequestFormInput";
import { isFlashPointValid } from "../../../../global";

const UpdateSampleSafetyInfo = ({
    isModalOpen,
    setIsModalOpen,
    setIsModalClose,
    shippingRequest,
    setShippingRequest,
    shippingItemToEdit,
    dotHazardClass,
    dotHazardClassLoading,
}) => {
    const [shippingItem, setShippingItem] = useState(shippingItemToEdit);
    const [errors, setErrors] = useState({
    });

    const commonProps = {
        setData: setShippingItem,
        data: shippingItem,
        setErrors,
        errors,
        margin: 'none',
        styled: {
            marginRight: "unset",
            marginBottom: "unset",
        }
    }

    function submit() {
        setShippingRequest({
            ...shippingRequest,
            shippingItems: shippingRequest?.shippingItems?.map(item => {
                if (item.sampleName === shippingItem.sampleName && shippingItem.containerID === item.containerID) {
                    return shippingItem;
                }
                return item;
            })
        })
        setIsModalClose();
    }

    return <ModalTwoButtons
        title="ShippingItem"
        button1Text={"Update"}
        button1Action={submit}
        isButton1Disabled={false}
        button2Text="Cancel"
        button2Action={() => {
            setIsModalClose();
        }}
        setOpen={setIsModalOpen}
        open={isModalOpen}
    >
        <form className="form-work-request-update-shipping-item ">
            <Box display="flex" alignItems="center" justifyContent="center" gap={1} width={"100%"}>
                <WorkRequestFormInput
                    {...commonProps}
                    label={'Blind Code Label Description'}
                    property="blindCodeDescription"
                />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" gap={1} width={"100%"}>
                <WorkRequestFormAutocomplete
                    {...commonProps}
                    options={dotHazardClass.map(dotHazard => ({ ...dotHazard, dotHazardClass: dotHazard.classNumber }))}
                    noOptionsText={
                        dotHazardClassLoading ? "Loading DOT Hazard Class..." : "No DOT Hazard Class Found"
                    }
                    property={'dotHazardClass'}
                    propertyLabel={'displayName'}
                    label="DOT Hazard Class"
                />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" gap={1} width={"100%"}>
                <WorkRequestFormInput
                    {...commonProps}
                    label={'Flash Point (F)'}
                    property="flashPointInC"
                    setData={(data) => {
                        const value = data.flashPointInC;
                        if (isFlashPointValid(value)) {
                            setShippingItem(data);
                        }
                    }}
                />
                <WorkRequestFormInput
                    {...commonProps}
                    type="number"
                    label={'Export Value'}
                    property="exportValue"
                />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" gap={1} width={"100%"}>
                <WorkRequestFormInput
                    {...commonProps}
                    label={'Description'}
                    property="description"
                />
            </Box>
        </form>
    </ModalTwoButtons>
}
export default UpdateSampleSafetyInfo
