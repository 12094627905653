import { useState, useEffect, useContext } from "react";
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell} from "../../components/UXDataTable";
import {TextField, TableRow, Autocomplete, Button, Switch, FormGroup, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import TestPriority from "../../api/LIMS/TestPriority";
import Method from "../../api/LIMS/Method";
import TrashIcon from '@mui/icons-material/Delete';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import UserContext from "../../context/UserContext";
import Template from "../../api/LIMS/Template";
import ModalMessages from "../../components/Modal/ModalSimpleButton"
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons"
import { isNumber, toCamelCase, DatePickerKeyDownEvent } from "../../global";
import VisibilityIcon from '@mui/icons-material/Visibility';
import LocationField from "../LocationField";

const containerGridFontSize = 12

const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontSize: containerGridFontSize,
    },
});

const Option = styled('li')({
    fontSize: containerGridFontSize,
});

export const defaultMethodObj =
{
    method: null,
    methodFacility: null,
    conditionOffering: null,
    testPriority:{testPriorityName:"Normal", isActive:true, priorityLevel:9},
    estimate: null,
    requestedCompletionDate: null,
    containerGrouping: null,
    externalLaboratory: null,
    requestedLocationName: null,
}

const conditionCols = [
    'Condition Name', 
    'Min Value', 
    'Max Value', 
    'Input Value', 
    'UoM', 
]

const conditionColsAlt = [
    'Condition Name', 
    'Input Value', 
    'UoM', 
]

const externalLabCols = [
    'Lab Name', 
    'Shipping Address', 
    'Lab Contact', 
    'On-Site Contact', 
    'Notes', 
    'Test Cost USD',
    'Required Size'    
]

const MethodSelection = ({ width, selectedMethods, setSelectedMethods, showCompletionDate, showContainerGrouping, incomingTemplate, isReadOnly = false, showSearchTemplateName = true, showEstimateAndPriority = true, maxMethodsAllowed = 999, disabledUserDefinedConditions = false, showFinalLocation = false}) => {

    const [testPriorities, setTestPriorities] = useState([])
    const [methods, setMethods] = useState([])

    const [availableMethodTemplates, setAvailableMethodTemplates] = useState([]) 
    const [isTemplatesLoading, setIsTemplatesLoading] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    
    const currentUser = useContext(UserContext)
    const [workingIndex, setWorkingIndex] = useState(null)

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const [modalInstructionsOpen, setModalInstructionsOpen] = useState(false)
    const [modalInstructionsButton1Text, setModalInstructionsButton1Text] = useState('')
    const [modalInstructionsButton2Text, setModalInstructionsButton2Text] = useState('')
    const [modalInstructionsTitle, setModalInstructionsTitle] = useState('')
    const [modalInstructionsText, setModalInstructionsText] = useState('')

    const [modalConditionsOpen, setModalConditionsOpen] = useState(false)
    const [modalConditionsButton1Text, setModalConditionsButton1Text] = useState('')
    const [modalConditionsButton2Text, setModalConditionsButton2Text] = useState('')
    const [modalConditionsTitle, setModalConditionsTitle] = useState('')
    const [modalConditionsText, setModalConditionsText] = useState('')

    const [modalExternalLabsOpen, setModalExternalLabsOpen] = useState(false)
    const [modalExternalLabsButton1Text, setModalExternalLabsButton1Text] = useState('')
    const [modalExternalLabsButton2Text, setModalExternalLabsButton2Text] = useState('')
    const [modalExternalLabsTitle, setModalExternalLabsTitle] = useState('')
    const [modalExternalLabsText, setModalExternalLabsText] = useState('')

    const [templateSaveName, setTemplateSaveName] = useState('')
    const [isTemplateSaving, setIsTemplateSaving] = useState(false)
    const [isPublicChecked, setIsPublicChecked] = useState(false)

    const [allowTemplateUpdates, setAllowTemplateUpdates] = useState(false)
    const [checkedLab, setCheckedLab] = useState([null]);

    const methodCols = [
        'Method', 
        'Method Description', 
        'Facility', 
        'Condition Offering', 
        showEstimateAndPriority && 'Estimate',
        showEstimateAndPriority && 'Priority', 
        showCompletionDate && 'Req. Completion Date', 
        showContainerGrouping && 'Test Group',
        showFinalLocation && 'Final Location',
    ].filter(Boolean)

    useEffect(() => {
        const copyCheckedLabs =  JSON.parse(JSON.stringify(checkedLab))

        selectedMethods.forEach((oMethod, index) => {
            copyCheckedLabs[index] = oMethod.externalLaboratory
        });

        setCheckedLab(copyCheckedLabs) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let cancelPromise = false
        setIsTemplatesLoading(true)
 
        Template.searchTemplates(currentUser.username, '', 'methods', 'true').then((res) => {
            if (cancelPromise) return
            setIsTemplatesLoading(false)
            setAvailableMethodTemplates(res)
       })

        TestPriority.getAllTestPriorities().then((res) => {
            if (cancelPromise) return
            setTestPriorities(res)
        })

        Method.getAll().then((res) => {
            if (cancelPromise) return
            setMethods(res.filter(result => result.isActive === true).sort((a, b) => a.name.localeCompare(b.name)))
        });
        
        return () => {
            cancelPromise = true
        }
    }, [currentUser.username]);

    useEffect(() => {
        if (incomingTemplate)
        {
            setSelectedTemplate(incomingTemplate)
            if (incomingTemplate.messages.length > 0)
            {
                openModalMessages("Template Errors", incomingTemplate.messages)
            }
        }
    }, [incomingTemplate]);

    useEffect(() => {
        if (selectedTemplate === null)
        {
            setTemplateSaveName('')
            setAllowTemplateUpdates(false)

        } else if (currentUser.username !== selectedTemplate.templateOwnerEmail) {
            setTemplateSaveName('')
            setAllowTemplateUpdates(false)

        }else {
            setTemplateSaveName(selectedTemplate.templateName)
            setAllowTemplateUpdates(true)
        }        
    }, [selectedTemplate, currentUser.username]);

    function closeModalInstructions() {
        setModalInstructionsOpen(false);
    }
    
    function openModalInstructions(title, text, buttonText, button2Text) {
        setModalInstructionsButton1Text(buttonText)
        setModalInstructionsButton2Text(button2Text)
        setModalInstructionsOpen(true);
        setModalInstructionsTitle(title);
        setModalInstructionsText(text);
    }

    function closeModalConditions() {
        setModalConditionsOpen(false);
    }
    
    function openModalConditions(title, text, buttonText, button2Text) {
        setModalConditionsButton1Text(buttonText)
        setModalConditionsButton2Text(button2Text)
        setModalConditionsOpen(true);
        setModalConditionsTitle(title);
        setModalConditionsText(text);
    }

    function closeModalMessages() {
        setModalMessagesOpen(false);
    }
    
    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    function closeModalExternalLabs() {
        setModalExternalLabsOpen(false);
    }
    
    function openModalExternalLabs(title, text, buttonText, button2Text) {
        setModalExternalLabsButton1Text(buttonText)
        setModalExternalLabsButton2Text(button2Text)
        setModalExternalLabsOpen(true);
        setModalExternalLabsTitle(title);
        setModalExternalLabsText(text);
    }

    function HandleTemplateSelection(value) {
        setSelectedTemplate(value)

        if (value === null)
        {
            return
        }

        let myTemplateObj = toCamelCase(JSON.parse(value.templateJSON))   

        const copyTemplateObj = myTemplateObj

        if (value.messages.length > 0)
        {
            openModalMessages("Template Errors", value.messages)
            return
        }
  
        myTemplateObj.forEach(async (oMethod, index) => {

            await Method.get(oMethod.method.name).then((res) => {
                if (res)
                {
                    copyTemplateObj[index].method = res
                }
            });

            if(index === myTemplateObj.length - 1)
            {
                setSelectedMethods(copyTemplateObj)
            }
        })
    };
  
    const DeleteMethodRow = (index) => {
        let copyMethods = JSON.parse(JSON.stringify(selectedMethods))
        const copyCheckedLabs =  JSON.parse(JSON.stringify(checkedLab))
  
        copyMethods.splice(index, 1)
        copyCheckedLabs.splice(index, 1)
  
        setSelectedMethods(copyMethods)
        setCheckedLab(copyCheckedLabs) 
    } 
 
    const AddMethodKeyPress = (e) => {
        let copyMethods = [];
        if (selectedMethods) 
        {
            copyMethods = JSON.parse(JSON.stringify(selectedMethods))
        }
        const copyCheckedLabs =  JSON.parse(JSON.stringify(checkedLab))

        copyMethods.push(defaultMethodObj)
        copyCheckedLabs.push(null)

        setSelectedMethods(copyMethods);
        setCheckedLab(copyCheckedLabs)  
    };

    async function UpdateProperty(value, index, propertyName)
    {
        const copyMethods =  JSON.parse(JSON.stringify(selectedMethods))

        copyMethods[index][propertyName] = value
  
        if (propertyName === 'method')
        {
            if (value && value.isPackageTest === true)
            {
                var response = await Method.getPackageTestValidationStatus(value.name)

                if (response.message === 'Success')
                {
                    if (response.result.length > 0)
                    {
                        openModalMessages(`Error Validating Package ${value.name}`, response.result)        
                        return
                    }
                } else {
                    openModalMessages("Error Validating Package Test", `There was an trying to validate the package test ${value.name}. ${response.message ? response.message : ''}`)        
                    return
                }
            }

            copyMethods[index].methodFacility = null 
            copyMethods[index].conditionOffering = null
            copyMethods[index].estimate = ""

            const copyCheckedLabs =  JSON.parse(JSON.stringify(checkedLab))
            copyCheckedLabs[index] = null
            setCheckedLab(copyCheckedLabs)  
        }

        if (propertyName === 'methodFacility')
        {
            copyMethods[index].conditionOffering = null       
            copyMethods[index].estimate = ""

            const copyCheckedLabs =  JSON.parse(JSON.stringify(checkedLab))
            copyCheckedLabs[index] = null
            setCheckedLab(copyCheckedLabs)  

            
            if (value && value.messageForSubmitter !== null && value.messageForSubmitter.trim() !== '' && showEstimateAndPriority === true)
            {
                openModalMessages("Method Facility Information", value.messageForSubmitter, "Ok")
            }

            if (value && value.testFacilityAbv === 'EXT')
            {
                openModalExternalLabs("External Lab Selection", "Please select from the list of approved external facilities.", "OK", "Cancel")
                setWorkingIndex(index)
            }
        }

        if (propertyName === 'conditionOffering')
        {
            if (value)
            { 
                if (value.testConditionSetupValues.some(tcsv => tcsv.minimumValue !== null))     
                {
                    setWorkingIndex(index)
                    openModalConditions("User Defined Parameters", "Please enter values within the min/max parameters for these user defined test parameters.", "Save", "Cancel")
                }          
            }
        }

        setSelectedMethods(copyMethods)
    }

    function UpdateTestTemplate () {
        if (templateSaveName.trim() === '')
        {
            openModalMessages("Invalid Template Name", "You must enter a template name to continue")
            return
        }

        if (selectedTemplate !== null && currentUser.username !== selectedTemplate.templateOwnerEmail)
        {
            openModalMessages("Template Error", "You cannot update a template from another user!")
            return
        }

        if (isTemplateSaving)
        { 
            return
        } else {
            setIsTemplateSaving(true)
        }

        let copySelectedMethods  = JSON.parse(JSON.stringify(selectedMethods))

        //don't save the requested completion date
        for(var i = 0; i < copySelectedMethods.length; i++)
        {
            copySelectedMethods[i].requestedCompletionDate = null
        }

        let newTemplate = new Template({
            templateName: templateSaveName,
            templateJSON: JSON.stringify(copySelectedMethods),
            templateType: "methods",
            templateOwnerEmail: currentUser.username,
            isPublic: selectedTemplate ? selectedTemplate.isPublicChecked : isPublicChecked,
            createdDate: null, 
            lastModifiedDate: null
        });

        Template.update(newTemplate).then((res) => {
            if (res.result) {
                setModalInstructionsOpen(false)
                setTemplateSaveName('')
                openModalMessages('Template Updated Successfully', `Template ${templateSaveName} has been updated!`);

                setIsTemplatesLoading(true)
                Template.searchTemplates(currentUser.username, '', 'methods', 'true').then((res) => {
                setIsTemplatesLoading(false)
                setAvailableMethodTemplates(res)
                })
            }else{
                openModalMessages('Template Failed to Update', `${res.message}. Contact support if you feel this is an error.`);
            }
            setIsTemplateSaving(false)
        })
    }
    
    const SaveNewTestTemplate = () => {
       
        if (templateSaveName.trim() === '')
        {
            openModalMessages("Invalid Template Name", "You must enter a template name to continue")
            return
        }

        if (isTemplateSaving)
        { 
            return
        } else {
            setIsTemplateSaving(true)
        }

        let copySelectedMethods  = JSON.parse(JSON.stringify(selectedMethods))

        //don't save the requested completion date
        for(var i = 0; i < copySelectedMethods.length; i++)
        {
            copySelectedMethods[i].requestedCompletionDate = null
        }

        let newTemplate = new Template({
            templateName: templateSaveName,
            templateJSON: JSON.stringify(copySelectedMethods),
            templateType: "methods",
            templateOwnerEmail: currentUser.username,
            isPublic: selectedTemplate ? selectedTemplate.isPublicChecked : isPublicChecked,
            createdDate: null, 
            lastModifiedDate: null
        });

        Template.add(newTemplate).then((res) => {
            if (res.result) {
                setModalInstructionsOpen(false)
                setTemplateSaveName('')
                openModalMessages('Template Saved Successfully', `Template ${templateSaveName} has been saved!`);

                setSelectedTemplate(res.result)
                setIsTemplatesLoading(true)
                Template.searchTemplates(currentUser.username, '', 'methods', 'true').then((res) => {
                setIsTemplatesLoading(false)
                setAvailableMethodTemplates(res)
                })
            }else{
                openModalMessages('Template Failed to Save', `${res.message ? res.message : ''}. Contact support if you feel this is an error.`);
            }
            setIsTemplateSaving(false) 
        })
    };

    function UpdateConditionDiscreteValues() {

        let errorMessages = []
        const copyMethods =  JSON.parse(JSON.stringify(selectedMethods))

        //check the method data for completness
        selectedMethods[workingIndex].conditionOffering.testConditionSetupValues.forEach((oTCSV, index) =>
        {
            if (oTCSV.discreteValue === null || oTCSV.discreteValue === '')
            {
              errorMessages.push(`You must enter a value for condition ${oTCSV.testConditionName}.`)
            } else {
                if (oTCSV.minimumValue !== null && oTCSV.maximumValue !== null)
                {
                    if (!(isNumber(oTCSV.discreteValue)))
                    {
                    errorMessages.push(`Invalid value for condition ${oTCSV.testConditionName}.`)
                    copyMethods[workingIndex].conditionOffering.testConditionSetupValues[index].discreteValue = null 

                    } else {
                        if(Number(oTCSV.discreteValue) > Number(oTCSV.maximumValue) || Number(oTCSV.discreteValue) < Number(oTCSV.minimumValue))                    
                        {
                            errorMessages.push(`Value for condition ${oTCSV.testConditionName} is outside of the min/max values.`)
                            copyMethods[workingIndex].conditionOffering.testConditionSetupValues[index].discreteValue = null 
                        }
                    }
                }
            }  
        })
  
        if (errorMessages.length === 0)
        {
            closeModalConditions()         
        } else {
            setSelectedMethods(copyMethods)
            openModalMessages("Method Selection Errors", errorMessages, "Ok")
        }
    }

    function UpdateDiscreteTCSVProperty(value, index)
    {
        const copyMethods =  JSON.parse(JSON.stringify(selectedMethods))

        copyMethods[workingIndex].conditionOffering.testConditionSetupValues[index].discreteValue = value

        setSelectedMethods(copyMethods)
    }

    function UpdateExternalLaboratory()
    {
        if (checkedLab[workingIndex] === null)
        {
            openModalMessages("Invalid Selection", "You must select an external lab or cancel", "OK")
            return
        }

        const copyMethods =  JSON.parse(JSON.stringify(selectedMethods))

        copyMethods[workingIndex].externalLaboratory = checkedLab[workingIndex]

        setSelectedMethods(copyMethods)
        closeModalExternalLabs()
    }

    function updateCheckedLab(value)
    {
        const copyCheckedLabs =  JSON.parse(JSON.stringify(checkedLab))

        copyCheckedLabs[workingIndex] = value

        setCheckedLab(copyCheckedLabs)        
    }

    function IsAddIconEnabled ()
    {
        if (isReadOnly === true)
        {
            return false
        } else if (selectedMethods && selectedMethods.length >= maxMethodsAllowed)
        {
            return false
        } else {
            return true
        }
    }

  return (
    <div>
        {showSearchTemplateName && (
            <div style={{display:'flex',  paddingBottom:"20px"}}>

                <p style={{
                    color:"#111112",
                    fontSize:"15px",
                    fontFamily:"EMprint",
                    paddingRight:"20px",
                    paddingTop:"10px"
                    
                }}>Search for Template:</p>

                <Autocomplete
                    disablePortal
                    options={availableMethodTemplates}
                    noOptionsText={isTemplatesLoading ? "Loading Templates..." : "No Templates Found"}
                    onChange={(event, value) => {
                        HandleTemplateSelection(value);
                    }}
                    getOptionLabel={(option) => option.templateName}
                    value={selectedTemplate}
                    isOptionEqualToValue={(option, value) => value.templateName === option.templateName}
                    autoHighlight
                    renderInput={(params) => <TextField {...params} style={{width:"400px"}} variant="outlined" size="small" inputProps={{ ...params.inputProps }} InputProps={{ ...params.InputProps }}/>} 
                    onInputChange={(e, newInput, reason)=>{
                        if(reason === "clear"){
                            setSelectedMethods([])
                        }
                    }}
               />
            </div>
        )}
       
        <UXDataTableWithoutBody 
            tableWidth={width} 
            cols={methodCols}
            blankFirstHeader={isReadOnly === false} //only show the blank first row if not in ready only state
            tableId="methodsTable"
            rowLength={selectedMethods ? selectedMethods.length : 0}
            enablePaging={false}
            rowsPerPage={0}
            page={0}
            handleChangePage={null}
            handleChangeRowsPerPage={null}
            noDataFoundMessage={'No methods found'}
            enableAddIcon={IsAddIconEnabled()}
            addFunction={AddMethodKeyPress}
            addToolTipText={'Add Method'}  
            isDataLoading={false}
        >
            {selectedMethods && selectedMethods.map((oMethod, index) => {

            return(
                <StyledTableBody key={index}>
                    <TableRow> 
                        {isReadOnly === false &&
                        <StyledTableCell component='th' scope='row'>
                            <TrashIcon 
                                disabled={isReadOnly}
                                onClick ={() => DeleteMethodRow(index)} 
                                color="inherit"
                            />
                        </StyledTableCell>
                        }

                        <StyledTableCell style={{verticalAlign:"center"}}>
                            <StyledAutocomplete
                                renderOption={(props2, option) => (
                                            <Option {...props2}>{option.name}</Option>
                                )}
                                disabled={isReadOnly}
                                disablePortal
                                id={"method-test" + index}
                                options={methods}
                                noOptionsText={"Loading Methods..."}
                                onChange={(event, value) => {UpdateProperty(value, index, 'method')}}
                                getOptionLabel={(option) => option.name}
                                value={oMethod.method}
                                isOptionEqualToValue={(option, value) => value.name === option.name}
                                autoHighlight
                                autoSelect
                                renderInput={(params) => <TextField {...params} sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                    },}}
                                    style={{width:"175px"}} variant="outlined" size="small" error = {oMethod.method === null} inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize}}} InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>

                        <StyledTableCell style={{width:"300px"}} align='left'>
                                {oMethod.method ? oMethod.method.description : ''}
                        </StyledTableCell>

                        <StyledTableCell align='left'>
                            <div style={{display:'flex'}}>
                                <Autocomplete
                                    renderOption={(props2, option) => (
                                        <Option {...props2}>{option.testFacilityAbv}</Option>
                                    )} 
                                    disablePortal
                                    disabled={oMethod.method === null || isReadOnly || oMethod.method?.isPackageTest === true}
                                    id={"method-facilities" + index}
                                    options={oMethod.method ? oMethod.method.methodFacilities.filter(result => result.methodStatusName === 'Active').sort((a, b) => a.testFacilityAbv.localeCompare(b.testFacilityAbv)) : []}
                                    noOptionsText={"No Active Facilities"}
                                    onChange={(event, value) => {UpdateProperty(value, index, 'methodFacility')}}
                                    getOptionLabel={(option) => option.testFacilityAbv}
                                    value={oMethod.methodFacility}
                                    isOptionEqualToValue={(option, value) => value.testFacilityAbv === option.testFacilityAbv}
                                    autoHighlight
                                    autoSelect
                                    renderInput={(params) => <TextField {...params} sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                        },}}
                                        style={{width:"100px"}} variant="outlined" size="small" 
                                        error = {oMethod.methodFacility === null  && oMethod.method?.isPackageTest === false ? true : (oMethod.method?.isPackageTest === true ? false : !(oMethod.methodFacility?.methodStatusName === 'Active'))} 
                                        inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize}}} InputProps={{ ...params.InputProps }}/>} 
                                />      

                               
                                <IconButton style={{ marginRight:"-2rem"}} sx={{ color: "black" }}
                                    disabled={isReadOnly || oMethod.methodFacility?.testFacilityAbv !== "EXT" || oMethod.methodFacility === null}
                                    onClick ={() => {openModalExternalLabs("External Lab Selection", "Please select from the list of approved external facilities.", "OK", "Cancel");
                                    setWorkingIndex(index)}} 
                                >
                                    <VisibilityIcon>
                                    </VisibilityIcon>
                                </IconButton>
                            </div>
                        </StyledTableCell>

                        <StyledTableCell align='left'>
                            <div style={{display:'flex'}}>
                                <Autocomplete
                                    renderOption={(props2, option) => (
                                        <Option {...props2}>{option.conditionOfferingName}</Option>
                                    )} 
                                    disablePortal
                                    disabled={oMethod.methodFacility === null || isReadOnly || oMethod.methodFacility?.requiresConditions === false}
                                    id={"method-condition" + index}
                                    options={oMethod.methodFacility ? oMethod.methodFacility.testConditionOfferings.filter(result => result.isActive === true && (disabledUserDefinedConditions ? result?.testConditionSetupValues.every(tcsv => tcsv.minimumValue === null) : result?.testConditionSetupValues.every(tcsv => tcsv.methodFacilityID !== null)))
                                        .sort((a, b) => a.conditionOfferingName.localeCompare(b.conditionOfferingName))  : []}
                                    noOptionsText={"No Active Conditions"}
                                    onChange = {(event, value) => UpdateProperty(value, index, 'conditionOffering')}
                                    getOptionLabel={(option) => option.conditionOfferingName}
                                    value={oMethod.conditionOffering}
                                    isOptionEqualToValue={(option, value) => value.conditionOfferingName && option.conditionOfferingName}
                                    autoHighlight
                                    autoSelect
                                    renderInput={(params) => <TextField {...params} sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                        },}}
                                        style={{width:"175px"}} variant="outlined" size="small" 
                                        error = {oMethod.conditionOffering === null ? true : !(oMethod.conditionOffering?.isActive)} 
                                        inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize}}} InputProps={{ ...params.InputProps }}/>} 
                                />

                                <IconButton style={{ marginRight:"-2rem"}} sx={{ color: "black" }}
                                    disabled={isReadOnly || oMethod.methodFacility?.requiresConditions === false || oMethod.conditionOffering === null}
                                    onClick ={() => {openModalConditions("User Defined Parameters", "Please enter values within the min/max parameters for these user defined test parameters.", "Save", "Cancel");
                                    setWorkingIndex(index)}} 
                                >
                                    <VisibilityIcon>
                                    </VisibilityIcon>
                                </IconButton>
                            </div>
                        </StyledTableCell>
                        
                        {showEstimateAndPriority && 
                        <StyledTableCell align='left'>
                            <TextField style={{width:"125px", marginTop:"5px"}}
                                sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                                },}}
                                disabled={(oMethod.methodFacility ? !(oMethod.methodFacility.requiresEstimates) : true) || isReadOnly}
                                size="small"
                                margin="dense" 
                                variant="outlined"
                                inputProps={{ style: { fontSize: containerGridFontSize}, maxLength:500 }}
                                InputLabelProps={{shrink: true}}
                                label = {oMethod.methodFacility ? (oMethod.methodFacility.requiresEstimates ? 'Estimate Required' : 'Not Required') : 'Not Required'}
                                value = {oMethod.estimate === null ? '' : oMethod.estimate}
                                onChange = {e => UpdateProperty(e.target.value, index, 'estimate')}
                                error = {oMethod.methodFacility ? (oMethod.methodFacility.requiresEstimates ? oMethod.estimate === '' : false) : false}
                            />
                        </StyledTableCell>
                        }
                        {showEstimateAndPriority &&
                        <StyledTableCell align='left'>
                            <Autocomplete
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.testPriorityName}</Option>
                                )} 
                                disabled = {oMethod.method?.isPackageTest === true || isReadOnly}
                                disablePortal
                                id={"test-priority" + index}
                                options={testPriorities}
                                noOptionsText={"Loading Priorities..."}
                                onChange={(event, value) => {
                                    UpdateProperty(value, index, 'testPriority')
                                }}
                                getOptionLabel={(option) => option.testPriorityName}
                                value={oMethod.testPriority}
                                isOptionEqualToValue={(option, value) => value.testPriorityName === option.testPriorityName}
                                autoHighlight
                                autoSelect
                                renderInput={(params) => <TextField {...params} sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                    },}}
                                    style={{width:"175px"}} variant="outlined" size="small" error = {oMethod.testPriority === null} inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize}}} InputProps={{ ...params.InputProps }}/>} 
                           />
                        </StyledTableCell>
                        }

                        {showCompletionDate && (    
                            <StyledTableCell align='left'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        disabled={isReadOnly || oMethod.method?.isPackageTest === true}
                                        size="small"
                                        inputFormat="MM/dd/yyyy"
                                        onChange={(event) => {
                                            UpdateProperty(event, index, 'requestedCompletionDate')
                                        }}
                                        minDate={new Date()}
                                        value={oMethod.requestedCompletionDate}
                                        renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} {...params} sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                            },}}
                                            style={{width:"140px"}} variant="outlined" size="small" inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize } }} InputProps={{ ...params.InputProps }}/>} 
                                    />
                                </LocalizationProvider>
                            </StyledTableCell>
                        )}
                        {showContainerGrouping && (
                            <StyledTableCell align='left'>
                                <TextField style={{width:"125px", marginTop:"5px"}}
                                    sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                    },}}
                                    disabled={isReadOnly}
                                    size="small"
                                    margin="dense" 
                                    variant="outlined"
                                    inputProps={{ style: { fontSize: containerGridFontSize} }}
                                    InputLabelProps={{shrink: true}}
                                    value = {oMethod.containerGrouping === null ? '' : oMethod.containerGrouping}
                                    onChange = {e => UpdateProperty(e.target.value, index, 'containerGrouping')}
                                />
                            </StyledTableCell>  
                        )}    
                        {showFinalLocation && (
                            <StyledTableCell align='left'>
                                <LocationField
                                    margin="dense"
                                    fieldWidth={"11rem"}
                                    value={oMethod.requestedLocationName}
                                    handleChange={(value) => {
                                        UpdateProperty(value, index, 'requestedLocationName')
                                    }}
                                    label=""
                                    inputProps={{ style: { fontSize: containerGridFontSize}}}
                                />
                            </StyledTableCell>  
                        )}    
                    </TableRow>
                </StyledTableBody>
            )})}
        </UXDataTableWithoutBody>
            {showSearchTemplateName && (
                <>
                    <Button
                        variant="contained" 
                        disabled={isReadOnly || selectedMethods === null || selectedMethods?.length < 1 || isTemplateSaving}
                        style={{ textTransform: "none", marginTop:"13px", marginLeft:"15px", height:"30px" }}
                        onClick={() => openModalInstructions("Save Template", "Save/update your method template", 'Save', 'Cancel')}
                    >
                        {'Save As Template'}
                    </Button>

                    <Button
                        variant="contained" 
                        disabled={isReadOnly || allowTemplateUpdates === false || isTemplateSaving}
                        style={{ textTransform: "none", marginTop:"13px", marginLeft:"15px", height:"30px" }}
                        onClick={() => UpdateTestTemplate()}
                        >
                        {'Update Template'}
                    </Button>
                </>
            )}

        <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
          {Array.isArray(modalMessagesText) ? (
            modalMessagesText && modalMessagesText.map((text, index) => (
              <div style={{display:"flex"}} key={`mySamples${index}`}>
                  <label style={{overflowWrap: 'anywhere'}}>
                {text}
                  </label>
                </div>
              ))
          ) : (
            <label>
              {modalMessagesText}
            </label>
          )} 
        </ModalMessages>

        <ModalTwoButtons title={modalInstructionsTitle} button1Text={modalInstructionsButton1Text} button1Action={SaveNewTestTemplate} button2Text={modalInstructionsButton2Text} button2Action={closeModalInstructions} open={modalInstructionsOpen} setOpen={setModalInstructionsOpen}>
            <label>
                {modalInstructionsText}
            </label>

            <div>
                <TextField
                    size="small"
                    multiline
                    label = "Template Name"
                    value = {templateSaveName}
                    onChange = {e => {setTemplateSaveName(e.target.value)}}
                    margin = "normal"
                    style={{width:500, marginTop:40}}
                    inputProps={{ maxLength: 250 }}
                ></TextField> 
            </div>

            <div>
            <FormGroup>
                <FormControlLabel control={<Switch/>} 
                checked={isPublicChecked}
                onChange={e => setIsPublicChecked(e.target.checked)}
                label="Make Template Public" />
            </FormGroup>
            </div>
        </ModalTwoButtons>

        <ModalTwoButtons title={modalConditionsTitle} button1Text={modalConditionsButton1Text} button1Action={UpdateConditionDiscreteValues} button2Text={modalConditionsButton2Text} button2Action={closeModalConditions} open={modalConditionsOpen} setOpen={setModalConditionsOpen}>
            <label>
                {modalConditionsText}
            </label>

            <div style={{marginTop:"1.5rem"}}>
  
                <UXDataTableWithoutBody 
                tableWidth={'600px'} 
                cols={selectedMethods ? selectedMethods[workingIndex]?.conditionOffering?.testConditionSetupValues?.some(result => result.maximumValue !== null) === true ? conditionCols : conditionColsAlt : []}
                blankFirstHeader={false} //only show the blank first row if not in readyonly state
                tableId="conditionsTable"
                rowLength={selectedMethods ? selectedMethods[workingIndex]?.conditionOffering?.testConditionSetupValues?.length > 0 ? selectedMethods[workingIndex]?.conditionOffering?.testConditionSetupValues?.length : 0 : 0}
                enablePaging={false}
                rowsPerPage={0}
                page={0}
                handleChangePage={null}
                handleChangeRowsPerPage={null}
                noDataFoundMessage={'No conditions found'}
                enableAddIcon={false}
                addFunction={null}
                addToolTipText={''}  
                isDataLoading={false}
                >
                    <StyledTableBody>
                        {selectedMethods && selectedMethods[workingIndex]?.conditionOffering && selectedMethods[workingIndex].conditionOffering.testConditionSetupValues.map((tcsv, index) => {
                            return(
                            <TableRow key={index}> 
                                <StyledTableCell align='left'>
                                    {tcsv.testConditionName}
                                </StyledTableCell>
                            {selectedMethods[workingIndex].conditionOffering.testConditionSetupValues.some(result => result.maximumValue !== null) === true &&
                                <>
                                <StyledTableCell align='left'>
                                    {tcsv.minimumValue === null ? '-' : tcsv.minimumValue}
                                </StyledTableCell>

                                
                                <StyledTableCell align='left'>
                                    {tcsv.maximumValue === null ? '-' : tcsv.maximumValue}
                                </StyledTableCell>
                                </>
                            }

                                <StyledTableCell align='left'>
                                {(tcsv.minimumValue !== null && tcsv.maximumValue !== null) ?
                                (
                                    <TextField style={{width:"125px", marginTop:"5px"}}
                                    sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                    },}}
                                    disabled={isReadOnly}
                                    size="small"
                                    margin="dense" 
                                    variant="outlined"
                                    inputProps={{ style: { fontSize: containerGridFontSize}, maxLength:500 }}
                                    InputLabelProps={{shrink: true}}
                                    value = {tcsv.discreteValue === null ? '' : tcsv.discreteValue}
                                    onChange = {e => UpdateDiscreteTCSVProperty(e.target.value, index)}
                                    />     
                                ): (tcsv.discreteValue === null ? '' : tcsv.discreteValue)
                                }
                                </StyledTableCell>

                                <StyledTableCell align='left'>
                                    {tcsv.unitOfMeasure}                                    
                                </StyledTableCell>
                            </TableRow>
                            )
                        })}
                    </StyledTableBody>
                </UXDataTableWithoutBody>
            </div>
        </ModalTwoButtons>

        <ModalTwoButtons title={modalExternalLabsTitle} button1Text={modalExternalLabsButton1Text} button1Action={UpdateExternalLaboratory} button2Text={modalExternalLabsButton2Text} button2Action={closeModalExternalLabs} open={modalExternalLabsOpen} setOpen={setModalExternalLabsOpen}>
            <label>
                {modalExternalLabsText}
            </label>

            <div style={{marginTop:"1rem"}}>

            <UXDataTableWithoutBody
                tableWidth='1250px' 
                cols={externalLabCols} 
                blankFirstHeader = {true}
                tableId= {`externalLabs`}
                rowLength={selectedMethods ? selectedMethods[workingIndex]?.methodFacility?.methodExternalLabs?.length > 0 ? selectedMethods[workingIndex]?.methodFacility?.methodExternalLabs?.length : 0 : 0}
                enablePaging={false}
                rowsPerPage={0}
                page={0}
                handleChangePage={null}
                handleChangeRowsPerPage={null}
                noDataFoundMessage={'No labs found'}
                enableAddIcon={false}
                addFunction={null}
                addToolTipText={''}  
                isDataLoading={false}
            >
                {selectedMethods && selectedMethods[workingIndex]?.methodFacility && selectedMethods[workingIndex].methodFacility.methodExternalLabs.map((oExternalLab, index) => {
                    return(
                        <StyledTableBody key={`SearchedSamples~${index}`}>
                            <TableRow> 
                                <StyledTableCell>
                                    <Checkbox 
                                        disabled = {checkedLab[workingIndex] !== null && checkedLab[workingIndex].laboratoryName !== oExternalLab.externalLaboratory.laboratoryName}
                                        checked = {checkedLab[workingIndex] === null ? false : checkedLab[workingIndex].laboratoryName === oExternalLab.externalLaboratory.laboratoryName}
                                        onChange = {e => e.target.checked ? updateCheckedLab(oExternalLab.externalLaboratory) : updateCheckedLab(null)}
                                    >
                                    </Checkbox>
                                </StyledTableCell>

                                <StyledTableCell>
                                    {oExternalLab.externalLaboratory.laboratoryName}
                                </StyledTableCell>

                                <StyledTableCell>
                                    {oExternalLab.externalLaboratory.shippingAddress ? 
                                    `${oExternalLab.externalLaboratory.shippingAddress?.address1} ${oExternalLab.externalLaboratory.shippingAddress?.city}, ${oExternalLab.externalLaboratory.shippingAddress?.state}`
                                    : 'N/A'}
                                </StyledTableCell>

                                <StyledTableCell>
                                    {oExternalLab.externalLaboratory.labContactEmailAddress}
                                </StyledTableCell>

                                <StyledTableCell>
                                    {oExternalLab.externalLaboratory.onSiteContactEmailAddress}
                                </StyledTableCell>

                                <StyledTableCell>
                                    {oExternalLab.externalLaboratory.notes}
                                </StyledTableCell>

                                <StyledTableCell>
                                    {oExternalLab.testCostUSD ? `$${oExternalLab.testCostUSD.toLocaleString()}` : 'N/A'}
                                </StyledTableCell>

                                <StyledTableCell>
                                    {oExternalLab.minimumSampleAmount ? `${oExternalLab.minimumSampleAmount}${oExternalLab.minimumSampleAmountUoM}` : 'N/A'}
                                </StyledTableCell>
                                  
                            </TableRow>
                        </StyledTableBody>
                    )
                })}
            </UXDataTableWithoutBody>
            </div>
        </ModalTwoButtons>
    </div>
  );
};

export default MethodSelection;