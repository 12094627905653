import { Box, styled } from "@mui/material";
import ModalSimpleButton from "../../../../../components/Modal/ModalSimpleButton";

export type AlertProps = {
  title?: string;
  message?: string;
  details?: string;
  display?: boolean;
  onOk?: () => void;
};

export const Alert = ({ title = "", message = "", details, display, onOk }: AlertProps) => {
  return (
    <AlertModal
      title={title}
      buttonText={"OK"}
      buttonAction={onOk}
      open={display}
      setOpen={undefined}>
      <Box display="flex" alignItems="center" justifyContent={"center"} textAlign={"center"}>
        {message}
        {details && (
          <>
            <br /> <br />
            {details}
          </>
        )}
      </Box>
    </AlertModal>
  );
};

const AlertModal = styled(ModalSimpleButton)({});
