import { useHistory } from 'react-router-dom';
import ModalMessages from '../../../../components/Modal/ModalSimpleButton'
import { StyledTableBody, StyledTableCell, StyledTableCellLink, StyledTableRow, UXDataTableWithoutBody } from '../../../../components/UXDataTable';
import { formatMidasNumber } from '../../../../global';
import { StyledTableCellMedium, StyledTableCellSmall } from '../constants';

const myCols = [
    "MIDAS #",
    "Container",
    "Method",
    "Method Description",
    "Facility",
];

const ModalMethodFacilityExternal = ({
    facilitiesExternal,
    setModalOpen,
    setModalClose,
    modalOpen,
    created
}) => {
    const history = useHistory();
    function onCloseModal() {
        if (setModalClose) setModalClose();
        else setModalOpen(false);
    }
    const modalText = created ? `This work request got splitted into another ${facilitiesExternal.length} requests` : `This work request will get splitted into another ${facilitiesExternal.length} requests`;
    return (
        <>
            <ModalMessages title={'Warning external facilites'} buttonText={'Ok'} buttonAction={onCloseModal} open={modalOpen} setOpen={setModalOpen}>
                <div className='modal-method-facility-external-body'>
                    <label>{modalText}</label>
                    <UXDataTableWithoutBody
                        tableWidth='100%'
                        cols={created ? [...myCols, 'Work Request'] : myCols}
                        tableId="facilitiesExternal"
                        rowLength={facilitiesExternal?.length}
                        noDataFoundMessage={'No facilties found'}
                    >
                        <StyledTableBody>
                            {facilitiesExternal.map((facilityExternal, index) => {
                                return <StyledTableRow
                                    key={index}
                                >
                                    <StyledTableCellMedium>
                                        {formatMidasNumber(facilityExternal?.sampleName)}
                                    </StyledTableCellMedium>
                                    <StyledTableCellSmall>
                                        {facilityExternal?.containerNumber}
                                    </StyledTableCellSmall>
                                    <StyledTableCellSmall>
                                        {facilityExternal?.method}
                                    </StyledTableCellSmall>
                                    <StyledTableCell className="table-cell-full">
                                        {facilityExternal?.methodDescription}
                                    </StyledTableCell>
                                    <StyledTableCellSmall>
                                        {facilityExternal?.facility}
                                    </StyledTableCellSmall>
                                    {created && <StyledTableCellLink
                                        scope="row"
                                        onClick={() => {
                                            history.push("/workRequests");
                                            setTimeout(() => {
                                                history.push(`/newWorkRequestRedesign/${facilityExternal?.workRequestId}`) 
                                            }, 1000)
                                        }}
                                        // component={Link}
                                        // to={{ pathname: `/newWorkRequestRedesign/${facilityExternal?.workRequestId}`}}
                                    >
                                        {facilityExternal?.workRequestId}
                                    </StyledTableCellLink>}
                                </StyledTableRow>
                            })}
                        </StyledTableBody>
                    </UXDataTableWithoutBody>
                </div>
            </ModalMessages>
        </>
    )
}

export default ModalMethodFacilityExternal