import React, { useContext } from "react";
import { Box, Divider } from "@mui/material";
import WorkRequests from "./WorkRequests";
import ProcessWorkRequests from "./ProcessWorkRequests";
import SpecialAnalysis from "./SpecialAnalysis";
import ProcessSpecialAnalysis from "./ProcessSpecialAnalysis";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";
import UserContext from "../../context/UserContext";
import { Roles, hasRole } from "../../global";
import { useHistory } from "react-router-dom";
import WorkRequest from "../../api/WorkRequest/WorkRequest";

const MIDASWorkRequests = ({ initialTabValue = 0 }) => {
  const [tabValue, setTabValue] = React.useState(initialTabValue);
  const currentUser = useContext(UserContext);
  const userRoles = currentUser?.idTokenClaims.roles;

  const isDeveloperRole = hasRole(Roles.Developer, userRoles);

  const history = useHistory();

  const isTechnicianRole =
    hasRole(Roles.WorkRequestTechnician, userRoles) ||
    hasRole(Roles.WorkRequestAssigner, userRoles) ||
    isDeveloperRole;
  // const isReporterRole =
  //   hasRole(Roles.WorkRequestReporter, userRoles) || isDeveloperRole;

  if (!isTechnicianRole && initialTabValue === 1)
    window.location.href = "/workRequests";

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  async function copyWorkRequest(workRequestId) {
    const result = await WorkRequest.getById(workRequestId);
    if (result) {
      result.typeName = result.typeName === "Fuels Request w/ Shipping" ? "Shipping" : result.typeName
      history.push({
        pathname: '/newWorkRequests',
        state: { myWorkRequest: result }
      })
    }
  }

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <WorkRequests copyWorkRequest={copyWorkRequest} />;
      }
      case 1: {
        return <ProcessWorkRequests copyWorkRequest={copyWorkRequest} />;
      }
      case 2: {
        return <SpecialAnalysis copyWorkRequest={copyWorkRequest} />;
      }
      case 3: {
        return <ProcessSpecialAnalysis copyWorkRequest={copyWorkRequest} />;
      }

      // case 2: {
      //   return (
      //     <div>
      //       <span className="pageheader">Work Request Metrics</span>
      //       <Divider className="dividerbar" />
      //       <p>This page is currently under construction</p>
      //     </div>
      //   );
      // }
      default: {
        alert(tabValue);
      }
    }
  };
  return (
    <Box paddingBottom={4}>
      <span className="pageheader">Work Requests</span>
      <Divider className="dividerbar" />
      <div>
        <Box sx={{ bgcolor: "#fff", pt: 3, pb: 1 }}>
          <GlobalTabCollection
            value={tabValue}
            onChange={handleChange}
            aria-label="ant example"
          >
            <StyledTab label="Submit Work Requests" />
            <StyledTab
              disabled={!isTechnicianRole}
              label="Process Work Requests"
            />

            <StyledTab label="Submit Special Analysis" />
            <StyledTab
              disabled={!isTechnicianRole}
              label="Process Special Analysis"
            />
            {/* <StyledTab
              disabled={!isReporterRole}
              label="Work Request Metrics"
            /> */}
          </GlobalTabCollection>
        </Box>
        {renderSearchTab()}
      </div>
    </Box>
  );
};

export default MIDASWorkRequests;
