import { ConvertUOMs } from "../../global";

const defaultUom = {
  uoMName: "g",
  type: "weight",
  metricStandardUoMName: "g",
  metricStandardConversion: 1,
};

export function getConvertedCurrentAmount(oWorkRequestSample, isUpdate, samplesSameKey = []) {
  if (isUpdate) {
    const selectedContainerWithValidCurrentAmountToDisplay =
      oWorkRequestSample &&
      oWorkRequestSample.existingContainer &&
      oWorkRequestSample.existingContainer.currentAmount &&
      oWorkRequestSample.existingContainer.containerSizeUoM;

    if (selectedContainerWithValidCurrentAmountToDisplay) {
      const validDataToDoConversion =
        oWorkRequestSample.sample &&
        oWorkRequestSample.sample.substance &&
        oWorkRequestSample.unitOfMeasure;

      if (oWorkRequestSample.requestedAmountUoM && validDataToDoConversion) {
        const sample = oWorkRequestSample.sample;
        const substance = oWorkRequestSample.sample.substance;
        const currentAmount =
          oWorkRequestSample.existingContainer.currentAmount;
        const originalUoM = oWorkRequestSample.existingContainer.uom;
        const convertToUoM = oWorkRequestSample.unitOfMeasure;

        const newConvertedCurrentAmount = ConvertUOMs(
          sample,
          substance,
          currentAmount,
          originalUoM,
          convertToUoM
        );

        if (typeof newConvertedCurrentAmount === "number") {
          return `${
            newConvertedCurrentAmount % 1
              ? newConvertedCurrentAmount.toFixed(4)
              : newConvertedCurrentAmount
          } ${oWorkRequestSample.requestedAmountUoM}`;
        }
      }
      return `${oWorkRequestSample.existingContainer.currentAmount} ${oWorkRequestSample.existingContainer.containerSizeUoM}`;
    }
  } else {
    const validForConversion =
      oWorkRequestSample &&
      oWorkRequestSample.sample &&
      oWorkRequestSample.sample.containers &&
      oWorkRequestSample.sample.containers.length > 0;

    if (validForConversion) {
      const container = oWorkRequestSample.sample.containers.find(
        (c) => c.id === oWorkRequestSample.existingContainerID
      );

      if (container) {
        const validDataToDoConversion =
          oWorkRequestSample.sample &&
          oWorkRequestSample.sample.substance &&
          oWorkRequestSample.unitOfMeasure;

        if (oWorkRequestSample.requestedAmountUoM && validDataToDoConversion) {
          const sample = oWorkRequestSample.sample;
          const substance = oWorkRequestSample.sample.substance;
          const currentAmount = container.currentAmount;
          const originalUoM = container.uom;
          const convertToUoM = oWorkRequestSample.unitOfMeasure;

          let newConvertedCurrentAmount = ConvertUOMs(
            sample,
            substance,
            currentAmount,
            originalUoM,
            convertToUoM
          );

          if (typeof newConvertedCurrentAmount === "number") {
            samplesSameKey.forEach(item => {
              const requestedAmount = item.requestedAmount ? parseFloat(item.requestedAmount) : 0;
              newConvertedCurrentAmount -= requestedAmount;
            })
            return `${
              newConvertedCurrentAmount % 1
                ? newConvertedCurrentAmount.toFixed(4)
                : newConvertedCurrentAmount
            } ${oWorkRequestSample.requestedAmountUoM}`;
          }
        }
        return `${container.currentAmount} ${container.containerSizeUoM}`;
      } else {
        const sample = oWorkRequestSample.sample;
        const substance = oWorkRequestSample.sample.substance;
        const convertToUoM = oWorkRequestSample.unitOfMeasure ?? null;

        let fullValue = oWorkRequestSample.sample.containers.reduce(
          (accumulator, currentValue) => {
            const newConvertedCurrentAmount = ConvertUOMs(
              sample,
              substance,
              currentValue.currentAmount,
              currentValue.uom,
              convertToUoM
            );

            return accumulator + newConvertedCurrentAmount;
          },
          0
        );

        if (typeof fullValue === "number") {
          samplesSameKey.forEach(item => {
            const requestedAmount = item.requestedAmount ? parseFloat(item.requestedAmount) : 0;
            fullValue -= requestedAmount;
          })
          return `${fullValue % 1 ? fullValue.toFixed(4) : fullValue} ${
            oWorkRequestSample.requestedAmountUoM ?? defaultUom.uoMName
          }`;
        }
      }
    }
  }
  return "";
}

export function getUpdatedWorkRequestsWhenChangingRequestedAmountUoM(
  myWorkRequest,
  index,
  value
) {
  return myWorkRequest.workRequestSamples.map((sample, idx) => {
    if (index === idx) {
      return {
        ...sample,
        requestedAmountUoM: value.uoMName,
        unitOfMeasure: value,
      };
    }
    return sample;
  });
}

export const getOriginalAmount = (totalAmt, sample) => {
  const uom = sample?.requestedAmountUoM ?? sample?.existingContainer?.containerSizeUoM;
  const total = totalAmt.split(" ")[0];
  if (total && sample && uom)
      return parseFloat(total) + parseFloat(sample?.requestedAmount ?? 0) + " " + uom;

  return "";
}