import { useState } from "react";
import { Field, FieldMetaProps, Form, Formik } from "formik";
import { Box, Grid, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { GlobalButton, GlobalSecondaryButton } from "../../../styles";
import EmailAddressTextField from "../../../../components/EmailAddressField";
import ModalTwoButtons from "../../../../components/Modal/ModalTwoButtons";
import { ProjectFormValidationSchema } from "./ProjectFormValidationSchema";
import Project from "../../../../api/Admin/Project";
import { FileField } from "./formFields/FileField";
import { downloadFile } from "./helpers";
import { Alert } from "./components/Alert";
import { DatePickerField } from "./formFields/DatePickerField";

enum ProjectFormField {
  Name = "name",
  Description = "description",
  OwnerEmail = "ownerEmail",
  SecondaryOwnerEmail = "secondaryOwnerEmail",
  ExpirationDate = "expirationDate",
  RestrictAtEndOfLife = "RestrictAtEndOfLife",
  Status = "isActive",
  NdaAgreementFile = "ndaAgreementFile",
}

export type ProjectFormCriteria = {
  id: number;
  name: string;
  description: string;
  ownerEmail: string;
  secondaryOwnerEmail: string;
  expirationDate?: Date;
  restrictAtEndOfLife: boolean;
  isActive: boolean;
  ndaAgreementFile?: File;
};

export type ProjectFormProps = {
  project: Project | undefined;
  invalidProjectNames?: string[];
  disabled?: boolean;
  onSave: (values: ProjectFormCriteria) => void;
  onCancel: () => void;
};

export const ProjectForm = ({
  project,
  invalidProjectNames,
  disabled = false,
  onSave,
  onCancel,
}: ProjectFormProps) => {
  const [ownerEmailError, setOwnerEmailError] = useState<boolean>(false);
  const [secondaryOwnerEmailError, setSecondaryOwnerEmailError] = useState<boolean>(false);
  const [displayLostChangesAlert, setDisplayLostChangesAlert] = useState<boolean>(false);
  const [disableFileCard, setDisableFileCard] = useState<boolean>(false);
  const [alert, setAlert] = useState<ProjectFormAlert | undefined>(undefined);
  const today = new Date();
  const defaultDateTime = new Date(today.getFullYear() + 3, today.getMonth(), today.getDate());

  const initialValues = {
    id: project?.id ?? 0,
    name: project?.name ?? "",
    description: project?.description ?? "",
    ownerEmail: project?.ownerEmail ?? "",
    secondaryOwnerEmail: project?.secondaryOwnerEmail ?? "",
    expirationDate: project?.projectEndDate ?? defaultDateTime,
    restrictAtEndOfLife: project?.restrictAtEndOfLife ?? true,
    isActive: project?.isActive ?? true,
    ndaAgreementFileName: project?.ndaAgreementFileName ?? undefined,
    ndaAgreementFile: project?.ndaAgreementFile ?? undefined,
  };

  const fieldErrorMessage = (fieldMetaProps: FieldMetaProps<ProjectFormCriteria>) => {
    const { error, touched } = fieldMetaProps;
    return touched && Boolean(error) ? error : undefined;
  };

  const handleSaveClick = (values: ProjectFormCriteria) => {
    onSave(values);
  };

  const handleCancelClick = (formIsDirty: boolean) => {
    if (formIsDirty) {
      setDisplayLostChangesAlert(true);
    } else {
      onCancel();
    }
  };

  const handleFileDownloadClick = () => {
    setDisableFileCard(true);
    Project.downloadNdaAgreementFile(project?.id)
      .then((response) => {
        downloadFile(response, project?.ndaAgreementFileName);
      })
      .catch((err) => {
        setAlert(errorDownloadFileAlert(err));
      })
      .finally(() => {
        setDisableFileCard(false);
      });
  };

  const handleLostChangesOkClick = () => {
    setDisplayLostChangesAlert(false);
    onCancel();
  };

  const handleLostChangesCancelClick = () => {
    setDisplayLostChangesAlert(false);
  };

  const handleAlertOkClick = () => {
    setAlert(undefined);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={() => ProjectFormValidationSchema(invalidProjectNames ?? [])}
      onSubmit={handleSaveClick}>
      {({
        values,
        isValid,
        dirty,
        touched,
        handleBlur,
        handleChange,
        getFieldMeta,
        setFieldValue,
      }) => (
        <Form>
          <Grid container spacing={2} style={{ padding: 5 }}>
            <Grid item xs={10}>
              <TextField
                id={ProjectFormField.Name}
                name={ProjectFormField.Name}
                label="* Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={Boolean(fieldErrorMessage(getFieldMeta(ProjectFormField.Name)))}
                helperText={fieldErrorMessage(getFieldMeta(ProjectFormField.Name)) ?? " "}
              />
            </Grid>
            <Grid
              item
              xs={2}
              container
              sx={{
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}>
              <FormControlLabel
                label="Active"
                labelPlacement="end"
                control={
                  <Switch
                    id={ProjectFormField.Status}
                    name={ProjectFormField.Status}
                    defaultChecked={initialValues.isActive}
                    value={values.isActive}
                    onChange={handleChange}
                    color="success"
                    disabled={disabled}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id={ProjectFormField.Description}
                name={ProjectFormField.Description}
                label="Description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                size="small"
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
                error={Boolean(fieldErrorMessage(getFieldMeta(ProjectFormField.Description)))}
                helperText={fieldErrorMessage(getFieldMeta(ProjectFormField.Description)) ?? " "}
              />
            </Grid>
            <Grid item xs={6}>
              <EmailAddressTextField
                key={ProjectFormField.OwnerEmail}
                validatedUserEmail={values.ownerEmail}
                setValidatedUserEmail={(value: string) => {
                  setFieldValue(ProjectFormField.OwnerEmail, value);
                }}
                setHasErrors={setOwnerEmailError}
                hasErrors={ownerEmailError}
                isDisabled={disabled}
                labelText="* Owner Email"
                placeholderText="Owner Email"
                size={"small"}
                margin={"none"}
                fontSize={16}
                fieldWidth={"85%"}
                showPlusMeButton={true}
              />
            </Grid>
            <Grid item xs={6}>
              <EmailAddressTextField
                key={ProjectFormField.SecondaryOwnerEmail}
                validatedUserEmail={values.secondaryOwnerEmail}
                setValidatedUserEmail={(value: string) => {
                  setFieldValue(ProjectFormField.SecondaryOwnerEmail, value);
                }}
                setHasErrors={setSecondaryOwnerEmailError}
                hasErrors={secondaryOwnerEmailError}
                isDisabled={disabled}
                labelText="Secondary Owner Email"
                placeholderText="Secondary Owner Email"
                size={"small"}
                margin={"none"}
                fontSize={16}
                fieldWidth={"85%"}
                showPlusMeButton={true}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={ProjectFormField.ExpirationDate}
                label="* Expiration Date/Time"
                value={values.expirationDate}
                component={DatePickerField}
                setFieldValue={setFieldValue}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={ProjectFormField.RestrictAtEndOfLife}
                    name={ProjectFormField.RestrictAtEndOfLife}
                    defaultChecked={initialValues.restrictAtEndOfLife}
                    value={values.restrictAtEndOfLife}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                }
                label="Restrict At End Of Life"
                labelPlacement="end"
              />
            </Grid>
            <Field
              name={ProjectFormField.NdaAgreementFile}
              label="* NDA/Agreement Number File"
              value={values.ndaAgreementFile}
              component={FileField}
              setFieldValue={setFieldValue}
              disabled={disableFileCard || disabled}
              onDownloadClick={handleFileDownloadClick}
            />

            <Grid item xs={6} container justifyContent="flex-end">
              <GlobalButton
                type="submit"
                variant="contained"
                disabled={
                  !isValid ||
                  !dirty ||
                  disabled ||
                  (values.ndaAgreementFile === undefined && !touched.ndaAgreementFile)
                }
                style={{ marginRight: 10 }}>
                Save
              </GlobalButton>
            </Grid>
            <Grid item xs={6}>
              <GlobalSecondaryButton
                variant="contained"
                onClick={() => {
                  handleCancelClick(dirty);
                }}>
                Cancel
              </GlobalSecondaryButton>
            </Grid>
          </Grid>

          <LostChangesAlert
            title="Unsaved Changes"
            button1Text="OK"
            button1Action={handleLostChangesOkClick}
            isButton1Disabled={false}
            button2Text="Cancel"
            button2Action={handleLostChangesCancelClick}
            isButton2Disabled={false}
            open={displayLostChangesAlert}
            setOpen={undefined}
            setClose={undefined}>
            <Box>You have unsaved changes. If you leave, your changes will be lost.</Box>
          </LostChangesAlert>
          <Alert
            title={alert?.title}
            message={alert?.message}
            display={alert !== undefined}
            onOk={handleAlertOkClick}
          />
        </Form>
      )}
    </Formik>
  );
};

type ProjectFormAlert = {
  title: string;
  message: string;
  details?: string | undefined;
};

const errorDownloadFileAlert = (details: string): ProjectFormAlert => ({
  title: "Error",
  message: "There was an error downloading the NDA Agreement file.",
  details: `Reason: ${details}`,
});

const LostChangesAlert = styled(ModalTwoButtons)({});
