import { Autocomplete, Divider, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { convertCelsiusToFahrenheit, convertFlashPoint, getGlobalHyperLinkColor } from "../../../global";

export const autoCompleteFontSize = 16;
export const sampleGridFontSize = 12;

export const Title = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "22px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
});

export const StyledTextField = styled(TextField)({
  width: "100%",
});

export const SubTitle = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "16px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "20px",
});

export const subsectionTitle = {
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "16px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "20px",
};

export const StyledShortTextField = styled(TextField)({
  id: "outlined-normal",
  width: "15%",
  marginRight: "15px",
  marginBottom: "20px",
});

export const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
    fontSize: autoCompleteFontSize,
  },
  width: "15%",
  marginRight: "15px",
  marginBottom: "20px",
});

export const StyledDivider = styled(Divider)({
  marginTop: "10px",
});

export const StyledDiv = styled("div")({
  display: "flex",
  marginTop: "5px",
});

export const Option = styled("li")({
  fontSize: sampleGridFontSize,
});

export const StyledShippingInfo = styled("div")({});

export const StyledShippingInfoTitle = styled("p")({
  fontFamily: "EMprint",
  fontSize: "12px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "10px",
  height: "30px",
});

export const StyledShippingInfoDescription = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "12px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "10px",
});

export const StyledFormGroup = styled("div")({
  display: "flex",
  alignItems: "center",
  paddingTop: "20px",
});

export const Description = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  fontSize: "14px",
  paddingRight: "20px",
});

export const DescriptionLink = styled("span")({
  fontFamily: "EMprint",
  fontWeight: "600",
  textAlign: "left",
  fontSize: "14px",
  paddingRight: "20px",
  textTransform: "none",
  color: getGlobalHyperLinkColor(),
  textDecoration: "underline",
  cursor: "pointer",
});

export const MySampleInfoCols = [
  "MIDAS #",
  "Container",
  "Description",
  "PSIMS ID",
  "UoM",
  "Original Amt.",
  "Amt. Requested",
  "Amt. Available",
  "Final Location",
  "Final Sublocation",
];

export const NonChecmicalItemCols = ["Description", "Qty", "UoM"];

export const MySafetyInfoCols = [
  "MIDAS #",
  "Container Number",
  "Current Amount",
  "UoM",
  "PSIMS ID",
  "Blind Code Label Description",
  "Flash Point (F)",
  "DOT Hazard Class",
  "Export Value",
  "Description",
];

export const defaultWorkRequestSample = {
  workRequest: null,
  sampleName: "",
  sample: null,
  requestedAmount: null,
  requestedAmountUoM: null,
  unitOfMeasure: null,
  newContainerID: null,
  existingContainerID: null,
  container: null,
  requestedLocationName: null,
  returnLocationName: null,
  location: null,
  deliveryLocationSublocation: "",
};

export const defaultWorkRequest = {
  typeName: null,
  workRequestTypeName: null,
  statusName: "Submitted",
  workRequestStatusName: null,
  facilityName: null,
  workRequestFacilityName: null,
  description: "",
  requestedByEmail: "",
  createdByEmail: null,
  lastModifiedByEmail: null,
  lastModifiedDate: null,
  requestedCompletionDate: null,
  lastAcceptableCompletionDate: null,
  assignedDate: null,
  inProgressDate: null,
  completedDate: null,
  priorityName: "Normal",
  workRequestPriority: null,
  priorityReason: "",
  chargeCode: "",
  deliveryLocationName: null,
  workRequestLocation: null,
  assignedToEmail: "",
  testSubmissionJSON: "",
  projectID: null,
  project: null,
  businessUnitName: null,
  businessUnit: null,
  specialAnalysisSamples: [defaultWorkRequestSample],
  shippingRequest: null,
  specialRequest: null,
  comments: null,
  completedByEmail: null,
};

export const defaultShippingAddress = {
  etqid: null,
  recipientName: null,
  company: null,
  department: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  country: null,
  postalCode: null,
  phoneNumber1: null,
  emailAddress: null,
  comments: null,
};

export const defaultShippingItem = {
  thisRequest: null,
  containerID: null,
  thisContainer: null,
  description: null,
  dotHazardClass: null,
  exportValue: null,
  flashPointInC: null,
  quantity: null,
  quantityUoMName: null,
  quantityUoM: null,
  comments: null,
  sampleName: null,
  blindCodeDescription: null,
};

export const defaultShippingRequest = {
  workRequest: null,
  description: null,
  requestedDeliveryDate: null,
  numberOfItems: null,
  containsSamples: false,
  shippingCarrierID: null,
  carrier: null,
  printBlindCode: false,
  attachCOA: null,
  shippingAddress: null,
  tackingRecords: null,
  tscaReviews: null,
  is3PTesting: false,
  shippingReasonName: null,
  shippingReason: null,
  shippingComments: null,
  referencePO: null,
  isPrepaid: null,
  shippingItems: [defaultShippingItem],
  iAcknowledge: false,
};

export const defaultErrorChecksWorkRequest = {
  typeName: null,
  facilityName: null,
  businessUnitName: null,
  chargeCode: null,
  requestedByEmail: null,
  requestedCompletionDate: null,
  lastAcceptableCompletionDate: null,
};

export const specialAnalysisErrors = {
  groupLeaderEmail: false,
  requestDescription: false,
  requestReason: false,
  testingRequirements: false,
};

export const defaultErrorChecksShipping = {
  recipientName: null,
  country: null,
  postalCode: null,
  state: null,
  city: null,
  address1: null,
  shippingCarrierID: null,
  shippingReasonName: null,
  isPrepaid: null,
  requestedDeliveryDate: null,
};

export const defaultSpecialAnalysisObject = {
  id: 0,
  workRequestID: 0,
  groupLeaderEmail: "",
  requestDescription: "",
  requestReason: "",
  testingRequirements: "",
  priority: 5,
  estimatedTotalHours: "",
  estimatedCompletionDate: null,
  dataClassificationName: "",
  reportFormatName: "",
};

export const defaultContainer = {
  sampleName: null,
  tests: null,
  containerNumber: 0,
  containerTypeName: "",
  containerType: null,
  containerStatusName: "Confirmed",
  containerStatus: null,
  cylinderSerialNumber: null,
  size: "",
  containerSizeUoM: "",
  uom: null,
  tareWeight: null,
  tareWeightUoM: null,
  currentAmount: "",
  ownerEmail: null,
  createdDate: null,
  createdByEmail: null,
  lastModifiedDate: null,
  lastModifiedByEmail: null,
  subLocation: null,
  expirationDate: null,
  nextDispositionDate: null,
  discardOnDisposition: false,
  locationName: "",
  location: null,
  parentID: null,
  parentContainer: null,
  returnLocationName: "",
  returnLocation: null,
};
export const newContainerErrorChecks = {
  containerType: false,
  containerStatus: false,
  size: false,
  currentAmount: false,
  uom: false,
  location: false,
  ownerEmail: false,
};
export const successMessage = "Work Request Created Successfully";

export enum WORK_REQUEST_TYPES_ENUM {
  ON_SITE_TRANSPORT = "On-site Transport",
  SHIPPING = "Shipping",
  SPECIAL_ANALYSIS = "Special Analysis",
  CONTAINER_REGISTRATION = "Container Registration",
  CONTAINER_REGISTRATION_TESTS = "Container Registration w/ Tests",
}

export enum WORK_REQUEST_PRIORITIES_ENUM {
  RUSH = "Rush",
}

export const ON_SITE_SAMPLE_TABLE_COLUMNS = [
  "MIDAS #",
  "Container",
  "Description",
  "PSIMS ID",
  "Amt. Available",
  "Final Location",
  "Final Sublocation",
];
export const SHIPPING_TABLE_COLUMNS = [
  "MIDAS #",
  "Container",
  "Description",
  "PSIMS ID",
  "UoM",
  "Original Amt.",
  "Amt. Requested",
  "Amt. Available",
  "Final Location",
  "Final Sublocation",
];

export const FILE_ATTACHMENT_TABLE_COLUMNS = [
  "File Name",
  "Send with Shipment",
  "File is SDS",
];

export const SPECIAL_ANALYSIS_TABLE_COLUMNS = [
  "MIDAS #",
  "Container",
  "Description",
  "Container Return Location",
];

export const copyWorkRequest = (workRequestToCopy: any) => {
  delete workRequestToCopy.id;
  delete workRequestToCopy.specialRequest?.id;
  delete workRequestToCopy.specialRequest?.workRequestID;

  const workRequest = {
    ...workRequestToCopy,
    statusName: defaultWorkRequest.statusName,
    createdByEmail: defaultWorkRequest.createdByEmail,
    createdDate: null,
    shippingRequest: null,
    comments: null,
    assignedToEmail: null,
    assignedDate: null,
    requestedCompletionDate: defaultWorkRequest.requestedCompletionDate,
    lastAcceptableCompletionDate: defaultWorkRequest.lastAcceptableCompletionDate,
    lastModifiedDate: defaultWorkRequest.lastModifiedDate,
    lastModifiedByEmail: defaultWorkRequest.lastModifiedByEmail,
    completedDate: defaultWorkRequest.completedDate,
    completedByEmail: defaultWorkRequest.completedByEmail,
    specialAnalysisSamples: workRequestToCopy.specialAnalysisSamples.map((wrSample: any) => {
      delete wrSample.id;
      delete wrSample.workRequestID;
      return {
        ...wrSample,
        newContainerID: null,
        newContainer: null,
        existingContainer: null,
      }
    })

  }
  return workRequest;
}
export const copyShippingRequest = (shippingRequestToCopy: any, wkrSamples: any) => {
  delete shippingRequestToCopy.id;
  delete shippingRequestToCopy.workRequestID;
  delete shippingRequestToCopy.shippingAddress?.id;
  delete shippingRequestToCopy.tscaReview;
  const shippingRequest = {
    ...shippingRequestToCopy,
    shippingItems: shippingRequestToCopy.shippingItems.map((item: any, index: number) => {
      delete item.id;
      delete item.shippingRequestID;
      return {
        ...item,
        sampleName: item.sampleName ?? wkrSamples[index]?.sampleName,
        flashPointInC: convertFlashPoint(convertCelsiusToFahrenheit, item.flashPointInC)
      }
    }),
    requestedDeliveryDate: defaultShippingRequest.requestedDeliveryDate,
    shippingTrackings: []
    // shippingTrackings: shippingRequestToCopy.shippingTrackings.map((tracking: any, index: number) => {
    //   delete tracking.id;
    //   delete tracking.shippingRequestID;
    //   delete tracking.shippingCarrier;
    //   return {
    //     ...tracking,
    //   }
    // })
  }
  return shippingRequest;
}