import React, { useState, useEffect, useContext } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { LocationContext } from "./../../context/LocationContext";

const LocationField = ({
  value,
  handleChange,
  isDisabled,
  margin,
  fieldWidth,
  hasErrors,
  helperText,
  label,
  disablePortal = true,
  inputProps,
}) => {
  const [locationLoading, setLocationLoading] = useState(false);
  const { locations, getAllLocations } = useContext(LocationContext);

  useEffect(() => {
    async function fetchData() {
      setLocationLoading(true);

      if (!locations || locations.length < 1) await getAllLocations();

      setLocationLoading(false);
    }
    fetchData();
  }, [getAllLocations, locations]);

  return (
    <>
      <Autocomplete
        size="small"
        id="location-select"
        disablePortal={disablePortal}
        noOptionsText={
          locationLoading ? "Loading Locations..." : "No Locations Found"
        }
        options={locations.map((location) => location.locationName)}
        getOptionLabel={(option) => option}
        onChange={(e, value) => {
          value = value === null ? "" : value;
          handleChange(value);
        }}
        autoHighlight
        autoSelect
        value={value}
        style={{ width: fieldWidth ? fieldWidth : "20rem" }}
        disabled={isDisabled}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            label={label ? label : label === "" ? "" : "Location"}
            margin={margin ? margin : "none"}
            error={Boolean(hasErrors)}
            helperText={Boolean(hasErrors) ? helperText : ""}
            InputProps={{ ...params.InputProps }}
            inputProps={{ ...params.inputProps, ...inputProps}}
          />
        )}
      />
    </>
  );
};
export default LocationField;
