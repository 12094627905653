import API from "..";

export default class BlendStudyRecentlyAccessed {

  constructor({
    studyID,
    blendStudy,
    userEmail,
    lastAccessed
  }) 
    
  {
    this.studyID = studyID;
    this.blendStudy = blendStudy;
    this.userEmail = userEmail;
    this.lastAccessed = lastAccessed;
  }

  static async getRecentlyAccessedBlendStudies(userEmail) {
    const api = await API();
    const { data } = await api.get(`/blendStudyRecentlyAccessed?userEmail=${userEmail}`);
    if (data.result) {
      return data.result.map((d) => new BlendStudyRecentlyAccessed(d));
    }
    else {
        return [];
    }
  }
}
