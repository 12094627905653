import { FieldProps } from "formik";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export type DatePickerFieldProps = FieldProps & {
  label: string;
  disabled: boolean;
};

export const DatePickerField: React.FC<DatePickerFieldProps> = ({
  field,
  form,
  label,
  disabled,
}) => {
  const { error, touched } = form.getFieldMeta(field.name);

  const handleOnChange = (date: Date | null) => {
    if (touched === false) {
      form.setFieldTouched(field.name, true);
    }
    form.setFieldValue(field.name, date, true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={field.value}
        onChange={handleOnChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(error && touched)}
            helperText={error && touched ? error : " "}
          />
        )}
      />
    </LocalizationProvider>
  );
};
