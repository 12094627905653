import { useState, useEffect, useContext } from "react";
import {TextField, Autocomplete, Checkbox, FormControlLabel, Divider, Tooltip, TableRow, TableCell, Menu, MenuItem, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
//import { Box } from "@mui/system";
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell } from "../../../components/UXDataTable";
import { ChemIDSearchField } from "../../../components/ChemIDSearch";
import { formatCreatedAndModifiedDateInfo, isNumber, formatMidasNumber, EvaluateOptionalNumberField, RoundNumber, downloadFile, DatePickerKeyDownEvent, hasRole, Roles, EvaluateRequiredIntegerField} from "../../../global";
import ValidatedMidasNumberTextField from  "../../../components/ValidatedMidasNumberTextField";
import CompareBlends from "../NewBlends/CompareBlends";
import BulkEditComponents from "../NewBlends/BulkEditComponents";
import BlendPriority from "../../../api/Formulations/BlendPriority";
import BatchProductGroup from "../../../api/Formulations/BatchProductGroup";
import BlendFacility from "../../../api/Formulations/BlendFacility";
import BlendComponent from "../../../api/Formulations/BlendComponent";
import Batch from "../../../api/Formulations/Batch";
import BillingInfo from "../../../api/Admin/BillingInfo";
import Attachments from "../../../api/Formulations/Attachments";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import Location from "../../../api/Admin/Location";
import TrashIcon from '@mui/icons-material/Delete';
//import ContentCopy from '@mui/icons-material/ContentCopy';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import ModalMessages from "../../../components/Modal/ModalSimpleButton";
import SettingsIcon from '@mui/icons-material/Settings';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import LocateMidasNumber from "./LocateMidasNumber";
import MethodSelection from "../../../components/MethodSelection";
import { GlobalTabCollection, GlobalButton, GlobalSecondaryButton, StyledTab } from "../../../pages/styles";
import UserContext from "../../../context/UserContext";
import FileDragAndDrop from "../../../components/FileDragAndDrop";
import BillingInfoField from "../../../components/BillingInfoField"; 
import EmailAddressTextField from "../../../components/EmailAddressField";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import BlendRequest from "../../../api/Formulations/BlendRequest";

const autoCompleteFontSize = 16
const componentGridFontSize = 12
const bottomMargins = "5px"
const errorsModalTitle = "Errors Detected"

const NoComponentsMessage = "No Components Added"
const helperLabel = "Required Input"
const helperLabelNumber = "Invalid"

const StyledShortTextField = styled(TextField)({
    id:"outlined-normal",
    width:"15%",
    //marginRight:"15px",
    marginBottom:bottomMargins
});

const StyledMediumTextField = styled(TextField)({
    id:"outlined-normal",
    width: "calc(30% + 1rem)",
    //marginRight:"15px",
    marginBottom:bottomMargins
});

const StyledLargeTextField = styled(TextField)({
    id:"outlined-normal",
    width:"46%",
    //marginRight:"15px",
    marginBottom:bottomMargins
});

const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontSize: autoCompleteFontSize,
    },
    width:"15%",
    //marginRight:"15px",
    marginBottom:bottomMargins
});

const StyledDivider = styled(Divider)({
    marginTop:"10px"
});

const StyledDiv = styled('div')({
    display:"flex", 
    marginTop:"5px"
});

const Option = styled('li')({
    fontSize: componentGridFontSize,
  });

const MyComponentCols = ['Balance', 'Chem ID', 'Description', 'Locate Sample', 'MIDAS #','Alt. MIDAS #', 'Units',  'Target', 'Add Order', 'Instructions', 'Add Temp (C)']
const MyDestinations = ['Internal', 'Shipping']
const BlendNames = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']

const batchComponentCols = ['ID', 'MIDAS #', 'Container', 'Chem ID', 'Substituation Reason', 'Actual Amount',  'Order Added', 'Actual Temp (C)']

const BlendsAndBatches = ({ myStudy, setMyStudy, errorCheck, setErrorCheck, defaultBlendRequest, defaultBlendRequestErrorCheck, defaultBlendComponent, 
                            defaultBlendComponentErrorCheck, defaultBatch, defaultBatchErrorCheck, GetTotalNeededForTesting, CheckBlendComponents, SubmitBlendStudy, SumOfBlendTargets, 
                            openModalNoButton, closeModalNoButton, reloadData, setReloadData }) => {

    const currentUser = useContext(UserContext)
    const [tabValue, setTabValue] = useState(0)
    const [tabValueBatch, setTabValueBatch] = useState(0)
    const [blendComponentIndex, setBlendComponentIndex] = useState(0)

    const [availableProductGroups, setAvailableProductGroups] = useState([])
    const [availableUOMs, setAvailableUOMs] = useState([])
    const [availableLocations, setAvailableLocations] = useState([])
    const [availableBlendPriorities, setAvailableBlendPriorities] = useState([])
    const [availableBlendFacilities, setAvailableBlendFacilities] = useState([])
    const [availableBillingInfo, setAvailableBillingInfo] = useState([])

    const [modalInstructionsOpen, setModalInstructionsOpen] = useState(false)
    const [modalInstructionsButton1Text, setModalInstructionsButton1Text] = useState('')
    const [modalInstructionsButton2Text, setModalInstructionsButton2Text] = useState('')
    const [modalInstructionsTitle, setModalInstructionsTitle] = useState('')
    const [modalInstructionsText, setModalInstructionsText] = useState('')
    const [tempComponentInstruction, setTempComponentInstruction] = useState('')
    const [tempComponentIndex, setTempComponentIndex] = useState(-1)

    const [modalDupeBatchOpen, setModalDupeBatchOpen] = useState(false)
    const [modalDupeBatchButton1Text, setModalDupeBatchButton1Text] = useState('')
    const [modalDupeBatchButton2Text, setModalDupeBatchButton2Text] = useState('')
    const [modalDupeBatchTitle, setModalDupeBatchTitle] = useState('')
    const [modalDupeBatchText, setModalDupeBatchText] = useState('')

    const [modalDupeBatchTestingOpen, setModalDupeBatchTestingOpen] = useState(false)
    const [modalDupeBatchTestingButton1Text, setModalDupeBatchTestingButton1Text] = useState('')
    const [modalDupeBatchTestingButton2Text, setModalDupeBatchTestingButton2Text] = useState('')
    const [modalDupeBatchTestingTitle, setModalDupeBatchTestingTitle] = useState('')
    const [modalDupeBatchTestingText, setModalDupeBatchTestingText] = useState('')

    const [modalTestsOpen, setModalTestsOpen] = useState(false)
    const [modalTestsButton1Text, setModalTestsButton1Text] = useState('')
    const [modalTestsButton2Text, setModalTestsButton2Text] = useState('')
    const [modalTestsTitle, setModalTestsTitle] = useState('')
    const [modalTestsText, setModalTestsText] = useState('')

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const [modalDeleteAttachOpen, setModalDeleteAttachOpen] = useState(false);
    const [modalDeleteAttachButton1Text, setModalDeleteAttachButton1Text] = useState('');
    const [modalDeleteAttachButton2Text, setModalDeleteAttachButton2Text] = useState('');
    const [modalDeleteAttachTitle, setModalDeleteAttachTitle] = useState('');
    const [modalDeleteAttachText, setModalDeleteAttachText] = useState('');

    const [modalDeleteComponentOpen, setModalDeleteComponentOpen] = useState(false);
    const [modalDeleteComponentButton1Text, setModalDeleteComponentButton1Text] = useState('');
    const [modalDeleteComponentButton2Text, setModalDeleteComponentButton2Text] = useState('');
    const [modalDeleteComponentTitle, setModalDeleteComponentTitle] = useState('');
    const [modalDeleteComponentText, setModalDeleteComponentText] = useState('');

    const [componentIDToDelete, setComponentIDToDelete] = useState(null);

    const [modalCopyMidasNumberOpen, setModalCopyMidasNumberOpen] = useState(false);
    const [modalCopyMidasNumberButton1Text, setModalCopyMidasNumberButton1Text] = useState('');
    const [modalCopyMidasNumberButton2Text, setModalCopyMidasNumberButton2Text] = useState('');
    const [modalCopyMidasNumberTitle, setModalCopyMidasNumberTitle] = useState('');
    const [modalCopyMidasNumberText, setModalCopyMidasNumberText] = useState('');

    const [midasNumberToFind, setMidasNumberToFind] = useState(null);
    
    const [modalCancelBatchOpen, setModalCancelBatchOpen] = useState(false);
    const [modalCancelBatchButton1Text, setModalCancelBatchButton1Text] = useState('');
    const [modalCancelBatchButton2Text, setModalCancelBatchButton2Text] = useState('');
    const [modalCancelBatchTitle, setModalCancelBatchTitle] = useState('');
    const [modalCancelBatchText, setModalCancelBatchText] = useState('');

    const [cancelReason, setCancelReason] = useState(null)

    const [modalInventoryCheckOpen, setModalInventoryCheckOpen] = useState(false);
    const [modalInventoryCheckButton1Text, setModalInventoryCheckButton1Text] = useState('');
    const [modalInventoryCheckButton2Text, setModalInventoryCheckButton2Text] = useState('');
    const [modalInventoryCheckTitle, setModalInventoryCheckTitle] = useState('');
    const [modalInventoryCheckText, setModalInventoryCheckText] = useState('');

    const [tempCopyToBlendName, setTempCopyToBlendName] = useState(null)
    const [tempCopyToBlendHasErrors, setTempCopyToBlendHasErrors] = useState(false)
    const [duplicateType, setDuplicateType] = useState(null)

    const [tempCopyToBatchNumber, setTempCopyToBatchNumber] = useState(null)
    const [tempCopyToBatchNumberHasErrors, setTempCopyToBatchNumberHasErrors] = useState(false)

    const [modalCompareBlendsOpen, setModalCompareBlendsOpen] = useState(false)
    const [modalBulkEditComponentsOpen, setModalBulkEditComponentsOpen] = useState(false)

    const [modalLocateMIDASNumberOpen, setModallocateMIDASNumberOpen] = useState(false)

    const [anchorEl, setAnchorEl] = useState(PopulateAnchorEl());
    const [anchorElComponents, setAnchorElComponents] = useState(PopulateAnchorElComponents());
    const [anchorElBlends, setAnchorElBlends] = useState(PopulateAnchorElBlends());


    const [tempSelectedMethods, setTempSelectedMethods] = useState(myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject)

    const roles = currentUser?.idTokenClaims.roles;
    const isDeveloper = hasRole(Roles.Developer, roles);
    const isBlendMaintainer = hasRole(Roles.BlendMaintainer, roles);

    //disable the add batch and blend buttons if you arent the owner
    const userDisabled = (myStudy.ownerEmail !== currentUser.username && myStudy.ownerEmail !== '' && myStudy.ownerEmail !== null && isDeveloper === false && isBlendMaintainer === false)

    //determines when to disable the reqeuests and batches
    const masterDisableRequests = (myStudy.blendRequests[tabValue].blendStatusName !== 'Draft' 
                                    //&& myStudy.blendRequests[tabValue].blendStatusName !== 'Submitted' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== 'Needs Review' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== 'Migrated' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== 'InProgress' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== 'OnHold' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== '' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== null) || userDisabled

    const masterDisableComponents = (myStudy.blendRequests[tabValue].blendStatusName !== 'Draft' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== 'Needs Review' 
                                    //&& myStudy.blendRequests[tabValue].blendStatusName !== 'Submitted' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== null) || userDisabled

    const masterDisableBatch = (myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== 'Draft' 
                                    //&& myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== 'Submitted'
                                    && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== 'OnHold' 
                                    && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== 'Needs Review' 
                                    && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== '' 
                                    && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== null) || userDisabled

    const masterDisableBatchStatusMenu = (myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== 'Draft' 
                                        && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== 'OnHold' 
                                        && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== 'Submitted' 
                                        && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== 'Needs Review' 
                                        && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== '' 
                                        && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== null) || userDisabled
    
    const [attachmentDeleteOptions, setAttachmentDeleteOptions] = useState(null)

    const blendErrorObj = errorCheck.blendRequests.find(obj => obj._blendLetter === myStudy.blendRequests[tabValue].blendName)
    const batchErrorObj = blendErrorObj.batches.find(obj => obj._batchNumber === myStudy.blendRequests[tabValue].batches[tabValueBatch].batchSequenceNumber)

  function PopulateAnchorEl ()
  {
    let newAnchorEl = []

    for(var i = 0; i <  myStudy.blendRequests.length; i++) 
    {
        newAnchorEl.push({batch:[null]})
    }    
    return newAnchorEl
  }

  function PopulateAnchorElComponents ()
  {
    let newAnchorEl = []

    for(var i = 0; i <  myStudy.blendRequests.length; i++) 
    {
        newAnchorEl.push(null)
    }    
    return newAnchorEl
  }

  function PopulateAnchorElBlends ()
  {
    let newAnchorEl = []

    for(var i = 0; i <  myStudy.blendRequests.length; i++) 
    {
        newAnchorEl.push(null)
    }    
    return newAnchorEl
  }
   //get all the enums on render
  useEffect(() => {
        let cancelPromise = false

        BatchProductGroup.getAllBatchProductGroups().then((res) => {
            if (cancelPromise) return
            setAvailableProductGroups(res.filter(result => result.isActive === true).sort((a, b) => a.name.localeCompare(b.name)))
        });

        BlendPriority.getAllBlendPriorities().then((res) => {
            if (cancelPromise) return
            setAvailableBlendPriorities(res.filter(result => result.isActive === true).sort((a, b) => a.blendPriorityName.localeCompare(b.blendPriorityName)))
        });

        BlendFacility.getAllFacilities().then((res) => {
            if (cancelPromise) return
            setAvailableBlendFacilities(res.filter(result => result.isActive === true).sort((a, b) => a.blendFacilityName.localeCompare(b.blendFacilityName)))
        });

        UnitOfMeasure.getAll().then((res) => {
            if (cancelPromise) return
            setAvailableUOMs(res.filter(result => ((result.type === 'weight' || result.type === 'volume') && result.metricStandardConversion !== null && result.isActive === true) || (result.uoMName === 'wt%' || result.uoMName ==='vol%')).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
        // || result.type === 'blending-weight' || result.type === 'blending-volume'
        });
           
        Location.getAll().then((res) => {
            if (cancelPromise) return
            setAvailableLocations(res.filter(result => result.isActive === true).sort((a, b) => a.locationName.localeCompare(b.locationName)))
        });

        BillingInfo.getAll().then((res) => {
            if (cancelPromise) return

            if (res.message === 'Success')
            {
                setAvailableBillingInfo(res.result)               
            }
    
        });
        
        return () => {
            cancelPromise = true
        }

  }, [])

  function closeModalDeleteAttach() {
    setModalDeleteAttachOpen(false);
  }

  function openModalDeleteAttach(title, text, buttonText, button2Text) {
    setModalDeleteAttachButton1Text(buttonText)
    setModalDeleteAttachButton2Text(button2Text)
    setModalDeleteAttachOpen(true);
    setModalDeleteAttachTitle(title);
    setModalDeleteAttachText(text);
  }

  function closeModalDeleteComponent() {
    setModalDeleteComponentOpen(false);
  }

  function openModalDeleteComponent(title, text, buttonText, button2Text) {
    setModalDeleteComponentButton1Text(buttonText)
    setModalDeleteComponentButton2Text(button2Text)
    setModalDeleteComponentOpen(true);
    setModalDeleteComponentTitle(title);
    setModalDeleteComponentText(text);
  }

  function closeModalCopyMidasNumber() {
    setModalCopyMidasNumberOpen(false);
  }

  function openModalCopyMidasNumber(title, text, buttonText, button2Text) {
    setModalCopyMidasNumberButton1Text(buttonText)
    setModalCopyMidasNumberButton2Text(button2Text)
    setModalCopyMidasNumberOpen(true);
    setModalCopyMidasNumberTitle(title);
    setModalCopyMidasNumberText(text);
  }

  function closeModalInstructions() {
    setModalInstructionsOpen(false);
    setTempComponentInstruction('')
    setTempComponentIndex(-1)
  }

  function openModalInstructions(title, text, buttonText, button2Text, componentIndex) {
    setModalInstructionsButton1Text(buttonText)
    setModalInstructionsButton2Text(button2Text)
    setModalInstructionsOpen(true);
    setModalInstructionsTitle(title);
    setModalInstructionsText(text);

    setTempComponentIndex(componentIndex)
    setTempComponentInstruction(myStudy.blendRequests[tabValue].blendComponents[componentIndex].componentInstructions)
  }

  function closeModalDupeBatch() {
    setModalDupeBatchOpen(false);
  }

  function openModalDupeBatch(title, text, buttonText, button2Text) {
    setModalDupeBatchButton1Text(buttonText)
    setModalDupeBatchButton2Text(button2Text)
    setModalDupeBatchOpen(true);
    setModalDupeBatchTitle(title);
    setModalDupeBatchText(text);
  }

  function closeModalDupeBatchTesting() {
    setModalDupeBatchTestingOpen(false);
  }

  function openModalDupeBatchTesting(title, text, buttonText, button2Text) {
    setModalDupeBatchTestingButton1Text(buttonText)
    setModalDupeBatchTestingButton2Text(button2Text)
    setModalDupeBatchTestingOpen(true);
    setModalDupeBatchTestingTitle(title);
    setModalDupeBatchTestingText(text);
  }

  function closeModalInventoryCheck() {
    setModalInventoryCheckOpen(false);
  }

  function openModalInventoryCheck(title, text, buttonText, button2Text) {
    setModalInventoryCheckButton1Text(buttonText)
    setModalInventoryCheckButton2Text(button2Text)
    setModalInventoryCheckOpen(true);
    setModalInventoryCheckTitle(title);
    setModalInventoryCheckText(text);
  }

  function HandleCancelButtonOnTestModal()
  {
    setTempSelectedMethods([])
    closeModalTests()
  }

  function closeModalTests() {
    setModalTestsOpen(false);
  }

  function openModalTests(title, text, buttonText, button2Text) {
    setTempSelectedMethods(myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject)

    setModalTestsButton1Text(buttonText)
    setModalTestsButton2Text(button2Text)
    setModalTestsOpen(true);
    setModalTestsTitle(title);
    setModalTestsText(text);
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  function closeModalCancelReason() {
    setModalCancelBatchOpen(false);
  }

  function openModalCancelReason(title, text, buttonText, button2Text) {
    setModalCancelBatchButton1Text(buttonText)
    setModalCancelBatchButton2Text(button2Text)
    setModalCancelBatchOpen(true);
    setModalCancelBatchTitle(title);
    setModalCancelBatchText(text);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    setTabValueBatch(0)
  };

  const handleTabChangeBatches = (event, newValue) => {
    setTabValueBatch(newValue);
  };

  function updateErrorChecks_BlendComponent (property, value, componentIndex, blendIndex)
  {
    const copyErrors = structuredClone(errorCheck)

    copyErrors.blendRequests[blendIndex].blendComponents[componentIndex][property] = value

    if (property === 'preferredSample')
    {
        if (myStudy.blendRequests[blendIndex].blendComponents[componentIndex].preferredSample?.substance !== null)
        {
            copyErrors.blendRequests[blendIndex].blendComponents[componentIndex].substance = false      
        }
    }

    setErrorCheck(copyErrors)
  }

  function updateErrorChecks_Batch (property, value)
  {
    const copyErrors = structuredClone(errorCheck)

    copyErrors.blendRequests[tabValue].batches[tabValueBatch][property] = value

    setErrorCheck(copyErrors)
  }

  function updateData (property, value)
  {
    const copyStudyData = structuredClone(myStudy)

    copyStudyData.blendRequests[tabValue][property] = value
    copyStudyData.blendRequests[tabValue].hasBeenEdited = true

    setMyStudy(copyStudyData)
  }

//   function updateErrorChecks (property, value)
//   {
//     const copyErrors = structuredClone(errorChecks)

//     copyErrors.blendRequests[tabValue][property] = value

//     setErrorCheck(copyErrors)
//   }

  function updateData_BlendComponent (property, value, componentIndex, blendIndex)
  {
    const copyStudyData = structuredClone(myStudy)

    copyStudyData.blendRequests[blendIndex].hasBeenEdited = true

    copyStudyData.blendRequests[blendIndex].blendComponents[componentIndex][property] = value

    if (property === 'preferredSample')
    {
        if (copyStudyData.blendRequests[blendIndex].blendComponents[componentIndex].substance === null)
        {
            if (copyStudyData.blendRequests[blendIndex].blendComponents[componentIndex].preferredSample !== null)
            {
                copyStudyData.blendRequests[blendIndex].blendComponents[componentIndex].substance = copyStudyData.blendRequests[blendIndex].blendComponents[componentIndex].preferredSample.substance 
            }
        }
    }

    if (property === 'substance')
    {
        if (copyStudyData.blendRequests[blendIndex].blendComponents[componentIndex].preferredSample !== null)
        {
            copyStudyData.blendRequests[blendIndex].blendComponents[componentIndex].preferredSample = null
        }
        if (copyStudyData.blendRequests[blendIndex].blendComponents[componentIndex].secondaryPreferredSample !== null)
        {
            copyStudyData.blendRequests[blendIndex].blendComponents[componentIndex].secondaryPreferredSample = null
        }
    }

    if (property === 'isBalance')
    {
        const copyErrors = structuredClone(errorCheck)

        if (value === true)
        {
            const balanceValue = GetBalanceValue(copyStudyData.blendRequests[blendIndex].blendComponents)
            copyStudyData.blendRequests[blendIndex].blendComponents[componentIndex].targetAmountLower = balanceValue

            copyErrors.blendRequests[blendIndex].blendComponents[componentIndex].targetAmountLower = false

        } else {
            copyStudyData.blendRequests[blendIndex].blendComponents[componentIndex].targetAmountLower = null
                        
            copyErrors.blendRequests[blendIndex].blendComponents[componentIndex].targetAmountLower = null
        }
            
        setErrorCheck(copyErrors)
    }

    if (property === 'targetAmountLower')// || property === 'targetAmountUpper')
    {
        const copyErrors = structuredClone(errorCheck)

        copyStudyData.blendRequests[blendIndex].blendComponents.forEach((oComponent, index) => 
        {
            if (oComponent.isBalance === true)
            {
                const balanceValue = GetBalanceValue(copyStudyData.blendRequests[blendIndex].blendComponents)
  
                copyStudyData.blendRequests[blendIndex].blendComponents[index].targetAmountLower = balanceValue
            }

            //copyErrors.blendRequests[blendIndex].blendComponents[index].targetAmountLower = EvaluateLowerAmountPercentages(oComponent, copyStudyData)    
        })

        copyStudyData.blendRequests[blendIndex].blendComponents.forEach((oComponent, index) => 
        {
            copyErrors.blendRequests[blendIndex].blendComponents[index].targetAmountLower = EvaluateLowerAmountPercentages(oComponent, copyStudyData, tabValue)    
        })

        setErrorCheck(copyErrors)
    }

    setMyStudy(copyStudyData)
  }

  function updateComponentSortOrder (currentIndex, newIndex) {
    const copyStudyData = structuredClone(myStudy)

    const element = copyStudyData.blendRequests[tabValue].blendComponents.splice(currentIndex, 1)[0]

    copyStudyData.blendRequests[tabValue].blendComponents.splice(newIndex, 0, element)

    copyStudyData.blendRequests[tabValue].hasBeenEdited = true

    copyStudyData.blendRequests[tabValue].blendComponents[currentIndex].sortOrder = newIndex - 1;

    setMyStudy(copyStudyData)
  }

  function GetBalanceValue(arrComponents){
    let targetValue = 100
    const workingUOM = myStudy.blendRequests[tabValue].requestUoM

    arrComponents.forEach((oComponent) => {
      if(isNumber(oComponent.targetAmountLower))
      {
        if (oComponent.isBalance === false && workingUOM.uoMName === oComponent.componentUoM.uoMName)
        {
            targetValue = RoundNumber(targetValue - RoundNumber(oComponent.targetAmountLower, 3), 3)
        }
      }
    })

    return targetValue
  }

  function updateData_Batch (property, value, batchIndex)
  {
    const copyStudyData = structuredClone(myStudy)

    copyStudyData.blendRequests[tabValue].hasBeenEdited = true
    copyStudyData.blendRequests[tabValue].batches[batchIndex].hasBeenEdited = true

    copyStudyData.blendRequests[tabValue].batches[batchIndex][property] = value

    if (property === 'chargeCode')
    {
        updateErrorChecks_Batch(property,  copyStudyData.blendRequests[tabValue].batches[batchIndex][property] === "" ||  copyStudyData.blendRequests[tabValue].batches[batchIndex][property] === null)
    }

    setMyStudy(copyStudyData)
  }
 
  function AutoCompleteChangeAndValidate_Batch (property, value, batchIndex)
  {
    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorCheck)

    copyMyStudy.blendRequests[tabValue].hasBeenEdited = true
    copyMyStudy.blendRequests[tabValue].batches[batchIndex].hasBeenEdited = true

    copyMyStudy.blendRequests[tabValue].batches[batchIndex][property] = value
    copyErrors.blendRequests[tabValue].batches[batchIndex][property] = value === null ? true : false 
    
    if (property === 'blendPriority')
    {
        if (value)
        {
            if (value.blendPriorityName === 'Normal')
            {
                copyMyStudy.blendRequests[tabValue].batches[batchIndex].priorityReason = ''   
                copyErrors.blendRequests[tabValue].batches[batchIndex].priorityReason = false    
            } else {
                if (copyMyStudy.blendRequests[tabValue].batches[batchIndex].priorityReason === null || copyMyStudy.blendRequests[tabValue].batches[batchIndex].priorityReason.trim() === '')
                {
                    copyErrors.blendRequests[tabValue].batches[batchIndex].priorityReason = true
                } else {
                    copyErrors.blendRequests[tabValue].batches[batchIndex].priorityReason = false                    
                }
            }
        } else {
            copyMyStudy.blendRequests[tabValue].batches[batchIndex].priorityReason = ''   
            copyErrors.blendRequests[tabValue].batches[batchIndex].priorityReason = false  
            
            copyMyStudy.blendRequests[tabValue].batches[batchIndex].blendPriorityName = null
        }
    }

    if (property === 'batchRetainLocation' && value === null)
    {
        copyMyStudy.blendRequests[tabValue].batches[batchIndex].batchRetainLocationName = null
    }

    if (property === 'unitOfMeasureRequested' && value === null)
    {
        copyMyStudy.blendRequests[tabValue].batches[batchIndex].requestedAmountUoM = null
    }

    if (property === 'batchProductGroup' && value === null)
    {
        copyMyStudy.blendRequests[tabValue].batches[batchIndex].batchProductGroupName = null
    }

    if (property === 'blendFacility' && value === null)
    {
        copyMyStudy.blendRequests[tabValue].batches[batchIndex].blendFacilityName = null
    }

    setMyStudy(copyMyStudy)
    setErrorCheck(copyErrors)
  }

  function EvaluatePriorityReason (batchIndex)
  {
    if (myStudy.blendRequests[tabValue].batches[batchIndex].blendPriority && myStudy.blendRequests[tabValue].batches[batchIndex].blendPriority.blendPriorityName !== 'Normal')
    {
       if (myStudy.blendRequests[tabValue].batches[batchIndex].priorityReason === null || myStudy.blendRequests[tabValue].batches[batchIndex].priorityReason.trim() === '')
       {
           return true
       } else {
           return false
       }
    } else {
       return false          
    }
  }

  function AutoCompleteChangeAndValidate_BlendRequest (property, value)
  {
    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorCheck)

    copyMyStudy.blendRequests[tabValue].hasBeenEdited = true
    
    copyMyStudy.blendRequests[tabValue][property] = value
    copyErrors.blendRequests[tabValue][property] = value === null ? true : false 

    if (property === 'requestUoM')
    {
        for(var iComponent = 0; iComponent <  copyMyStudy.blendRequests[tabValue].blendComponents.length; iComponent++) {
            if (copyMyStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM !== null)
            {
                if (value === null)
                {
                    copyMyStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM = null
                    copyMyStudy.blendRequests[tabValue].blendComponents[iComponent].isBalance = false

                } else {
                    if (value.type !==  copyMyStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM.type)
                    {
                        copyMyStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM = null
                        copyMyStudy.blendRequests[tabValue].blendComponents[iComponent].isBalance = false
                    }
                }
            }
        }
    }

    setMyStudy(copyMyStudy)
    setErrorCheck(copyErrors)
  }

  function AutoCompleteChangeAndValidate_BlendComponent (property, value, componentIndex)
  {
    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorCheck)

    copyMyStudy.blendRequests[tabValue].hasBeenEdited = true
    
    copyMyStudy.blendRequests[tabValue].blendComponents[componentIndex][property] = value
    copyErrors.blendRequests[tabValue].blendComponents[componentIndex][property] = value === null ? true : false 

    if (property === 'componentUoM')
    {
        if (value === null)
        {
            copyMyStudy.blendRequests[tabValue].blendComponents[componentIndex].isBalance = false       
        }

        //copyMyStudy.blendRequests[tabValue].blendComponents[componentIndex].targetAmountUpper = null
        copyMyStudy.blendRequests[tabValue].blendComponents[componentIndex].targetAmountLower = null
                    
        //copyErrors.blendRequests[tabValue].blendComponents[componentIndex].targetAmountUpper = null
        copyErrors.blendRequests[tabValue].blendComponents[componentIndex].targetAmountLower = null
    }

    setMyStudy(copyMyStudy)
    setErrorCheck(copyErrors)
  }

  function GetAdditionOrderValues ()
  {
    let additionOrderValues = []

    for(var i = 0; i <  myStudy.blendRequests[tabValue].blendComponents.length; i++) {
       additionOrderValues.push(i + 1)
    }

    myStudy.blendRequests[tabValue].blendComponents.forEach(component => {
        if (component.addOrder && component.addOrder > 0)
        {
            additionOrderValues = additionOrderValues.filter(number => number !== component.addOrder)
        }
    });

    return additionOrderValues
  }

  function AddNewBlend (blendObject, errorChecksObject)
  {
    const newBlendObj = structuredClone(blendObject)
    const newErrorObj = structuredClone(errorChecksObject)

    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorCheck)
    const copyAnchorEl = structuredClone(anchorEl)
    const copyAnchorElComponents = structuredClone(anchorElComponents)
    const copyAnchorElBlends = structuredClone(anchorElBlends)


    newBlendObj.id = 0
    newBlendObj.blendStatusName = 'Draft'
    newBlendObj.blendSubstanceName = null
    newBlendObj.createdByEmail = ''
    newBlendObj.createdDate = null
    newBlendObj.lastModifiedByEmail = null
    newBlendObj.lastModifiedDate = null

    for(var x = 0; x <  newBlendObj.blendComponents.length; x++) 
    {
        newBlendObj.blendComponents[x].id = 0
        newBlendObj.blendComponents[x].blendID = 0
        newBlendObj.blendComponents[x].componentUoMName = null
        newBlendObj.blendComponents[x].preferredSampleName = null
        newBlendObj.blendComponents[x].secondaryPreferredSampleName = null
        newBlendObj.blendComponents[x].substanceName = null
    }

    for(var z = 0; z <  newBlendObj.batches.length; z++) 
    {
        newBlendObj.batches[z].id = 0
        newBlendObj.batches[z].actualAmount = null
        newBlendObj.batches[z].actualAmountUoM = null
        newBlendObj.batches[z].blendID = 0
        newBlendObj.batches[z].batchStatusName = 'Draft'
        newBlendObj.batches[z].batchCertificationStatusName = null
        newBlendObj.batches[z].batchComponents = null
        newBlendObj.batches[z].lastCertifiedByEmail = null
        newBlendObj.batches[z].lastCertifiedDate = null
        newBlendObj.batches[z].preparedByEmail = null
        newBlendObj.batches[z].preparedDate = null
        newBlendObj.batches[z].preparedSample = null
        newBlendObj.batches[z].preparedSampleName = null
        newBlendObj.batches[z].shelfLifeStartDate = null
        newBlendObj.batches[z].shelfLifeEndDate = null
        newBlendObj.batches[z].requestedCompletionDate = null
        newBlendObj.batches[z].lastModifiedByEmail = null
        newBlendObj.batches[z].lastModifiedDate = null
        newBlendObj.batches[z].createdByEmail = null
        newBlendObj.batches[z].createdDate = null
        newBlendObj.batches[z].batchAppearance = null
        newBlendObj.batches[z].batchAppearanceName = null
        newBlendObj.batches[z].freeWater = null
        newBlendObj.batches[z].appearanceComments = null
        newBlendObj.batches[z].containerJSON = null

    }

    copyAnchorEl.push({batch:[null]})
    copyAnchorElComponents.push(null)
    copyAnchorElBlends.push(null)

    const newestBlend = myStudy.blendRequests.sort(function(a, b) {
        return b.blendName.length - a.blendName.length || b.blendName.localeCompare(a.blendName)
      }).slice(0, 1).shift();

    if (newestBlend.blendName.length > 2)
    {
        openModalMessages("Blend Error", "Cannot go past blend ZZ!")
        return
    }

    if (newestBlend.blendName.length > 1)
    {
        var arr = newestBlend.blendName.match(/.{1}/g)

        var firstLetter = arr[0];
        var secondLetter = arr[1];

        if (secondLetter === 'Z')
        {
            newBlendObj.blendName = `${BlendNames[BlendNames.indexOf(firstLetter) + 1]}${BlendNames[0]}`;
        } else {
            newBlendObj.blendName = `${BlendNames[BlendNames.indexOf(firstLetter)]}${BlendNames[BlendNames.indexOf(secondLetter) + 1]}`;
        }
    }
    else
    { 
        if (newestBlend.blendName === 'Z')
        {
            newBlendObj.blendName = `${BlendNames[0]}${BlendNames[0]}`;
        } else {
            newBlendObj.blendName = `${BlendNames[BlendNames.indexOf(newestBlend.blendName) + 1]}`;
        }
    }

    copyMyStudy.blendRequests.push(newBlendObj)

    newErrorObj._blendLetter = newBlendObj.blendName
    copyErrors.blendRequests.push(newErrorObj)

    setMyStudy(copyMyStudy)
    setErrorCheck(copyErrors)
    setAnchorEl(copyAnchorEl)
    setAnchorElComponents(copyAnchorElComponents)
    setAnchorElBlends(copyAnchorElBlends)

    setTabValue(copyMyStudy.blendRequests.length -1)
    setTabValueBatch(0)
  }

  function AddNewComponent ()
  {
    const newBlendComponentObj = structuredClone(defaultBlendComponent)
    const newErrorObj = structuredClone(defaultBlendComponentErrorCheck)

    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorCheck)

    // newErrorObj._blendLetter = copyMyStudy.blendRequests[tabValue].blendName

    if (copyMyStudy.blendRequests[tabValue].requestUoM)
    {
        if (copyMyStudy.blendRequests[tabValue].requestUoM.uoMName === 'wt%')
        {
            newBlendComponentObj.componentUoM = {uoMName: 'wt%', type: 'weight', metricStandardUoMName: null, metricStandardConversion: null}
        } else {
            newBlendComponentObj.componentUoM = {uoMName: 'vol%', type: 'volume', metricStandardUoMName: null, metricStandardConversion: null}
        }
    } else {
        
        newErrorObj.componentUoM = null;
    }

    copyMyStudy.blendRequests[tabValue].blendComponents.push(newBlendComponentObj)
    copyErrors.blendRequests[tabValue].blendComponents.push(newErrorObj)

    setMyStudy(copyMyStudy)
    setErrorCheck(copyErrors)
  }

  function AddNewBatch (batchObject, errorChecksObject, blendIndex)
  {
    const newBatchObj = structuredClone(batchObject)
    const newErrorObj = structuredClone(errorChecksObject)

    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorCheck)
    const copyAnchorEl = structuredClone(anchorEl)

    newBatchObj.id = 0
    newBatchObj.actualAmount = null
    newBatchObj.actualAmountUoM = null
    newBatchObj.blendID = 0
    newBatchObj.batchStatusName = 'Draft'
    newBatchObj.batchCertificationStatusName = null
    newBatchObj.batchComponents = null
    newBatchObj.lastCertifiedByEmail = null
    newBatchObj.lastCertifiedDate = null
    newBatchObj.preparedByEmail = null
    newBatchObj.preparedDate = null
    newBatchObj.preparedSample = null
    newBatchObj.preparedSampleName = null
    newBatchObj.shelfLifeStartDate = null
    newBatchObj.shelfLifeEndDate = null
    newBatchObj.requestedCompletionDate = null
    newBatchObj.lastModifiedByEmail = null
    newBatchObj.lastModifiedDate = null
    newBatchObj.createdByEmail = null
    newBatchObj.createdDate = null
    newBatchObj.batchAppearance = null
    newBatchObj.batchAppearanceName = null
    newBatchObj.freeWater = null
    newBatchObj.appearanceComments = null
    newBatchObj.containerJSON = null

    const newestBatch = myStudy.blendRequests[blendIndex].batches.sort((a, b) => b.batchSequenceNumber - a.batchSequenceNumber).slice(0, 1).shift();

    newBatchObj.batchSequenceNumber = newestBatch.batchSequenceNumber + 1;

    copyMyStudy.blendRequests[blendIndex].batches.push(newBatchObj)

    newErrorObj._blendLetter = copyMyStudy.blendRequests[blendIndex].blendName
    newErrorObj._batchNumber =  newBatchObj.batchSequenceNumber

    copyErrors.blendRequests[blendIndex].batches.push(newErrorObj)
    copyAnchorEl[blendIndex].batch.push(null)

    setMyStudy(copyMyStudy)
    setErrorCheck(copyErrors)
    setTabValue(blendIndex)
    setTabValueBatch(copyMyStudy.blendRequests[blendIndex].batches.length -1)
    setAnchorEl(copyAnchorEl)
  }

  function DeleteBlend ()
  {
    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorCheck)
    const copyAnchorEl = structuredClone(anchorEl)
    const copyAnchorElComponents = structuredClone(anchorElComponents)
    const copyAnchorElBlends = structuredClone(anchorElBlends)

    copyMyStudy.blendRequests.splice(tabValue, 1)
    copyErrors.blendRequests.splice(tabValue, 1)

    copyAnchorEl.splice(tabValue, 1)
    copyAnchorElComponents.splice(tabValue, 1)
    copyAnchorElBlends.splice(tabValue, 1)

    let i = 0
    for(i = tabValue; i < copyMyStudy.blendRequests.length; i++)
    {
        //set the blend name for everything downstream
        copyMyStudy.blendRequests[i].blendName = BlendNames[i]
        copyErrors.blendRequests[i]._blendLetter = BlendNames[i]
    }

    setMyStudy(copyMyStudy)
    setErrorCheck(copyErrors)
    setTabValue(0)
    setTabValueBatch(0)
    setAnchorEl(copyAnchorEl)
    setAnchorElComponents(copyAnchorElComponents)
    setAnchorElBlends(copyAnchorElBlends)  
  }

  function DeleteComponent (componentIndex, componentID)
  {

    if (componentID > 0)
    {
        setComponentIDToDelete(componentID)
        openModalDeleteComponent(`Remove Component?`, "Are you sure you want to permanently remove this component?  This is permenant action.", "Yes", "No")

    } else {
        const copyMyStudy = structuredClone(myStudy)
        const copyErrors = structuredClone(errorCheck)
    
        copyMyStudy.blendRequests[tabValue].blendComponents.splice(componentIndex, 1)
        copyErrors.blendRequests[tabValue].blendComponents.splice(componentIndex, 1)
    
        setMyStudy(copyMyStudy)
        setErrorCheck(copyErrors)
    }
  }

  function PermanentDeleteComponent () {

    closeModalDeleteComponent()

    BlendComponent.delete(componentIDToDelete).then((res) => {  
        if (res && res.result === 'Success') 
        {
            openModalMessages('Component Deleted', `The blend component has been deleted successfully!`)

            const copyMyStudy = structuredClone(myStudy)
            const copyErrors = structuredClone(errorCheck)

            const componentIndex = copyMyStudy.blendRequests[tabValue].blendComponents.findIndex(bc => bc.id === componentIDToDelete)
        
            copyMyStudy.blendRequests[tabValue].blendComponents.splice(componentIndex, 1)
            copyErrors.blendRequests[tabValue].blendComponents.splice(componentIndex, 1)
        
            setMyStudy(copyMyStudy)
            setErrorCheck(copyErrors)

        } else {
            openModalMessages('Blend Component Failed to Delete', ` ${res ? res.message : 'Unspecified Error'}. Contact support if you feel this is an error.`);          
        }
  
    });

    setComponentIDToDelete(null)
  }

  function DeleteBatch (batchIndex)
  {
    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorCheck)
    const copyAnchorEl = [...anchorEl]

    copyMyStudy.blendRequests[tabValue].batches.splice(batchIndex, 1)
    copyErrors.blendRequests[tabValue].batches.splice(batchIndex, 1)
    copyAnchorEl[tabValue].batch.splice(batchIndex, 1)

    let i = 0
    for(i = tabValue; i < copyMyStudy.blendRequests[tabValue].batches.length; i++)
    {
        //set the blend name for everything downstream
        copyMyStudy.blendRequests[tabValue].batches[i].batchSequenceNumber = i + 1
    }

    setMyStudy(copyMyStudy)
    setErrorCheck(copyErrors)
    setTabValueBatch(0)
    setAnchorEl(copyAnchorEl)
  }

  function EvaluateLowerAmountPercentages (oComponent, study, blendIndex)
  {
    const value = oComponent.targetAmountLower
    const workingUOM = study.blendRequests[blendIndex].requestUoM

    if (workingUOM === null)
    {
        return true
    }

    if (value === null || value === '') 
    {
        return false
    } else
    {
        let numberCheck = isNumber(value)
        let totalPercentage = 0

        if (numberCheck)
        {
            if (RoundNumber(value, 3) < 0)
            {
                return true
            }

            study.blendRequests[blendIndex].blendComponents.forEach((oComponent) => {
                if (isNumber(oComponent.targetAmountLower)  && workingUOM.uoMName === oComponent.componentUoM?.uoMName)
                {
                    totalPercentage = RoundNumber(totalPercentage + RoundNumber(oComponent.targetAmountLower, 3), 3)
                }
            })

            if (isNumber(oComponent.targetAmountLower))
            {
                if (workingUOM.uoMName !== oComponent.componentUoM?.uoMName){
                    return false
                }
            } else {
                return true 
            }

            return(totalPercentage > 100)
        }
        else
        {
            return(!(numberCheck))
        }
    }

  }

  function SaveOrUpdateComponentInstructions ()
  {
      updateData_BlendComponent('componentInstructions', tempComponentInstruction, tempComponentIndex, tabValue)
      closeModalInstructions()
  }

  function SetBatchStatus (batchID, newBatchStatusName, statusReason)
  {
    Batch.updateBatchStatus(batchID, newBatchStatusName, statusReason).then((res) => {

        if (res.message === 'Success') {
          openModalMessages('Batch Update Complete', `Batch set to ${newBatchStatusName} state!`)

          setReloadData(!reloadData)
        //   const copyMyStudy = structuredClone(myStudy)

        //   copyMyStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName = newBatchStatusName
        //   copyMyStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatus = {batchStatusName: newBatchStatusName, isActive: true , displayOrder: 0}

        //   setMyStudy(copyMyStudy)
        }
        else{
          if (res.message)
          {
            openModalMessages('Batch Update Failed', `${res.message}. Contact support if you feel this is an error.`);
          } else {
            openModalMessages('Batch Update Failed', `Unspecified Error, Contact support if you feel this is an error.`);
          }
            
        }
    });
  }

  function ConfirmCancel()
  {
    closeModalCancelReason()

    if (cancelReason === null || cancelReason.trim() === '')
    {
        openModalMessages('Batch Error', "You must provide a cancellation reason!")
        return;
    } else {
        SetBatchStatus(myStudy.blendRequests[tabValue].batches[tabValueBatch].id, 'Cancelled', cancelReason)
    }
  }

  function ConfirmDuplicateBatch ()
  {
      if ((!(tempCopyToBlendHasErrors) && tempCopyToBlendName !== null && duplicateType !== 'Testing') || (!(tempCopyToBatchNumberHasErrors) && tempCopyToBatchNumber !== null && duplicateType === 'Testing')) 
      {
        if (duplicateType === 'Batch')
        {
            const myIndex = myStudy.blendRequests.findIndex(obj => obj.blendName === tempCopyToBlendName.blendName)

            AddNewBatch(myStudy.blendRequests[tabValue].batches[tabValueBatch], batchErrorObj, myIndex)

        } else if (duplicateType === 'Testing')
        {
            const myIndex = myStudy.blendRequests[tabValue].batches.findIndex(obj => obj.batchSequenceNumber === tempCopyToBatchNumber.batchSequenceNumber)

            const copyMyStudy = structuredClone(myStudy)

            copyMyStudy.blendRequests[tabValue].batches[myIndex].analyticalTestingObject = copyMyStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject

            setMyStudy(copyMyStudy)

        } else if (duplicateType === 'Components')
        {
            const myIndex = myStudy.blendRequests.findIndex(obj => obj.blendName === tempCopyToBlendName.blendName)

            const copyMyStudy = structuredClone(myStudy)
            const copyErrors = structuredClone(errorCheck)

            const newBlendComponentObj = structuredClone(copyMyStudy.blendRequests[tabValue].blendComponents)
            const newErrorObj = structuredClone(errorCheck.blendRequests[tabValue].blendComponents)     
            
            for(var i = 0; i <  newBlendComponentObj.length; i++) 
            {
                newBlendComponentObj[i].id = 0
                newBlendComponentObj[i].blendID = 0
            }
        
            copyMyStudy.blendRequests[myIndex].blendComponents = newBlendComponentObj
            copyErrors.blendRequests[myIndex].blendComponents = newErrorObj
        
            setMyStudy(copyMyStudy)
            setErrorCheck(copyErrors)
        }
       
        closeModalDupeBatch()
        closeModalDupeBatchTesting()

        setTempCopyToBlendName(null)
        setTempCopyToBatchNumber(null)
      }     
  }

  const openMenuComponents = () => {
    return anchorElComponents[tabValue] != null
  }

  const openMenuBlends = () => {
    return anchorElBlends[tabValue] != null
  }

  const handleClickMenuComponents = (event) => {
    const newAnchorEl = [...anchorElComponents]

    newAnchorEl[tabValue] = event.currentTarget
    
    setAnchorElComponents(newAnchorEl)
  }

  const handleCloseMenuComponents= (e) => {
    const newAnchorEl = [...anchorElComponents]

    newAnchorEl[tabValue] = null
    
    setAnchorElComponents(newAnchorEl)
  }

  const openMenu = () => {
    return anchorEl[tabValue].batch[tabValueBatch] != null
  }

  const handleClickMenu = (event) => {
    const newAnchorEl = [...anchorEl]

    newAnchorEl[tabValue].batch[tabValueBatch] = event.currentTarget
    
    setAnchorEl(newAnchorEl)
  }

  const handleCloseMenu = (e) => {
    const newAnchorEl = [...anchorEl]

    newAnchorEl[tabValue].batch[tabValueBatch] = null
    
    setAnchorEl(newAnchorEl)
  }

  const handleClickMenuBlends = (event) => {
    const newAnchorEl = [...anchorElBlends]

    newAnchorEl[tabValue] = event.currentTarget
    
    setAnchorElBlends(newAnchorEl)
  }

  const handleCloseMenuBlends = (e) => {
    const newAnchorEl = [...anchorElBlends]

    newAnchorEl[tabValue] = null
    
    setAnchorElBlends(newAnchorEl)
  }

  function ConfirmTestsSelection ()
  {
    let errorMessages = []

    //check the method data for completness
    tempSelectedMethods.forEach((oMethod) =>
    {
        if (oMethod.method === null)
        {
            errorMessages.push('You must select a method for all entries.')
        }

        if (oMethod.method.isPackageTest === false)
        {
            if (oMethod.methodFacility === null)
            {
                errorMessages.push('You must select a method facility for all entries.')   
            } else {
                if (oMethod.methodFacility.requiresConditions)
                {
                    if (oMethod.conditionOffering === null)
                    {
                        errorMessages.push(`A condition is required for method ${oMethod.method.name} and method facility ${oMethod.methodFacility.testFacilityAbv}`)  
                    } else {
                    oMethod.conditionOffering.testConditionSetupValues.forEach((oTCSV, index) => {
                        if (oTCSV.discreteValue === null || oTCSV.discreteValue.trim() === '')
                        {
                            errorMessages.push(`A user defined condition value is required for method ${oMethod.method.name} and method facility ${oMethod.methodFacility.testFacilityAbv}`) 
                        }
                    })
                    }

                    if (oMethod.methodFacility.testFacilityAbv === 'EXT')
                    {
                        if(oMethod.externalLaboratory === null)
                        {
                            errorMessages.push(`An external lab selection is required for method ${oMethod.method.name} and method facility ${oMethod.methodFacility.testFacilityAbv}`)                  
                        }
                    }
                }

                if (oMethod.methodFacility.requiresEstimates)
                {
                    if (oMethod.estimate.trim() === '')
                    {
                        errorMessages.push(`An estimate is required for method ${oMethod.method.name} and method facility ${oMethod.methodFacility.testFacilityAbv}`)  
                    }
                }
            }

            if (oMethod.testPriority === null)
            {
                errorMessages.push('You must select a test priority for all entries.')
            }

            var today = new Date()
            var formattedDate = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`

            var comparDate = new Date(formattedDate)
            var reqDate = new Date(oMethod.requestedCompletionDate)

            if (oMethod.requestedCompletionDate && reqDate < comparDate){
                errorMessages.push(`Requested completion date for ${oMethod.method.name} cannot be a future date`)
            }
        }   
    })

    if (errorMessages.length === 0)
    {
        const copyMyStudy = structuredClone(myStudy)

        copyMyStudy.blendRequests[tabValue].batches[tabValueBatch].hasBeenEdited = true
        copyMyStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject = tempSelectedMethods
        //copyMyStudy.blendRequests[tabValue].analyticalTestingObject = tempSelectedMethods

        setMyStudy(copyMyStudy)
        setModalTestsOpen(false)
    } else {
        openModalMessages(errorsModalTitle, errorMessages, "Ok")
    }

  }

//   function DetermineNewStateToSet ()
//   {
//     let newState = ''

//     if ((myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedByEmail !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedByEmail !== '') && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComponents.length > 0)
//     {
//         newState = 'InProgress'
//     } else if (myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedByEmail !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedByEmail !== '')
//     {
//         newState = 'Assigned'
//     } else {
//         newState = 'Submitted'
//     }

//     return newState
//   }

//   function HandleFileAdd (newFiles) {
//     const listFiles = newFiles.map(item => {
//         return {
//             id: 0,
//             blendID: myStudy.blendRequests[tabValue].id,
//             fileName: item.name,
//             storagePath: '',
//             fileObject: item,
//             base64String: null
//         }
//     })

//     updateData('blendAttachments', myStudy.blendRequests[tabValue].blendAttachments.concat(listFiles))
//   }

  function deleteFile () {
    let newFiles

    closeModalDeleteAttach()

    if (attachmentDeleteOptions === null)
    { 
        return
    }

    const property = attachmentDeleteOptions.property

    if (attachmentDeleteOptions.file.id > 0)
    {
        Attachments.deleteBatchAttachment(attachmentDeleteOptions.file.id).then((res) => {
            if (res && res.message === 'Success') {
                newFiles = myStudy.blendRequests[tabValue].batches[tabValueBatch].batchAttachments.filter(f => f !== attachmentDeleteOptions.file)     
                setAttachmentDeleteOptions(null)
                updateData_Batch(property, newFiles, tabValueBatch)       
            } else {
                openModalMessages("Error Removing Attachment", `${res ? res.message : 'Error removing attachment'}.  Please try again or contact support.`)
                return
            }
        })
    } else {
        newFiles = myStudy.blendRequests[tabValue].batches[tabValueBatch].batchAttachments.filter(f => f !== attachmentDeleteOptions.file)    
        setAttachmentDeleteOptions(null)
        updateData_Batch(property, newFiles, tabValueBatch)
    }  

    // const property = attachmentDeleteOptions.property

    // if (property === 'blendAttachments')
    // {
        // if (attachmentDeleteOptions.file.id > 0)
        // {
        //     Attachments.deleteBlendAttachment(attachmentDeleteOptions.file.id).then((res) => {
        //         if (res && res.message === 'Success') {
        //             newFiles = myStudy.blendRequests[tabValue].blendAttachments.filter(f => f !== attachmentDeleteOptions.file)            
        //             setAttachmentDeleteOptions(null)
        //             updateData(property, newFiles)  

        //             openModalMessages("Attachment Deleted", `Attachment successfully deleted.`) 
        //         } else {
        //             openModalMessages("Error Removing Attachment", `${res ? res.message : 'Error removing attachment'}.  Please try again or contact support.`)
        //             return
        //         }
        //     })
        // } else {
        //     newFiles = myStudy.blendRequests[tabValue].blendAttachments.filter(f => f !== attachmentDeleteOptions.file)  
        //     setAttachmentDeleteOptions(null)
        //     updateData(property, newFiles)   
        // }        
    // } else if (property === 'batchAttachments')
    // {
    //     if (attachmentDeleteOptions.file.id > 0)
    //     {
    //         Attachments.deleteBatchAttachment(attachmentDeleteOptions.file.id).then((res) => {
    //             if (res && res.message === 'Success') {
    //                 newFiles = myStudy.blendRequests[tabValue].batches[tabValueBatch].batchAttachments.filter(f => f !== attachmentDeleteOptions.file)     
    //                 setAttachmentDeleteOptions(null)
    //                 updateData_Batch(property, newFiles, tabValueBatch)       
    //             } else {
    //                 openModalMessages("Error Removing Attachment", `${res ? res.message : 'Error removing attachment'}.  Please try again or contact support.`)
    //                 return
    //             }
    //         })
    //     } else {
    //         newFiles = myStudy.blendRequests[tabValue].batches[tabValueBatch].batchAttachments.filter(f => f !== attachmentDeleteOptions.file)    
    //         setAttachmentDeleteOptions(null)
    //         updateData_Batch(property, newFiles, tabValueBatch)
    //     }  
    // }
  }

//   function HandleFileDownload (file) {
//     downloadFile('blendrequestfiles', file.fileName, file.storagePath, openModalMessages)
//   }

  function HandleFileAddBatch (newFiles) {
    const listFiles = newFiles.map(item => {
        return {
          id: 0,
          batchID: myStudy.blendRequests[tabValue].batches[tabValueBatch].id,
          fileName: item.name,
          storagePath: '',
          fileObject: item,
          base64String: null
        }
    })

    updateData_Batch('batchAttachments', myStudy.blendRequests[tabValue].batches[tabValueBatch].batchAttachments.concat(listFiles), tabValueBatch)
  }

  function HandleFileDownloadBatch (file) {
    downloadFile('batchfiles', file.fileName, file.storagePath, openModalMessages)
  }

//   const ConfirmRemoveAttachmentBlend = (file) => {  
//     setAttachmentDeleteOptions({property:'blendAttachments', file:file}) 
//     openModalDeleteAttach(`Remove Attachment?`, "Are you sure you want to permanently remove the attachment?", "Yes", "No")
//   }

  const ConfirmRemoveAttachmentBatch = (file) => {   
    setAttachmentDeleteOptions({property:'batchAttachments', file:file})
    openModalDeleteAttach(`Remove Attachment?`, "Are you sure you want to permanently remove the attachment?", "Yes", "No")
  }

//   const SumOfBlendTargets = () => {

//     let sum = 0;

//     for(var iComponent = 0; iComponent <  myStudy.blendRequests[tabValue].blendComponents.length; iComponent++) 
//     {
//         if (isNumber(myStudy.blendRequests[tabValue].blendComponents[iComponent].targetAmountLower) && myStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM 
//         && (myStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM.uoMName === 'wt%' || myStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM.uoMName === 'vol%'))
//         {
//             sum = sum + Number(myStudy.blendRequests[tabValue].blendComponents[iComponent].targetAmountLower)
//         }
//     }

//     return RoundNumber(sum, 2);
//   }

  async function CheckBatchForErrors ()
  {
    //checks the current Blend and Batch for errors
    let hasErrors = []

    hasErrors = CheckBlendComponents(myStudy);

    // if (myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName === 'Submitted' || myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName === 'Needs Review')
    // {
    //check to make sure there are tests if return location is discard
    //check to make sure there is enough requested in the batch to fulfill the testing
    if (myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject === null || myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject.length < 1)
    {
        if(myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation.locationName === 'DISCARD')
        {
            hasErrors.push(`Your batch has retains set to DISCARD and no analytical testing assigned...meaning no work needs to be done.`)            
        }
    } else {
        const masterUoM = myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested
        let totalNeededForTesting = GetTotalNeededForTesting(tabValue, tabValueBatch, masterUoM)

        if(isNumber(myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount) && myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested)
        {
            if(totalNeededForTesting > Number(myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount))
            {
                hasErrors.push(`The amount needed for the assigned tests (${totalNeededForTesting}${masterUoM.uoMName}) exceeds the requsted amount ${myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount}${masterUoM.uoMName}.`)              
            }
        } 
    }
    //}

    if (batchErrorObj.batchProductGroup === true || batchErrorObj.batchProductGroup === null)
    {
        hasErrors.push(`A batch product group is required.`)
    }

    if (batchErrorObj.description === true || batchErrorObj.description === null)
    {
        hasErrors.push(`A batch description is required.`)
    }

    if (batchErrorObj.chargeCode === true || batchErrorObj.chargeCode === null)
    {
        hasErrors.push(`A batch cost code is required.`)
    }

    if (batchErrorObj.requestedAmount === true || batchErrorObj.requestedAmount === null)
    {
        hasErrors.push(`A target amount is required or invalid.`)
    }
    
    if (batchErrorObj.batchPrecision === true || batchErrorObj.batchPrecision === null)
    {
        hasErrors.push(`The batch precision is required/invalid.`)
    } 

    if (batchErrorObj.unitOfMeasureRequested === true || batchErrorObj.unitOfMeasureRequested === null)
    {
        hasErrors.push(`The batch unit of measure is required.`)
    } 

    if (batchErrorObj.batchRetainLocation === true || batchErrorObj.batchRetainLocation === null)
    {
        hasErrors.push(`The batch retain location is required.`)
    } 

    if (batchErrorObj.blendPriority === true || batchErrorObj.blendPriority === null)
    {
        hasErrors.push(`The batch priority is required.`)
    } 

    if (batchErrorObj.priorityReason === true || batchErrorObj.priorityReason === null)
    {
        hasErrors.push(`The batch priority reason is required.`)
    } 

    if (batchErrorObj.destination === true || batchErrorObj.destination === null)
    {
        hasErrors.push(`The batch destination is required.`)
    } 

    if (batchErrorObj.blendFacility === true || batchErrorObj.blendFacility === null)
    {
        hasErrors.push(`The batch blend facility is required.`)
    } 

    if (batchErrorObj.analyticalTestingOwnerEmail === true)
    {
        hasErrors.push(`The analytical testing owner appears to be invalid.`)
    }

    //if all checks were passed, submit it
    if (hasErrors.length === 0) 
    {
        let copyStudy = structuredClone(myStudy)

        //filter out the blend requests that aren't being submitted
        copyStudy.blendRequests = copyStudy.blendRequests.filter((obj, index) => index === tabValue);
    
        //filter out the batches that aren't being submitted
        copyStudy.blendRequests[0].batches = copyStudy.blendRequests[0].batches.filter((obj, index) => index === tabValueBatch);

        //only send the blend and batch that you are checking
        var inventoryAlerts = await CheckComponentInventory(copyStudy.blendRequests[0])

        if (inventoryAlerts.length > 0)
        {
            //ask them if they want to continue
            openModalInventoryCheck("Confirm Inventory", inventoryAlerts, "Yes", "No")

        } else {
            PreparePayloadToSubmit()
        }        
    } else {
        openModalMessages(errorsModalTitle, hasErrors, "Ok")
    } 
  }

  function PreparePayloadToSubmit ()
  {
    closeModalInventoryCheck()

    let copyStudy = structuredClone(myStudy)

    //filter out the blend requests that aren't being submitted
    copyStudy.blendRequests = copyStudy.blendRequests.filter((obj, index) => index === tabValue);

    //filter out the batches that aren't being submitted
    copyStudy.blendRequests[0].batches = copyStudy.blendRequests[0].batches.filter((obj, index) => index === tabValueBatch);


    //only included edit BlendRequests and Blend
    // if (copyStudy.id !== 0)
    // {
    //   //only get blend requests that are new, have components new/edited or have batches new/edited
    //   copyStudy.blendRequests = structuredClone(copyStudy.blendRequests.filter(request => request.id === 0 
    //                                 || request.hasBeenEdited === true 
    //                                 || request.blendComponents.filter(component => component.id === 0).length > 0
    //                                 || request.batches.filter(batch => batch.id === 0 || batch.hasBeenEdited === true).length > 0))

    //   for(var y = 0; y <  copyStudy.blendRequests.length; y++) 
    //   {
    //     copyStudy.blendRequests[y].batches = structuredClone(copyStudy.blendRequests[y].batches.filter(batch => batch.id === 0 || batch.hasBeenEdited === true))
    //   }
    // }

    if (copyStudy.blendRequests.length === 0)
    {
      openModalMessages('Error', "Something went wrong, could not find the blend request and batch to submit!", "Ok")
      return;
    }

    if (copyStudy.projectID === null)
    {
      copyStudy.projectID = 1
    }


    if (copyStudy.blendRequests[0].id === 0)
    {
        copyStudy.blendRequests[0].blendStudyID = 0
    }

    for(var x = 0; x <  copyStudy.blendRequests[0].blendComponents.length; x++) 
    {

        if (copyStudy.blendRequests[0].blendComponents[x].id === 0)
        {
            copyStudy.blendRequests[0].blendComponents[x].blendID = 0
        }

        //set the target upper = lower until ranges are implemented
        copyStudy.blendRequests[0].blendComponents[x].targetAmountUpper = copyStudy.blendRequests[0].blendComponents[x].targetAmountLower
    }

    if (copyStudy.blendRequests[0].batches[0].id === 0)
    {
        copyStudy.blendRequests[0].batches[0].blendID = 0
    }

    copyStudy.blendRequests[0].batches[0].analyticalTesting = copyStudy.blendRequests[0].batches[0].analyticalTestingObject.length === 0 ? null : JSON.stringify(copyStudy.blendRequests[0].batches[0].analyticalTestingObject)

    copyStudy.blendRequests[0].batches[0].batchStatusName = 'Submitted';

    //check the blend components for the ones being saved to ensure they are complete
    //let errors = CheckBlendComponents(copyStudy)

    // if (errors.length > 0) 
    // {
    //   openModalMessages(errorsModalTitle, errors, "Ok")
    //   return;
    // };

    const formData = new FormData();

    formData.append("studyJSON", JSON.stringify(copyStudy))

    copyStudy.blendRequests.forEach(oRequest => {
      oRequest.batches.forEach(oBatch => {
        oBatch.batchAttachments.forEach(batchAttachment => {
          if (batchAttachment.id === null || batchAttachment.id < 1) {
            formData.append("filesToUpload", batchAttachment.fileObject, batchAttachment.fileName)
          }
        })
      })
    })

    SubmitBlendStudy(formData, copyStudy.studyName, true);
  }

  function CreateMidasNumberCopy () {
        if (midasNumberToFind === null || midasNumberToFind.trim().length === 0)
        {
            openModalMessages("You must enter a MIDAS number to search.")
            return
        }

        Batch.GetByPreparedMidasNumber(midasNumberToFind).then((resBatch) => {
            if (resBatch && resBatch.message === 'Success')
            {
                if (resBatch.result)
                {
                    BlendRequest.GetBlendByPreparedMidasNumber(midasNumberToFind).then((res) => {  

                        closeModalCopyMidasNumber()

                        if (res && res.message === 'Success') 
                        {
                            if (res.result)
                            {
                                const foundBlend = structuredClone(res.result)
                                const newErrorCheck = structuredClone(defaultBlendRequestErrorCheck)

                                //resBatch.result.analyticalTesting = null
                                //resBatch.result.analyticalTestingObject = []

                                resBatch.result.analyticalTestingObject = resBatch.result.analyticalTesting === null || resBatch.result.analyticalTesting === '' ? [] : JSON.parse(resBatch.result.analyticalTesting)
                                resBatch.result.batchSequenceNumber = 1

                                foundBlend.batches.push(structuredClone(resBatch.result))

                                newErrorCheck.blendComponents = []

                                for (var x = 0; x <  foundBlend.blendComponents.length; x++) {
                                
                                    newErrorCheck.blendComponents.push({
                                    _blendLetter: "",
                                    substance: false,
                                    targetAmountUpper: false,
                                    targetAmountLower: false,
                                    blendAdditionTemperatureC: false,
                                    preferredSample: false,
                                    secondaryPreferredSample: false
                                    })
                                }

                                //need to set the default error checks for the batch
                                newErrorCheck.batches[0].requestedAmount = !(isNumber(resBatch.result.requestedAmount))
                                newErrorCheck.batches[0].batchPrecision = EvaluateRequiredIntegerField(resBatch.result.batchPrecision)
                                newErrorCheck.batches[0].unitOfMeasureRequested = resBatch.result.unitOfMeasureRequested === null
                                newErrorCheck.batches[0].batchRetainLocation = resBatch.result.batchRetainLocation === null
                                newErrorCheck.batches[0].destination = resBatch.result.destination === null || resBatch.result.destination.trim() === ''
                                newErrorCheck.batches[0].blendPriority = resBatch.result.blendPriority === null
                                newErrorCheck.batches[0].priorityReason = resBatch.result.blendPriority ? resBatch.result.blendPriority.blendPriorityName === 'Normal' ? false : true : false
                                newErrorCheck.batches[0].blendFacility = resBatch.result.blendFacility === null
                                newErrorCheck.batches[0].description = resBatch.result.description === null || resBatch.result.description.trim() === ''
                                newErrorCheck.batches[0].chargeCode = resBatch.result.chargeCode === null || resBatch.result.chargeCode.trim() === '' || availableBillingInfo.find(info => info.costCode === resBatch.result.chargeCode) === undefined
                                newErrorCheck.batches[0].batchProductGroup = resBatch.result.batchProductGroup === null

                                AddNewBlend(foundBlend, newErrorCheck)

                                setMidasNumberToFind(null)
                            } else {
                                openModalMessages("Could not find a blend using that MIDAS number.")
                            }

                        } else {
                            openModalMessages('Could Not Find Blend Info', ` ${res ? res.message : 'Unspecified Error'}. Contact support if you feel this is an error.`);          
                        }
                    });
                } else {
                    openModalMessages("Could not find a batch using that MIDAS number.")
                }
            } else {
                openModalMessages('Could Not Find Batch Info', ` ${resBatch ? resBatch.message : 'Unspecified Error'}. Contact support if you feel this is an error.`);   
            }
        })
  } 

  async function CheckComponentInventory(blendToCheck) {
    return new Promise((value) => {

    let hasErrors = []

    let arrayBlendRequestsToCheck = []

    arrayBlendRequestsToCheck.push(structuredClone(blendToCheck))  
    // myBlendStudy.blendRequests.forEach(oBlendRequest => {

    //   if(oBlendRequest.batches.filter(obj => obj.batchStatusName === 'Submitted' || obj.batchStatusName === null || obj.batchStatusName ==='').length > 0)
    //   {
    //     arrayBlendRequestsToCheck.push(structuredClone(oBlendRequest))  
    //   }
    // });

    openModalNoButton("Inventory Check", "Checking component inventory at the site, be patient.")

    BlendRequest.CheckBlendComponentSiteVolumes(arrayBlendRequestsToCheck).then((res) => {

      closeModalNoButton()

      if (res.message === 'Success') 
      {
        if (res.result !== null && res.result.length > 0)
        {
          res.result.forEach(oMessage => {
            hasErrors.push(oMessage)              
          });

          value(hasErrors)

        } else {
          value(hasErrors)
        }

      } else {
        openModalMessages("Error Checking Components", `There was an error checking the container inventory for the components.  ${res.message ? res.message : ''}`)
        value(hasErrors)
      }
    }) 
  })
  }

  return (
    <div style={{marginTop:"0px"}}>

    <StyledDiv>
    {/* <GlobalTabCollection style={{width: myStudy.blendRequests.length > 10 ? "60%" :  `${myStudy.blendRequests.length * 6}%` }} value={tabValue} onChange={handleTabChange} variant={myStudy.blendRequests.length > 10 ? "scrollable":"standard"} scrollButtons="auto" aria-label='ant example'> */}
        <GlobalTabCollection style={{width: myStudy.blendRequests.length > 10 ? "60%" :  `${myStudy.blendRequests.length * 6}%` }} value={tabValue} onChange={handleTabChange} variant={myStudy.blendRequests.length > 9 ? "scrollable" : "standard"} scrollButtons="auto" aria-label='ant example'>
            {myStudy.blendRequests.map((oBlend, index) => {
                return(
                    <StyledTab key={index}
                    label={`Blend ${oBlend.blendName}`} />
                )
            })}
             
        </GlobalTabCollection>

        <Box>
             <GlobalButton 
                disabled={userDisabled}
                variant="contained" 
                style={{ textTransform: "none", marginTop:"-5px", marginLeft:"15px", height:"30px"}}
                onClick={e => {AddNewBlend(defaultBlendRequest, defaultBlendRequestErrorCheck)}}
            >Add New Blend</GlobalButton>
        </Box>
    </StyledDiv>

        <StyledDiv>
            <p style={{
               fontFamily:"EMprint",
               fontWeight:"600",
               fontSize:"16px",
               color:"#545459",
               textTransform: 'none',
               textAlign:"left",
               paddingTop:"20px"
              }}>Blend Design</p>
        </StyledDiv>

    {/* Blend Type */}
        <StyledDiv>
            <GlobalButton 
                variant="contained" 
                style={{ marginTop:"20px", marginLeft:"15px", marginRight:"20px"}}
                onClick={() => setModalCompareBlendsOpen(true)}
            >Compare All Blends
            </GlobalButton>

            <GlobalSecondaryButton 
                variant="contained" 
                style={{ marginTop:"20px", marginLeft:"15px", marginRight:"20px"}}
                onClick={() => setModalBulkEditComponentsOpen(true)}
            >Bulk Edit Components
            </GlobalSecondaryButton>

            <StyledAutocomplete
                disabled={masterDisableComponents}
                disablePortal
                noOptionsText={"Loading UoMs..."}
                options={availableUOMs.filter(result => result.uoMName === 'wt%' || result.uoMName ==='vol%').sort((a, b) => a.uoMName.localeCompare(b.uoMName))}
                getOptionLabel={(option) => option.uoMName}
                onChange={(e, value) => AutoCompleteChangeAndValidate_BlendRequest("requestUoM", value)}
                autoHighlight
                autoSelect
                value={myStudy.blendRequests[tabValue].requestUoM}
                isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Measurement" 
                error={blendErrorObj.requestUoM === null ? false : blendErrorObj.requestUoM}
                helperText = {blendErrorObj.requestUoM ? helperLabel : ''}
                InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
            />

            <Typography style={{marginTop:"20px", marginLeft:"1rem"}} fontSize={16}>
                {`Current Cumulative Target: ${SumOfBlendTargets(myStudy.blendRequests[tabValue].blendComponents)}%`}
            </Typography>

            <Box display="flex" alignItems={"center"} marginLeft="auto" marginRight="100px">
                <SettingsIcon style={{
                    paddingTop:"10px",
                    paddingLeft:"15px",
                    fontSize:40,
                    marginTop:"10px"
                    }}
                    id="gearicon"
                    onClick={e => {handleClickMenuComponents(e)}}
                >
                </SettingsIcon>
                    <Menu
                        key={`TestingMenu${tabValue}`}
                        anchorEl={anchorElComponents[tabValue]}
                        open={openMenuComponents()}
                        onClose={(e) => handleCloseMenuComponents(e)}    
                    >
                        <MenuItem 
                            key={`menuItem${tabValue}~DupeComponentsToAnotherBlend`}
                            onClick={() => {openModalDupeBatch("Select Blends", "Select which blend you want to duplicate the components to.", "Save", "Cancel"); handleCloseMenuComponents(); setDuplicateType('Components')}}
                        >Duplicate Components to Another Blend</MenuItem>
                    </Menu>
            </Box>
        </StyledDiv>

    {/* Blend Components */}
        <StyledDiv>
            
            <UXDataTableWithoutBody
            tableWidth='95%' 
            cols={MyComponentCols} 
            blankFirstHeader = {true}
            tableId= {`componentTable${tabValue}`}
            rowLength={myStudy.blendRequests[tabValue].blendComponents.length}
            enablePaging = {false}
            rowsPerPage={0}
            page={0}
            handleChangePage={null}
            handleChangeRowsPerPage={null}
            noDataFoundMessage={NoComponentsMessage}
            enableAddIcon = {!(masterDisableComponents)}
            addFunction={() => AddNewComponent()}
            addToolTipText="Add new component"
            isDataLoading={false}
            >
            
            {myStudy.blendRequests[tabValue].blendComponents.map((oComponent, index) => {

            return(
                <StyledTableBody key={`ComponentsTable${tabValue}~${index}`}>
                    <TableRow> 
                        <StyledTableCell component='th' scope='row' style={{ whiteSpace: 'nowrap'}}>

                    {myStudy.blendRequests[tabValue].blendComponents.length > 1 && masterDisableComponents === false && //oComponent.id === 0 &&
                        // <Tooltip title="Delete Component" placement="right">
                            <TrashIcon onClick ={() => DeleteComponent(index, oComponent.id)}>
                            </TrashIcon>
                        // </Tooltip>
                    }       
                    {index !== 0 && myStudy.blendRequests[tabValue].blendComponents.length > 1 &&
                        <ArrowUpwardIcon
                            //disabled = {masterDisableComponents}
                            onClick={e => {updateComponentSortOrder(index, index - 1)}}
                        ></ArrowUpwardIcon>
                    }

                    {index < myStudy.blendRequests[tabValue].blendComponents.length - 1 && myStudy.blendRequests[tabValue].blendComponents.length > 1 &&
                        <ArrowDownwardIcon
                            //disabled = {masterDisableComponents}
                            onClick={e => {updateComponentSortOrder(index, index + 1)}}
                        ></ArrowDownwardIcon>
                    }

                        </StyledTableCell>
                        <StyledTableCell>
                            <FormControlLabel style={{marginLeft:"5px", width:"20px"}} 
                                control={<Checkbox 
                                    disabled={masterDisableComponents || 
                                        (myStudy.blendRequests[tabValue].blendComponents.filter(result => result.isBalance === true).length > 0 && oComponent.isBalance === false) ||
                                        oComponent.componentUoM === null || (oComponent.componentUoM?.uoMName !== 'wt%' && oComponent.componentUoM?.uoMName !== 'vol%')} 
                                    checked={oComponent.isBalance}
                                onChange={e => {updateData_BlendComponent('isBalance', e.target.checked, index, tabValue)}}
                            ></Checkbox>} label=""/>
                         </StyledTableCell>

                        {/* CHEM ID */}
                        <StyledTableCell align='left'>
                            <ChemIDSearchField 
                                fontSize = {12}
                                fieldWidth = {"175px"} 
                                selectedChemIDObject = {oComponent.substance}
                                setSelectedChemIDObject = {(e) => updateData_BlendComponent('substance', e, index, tabValue)}
                                hasErrors = {blendErrorObj.blendComponents[index].substance === null ? false : blendErrorObj.blendComponents[index].substance}
                                setHasErrors = {(e) => updateErrorChecks_BlendComponent('substance', e, index, tabValue)}
                                isDisabled={masterDisableComponents}
                                allowInactiveChemIDs={false}
                                returnSubstanceStrucureData={false}
                            ></ChemIDSearchField>
                        </StyledTableCell> 

                        {/* Description */}
                        <StyledTableCell style={{width:"200px", fontSize: componentGridFontSize}} align='left'>{oComponent.substance !== null ?  oComponent.substance.substanceName : ''}</StyledTableCell>

                        {/* Locate Midas number */}
                        <TableCell align='center' style={{width:"75px"}}>
                            {oComponent.substance !== null && !masterDisableComponents &&
                                <ManageSearchIcon 
                                    onClick={() => {setModallocateMIDASNumberOpen(true); setBlendComponentIndex(index)}}>
                                </ManageSearchIcon>
                            }
                        </TableCell>

                        {/* MIDAS Number */}
                        <StyledTableCell align='left'>
                            <ValidatedMidasNumberTextField
                                isDisabled = {masterDisableComponents}
                                margin = {"none"}
                                showLabel = {true}
                                fontSize = {12}
                                fieldWidth = {"150px"}
                                midasNumberObject = {oComponent.preferredSample}
                                setMidasNumberObject = {(e) => updateData_BlendComponent('preferredSample', e, index, tabValue)}
                                hasErrors = {blendErrorObj.blendComponents[index].preferredSample === null ? false : blendErrorObj.blendComponents[index].preferredSample}
                                setHasErrors = {(e) => updateErrorChecks_BlendComponent('preferredSample', e, index, tabValue)}
                                chemIDToMatch = {oComponent.substance ? oComponent.substance.chemID : null}
                            ></ValidatedMidasNumberTextField>
                        </StyledTableCell>

                        {/* MIDAS Number Alt */}
                        <StyledTableCell align='left'>
                            <ValidatedMidasNumberTextField
                                isDisabled = {oComponent.substance === null || masterDisableComponents}
                                margin = {"none"}
                                showLabel = {true}
                                fontSize = {12}
                                fieldWidth = {"150px"}
                                midasNumberObject = {oComponent.secondaryPreferredSample}
                                setMidasNumberObject = {(e) => updateData_BlendComponent('secondaryPreferredSample', e, index, tabValue)}
                                hasErrors = {blendErrorObj.blendComponents[index].secondaryPreferredSample === null ? false : blendErrorObj.blendComponents[index].secondaryPreferredSample}
                                setHasErrors = {(e) => updateErrorChecks_BlendComponent('secondaryPreferredSample', e, index, tabValue)}
                                chemIDToMatch = {oComponent.substance ? oComponent.substance.chemID : null}
                            ></ValidatedMidasNumberTextField>
                        </StyledTableCell>

                        {/* component UOM */}
                        <StyledTableCell align='left'>
                            <StyledAutocomplete
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.uoMName}</Option>
                                    )} 
                                disabled = {oComponent.substance === null || myStudy.blendRequests[tabValue].requestUoM === null || masterDisableComponents}
                                disablePortal
                                noOptionsText={"Loading UoMs..."}
                                options={(myStudy.blendRequests[tabValue].requestUoM && myStudy.blendRequests[tabValue].requestUoM.uoMName === 'wt%') ?
                                    availableUOMs.filter(result => result.type === 'weight').sort((a, b) => a.uoMName.localeCompare(b.uoMName)) :
                                    availableUOMs.filter(result => result.type ==='volume').sort((a, b) => a.uoMName.localeCompare(b.uoMName))}
                                onChange={(e, value) => AutoCompleteChangeAndValidate_BlendComponent("componentUoM", value, index)}
                                getOptionLabel={(option) => option.uoMName}
                                value={oComponent.componentUoM}
                                isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                                autoHighlight
                                autoSelect
                                style={{marginTop:"3px"}}
                                renderInput={(params) => <TextField {...params} style={{width:"140px", marginTop:"3px"}} variant="outlined" size="small" 
                                error={blendErrorObj.blendComponents[index].componentUoM === null ? false : blendErrorObj.blendComponents[index].componentUoM}
                                helperText = {blendErrorObj.blendComponents[index].componentUoM ? helperLabel : ''}
                                inputProps={{ ...params.inputProps,  style: { fontSize: componentGridFontSize }}} InputProps={{ ...params.InputProps }}/>}  
                            />
                        </StyledTableCell>
       
                        {/* Target Amount */}
                        <StyledTableCell align='left'>
                            <TextField style={{width:"75px", marginTop:"5px"}}
                                disabled = {oComponent.substance === null || masterDisableComponents || oComponent.isBalance || myStudy.blendRequests[tabValue].requestUoM === null}
                                size="small"
                                margin="dense" 
                                variant="outlined"
                                inputProps={{ style: { fontSize: componentGridFontSize} }}
                                InputLabelProps={{shrink: true}}
                                value = {oComponent.targetAmountLower === null ? '' : oComponent.targetAmountLower}
                                onChange = {e => {updateData_BlendComponent('targetAmountLower', e.target.value, index, tabValue)}}
                                error = {blendErrorObj.blendComponents[index].targetAmountLower === null ? false : blendErrorObj.blendComponents[index].targetAmountLower}
                                onBlur={e => {updateErrorChecks_BlendComponent('targetAmountLower', EvaluateLowerAmountPercentages(oComponent, myStudy, tabValue), index, tabValue)}}
                                helperText = {blendErrorObj.blendComponents[index].targetAmountLower ? helperLabelNumber : ''}
                            />
                        </StyledTableCell>

                         {/* Upper Amount */}
                         {/* <StyledTableCell align='left'>
                            <TextField style={{width:"75px", marginTop:"5px"}}
                                disabled = {oComponent.substance === null || masterDisableRequests || oComponent.isBalance || myStudy.blendRequests[tabValue].requestUoM === null}
                                size="small"
                                margin="dense" 
                                variant="outlined"
                                inputProps={{ style: { fontSize: componentGridFontSize} }}
                                InputLabelProps={{shrink: true}}
                                value = {oComponent.targetAmountUpper === null ? '' : oComponent.targetAmountUpper}
                                onChange = {e => {updateData_BlendComponent('targetAmountUpper', e.target.value, index)}}
                                error = {blendErrorObj.blendComponents[index].targetAmountUpper === null ? false : blendErrorObj.blendComponents[index].targetAmountUpper}
                                onBlur={e => {updateErrorChecks_BlendComponent('targetAmountUpper', EvaulateMaxUpperAmountPercentages(oComponent, myStudy), index)}}
                                helperText = {blendErrorObj.blendComponents[index].targetAmountUpper ? helperLabelNumber : ''}
                            />
                        </StyledTableCell> */}

                        {/* Addition Order */}
                        <StyledTableCell align='left'>
                            <StyledAutocomplete
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option}</Option>
                                    )} 
                                disabled = {oComponent.substance === null || masterDisableComponents}
                                disablePortal
                                options={GetAdditionOrderValues()}
                                onChange={(e, value) => updateData_BlendComponent("addOrder", value, index, tabValue)}
                                getOptionLabel={(option) => option.toString()}
                                value={oComponent.addOrder}
                                isOptionEqualToValue={(option, value) => value === option}
                                autoHighlight
                                autoSelect
                                style={{marginTop:"3px"}}
                                renderInput={(params) => <TextField {...params} style={{width:"75px", marginTop:"3px"}} variant="outlined" size="small" 
                                error = {false}  
                                inputProps={{ ...params.inputProps,  style: { fontSize: componentGridFontSize }}} InputProps={{ ...params.InputProps }}/>} 
                                />
                        </StyledTableCell>

                        {/* Instructions */}
                        <TableCell align='center'>
                            {oComponent.substance !== null && !masterDisableComponents &&
                                <IntegrationInstructionsIcon 
                                    onClick={() => openModalInstructions("View/Edit Component Instructions", "You can add/edit an existing set of instructions for this component from here.", "Save", "Cancel", index)}>
                                </IntegrationInstructionsIcon>
                            }
                        </TableCell>

                        {/* Addition Temp */}
                        <StyledTableCell align='left'>
                            <TextField style={{width:"100px", marginTop:"5px"}}
                                disabled = {oComponent.substance === null || masterDisableComponents}
                                size="small"
                                margin="dense" 
                                variant="outlined"
                                inputProps={{ style: { fontSize: componentGridFontSize} }}
                                InputLabelProps={{shrink: true}}
                                value = {oComponent.blendAdditionTemperatureC === null ? '' : oComponent.blendAdditionTemperatureC}
                                onChange = {e => {updateData_BlendComponent('blendAdditionTemperatureC', e.target.value, index, tabValue)}}
                                error = {blendErrorObj.blendComponents[index].blendAdditionTemperatureC === null ? false : blendErrorObj.blendComponents[index].blendAdditionTemperatureC}
                                onBlur={e => {updateErrorChecks_BlendComponent('blendAdditionTemperatureC', EvaluateOptionalNumberField(oComponent.blendAdditionTemperatureC), index, tabValue)}}
                                helperText = {blendErrorObj.blendComponents[index].blendAdditionTemperatureC ? helperLabelNumber : ''}
                            />
                        </StyledTableCell>
                    </TableRow>
                    
                </StyledTableBody>
            )
            })}
            </UXDataTableWithoutBody>
        </StyledDiv>

    {/* Blend Details */}
        <StyledDivider></StyledDivider>
        <div style={{display:"flex"}}>
            <p style={{
                fontFamily:"EMprint",
                fontWeight:"600",
                fontSize:"16px",
                color:"#545459",
                textTransform: 'none',
                textAlign:"left",
                paddingTop:"30px"
            }}>Formulation Details</p>

                <Box display="flex" alignItems={"center"} marginLeft="auto" marginRight="100px">

                    <SettingsIcon style={{
                        paddingBottom:"5px",
                        paddingLeft:"15px",
                        fontSize:40,
                        marginTop:"10px"
                        }}
                        id="gearicon"
                        onClick={e => {handleClickMenuBlends(e, tabValue)}}
                    >
                    </SettingsIcon>
                        <Menu
                            key={`BlendMenu${tabValue}`}
                            anchorEl={anchorElBlends[tabValue]}
                            open={openMenuBlends()}
                            onClose={(e) => handleCloseMenuBlends(e)}    
                        >

                        <MenuItem 
                            key={`menuItem${tabValue}~LoadFromMIDASNumber`}
                            onClick={(e) => {openModalCopyMidasNumber("Enter MIDAS Number", "Enter your MIDAS Number to find and copy from", "Ok", "Cancel"); handleCloseMenuBlends(e)}}
                        >Create New Blend from MIDAS Number</MenuItem>
                        </Menu>

                {myStudy.blendRequests[tabValue].id === 0 && myStudy.blendRequests.length > 1 ?
                (
                    <Tooltip title={"Delete Blend"} placement="bottom">
                        <TrashIcon style={{
                            paddingBottom:"5px",
                            paddingLeft:"15px",
                            fontSize:40,
                            marginTop:"10px"
                        }}
                        onClick={()=> {DeleteBlend()}}                  
                        ></TrashIcon>
                    </Tooltip>
                 ) : (<></>)
                } 
                </Box>
        </div>

        <div>
        <Box display="flex" gap={2} alignItems="center" marginTop={"1rem"} width="95%">
            <StyledLargeTextField multiline size="small" margin="normal" variant="outlined" label="Formulation Comments" InputLabelProps={{shrink: true}}
                disabled={masterDisableRequests}
                onChange={e => {updateData('blendComments', e.target.value)}}
                value={myStudy.blendRequests[tabValue].blendComments === null ? '' : myStudy.blendRequests[tabValue].blendComments}
            ></StyledLargeTextField>

            <StyledLargeTextField size="small" margin="normal" variant="outlined" label="Add Keywords" InputLabelProps={{shrink: true}}
                disabled={masterDisableRequests}
                onChange={e => {updateData('keywords', e.target.value)}}
                value={myStudy.blendRequests[tabValue].keywords === null ? '' : myStudy.blendRequests[tabValue].keywords}
            ></StyledLargeTextField>
        </Box>

        {formatCreatedAndModifiedDateInfo(myStudy.blendRequests[tabValue].createdDate === null ? 'N/A' : myStudy.blendRequests[tabValue].createdDate, 
                                myStudy.blendRequests[tabValue].createdByEmail === null ||  myStudy.blendRequests[tabValue].createdByEmail.trim() === '' ? 'N/A' : myStudy.blendRequests[tabValue].createdByEmail,
                                myStudy.blendRequests[tabValue].lastModifiedDate === null ? 'N/A' : myStudy.blendRequests[tabValue].lastModifiedDate, 
                                myStudy.blendRequests[tabValue].lastModifiedByEmail === null || myStudy.blendRequests[tabValue].lastModifiedByEmail.trim() === '' ? 'N/A' : myStudy.blendRequests[tabValue].lastModifiedByEmail)}
        </div>

        <StyledDivider></StyledDivider>

    {/* Batch Information */}
        <div style={{marginTop:"20px"}}>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }} display="flex">
            <GlobalTabCollection style={{width: myStudy.blendRequests[tabValue].batches.length > 10 ? "80%" :  `${myStudy.blendRequests[tabValue].batches.length * 8}%` }} value={tabValueBatch} onChange={handleTabChangeBatches} variant={myStudy.blendRequests[tabValue].batches.length > 9 ? "scrollable" : "standard"} scrollButtons="auto" aria-label='ant example'>
                {myStudy.blendRequests[tabValue].batches.map((oBatch, index) => {
                    return(
                        <StyledTab key={`${tabValue}~${index}`}
                        label={`Batch ${oBatch.batchSequenceNumber}`} />
                    )
                })}      
            </GlobalTabCollection>

                <Box>
                    <GlobalButton
                        disabled={userDisabled}
                        variant="contained" 
                        style={{marginTop:"-5px", marginLeft:"15px"}}
                        onClick={() => AddNewBatch(defaultBatch, defaultBatchErrorCheck, tabValue)}
                    >Add New Batch</GlobalButton>

                    <GlobalSecondaryButton 
                        variant='contained'
                        style={{ marginTop:"-5px", marginLeft:"15px" }}
                        onClick={(e) => openModalTests("Select Test Methods for Batch", "You can select the methods from scratch or use an existing template", 'Confirm', 'Cancel')}
                    >{masterDisableBatch ? 'View Testing' : 'Add Testing to Batch'}
                    </GlobalSecondaryButton>
                </Box>
        </Box>

            <StyledDiv>
                <p style={{
                fontFamily:"EMprint",
                fontWeight:"600",
                fontSize:"16px",
                color:"#545459",
                textTransform: 'none',
                textAlign:"left",
                paddingTop:"20px"
                }}>{`Batch Information`}</p>
                {/* ${myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName === null || myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName === '' ? 'N/A' : myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName}`}</p> */}

            <Typography style={{marginTop:"20px", marginLeft:"1rem"}} fontSize={16}>
                {`Min batch size to fulfill testing: ${GetTotalNeededForTesting(tabValue, tabValueBatch, myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested)} ${myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested ? myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested.uoMName : 'g'}`}

            </Typography>

            <FormControlLabel style={{marginTop:"0.7rem", marginLeft:"0.5rem"}} control={<Checkbox checked={myStudy.blendRequests[tabValue].batches[tabValueBatch].isMetalsFree}
                    disabled={masterDisableBatch}
                    onChange={e => {updateData_Batch('isMetalsFree', e.target.checked, tabValueBatch)}}
                ></Checkbox>} label="Metals Free"/>

                <FormControlLabel style={{marginTop:"0.7rem"}} control={<Checkbox checked={myStudy.blendRequests[tabValue].batches[tabValueBatch].isISOBlend}
                    disabled={masterDisableBatch}
                    onChange={e => {updateData_Batch('isISOBlend', e.target.checked, tabValueBatch)}}
                ></Checkbox>} label="ISO Blend"/>

                <Box display="flex" alignItems={"center"} marginLeft="auto" marginRight="100px">
                <Tooltip title={"Batch Options"} placement="bottom">
                    <SettingsIcon style={{
                        paddingBottom:"5px",
                        paddingLeft:"15px",
                        fontSize:40,
                        marginTop:"10px"
                        }}
                        id="gearicon"
                        onClick={e => {handleClickMenu(e)}}
                    >
                    </SettingsIcon>
                </Tooltip>

                    {(myStudy.blendRequests[tabValue].batches[tabValueBatch].id === 0 && myStudy.blendRequests[tabValue].batches.length > 1) &&
                        <Tooltip title={"Delete Batch"} placement="bottom">
                            <TrashIcon style={{
                                paddingBottom:"5px",
                                paddingLeft:"15px",
                                fontSize:40,
                                marginTop:"10px"
                                }}
                                onClick={() => {handleCloseMenu(); DeleteBatch(tabValueBatch)}}
                            ></TrashIcon>
                        </Tooltip>
                    }
                </Box>

                <Menu
                    key={`batch-menu${tabValue}~${tabValueBatch}`}
                    anchorEl={anchorEl[tabValue].batch[tabValueBatch]}
                    open={openMenu()}
                    onClose={(e) => handleCloseMenu(e)}    
                    >
                    <MenuItem 
                        key={`menuItem${tabValue}~${tabValueBatch}~DupeToOther`}
                        onClick={() => {handleCloseMenu(); openModalDupeBatch("Select Blends", "Select which blend you want to dupliate this batch onto.", "Save", "Cancel"); setDuplicateType('Batch')}}
                    >Duplicate Batch to Another Blend</MenuItem>

                    {/* {myStudy.blendRequests[tabValue].id === 0 && */}
                    <MenuItem 
                        key={`menuItem${tabValue}~${tabValueBatch}~DupeToSelf`}
                        onClick={() => {handleCloseMenu(); AddNewBatch(myStudy.blendRequests[tabValue].batches[tabValueBatch], batchErrorObj, tabValue)}}
                    >Duplicate Batch to this Blend</MenuItem>

                    <MenuItem
                        divider 
                        key={`menuItem${tabValue}~DupeTestsToAnotherBatch`}
                        onClick={() => {openModalDupeBatchTesting("Select Batch", "Select which batch you want to dupliate this testing on to.", "Save", "Cancel"); handleCloseMenu(); setDuplicateType('Testing')}}
                    >Duplicate Testing to Another Batch</MenuItem>

                    {/* {myStudy.blendRequests[tabValue].batches[tabValueBatch].id === 0 && myStudy.blendRequests[tabValue].batches.length > 1 ?
                    (
                    <MenuItem
                        key={`menuItem${tabValue}~${tabValueBatch}~Delete`}
                        onClick={() => {handleCloseMenu(); DeleteBatch(tabValueBatch)}}
                    >Delete Batch</MenuItem>
                    ) : ([])
                    } */}
                    <MenuItem 
                        disabled={masterDisableBatchStatusMenu || myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName === 'Draft'}
                        key={`menuItem${tabValue}~${tabValueBatch}~SetToDraft`}
                        onClick={() => {handleCloseMenu(); SetBatchStatus(myStudy.blendRequests[tabValue].batches[tabValueBatch].id, 'Draft', `Changed to draft by ${currentUser.username}`)}}
                    >{`Set back to Draft`}</MenuItem>

                    <MenuItem 
                        disabled={masterDisableBatchStatusMenu || myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName === 'Submitted'}
                        key={`menuItem${tabValue}~${tabValueBatch}~SubmitBatch`}
                        onClick={() => {handleCloseMenu(); CheckBatchForErrors()}}
                    >{`Submit Batch`}</MenuItem>

                    <MenuItem 
                        disabled={masterDisableBatchStatusMenu || myStudy.blendRequests[tabValue].batches[tabValueBatch].id < 1} // || myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName === 'Submitted' || myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName === 'Draft'}
                        key={`menuItem${tabValue}~${tabValueBatch}~CancelBatch`}
                        onClick={() => {handleCloseMenu(); openModalCancelReason("Cancel Reason", "Enter a Batch Cancellation Reason", "Confirm", "Cancel") }}//SetBatchStatus(myStudy.blendRequests[tabValue].batches[tabValueBatch].id, 'Cancelled', `Changed to cancelled by ${currentUser.username}`)}}
                    >{`Cancel Batch`}</MenuItem>
                </Menu>
            </StyledDiv>
        </div>

        <div>
            {/* <StyledDiv> */}
            <Box display="flex" gap={2} alignItems="center" marginTop={"0rem"} width="100%">

                 <StyledMediumTextField size="small" margin="dense" variant="outlined" label="* Description" InputLabelProps={{shrink: true}}
                    disabled={masterDisableBatch}
                    onChange={e => {updateData_Batch('description', e.target.value, tabValueBatch)}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].description === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].description}
                    error = {batchErrorObj.description === null ? false : batchErrorObj.description}  
                    onBlur={e => {updateErrorChecks_Batch('description', myStudy.blendRequests[tabValue].batches[tabValueBatch].description === "" || myStudy.blendRequests[tabValue].batches[tabValueBatch].description === null)}}
                    helperText = {batchErrorObj.description ? helperLabel : ''}
                ></StyledMediumTextField>

                <StyledMediumTextField size="small" margin="dense" variant="outlined" label="Purpose" InputLabelProps={{shrink: true}}
                    disabled={masterDisableBatch}
                    onChange={e => {updateData_Batch('batchPurpose', e.target.value, tabValueBatch)}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPurpose === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPurpose}
                ></StyledMediumTextField>

                
                <StyledAutocomplete
                    disabled = {masterDisableBatch}
                    disablePortal
                    noOptionsText={"Loading Destinations..."}
                    options={MyDestinations}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate_Batch('destination', value, tabValueBatch)}}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].destination}
                    isOptionEqualToValue={(option, value) => value === option}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Destination" 
                        error={batchErrorObj.destination === null ? false : batchErrorObj.destination}
                        helperText = {batchErrorObj.destination ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />  

                <StyledShortTextField size="small" margin="dense" variant="outlined" label="Status" InputLabelProps={{shrink: true}}
                    disabled = {true}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName === null ? '???' : myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName}
                ></StyledShortTextField>
            </Box>
            {/* </StyledDiv> */}

            <Box display="flex" gap={"1rem"} alignItems="center" marginTop={"0rem"} width="100%">


                <StyledAutocomplete
                    disabled = {masterDisableBatch}
                    disablePortal
                    noOptionsText={"Loading Faclities..."}
                    options={availableBlendFacilities}
                    getOptionLabel={(option) => option.blendFacilityName}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate_Batch('blendFacility', value, tabValueBatch)}}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].blendFacility}
                    isOptionEqualToValue={(option, value) => value.blendFacilityName === option.blendFacilityName}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Blend Facility" 
                        error={batchErrorObj.blendFacility === null ? false : batchErrorObj.blendFacility}
                        helperText = {batchErrorObj.blendFacility ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />   

                <StyledAutocomplete
                    disabled={masterDisableBatch}
                    disablePortal
                    noOptionsText={"Loading Groups..."}
                    options={availableProductGroups}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => AutoCompleteChangeAndValidate_Batch("batchProductGroup", value, tabValueBatch)}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchProductGroup}
                    isOptionEqualToValue={(option, value) => value.name === option.name}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Product Group" 
                    error={batchErrorObj.batchProductGroup === null ? false : batchErrorObj.batchProductGroup}
                    helperText = {batchErrorObj.batchProductGroup ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />
                <StyledShortTextField size="small" margin="dense" variant="outlined" label="* Target Amount" InputLabelProps={{shrink: true}}
                    disabled = {masterDisableBatch}
                    onChange={e => {updateData_Batch('requestedAmount', e.target.value, tabValueBatch)}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount}
                    error = {batchErrorObj.requestedAmount === null ? false : batchErrorObj.requestedAmount}       
                    onBlur={e => {updateErrorChecks_Batch('requestedAmount', !(isNumber(myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount)))}}
                    helperText = {batchErrorObj.requestedAmount ? helperLabelNumber : ''}
                >
                </StyledShortTextField>

                <StyledAutocomplete
                    disabled = {masterDisableBatch}
                    disablePortal
                    options={[0, 1, 2, 3, 4]}
                    getOptionLabel={(option) => option.toString()}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate_Batch('batchPrecision', value, tabValueBatch)}}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPrecision}
                    isOptionEqualToValue={(option, value) => value === option}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label = "* Precision" 
                        error={batchErrorObj.batchPrecision === null ? false : batchErrorObj.batchPrecision}
                        helperText = {batchErrorObj.batchPrecision ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />

                <StyledAutocomplete
                    disabled = {masterDisableBatch}
                    disablePortal
                    noOptionsText={"Loading UoMs..."}
                    options={availableUOMs}
                    getOptionLabel={(option) => option.uoMName}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate_Batch('unitOfMeasureRequested', value, tabValueBatch)}}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested}
                    isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label = "* UoM" 
                        error={batchErrorObj.unitOfMeasureRequested === null ? false : batchErrorObj.unitOfMeasureRequested}
                        helperText = {batchErrorObj.unitOfMeasureRequested ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />
    
                <StyledAutocomplete
                    disabled = {masterDisableBatch}
                    disablePortal
                    noOptionsText={"Loading Locations..."}
                    options={availableLocations}
                    getOptionLabel={(option) => option.locationName}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate_Batch('batchRetainLocation', value, tabValueBatch)}}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation}
                    isOptionEqualToValue={(option, value) => value.locationName === option.locationName}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Retain Location" 
                        error={batchErrorObj.batchRetainLocation === null ? false : batchErrorObj.batchRetainLocation}
                        helperText = {batchErrorObj.batchRetainLocation ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />
        
            {/* </StyledDiv> */}
            </Box>

            <Box display="flex" gap={"1rem"} alignItems="center" marginTop={"0rem"} width="100%">

                <BillingInfoField
                    billingValue={myStudy.blendRequests[tabValue].batches[tabValueBatch].chargeCode}
                    handleChange={value => {
                        updateData_Batch('chargeCode', value, tabValueBatch);
                    }}
                    isDisabled={masterDisableBatch}
                    margin="dense"
                    marginLeft="0px"
                    marginRight="0px"
                    fieldWidth="15%"
                    hasErrors={batchErrorObj.chargeCode === null ? false : batchErrorObj.chargeCode}
                    helperText="JADE is Required"        
                    required={true}       
                >
                </BillingInfoField> 

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        disabled = {masterDisableBatch}
                        size="small"
                        inputFormat="MM/dd/yyyy"
                        label="Requested Completion Date"
                        onChange={(e) => {updateData_Batch('requestedCompletionDate', e, tabValueBatch)}}
                        minDate={new Date()}
                        value={myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedCompletionDate}
                        renderInput={(params) => <StyledShortTextField onKeyDown={DatePickerKeyDownEvent} {...params} margin="dense" variant="outlined" size="small" InputLabelProps={{shrink: true}} inputProps={{ ...params.inputProps }} InputProps={{ ...params.InputProps }}/>} 
                    />
                </LocalizationProvider> 

                <StyledShortTextField size="small" margin="dense" variant="outlined" label="Reference" InputLabelProps={{shrink: true}}
                    disabled={masterDisableBatch}
                    onChange={e => {updateData_Batch('reference', e.target.value, tabValueBatch)}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].reference === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].reference}
                ></StyledShortTextField>

                <StyledAutocomplete
                    disabled = {masterDisableBatch}
                    disablePortal
                    noOptionsText={"Loading Priorities..."}
                    options={availableBlendPriorities}
                    getOptionLabel={(option) => option.blendPriorityName}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate_Batch('blendPriority', value, tabValueBatch)}}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].blendPriority}
                    isOptionEqualToValue={(option, value) => value.blendPriorityName === option.blendPriorityName}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Priority" 
                        error={batchErrorObj.blendPriority === null ? false : batchErrorObj.blendPriority}
                        helperText = {batchErrorObj.blendPriority ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />
                <StyledMediumTextField size="small" margin="dense" variant="outlined" label="Reason for Priority" InputLabelProps={{shrink: true}}
                    disabled = {masterDisableBatch}
                    inputProps={{ maxLength: 100 }}
                    onChange={e => {updateData_Batch('priorityReason', e.target.value, tabValueBatch)}}
                    onBlur={e => {updateErrorChecks_Batch('priorityReason', EvaluatePriorityReason(tabValueBatch))}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason}
                    error={batchErrorObj.priorityReason === null ? false : batchErrorObj.priorityReason}
                    helperText = {batchErrorObj.priorityReason ? helperLabel : ''}
                >
                </StyledMediumTextField>

            </Box>

            <Box display="flex" gap={"1rem"} alignItems="center" marginTop={"1rem"} width="100%">
                <StyledShortTextField size="small" margin="dense" variant="outlined" label="CMA Oil Code" InputLabelProps={{shrink: true}}
                    disabled={masterDisableBatch}
                    inputProps={{ maxLength: 32 }}
                    onChange={e => {updateData_Batch('cmaOilCode', e.target.value, tabValueBatch)}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].cmaOilCode === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].cmaOilCode}
                >
                </StyledShortTextField>

                <StyledShortTextField size="small" margin="dense" variant="outlined" label="ProMIS R Code" InputLabelProps={{shrink: true}}
                    disabled={masterDisableBatch}
                    inputProps={{ maxLength: 30 }}
                    onChange={e => {updateData_Batch('proMISRCode', e.target.value, tabValueBatch)}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].proMISRCode === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].proMISRCode}
                ></StyledShortTextField>

                <StyledMediumTextField size="small" margin="dense" variant="outlined" label="Safety Information" InputLabelProps={{shrink: true}}
                    disabled={masterDisableBatch}
                    onChange={e => {updateData_Batch('safetyInformation', e.target.value, tabValueBatch)}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].safetyInformation === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].safetyInformation}
                ></StyledMediumTextField>

                <StyledMediumTextField size="small" margin="dense" variant="outlined" label="Batch Comments" InputLabelProps={{shrink: true}}
                    disabled = {masterDisableBatch}
                    multiline
                    onChange={e => {updateData_Batch('batchComments', e.target.value, tabValueBatch)}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComments === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComments}
                ></StyledMediumTextField>
            </Box>

            <Box display="flex" gap={"1rem"} alignItems="center" marginTop={"1rem"} width="100%">

                {/* <FormControlLabel control={<Checkbox checked={myStudy.blendRequests[tabValue].batches[tabValueBatch].isMetalsFree}
                    disabled={masterDisableRequests}
                    onChange={e => {updateData_Batch('isMetalsFree', e.target.checked, tabValueBatch)}}
                ></Checkbox>} label="Metals Free"/>

                <FormControlLabel control={<Checkbox checked={myStudy.blendRequests[tabValue].batches[tabValueBatch].isISOBlend}
                    disabled={masterDisableRequests}
                    onChange={e => {updateData_Batch('isISOBlend', e.target.checked, tabValueBatch)}}
                ></Checkbox>} label="ISO Blend"/> */}

                <EmailAddressTextField
                    fontSize = {16}
                    fieldWidth = {"calc(30% + 1rem)"} 
                    validatedUserEmail = {myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingOwnerEmail === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingOwnerEmail}
                    setValidatedUserEmail = {(e) => updateData_Batch('analyticalTestingOwnerEmail', e, tabValueBatch)}
                    hasErrors={batchErrorObj.analyticalTestingOwnerEmail}
                    setHasErrors = {(e) => updateErrorChecks_Batch('analyticalTestingOwnerEmail', e)}
                    isDisabled={masterDisableBatch}
                    labelText="Testing Owner Email"
                    placeholderText="Testing Owner Email"
                    showPlusMeButton={false}
                    size="small"
                    margin="dense"
                    isRequired={false}>
                </EmailAddressTextField>
{/* 
                onChange={e => {updateData_Batch('priorityReason', e.target.value, tabValueBatch)}}
                    onBlur={e => {updateErrorChecks_Batch('priorityReason', EvaluatePriorityReason(tabValueBatch))}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason}
                    error={batchErrorObj.priorityReason === null ? false : batchErrorObj.priorityReason} */}

                <StyledShortTextField size="small" margin="dense" variant="outlined" label="Prepared MIDAS #" InputLabelProps={{shrink: true}}
                    disabled = {true}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedSampleName === null ? '' : formatMidasNumber(myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedSampleName)}
                ></StyledShortTextField>

                <StyledShortTextField size="small" margin="dense" variant="outlined" label="Prepared ChemID" InputLabelProps={{shrink: true}}
                    disabled = {true}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedSampleName === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedSample.chemID}
                ></StyledShortTextField>  

                <StyledShortTextField size="small" margin="dense" variant="outlined" label="Appearance Result" InputLabelProps={{shrink: true}}
                    disabled = {true}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchAppearanceName === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].batchAppearanceName}
                ></StyledShortTextField>

                <StyledShortTextField size="small" margin="dense" variant="outlined" label="Free Water" InputLabelProps={{shrink: true}}
                    disabled = {true}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].freeWater === null ? '' : (myStudy.blendRequests[tabValue].batches[tabValueBatch].freeWater === true ? "Yes" : "No")}
                ></StyledShortTextField>          
            </Box>

            <Box display="flex" gap={"1rem"} alignItems="center" marginTop={"1rem"} width="100%">
                <StyledMediumTextField size="small" margin="dense" variant="outlined" label="Appearance Comments" InputLabelProps={{shrink: true}}
                    disabled = {true}
                    multiline
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].appearanceComments === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].appearanceComments}
                ></StyledMediumTextField>
            </Box>

            <div style={{ display: "flex", width: "95%", marginTop:"1rem", height:"15rem" }}>
                <FileDragAndDrop files={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchAttachments} disabled={masterDisableBatch} showFiles={true} handleFiles={HandleFileAddBatch} handleDeleteFile={ConfirmRemoveAttachmentBatch} handleFileDownload={HandleFileDownloadBatch}></FileDragAndDrop>
            </div>

            {myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComponents && 
            <>
                <StyledDiv>
                <p style={{
                    fontFamily:"EMprint",
                    fontWeight:"600",
                    fontSize:"16px",
                    color:"#545459",
                    textTransform: 'none',
                    textAlign:"left",
                    paddingTop:"20px"
                    }}>{`Batch Component Information`}</p>
                </StyledDiv>

                 <StyledDiv>
                    <UXDataTableWithoutBody
                    tableWidth='95%' 
                    cols={batchComponentCols} 
                    blankFirstHeader = {false}
                    tableId= {`batchComponentTable${tabValue}_${tabValueBatch}`}
                    rowLength={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComponents ? myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComponents.length - 1 : 0}
                    enablePaging = {false}
                    rowsPerPage={0}
                    page={0}
                    handleChangePage={null}
                    handleChangeRowsPerPage={null}
                    noDataFoundMessage={'No batch components found'}
                    enableAddIcon = {false}
                    addFunction={null}
                    addToolTipText="Add new component"
                    isDataLoading={false}
                    >
                    {myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComponents.sort((a,b) => a.orderAdded - b.orderAdded).map((oBatchComponent, index) => {
                        return(
                            <StyledTableBody key={`batchComponentsTable${tabValue}_${tabValueBatch}~${index}`}>
                                <TableRow> 
                                    <StyledTableCell>{oBatchComponent.id}</StyledTableCell>
                                    <StyledTableCell>{formatMidasNumber(oBatchComponent.container?.sampleName)}</StyledTableCell>
                                    <StyledTableCell>{oBatchComponent.container?.containerNumber}</StyledTableCell>
                                    <StyledTableCell>{oBatchComponent.container?.sample.chemID}</StyledTableCell>
                                    <StyledTableCell>{oBatchComponent.substitutionReason}</StyledTableCell>
                                    <StyledTableCell>{`${oBatchComponent.actualAmount} ${oBatchComponent.uoMName}`}</StyledTableCell>
                                    <StyledTableCell>{oBatchComponent.orderAdded}</StyledTableCell>
                                    <StyledTableCell>{oBatchComponent.actualTemperatureC}</StyledTableCell>
                                </TableRow>
                            </StyledTableBody>
                          
                    )})}
                    </UXDataTableWithoutBody>
                </StyledDiv>
            </>
            }
        </div>

        {/* just used for spacing at the bottom of the page */}
        <div style={{display:"flex", marginTop:"100px"}}>
        </div>

            <ModalTwoButtons title={modalInstructionsTitle} button1Text={modalInstructionsButton1Text} button1Action={SaveOrUpdateComponentInstructions} button2Text={modalInstructionsButton2Text} button2Action={closeModalInstructions} open={modalInstructionsOpen} setOpen={setModalInstructionsOpen}>
                <label>
                    {modalInstructionsText}
                </label>

                <div></div>
        
                <TextField
                    size="small"
                    multiline
                    label = "Instructions"
                    value = {tempComponentInstruction}
                    onChange = {e => {setTempComponentInstruction(e.target.value)}}
                    margin = "normal"
                    style={{width:500, marginTop:40}}
                    inputProps={{ maxLength: 250 }}
                ></TextField>  
            </ModalTwoButtons>

            <ModalTwoButtons title={modalDupeBatchTitle} button1Text={modalDupeBatchButton1Text} button1Action={ConfirmDuplicateBatch} button2Text={modalDupeBatchButton2Text} button2Action={closeModalDupeBatch} open={modalDupeBatchOpen} setOpen={setModalDupeBatchOpen}>
                <label>
                    {modalDupeBatchText}
                </label>

                <div></div>

                <Autocomplete
                    disablePortal
                    //options={myStudy.blendRequests.filter(e => e.id === 0 && e.blendName !== myStudy.blendRequests[tabValue].blendName)}
                    options={myStudy.blendRequests.filter(e => e.blendName !== myStudy.blendRequests[tabValue].blendName && (duplicateType === 'Components' ? e.blendStatusName === 'Draft' : e.blendStatusName !== ''))}
                    getOptionLabel={(option) => `Blend ${option.blendName}`}
                    onChange={(e, value) => {setTempCopyToBlendName(value); value !== null ? setTempCopyToBlendHasErrors(false) : setTempCopyToBlendHasErrors(true)}}
                    autoHighlight
                    value={tempCopyToBlendName}
                    isOptionEqualToValue={(option, value) => value.blendName === option.blendName}
                    style={{width:"75%", marginTop:"10px"}}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="Blend Name" 
                    error={tempCopyToBlendHasErrors}
                    helperText = {tempCopyToBlendHasErrors ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />   
            </ModalTwoButtons>

            <ModalTwoButtons title={modalDupeBatchTestingTitle} button1Text={modalDupeBatchTestingButton1Text} button1Action={ConfirmDuplicateBatch} button2Text={modalDupeBatchTestingButton2Text} button2Action={closeModalDupeBatchTesting} open={modalDupeBatchTestingOpen} setOpen={setModalDupeBatchTestingOpen}>
                <label>
                    {modalDupeBatchTestingText}
                </label>

                <div></div>

                <Autocomplete
                    disablePortal
                    options={myStudy.blendRequests[tabValue].batches.filter(e => (e.batchStatusName === 'Submitted' || e.batchStatusName === 'Draft') && e.batchSequenceNumber !== myStudy.blendRequests[tabValue].batches[tabValueBatch].batchSequenceNumber)}
                    getOptionLabel={(option) => `Batch ${option.batchSequenceNumber}`}
                    onChange={(e, value) => {setTempCopyToBatchNumber(value); value !== null ? setTempCopyToBatchNumberHasErrors(false) : setTempCopyToBatchNumberHasErrors(true)}}
                    autoHighlight
                    value={tempCopyToBatchNumber}
                    isOptionEqualToValue={(option, value) => value.batchSequenceNumber === option.batchSequenceNumber}
                    style={{width:"75%", marginTop:"10px"}}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="Batch Number" 
                    error={tempCopyToBatchNumberHasErrors}
                    helperText = {tempCopyToBatchNumberHasErrors ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />   
            </ModalTwoButtons>

            <ModalTwoButtons title={modalTestsTitle} button1Text={modalTestsButton1Text} button1Action={ConfirmTestsSelection} button2Text={modalTestsButton2Text} button2Action={HandleCancelButtonOnTestModal} open={modalTestsOpen} setOpen={setModalTestsOpen}>
                <label>
                    {modalTestsText}
                </label>

                <div style={{paddingTop:"30px"}}></div>
                <MethodSelection 
                    width={'1500px'} 
                    selectedMethods={tempSelectedMethods} 
                    setSelectedMethods={setTempSelectedMethods} 
                    showCompletionDate={false} 
                    showContainerGrouping={false}
                    incomingTemplate={null}
                    isReadOnly={masterDisableBatch}
                    showSearchTemplateName={!(masterDisableBatch)}
                    showEstimateAndPriority={true}
                    maxMethodsAllowed={999}
                >
                </MethodSelection>
            </ModalTwoButtons>

            {/* Informational Messages */}
            <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>

                {modalMessagesTitle === errorsModalTitle ?
                (modalMessagesText && modalMessagesText.map((text, index) => {
                    return (
                        <div style={{display:"flex"}} key={`mySamples${index}`}>
                            <label>
                            {text}
                            </label>
                        </div>
                    )
                    })
                )
                :
                ( <label>
                    {modalMessagesText}
                </label>
                )}      
            </ModalMessages>

            {/* Attachment Removal */}
            <ModalTwoButtons title={modalDeleteAttachTitle} button1Text={modalDeleteAttachButton1Text} button1Action={deleteFile} button2Text={modalDeleteAttachButton2Text} button2Action={closeModalDeleteAttach} open={modalDeleteAttachOpen} setOpen={setModalDeleteAttachOpen}>
                <label>
                    {modalDeleteAttachText}
                </label>

                <div></div>
            </ModalTwoButtons>

            {/* Component Removal */}
            <ModalTwoButtons title={modalDeleteComponentTitle} button1Text={modalDeleteComponentButton1Text} button1Action={PermanentDeleteComponent} button2Text={modalDeleteComponentButton2Text} button2Action={closeModalDeleteComponent} open={modalDeleteComponentOpen} setOpen={setModalDeleteComponentOpen}>
                <label>
                    {modalDeleteComponentText}
                </label>

            <div></div>
            </ModalTwoButtons>

            <ModalTwoButtons title={modalCopyMidasNumberTitle} button1Text={modalCopyMidasNumberButton1Text} button1Action={CreateMidasNumberCopy} button2Text={modalCopyMidasNumberButton2Text} button2Action={closeModalCopyMidasNumber} open={modalCopyMidasNumberOpen} setOpen={setModalCopyMidasNumberOpen}>
                <label>
                    {modalCopyMidasNumberText}
                </label>

                <div></div>

                <TextField
                    size="small"
                    label = "Midas Number"
                    value = {midasNumberToFind}
                    onChange = {e => setMidasNumberToFind(e.target.value)}
                    margin = "normal"
                    style={{width:250, marginTop:40}}
                    inputProps={{ maxLength: 250 }}
                ></TextField>
            </ModalTwoButtons>

            <CompareBlends open={modalCompareBlendsOpen} setOpen={setModalCompareBlendsOpen} myBlends={myStudy.blendRequests}>
            </CompareBlends>

            <BulkEditComponents 
                open={modalBulkEditComponentsOpen} 
                setOpen={setModalBulkEditComponentsOpen} 
                myStudy={myStudy}
                isUserDisabled={userDisabled}
                errorCheck={errorCheck}
                updateData_BlendComponent={updateData_BlendComponent}
                updateErrorChecks_BlendComponent={updateErrorChecks_BlendComponent}
                EvaluateLowerAmountPercentages={EvaluateLowerAmountPercentages}
            >
            </BulkEditComponents>

            <LocateMidasNumber 
                open={modalLocateMIDASNumberOpen} 
                setOpen={setModallocateMIDASNumberOpen} 
                myStudy={myStudy} 
                setMyStudy={setMyStudy} 
                blendIndex={tabValue} 
                blendComponentIndex={blendComponentIndex}
                batchComponents={null}
                batchSampleComponentIndex={null}
                setBatchComponents={null}
                bLocateContainers={false}
                incomingUoMObject={null}
                //onlyISOSamples={myStudy.blendRequests[tabValue].isISOBlend ? true : false}
            >
            </LocateMidasNumber>

              {/* Cancel Batch Reason */}
            <ModalTwoButtons title={modalCancelBatchTitle} button1Text={modalCancelBatchButton1Text} button1Action={ConfirmCancel} button2Text={modalCancelBatchButton2Text} button2Action={closeModalCancelReason} open={modalCancelBatchOpen} setOpen={setModalCancelBatchOpen}>
                <label>
                    {modalCancelBatchText}
                </label>

                <div></div>

                <TextField
                    size="small"
                    label = "Cancel Reason"
                    value = {cancelReason === null ? '' : cancelReason}
                    onChange = {e => setCancelReason(e.target.value)}
                    margin = "normal"
                    style={{width:250, marginTop:40}}
                    inputProps={{ maxLength: 250 }}
                ></TextField>
            </ModalTwoButtons>

                
            <ModalTwoButtons title={modalInventoryCheckTitle} button1Text={modalInventoryCheckButton1Text} button1Action={PreparePayloadToSubmit} button2Text={modalInventoryCheckButton2Text} button2Action={closeModalInventoryCheck} open={modalInventoryCheckOpen} setOpen={setModalInventoryCheckOpen}>
                {modalInventoryCheckText && modalInventoryCheckText.map((text, index) => {
                    return (
                    <div style={{display:"flex"}} key={`blendingInventoryChecks${index}`}>
                        <label>
                        {text}
                        </label>
                    </div>
                    )
                    })
                }   
                <Box style={{marginTop:"2rem"}}>
                    <label>{'Would you like to continue?'}</label>
                </Box>
            </ModalTwoButtons>
    </div>
  );
};

export default BlendsAndBatches;