import React, { useContext, useState } from "react";
import MoreOptions from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { pdf } from "@react-pdf/renderer";
import User from "../../api/Admin/User";
import Summary from "./PrintableComponents/Summary";
import UserContext from "../../context/UserContext";
import WorkRequest from "../../api/WorkRequest/WorkRequest";
import SpecialAnalysis from "../../api/SpecialAnalysis/SpecialAnalysis";
import PrintLabel from "../../api/LIMS/PrintLabel";
import AssignTechnicianModal from "./AssignTechnicianModal";
import PrintInformation from "../../components/PrintInformation";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";
import { featureToggle } from "../../global";

export default function ProcessMenuItem({ row, myRequests, billingList, reloadWorRequest, copyWorkRequest, isSpecialAnalysis }) {
  const [moreOptionsSelected, setMoreOptionsSelected] = useState(null);
  const [clickedMenuId, setClickedMenuId] = useState(null);
  const [assignTechnicianModal, setAssignTechnicianModal] = useState(false);
  const [printInfoOpen, setPrintInfoOpen] = React.useState(false);
  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const [modalMessagesText, setModalMessagesText] = useState("");
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState("Ok");
  const [printLabelInfo, setPrintLabelInfo] = React.useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const currentUser = useContext(UserContext);

  async function getSummary() {
    if (moreOptionsSelected && moreOptionsSelected.id) {
      let businessPhones = "";

      const userPreferences = await User.validateEmailAddress(
        currentUser.username
      );

      if (
        userPreferences &&
        userPreferences.businessPhones &&
        userPreferences.businessPhones.length > 0
      ) {
        businessPhones = userPreferences.businessPhones.join(", ");
      }

      WorkRequest.getById(moreOptionsSelected.id).then((response) => {
        if (response) {
          const asPdf = pdf([]);
          asPdf.updateContainer(
            <Summary
              workRequest={response}
              businessPhones={businessPhones}
              billingList={billingList}
            ></Summary>
          );

          asPdf.toBlob().then((blob) => {
            var url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      });
    }
  }

  function handleOpenMoreOptions(e, workRequest) {
    setMoreOptionsSelected(workRequest);
    setAnchorEl(e.currentTarget);
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null);

    setAnchorEl(null);
  }

  function openPrintInfo(sample, message = "") {
    let arrayPrintInfo = [];
    sample.forEach((sample) => {
      let newPrintInfo = new PrintLabel({
        sampleName: sample.sampleName,
        containerNumbers: [sample.existingContainer.containerNumber],
        includeAdditionalSampleInformation: false,
        chemIDOnly: false,
        printLabelSizeType: null,
        isBlindCoded: false,
        blindDescription: null,
        blindSampleName: null,
        isShelfLabel: false,
        shelfLabelText: null,
        includeShelfBarcode: false
      });

      arrayPrintInfo.push(newPrintInfo);
    });

    setPrintLabelInfo(arrayPrintInfo);
    setPrintInfoOpen(true);
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText("");
    setModalMessagesButtonText("");
    setModalMessagesTitle("");
  }

  const openModalMessage = (title, message, btnText) => {
    setModalMessagesOpen(true);
    setModalMessagesText(message);
    setModalMessagesButtonText(btnText);
    setModalMessagesTitle(title);
  };

  function closePrintInformation() {
    setPrintInfoOpen(false);
  }

  const assignTechnician = (assignTechnicianEmail) => {
    if (isSpecialAnalysis) {
      SpecialAnalysis.updateAssignTo(clickedMenuId, assignTechnicianEmail)
      .then((res) => {
        if (res?.message !== 'Success') {
          openModalMessage('Fail', `There was an error updating technician. ${res?.message ? res?.message : ""}`, 'Ok');
        } else {
          reloadWorRequest();
          openModalMessage("Success", "Technician successfully updated", "Close");
        }
      })
      .catch((err) => {
        openModalMessage(
          "Error",
          "There was an error updating technician",
          "Close"
        );
      });
    } else {
      WorkRequest.updateAssignTo(clickedMenuId, assignTechnicianEmail)
        .then((res) => {
          if (res?.message !== 'Success') {
            openModalMessage('Fail', `There was an error updating technician. ${res?.message ? res?.message : ""}`, 'Ok');
          } else {
            reloadWorRequest();
            openModalMessage("Success", "Technician successfully updated", "Close");
          }
        })
        .catch((err) => {
          openModalMessage(
            "Error",
            "There was an error updating technician",
            "Close"
          );
        });
    }
  };

  const reprintLabel = (id) => {
    WorkRequest.getById(id).then((result) => {
      if (!result.workRequestSamples.length) {
        openModalMessage(
          "No samples to print",
          "The selected request has no samples to be printed",
          "Close"
        );
        return;
      }
      openPrintInfo(result.workRequestSamples);
    });
  };

  const currentRequest = myRequests?.find((x) => x.id === clickedMenuId) ?? null;

  return (
    <>
      <IconButton
        onClick={(e) => {
          setClickedMenuId(row.id);
          handleOpenMoreOptions(e, row);
        }}
      >
        <MoreOptions color="black" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={() => handleCloseMoreOptions()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            getSummary();
            handleCloseMoreOptions();
          }}
        >
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            reprintLabel(currentRequest.id);
            handleCloseMoreOptions();
          }}
          disabled={false}
        >
          Reprint Work Request Label
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAssignTechnicianModal(true);
            handleCloseMoreOptions();
          }}
          disabled={currentRequest?.statusName === "Completed" || currentRequest?.statusName === "Cancelled"}
        >
          Assign
        </MenuItem>
        <MenuItem
          onClick={() => {
            copyWorkRequest(currentRequest.id);
            handleCloseMoreOptions();
          }}
          disabled={featureToggle.WorkRequestRedesign}
        >
          Copy Work Request
        </MenuItem>
      </Menu>
      {clickedMenuId > 0 && (
        <AssignTechnicianModal
          workRequestId={clickedMenuId}
          assignTechnician={assignTechnician}
          assignTechnicianModal={assignTechnicianModal}
          setAssignTechnicianModal={setAssignTechnicianModal}
        />
      )}
      <PrintInformation
        open={printInfoOpen}
        setOpen={setPrintInfoOpen}
        button2Action={closePrintInformation}
        button2Text={"Close"}
        printLabelInfo={printLabelInfo}
        isShelfLabel={false}
      ></PrintInformation>

      <ModalSimpleButton
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}
      >
        <label>{modalMessagesText}</label>
      </ModalSimpleButton>
    </>
  );
}
