import * as React from 'react';
import { styled } from "@mui/material/styles";
import MoreOptions from "@mui/icons-material/MoreVert";
import { formatMidasNumber, convertToLocalTime, convertDateFormat, getGlobalHyperLinkColor, toCamelCase } from "../../global";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/AddCircleOutline';
import { Checkbox, CircularProgress, TableSortLabel } from "@mui/material";
import ModalSimpleButton from '../Modal/ModalSimpleButton';
import MethodSelection from '../MethodSelection/index'
//import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import {
  Box,
  Button,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  tableCellClasses,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Paper,
  Collapse,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  verticalAlign: 'middle',
  border: "1px solid #F4F4F4",
  textAlign: "left",
  [`&.${tableCellClasses.head}`]: {
    borderBottomColor: "#D2D2D2",
    fontWeight: 600,
    color: "rgba(0, 0, 0, 0.8)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export { StyledTableCell };

const StyledTableCell12pt = styled(TableCell)(({ theme }) => ({
  verticalAlign: 'middle',
  border: "1px solid #F4F4F4",
  textAlign: "left",
  [`&.${tableCellClasses.head}`]: {
    borderBottomColor: "#D2D2D2",
    fontWeight: 600,
    color: "rgba(0, 0, 0, 0.8)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

export { StyledTableCell12pt };

const StyledTableCellLink = styled(TableCell)(({ theme }) => ({
  border: "1px solid #F4F4F4",
  textAlign: "left",
  [`&.${tableCellClasses.head}`]: {
    borderBottomColor: "#D2D2D2",
    fontWeight: 600,
    color: "rgba(0, 0, 0, 0.8)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  fontFamily:"EMprint",
  fontWeight:"600",
  textTransform: 'none',
  color: getGlobalHyperLinkColor(),
  textDecoration: 'underline',
  cursor: 'pointer'
}));

export { StyledTableCellLink };


  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
  }));

  export const StyledTableBody = styled(TableBody)(({ theme }) => ({
    //nth-child(odd)
    "&:nth-of-type(odd)": {
      backgroundColor: 'white',
    },
  
    //nth-child(even)
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
  },
  }));

  const StyledNotFound = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'lightgrey',
    paddingTop: '7px',
    alignItems: 'center',
    paddingBottom: '7px',
    marginBottom: '10px',
    marginTop: '-1rem'
  }));

  export {StyledTableRow};

  const CustomMenuItem = (rowIndex, key, menuType, menuText, redirectPath, propsToPass, closeMenuFunction, onClickFunction, isDisabled) => {

    if (menuType) {

        switch (menuType) {

            case 'link-newSample':

              const copySampleObj = JSON.parse(JSON.stringify(propsToPass))

              copySampleObj.sampleName = 'NEW'
              copySampleObj.containers = []

              return (
                <MenuItem 
                  key={key}
                  component={Link} 
                  to={{pathname: redirectPath, state: {sampleObject: copySampleObj, draftSamples: [], draftObject:null}}}
                  disabled={isDisabled}
                >{menuText}</MenuItem>
                )

            case 'link-requestTest':

              const copySample = JSON.parse(JSON.stringify(propsToPass))

              return (
                <MenuItem 
                  key={key}
                  component={Link} 
                  to= {{pathname: redirectPath, state: {sampleInfo: [copySample]}}}
                  disabled={isDisabled}
                >{menuText}</MenuItem>
                )
    
            case 'link-editStudy':

              const editStudy = JSON.parse(JSON.stringify(propsToPass))
                  
              return (
                <MenuItem 
                  key={key}
                  component={Link} 
                  to= {{pathname: redirectPath, state: {incomingStudyID: editStudy.id}}}
                  disabled={isDisabled}
                >{menuText}</MenuItem>
                )

            case 'link-editStudyFromRecentStudies':

              const editStudyFromRecent = JSON.parse(JSON.stringify(propsToPass))
                
              return (
                <MenuItem 
                  key={key}
                  component={Link} 
                  to= {{pathname: redirectPath, state: {incomingStudyID: editStudyFromRecent.blendStudy.id}}}
                  disabled={isDisabled}
                >{menuText}</MenuItem>
                )

            case 'link-editBatch':

              const editBatch = JSON.parse(JSON.stringify(propsToPass))
                    
              return (
                <MenuItem 
                  key={key}
                  component={Link} 
                  to= {{pathname: redirectPath, state: {incomingBatchID: editBatch.id}}}
                  disabled={isDisabled}
                >{menuText}</MenuItem>
              )

            case 'link-viewExperiment':
              const experiment = JSON.parse(JSON.stringify(propsToPass))
                  
              return (
                <MenuItem 
                  key={key}
                  component={Link} 
                  to= {{pathname: redirectPath, state: {incomingExperimentID: experiment.id}}}
                  disabled={isDisabled}
                >
                  {menuText}
                </MenuItem>
              )

            case 'link-processDistillation':

              const processDistillation = JSON.parse(JSON.stringify(propsToPass))
                    
              return (
                <MenuItem 
                  key={key}
                  component={Link} 
                  to= {{pathname: redirectPath, state: {incomingExperimentID: processDistillation.id}}}
                  disabled={isDisabled}
                >{menuText}</MenuItem>
              )  

            case 'text':

              return (
                <MenuItem 
                  key={key}
                  onClick = {(e) => {onClickFunction(propsToPass); closeMenuFunction(null, null, rowIndex)}}
                  disabled={isDisabled}
                >{menuText}</MenuItem>
              )

            default: <MenuItem key={key}></MenuItem>
        }
    }
  }

  const CustomTableCell = (colIndex, rowIndex, fieldType, fieldName, cellValue, toPath, propsToPass, tableName, openModalFunction, setTemplateMethods, setSelectedTemplate) => {

    if (fieldType) {
        switch (fieldType) {
          case 'label':
            return (
              <StyledTableCell key={`${tableName}custom-tcell-field-${fieldName}-${rowIndex}-${colIndex}`}>{cellValue}</StyledTableCell>
            )

          case 'label-blendPriority':
            return (
              <StyledTableCell style={{background: (cellValue === 'Normal' ? '' : 'pink') }} key={`${tableName}custom-tcell-field-${fieldName}-${rowIndex}-${colIndex}`}>{cellValue}</StyledTableCell>
            )

          case 'number':
            return (
              <StyledTableCell key={`${tableName}custom-tcell-field-number-${fieldName}-${rowIndex}-${colIndex}`}>{cellValue}</StyledTableCell>
            )

          case 'list-strings':

          const myReturn =
            (cellValue && cellValue.map((oString, index) => {
              return (
                  <div style={{display:"flex"}}>
                      <label>
                      {oString}
                      </label>
                  </div>
              )
              })
            )

            return (
              <StyledTableCell key={`${tableName}custom-tcell-field-${fieldName}-${rowIndex}-${colIndex}`}>{myReturn}</StyledTableCell>
            )

          case 'boolean':
            return (
              <StyledTableCell key={`${tableName}custom-tcell-field-bool-${fieldName}-${rowIndex}-${colIndex}`}>{cellValue ? 'Yes' : 'No'}</StyledTableCell>
            )

          case 'batch-label':

            return (
              <StyledTableCell key={`${tableName}custom-tcell-field-batchlabel-${fieldName}-${rowIndex}-${colIndex}`}>{`Batch ${cellValue}`}</StyledTableCell>
            )

          case 'object':

            //need some checking to make sure the object isn't null
            let validatedCellValue = cellValue
            if (propsToPass[fieldName.split('.')[0]] === null)
            {
              validatedCellValue = ''
            } else {
              validatedCellValue = propsToPass[fieldName.split('.')[0]][fieldName.split('.')[1]]
            }

            return (
              <StyledTableCell key={`${tableName}custom-object-field-${fieldName}-${rowIndex}-${colIndex}`}>{validatedCellValue}</StyledTableCell>
            )

          case 'object-midasnumber':

            //need some checking to make sure the object isn't null
            let validatedCellValue_midasnumber = cellValue

            if (propsToPass[fieldName.split('.')[0]] === null)
            {
              validatedCellValue_midasnumber = ''
            } else {
              validatedCellValue_midasnumber = propsToPass[fieldName.split('.')[0]][fieldName.split('.')[1]]
            }

            return (
              <StyledTableCell key={`${tableName}custom-object-field-midasnumber-${fieldName}-${rowIndex}-${colIndex}`}>{formatMidasNumber(validatedCellValue_midasnumber)}</StyledTableCell>
            )

          case 'object-condition':

            //need some checking to make sure the object isn't null
            //let prasedCondition = cellValue?.conditionValue ? `${cellValue.conditionValue} ${cellValue.conditionUoM}` : ''

            return (
              <StyledTableCell key={`${tableName}custom-object-field-condition-${fieldName}-${rowIndex}-${colIndex}`}>{cellValue?.conditionOfferingName}</StyledTableCell>
            )

          case 'label-loading':
            return (
              <StyledTableCell key={`${tableName}custom-tcell-field-loading-${fieldName}-${rowIndex}-${colIndex}`}>
              <CircularProgress key={`${tableName}custom-circle-field-loading-${fieldName}-${rowIndex}-${colIndex}`} size={20} style={{ marginRight:"10px"}}></CircularProgress>
                {"Fetching Results..."}</StyledTableCell>
            )

          case 'label-midasnumber':
            return (
              <StyledTableCell key={`${tableName}custom-labelmidasnumber-field-${fieldName}-${rowIndex}-${colIndex}`}>{formatMidasNumber(cellValue)}</StyledTableCell>
            )

          case 'datetime':
            return (
              <StyledTableCell key={`${tableName}custom-datetime-field-${fieldName}-${rowIndex}-${colIndex}`}>{convertToLocalTime(cellValue)}</StyledTableCell>
            )

          case 'object-datetime':

            //need some checking to make sure the object isn't null
            let validatedCellValue_datetime = cellValue
            if (propsToPass[fieldName.split('.')[0]] === null)
            {
              validatedCellValue_datetime = ''
            } else {
              validatedCellValue_datetime = propsToPass[fieldName.split('.')[0]][fieldName.split('.')[1]]
            }

            return (
              <StyledTableCell key={`${tableName}custom-object-field-${fieldName}-${rowIndex}-${colIndex}`}>{convertToLocalTime(validatedCellValue_datetime)}</StyledTableCell>
            )


          case 'date':
            return (
              <StyledTableCell key={`${tableName}custom-date-field-${fieldName}-${rowIndex}-${colIndex}`}>{convertDateFormat(cellValue)}</StyledTableCell>
            )

          case 'button-midasnumber':

            return (
              <StyledTableCell key={`${tableName}custom-buttonmidasnumber-field-${fieldName}-${rowIndex}-${colIndex}`}>
              <Link  style={{
                fontFamily:"EMprint",
                fontWeight:"600",
                textTransform: 'none',
                color: getGlobalHyperLinkColor(),
                textDecoration: 'none'
                }}
                to= {{pathname: toPath, state: {sampleObject: propsToPass, draftSamples: [], draftObject:null}}}
              >{formatMidasNumber(cellValue)}</Link>
              </StyledTableCell>
            )

          case 'button-text-draft':

            return (
              <StyledTableCell key={`${tableName}custom-buttontextdraft-field-${fieldName}-${rowIndex}-${colIndex}`}>
                <Link  style={{
                  fontFamily:"EMprint",
                  fontWeight:"600",
                  textTransform: 'none',
                  color: getGlobalHyperLinkColor(),
                  textDecoration: 'none'
                  }}
                  to= {{pathname: toPath, state: {sampleObject: null, draftSamples: JSON.parse(propsToPass.draftJSON), draftObject:propsToPass, templateObject:null}}}
                >{cellValue}</Link>
              </StyledTableCell>
            )

          case 'button-text-template':

            return (
              <StyledTableCell key={`${tableName}custom-buttontexttemplate-field-${fieldName}-${rowIndex}-${colIndex}`}>
                <Link  style={{
                  fontFamily:"EMprint",
                  fontWeight:"600",
                  textTransform: 'none',
                  color: getGlobalHyperLinkColor(),
                  textDecoration: 'none'
                  }}
                  to = {{pathname: toPath, state: {sampleObject: toCamelCase(JSON.parse(propsToPass.templateJSON)), draftSamples: [], draftObject:null, templateObject:propsToPass}}}
                >{cellValue}</Link>
              </StyledTableCell>
            )

          case 'button-text-draft-tests':

            return (
              <StyledTableCell key={`${tableName}custom-buttontextdraft-tests-field-${fieldName}-${rowIndex}-${colIndex}`}>
              <Link  style={{
                fontFamily:"EMprint",
                fontWeight:"600",
                textTransform: 'none',
                color: getGlobalHyperLinkColor(),
                textDecoration: 'none'
                }}
                to = {{pathname: toPath, state: {sampleInfo: JSON.parse(propsToPass.draftJSON), draftObject:propsToPass}}}
              >{cellValue}</Link>
              </StyledTableCell>
          )

          case 'button-text-template-tests':

            return (
              <StyledTableCell key={`${tableName}custom-buttontexttemplate-tests-field-${fieldName}-${rowIndex}-${colIndex}`}>
                <Button  style={{
                  fontFamily:"EMprint",
                  fontWeight:"600",
                  textTransform: 'none',
                  color: getGlobalHyperLinkColor(),
                  textDecoration: 'none'
                  }}
                  onClick = {(e) => {openModalFunction('Modify Method Template', 'You can modify or save a new method template from here', 'Cancel'); setTemplateMethods(toCamelCase(JSON.parse(propsToPass.templateJSON))); setSelectedTemplate(propsToPass)}}
                >{cellValue}</Button>
              </StyledTableCell>
            )

          case 'button-blends':

            const editStudy = JSON.parse(JSON.stringify(propsToPass))
                
            return (
              <StyledTableCell key={`${tableName}custom-button-blends-field-${fieldName}-${rowIndex}-${colIndex}`}>
              <Link  style={{
                fontFamily:"EMprint",
                fontWeight:"600",
                textTransform: 'none',
                color: getGlobalHyperLinkColor(),
                textDecoration: 'none'
                }}
                to= {{pathname: toPath, state: {incomingStudyID: editStudy.id}}}
                // to= {{pathname: toPath, state: {incomingStudyID: editStudy.id, isDupeStudy: false, draftObject:null, templateObject:null, incomingStudyObj:null}}}
              >{cellValue}</Link>
              </StyledTableCell>
            )
            
          case 'button-distillation':
            const incomingExperiment = JSON.parse(JSON.stringify(propsToPass))
              
            return (
              <StyledTableCell key={`${tableName}custom-button-distillation-field-${fieldName}-${rowIndex}-${colIndex}`}>
                <Link  
                  style={{
                    fontFamily:"EMprint",
                    fontWeight:"600",
                    textTransform: 'none',
                    color: getGlobalHyperLinkColor(),
                    textDecoration: 'none'
                  }}
                  to={{pathname: toPath, state: { incomingExperimentID: incomingExperiment?.id }}}
                >
                  {cellValue}
                </Link>
              </StyledTableCell>
            )

            case 'button-text-draft-workRequest':

              return (
                <StyledTableCell key={`${tableName}custom-buttontextdraft-workRequest-field-${fieldName}-${rowIndex}-${colIndex}`}>
                  <Link  style={{
                    fontFamily:"EMprint",
                    fontWeight:"600",
                    textTransform: 'none',
                    color: getGlobalHyperLinkColor(),
                    textDecoration: 'none'
                    }}
                    to= {{pathname: toPath, state: {incomingStudyID: null, isDupeStudy: null, draftObject:propsToPass, templateObject:null, incomingStudyObj:JSON.parse(propsToPass.draftJSON)}}}
                  >{cellValue}</Link>
                </StyledTableCell>
              )

          case 'checkbox':
            return (
              <StyledTableCell key={`${tableName}custom-checkbox-field-${fieldName}-${rowIndex}-${colIndex}`}>
                <Checkbox checked={cellValue}>
                </Checkbox>
            
              </StyledTableCell>
              )

          default: <StyledTableCell key={`${tableName}custom-default-field-${fieldName}-${rowIndex}-${colIndex}`}></StyledTableCell>
        }
    }
  }

const isRowOpen = (comparator, array) => array.indexOf(comparator) !== -1;

const UXDataTable = ({ tableWidth, cols, rows, moreOptionsCell, enablePaging, noDataMessage, menuProps, defaultRowsPerPage, isDataLoading = false, tableName, dataRefreshTrigger, setDataRefreshTrigger, enableSorting}) => {

  const [modalSimpleOpen, setModalSimpleOpen] = React.useState(false);
  const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
  const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
  const [modalSimpleText, setModalSimpleText] = React.useState('');

  const [selectedTemplate, setSelectedTemplate] = React.useState([])
  const [tempSelectedMethods, setTempSelectedMethods] = React.useState([])

  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [page, setPage] = React.useState(0);

  const [sortedProperty, setSortedProperty] = React.useState(null);
  const [sortedDataType, setSortedDataType] = React.useState(null);
  const [sortDirection, setSortDirection] = React.useState(null);

  const sortableTypes = ["label", "boolean", "number", "date", "datetime", "label-midasnumber", "button-midasnumber", "button-text-draft", "button-text-template", "button-text-draft-tests",
  "button-text-template-tests", "button-text-draft-blends", "button-text-template-blends", "button-blends", "button-distillation", "button-text-draft-workRequest"]

  function closeModalSimple() {
    setModalSimpleOpen(false)
    setDataRefreshTrigger(!dataRefreshTrigger)
  }

  function openModalSimple(title, text, buttonText) {
    setModalSimpleButtonText(buttonText)
    setModalSimpleOpen(true)
    setModalSimpleTitle(title)
    setModalSimpleText(text)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const DetermineStartArray = () => {
    if (enablePaging) {
      if (sortDirection !== null && sortedProperty !== null && rows?.length > 0 && sortedDataType !== null)
      {
        switch (sortedDataType) {
          case "date": case "datetime":
            if (sortDirection === "asc")
            {
              return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a, b) => new Date(a[sortedProperty]) - new Date(b[sortedProperty]))
            } else {
              return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a, b) => new Date(b[sortedProperty]) - new Date(a[sortedProperty]))
            }
            //break;
        
          case "number": case "button-distillation": case "boolean":
            if (sortDirection === "asc")
            {
              return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a, b) => a[sortedProperty] - b[sortedProperty])
            } else {
              return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a, b) => b[sortedProperty] - a[sortedProperty])
            }

          default:
            if (sortDirection === "asc")
            {
              return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a, b) => a[sortedProperty].localeCompare(b[sortedProperty]))
            } else {
              return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a, b) => b[sortedProperty].localeCompare(a[sortedProperty]))
            }
            //break;
        }
      } else {
        return (rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
      }
      //return (rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))

    } else {
      if (defaultRowsPerPage > 0)
      {
        if (sortDirection !== null && sortedProperty !== null && rows?.length > 0 && sortedDataType !== null)
      {
        switch (sortedDataType) {
          case "date": case "datetime":
            if (sortDirection === "asc")
            {
              return rows.slice(page * rowsPerPage, rowsPerPage).sort((a, b) => new Date(a[sortedProperty]) - new Date(b[sortedProperty]))
            } else {
              return rows.slice(page * rowsPerPage, rowsPerPage).sort((a, b) => new Date(b[sortedProperty]) - new Date(a[sortedProperty]))
            }
            //break;

          case "number": case "button-distillation": case "boolean":
            if (sortDirection === "asc")
            {
              return rows.slice(page * rowsPerPage, rowsPerPage).sort((a, b) => a[sortedProperty] - b[sortedProperty])
            } else {
              return rows.slice(page * rowsPerPage, rowsPerPage).sort((a, b) => b[sortedProperty] - a[sortedProperty])
            }
        
          default:
            if (sortDirection === "asc")
            {
              return rows.slice(page * rowsPerPage, rowsPerPage).sort((a, b) => a[sortedProperty].localeCompare(b[sortedProperty]))
            } else {
              return rows.slice(page * rowsPerPage, rowsPerPage).sort((a, b) => b[sortedProperty].localeCompare(a[sortedProperty]))
            }
            //break;
        }
      } else {
        return (rows.slice(page * rowsPerPage, rowsPerPage))
      }
      } else
      {
        if (sortDirection !== null && sortedProperty !== null && rows?.length > 0 && sortedDataType !== null)
        {
          switch (sortedDataType) {
            case "date": case "datetime":
              if (sortDirection === "asc")
              {
                return rows.sort((a, b) => new Date(a[sortedProperty]) - new Date(b[sortedProperty]))
              } else {
                return rows.sort((a, b) => new Date(b[sortedProperty]) - new Date(a[sortedProperty]))
              }
              //break;
          
            case "number": case "button-distillation": case "boolean":
              if (sortDirection === "asc")
              {
                return rows.sort((a, b) => a[sortedProperty] - b[sortedProperty])
              } else {
                return rows.sort((a, b) => b[sortedProperty] - a[sortedProperty])
              }

            default:
              if (sortDirection === "asc")
              {
                return rows.sort((a, b) => a[sortedProperty].localeCompare(b[sortedProperty]))
              } else {
                return rows.sort((a, b) => b[sortedProperty].localeCompare(a[sortedProperty]))
              }
              //break;
          }
        } else {
          return (rows)
        }

      }
    }
  }

  const [anchorEl, setAnchorEl] = React.useState([]);

  const openMenu = (index) => {
    return anchorEl[index] != null
  }

  const handleClickMenu = (event, index) => {
    const newAnchorEl = [...anchorEl]

    newAnchorEl[index] = event.currentTarget
    
    setAnchorEl(newAnchorEl)
  }

  const handleCloseMenu = (e, reason, index) => {
    const newAnchorEl = [...anchorEl]

    newAnchorEl[index] = null
    
    setAnchorEl(newAnchorEl)
  }

  function sortClick (sortProperty, dataType)
  {
    if (enableSorting === false)
    {
      return
    }

    setSortedDataType(dataType)

    if (sortedProperty === null && sortDirection === null)
    {
      setSortedProperty(sortProperty)
      setSortDirection("asc")

    } else if (sortedProperty === sortProperty)
    {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc")

    } else {
      setSortedProperty(sortProperty)
      setSortDirection("asc")
    }
  }

  function arrowDirection ()
  {
    if (sortDirection === "asc")
    {
      return <ArrowDownwardIcon></ArrowDownwardIcon>
    } else if (sortDirection === "desc")
    {
      return <ArrowUpwardIcon></ArrowUpwardIcon>
    }
  }

  return (
    <>
      <Box sx={{ width: tableWidth, marginRight:"20px" }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer sx={{ width: '100%' }}>
            <Table sx={{ minWidth: 500 }} size='small' aria-label='customized table'>
              <TableHead key={`${tableName}-table header`}>
                <TableRow>

                  {moreOptionsCell &&
                    <StyledTableCell style={{ width: "10px" }}></StyledTableCell>
                  }

                  {cols ?
                    cols.map((col) => (
                      <StyledTableCell sx={{cursor: enableSorting ? "pointer" : "default"}} onClick={e => sortClick(col.field, col.type)} key={`${tableName}custom-header-${col.headerName}`}>{col.headerName} 
                      {(sortableTypes.includes(col.type) && enableSorting && sortedProperty && sortedProperty === col.field) &&
                        arrowDirection()
                      }
                      </StyledTableCell>
                    )) : <StyledTableCell></StyledTableCell>
                  }
                </TableRow>
              </TableHead>

              {rows && rows?.length > 0 ?
                <TableBody key={`${tableName}-myTablebody`}>
                  {DetermineStartArray().map((rowValue, rowIndex) => {
                    return (
                      <StyledTableRow
                        key={`${tableName}custom-row-${rowIndex}`}>

                        {moreOptionsCell &&
                          <>
                              <StyledTableCell component='th' scope='row'>
                                <MoreOptions color='black'
                                  aria-haspopup="true"
                                  onClick={e => handleClickMenu(e, rowIndex)}
                                   />

                                <Menu
                                  key={`${tableName}basic-menu${rowIndex}`}
                                  id={`basic-menu${rowIndex}`}
                                  anchorEl={anchorEl[rowIndex]}
                                  open={openMenu(rowIndex)}
                                  onClose={(e, reason) => handleCloseMenu(e, reason, rowIndex)}

                                  >
                                    {menuProps && 
                                      menuProps.map((menuItem, index) => (
                                        CustomMenuItem(rowIndex, `customMenu-${rowIndex}${index}-${menuItem.text}-${rowValue.sampleName}`, menuItem.menuType, 
                                                        menuItem.text, menuItem.redirectPath, rowValue, handleCloseMenu, menuItem.onClickFunction, menuItem.isDisabled)
                                      ))

                                    }
                                </Menu>
                              </StyledTableCell>

                          </>
                        }

                        {cols ?
                          cols.map((col, index) => {
                            return (
                            CustomTableCell(index, rowIndex, col.type, col.field, (col.type === 'object' ? null : rowValue[col.field]), col.path ? col.path : null, rowValue, tableName, openModalSimple, setTempSelectedMethods, setSelectedTemplate)
                        )}) : <TableCell></TableCell>
                        }

                     
                      </StyledTableRow>
                    );
                  })}

                </TableBody> 
                    : 
                <TableBody>
                  <StyledTableRow>
                        {moreOptionsCell &&
                          <StyledTableCell component='th' scope='row'>
                            <MoreOptions color='black' />
                          </StyledTableCell>
                        }
                         {cols ?
                          cols.map((col, index) => {
                            return(
                              index === 0 ? (
                                isDataLoading ?
                                (CustomTableCell(index, 0, 'label-loading', `${col.field}-loading`, noDataMessage, null, null, tableName))
                              : (CustomTableCell(index, 0, 'label', `${col.field}-noData`, noDataMessage, null, null, tableName))
                              )
                              :
                              (CustomTableCell(index, 0, 'label', `${col.field}-noData`, "", null, null, tableName) 
                              )
                          )}) 
                          : <TableCell></TableCell>
                        }
                  </StyledTableRow>
                </TableBody>
              }

            </Table>
          </TableContainer>

          {enablePaging &&
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          }
        </Paper>
      </Box>

       {/* Modal for Test Template Editing */}
      <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimple} open={modalSimpleOpen} setOpen={setModalSimpleOpen}>

        <div style={{marginBottom:"1.5rem"}}>
          <label >
            {modalSimpleText}
          </label>
        </div>

        <MethodSelection 
          width={'1700px'} 
          selectedMethods={tempSelectedMethods} 
          setSelectedMethods={setTempSelectedMethods} 
          showCompletionDate={true} 
          showContainerGrouping={false}
          incomingTemplate={selectedTemplate}
          isReadOnly={false}
          showSearchTemplateName={true}
          showEstimateAndPriority={true}
          maxMethodsAllowed={999}
          >
        </MethodSelection>
      </ModalSimpleButton>
    </>
  );
}
export { UXDataTable };

function SorteableTableHead(props) {
  const { enableCheckbox, headCells, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, blankFirstHeader } = props;
  const createSortHandler =
    (property) => (event) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {blankFirstHeader &&
          <StyledTableCell></StyledTableCell>
        }
        {enableCheckbox &&
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        }
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.notSorteable ? (headCell.label) : (<TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const UXDataTableWithoutBody = ({ 
    children, 
    tableWidth, 
    cols, 
    blankFirstHeader, 
    tableId, 
    enablePaging,
    rowLength, 
    rowsPerPage, 
    page, 
    handleChangePage, 
    handleChangeRowsPerPage, 
    noDataFoundMessage, 
    enableAddIcon, 
    addFunction, 
    addToolTipText, 
    isDataLoading = false, 
    enableCheckbox,
    enableSorteable = false, 
    selected = [], 
    order, 
    orderBy, 
    handleRequestSort, 
    handleSelectAllClick,
    stickyHeader = false,
    maxHeight = 'unset',
    noWrapCols = false
  }) => {
  
  return (
    <>
      <Box sx={{ width: tableWidth }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer sx={{ width: '100%', maxHeight: maxHeight, minHeight: maxHeight ? rowLength === 0 ? 38 : 120 : 'unset' }} >

            {enableAddIcon &&
              <Tooltip title={addToolTipText} placement="right">
                <AddIcon style={{
                  paddingTop:"10px",
                  paddingLeft:"15px",
                  fontSize:40
                }}
                onClick={(e) => addFunction(e)}></AddIcon>
              </Tooltip>
                }
            
            <Table sx={{ minWidth: 500 }} size='small' aria-label='customized table' id={tableId} stickyHeader={stickyHeader}>
              {enableSorteable ? (
                <SorteableTableHead
                  enableCheckbox={enableCheckbox}
                  headCells={cols}
                  onSelectAllClick={handleSelectAllClick}
                  order={order}
                  orderBy={orderBy}
                  numSelected={selected.length}
                  rowCount={rowLength}
                  onRequestSort={handleRequestSort}
                  blankFirstHeader={blankFirstHeader}
                />
              ) : (
                <TableHead key="table header" style={{ backgroundColor: 'white' }}>
                  <TableRow>

                    {blankFirstHeader &&
                      <StyledTableCell></StyledTableCell>
                    }
                    {enableCheckbox &&
                      <TableCell padding="checkbox" style={{ textAlign: 'center' }}>
                        <Checkbox
                          color="primary"
                          indeterminate={selected.length > 0 && selected.length < rowLength}
                          checked={rowLength > 0 && (selected.length === rowLength || selected.length > rowLength)}
                          onChange={handleSelectAllClick}
                          inputProps={{
                            'aria-label': 'select all desserts',
                          }}
                        />
                      </TableCell>
                    }

                    {cols ?
                      cols.map((col) => (
                        <StyledTableCell key={`custom-header-${col}`} style={{ whiteSpace: noWrapCols ? 'nowrap' : '' }}>{col}</StyledTableCell>
                      )) : <StyledTableCell></StyledTableCell>
                    }
                  </TableRow>
                </TableHead>
              )}

              {rowLength > 0 ? children : null}

            </Table>
          </TableContainer>
          {enablePaging && rowLength > 0 &&
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={rowLength}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          }
        </Paper>
      </Box>

      {
        rowLength === 0 && <StyledNotFound style={{
          width: tableWidth
        }}>
          {
            isDataLoading ? (
              <>
              <CircularProgress size={20} style={{ marginRight:"10px"}}></CircularProgress>
              <label>{"Fetching Results..."}</label>
              </>
            ) : (
              noDataFoundMessage ? <label>{noDataFoundMessage}</label> : <label>No data available</label>
            )
           
          }
        </StyledNotFound>
      } 

    </>
  );
}
export { UXDataTableWithoutBody };

const UXDataTableNested = ({ children, tableWidth, cols, blankFirstHeader, colSpan, isOpenComparator, isOpenArray, maxWidth, beforeChild }) => {

  return (
      <StyledTableRow>
        <StyledTableCell colSpan={colSpan}>
          <Collapse
            in={isRowOpen(isOpenComparator, isOpenArray)}
            timeout='auto'
            unmountOnExit>
            {beforeChild && beforeChild}
            <Box margin={0}>
              <Table sx={{ minWidth: 500, maxWidth: maxWidth ? maxWidth : '100%' }} size='small' aria-label='customized table'>
                <TableHead style={{ backgroundColor: 'white' }}>
                  <TableRow>

                    {blankFirstHeader &&
                      <StyledTableCell></StyledTableCell>
                    }

                    {cols ?
                      cols.map((col) => (
                        <StyledTableCell key={`custom-header-${col}`}>{col}</StyledTableCell>
                      )) : <StyledTableCell></StyledTableCell>
                    }
                  </TableRow>
                </TableHead>

                {children}

              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
  );
}

export { UXDataTableNested };

