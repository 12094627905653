import { useState } from "react";
import ModalTwoButtons from '../../../../components/Modal/ModalTwoButtons'
import ValidatedMidasNumberTextField from "../../../../components/ValidatedMidasNumberTextField";
import "./styles.css";
import { Box } from "@mui/system";

const defaultSampleInfo = {
    sample: null,
    containers: [],
};

const AddSamples = ({
    isModalOpen,
    setIsModalOpen,
    setIsModalClose,
    mySamplesInfo,
    setMySamplesInfo,
}) => {
    const [sampleInfo, setSampleInfo] = useState(defaultSampleInfo);
    const [errors, setErrors] = useState({
        sample: null,
    });

    const hasError = () => Object.entries(errors).some(([key, value]) => value === null || value === true) || !sampleInfo.sample;

    function updateErrors() {
        setErrors((prevErrors) => {
            const updatedErrors = Object.keys(prevErrors).reduce((acc, key) => {
                acc[key] = prevErrors[key] === null ? true : prevErrors[key];
                return acc;
            }, {});

            return updatedErrors;
        });
    }

    function submit() {
        if (hasError()) {
            updateErrors();
            return;
        } else {
            setIsModalClose();
        
            if (mySamplesInfo.some(s => s?.sample?.sampleName === sampleInfo?.sample?.sampleName)) return;
            else setMySamplesInfo([...mySamplesInfo, { ...sampleInfo }]);
        }
    }

    return <ModalTwoButtons
        title="Sample"
        button1Text={"Add"}
        button1Action={submit}
        isButton1Disabled={false}
        button2Text="Cancel"
        button2Action={() => {
            setIsModalClose();
        }}
        setOpen={setIsModalOpen}
        open={isModalOpen}
    >
        <div className="form-work-request-add-samples">
            <Box display="flex" alignItems="center" justifyContent="center" gap={1} width="100%">
                <ValidatedMidasNumberTextField
                    margin="none"
                    showLabel={true}
                    fieldWidth="100%"
                    midasNumberObject={sampleInfo?.sample}
                    setMidasNumberObject={(e) => {
                        setSampleInfo({
                            ...sampleInfo,
                            sample: e
                        });
                    }}
                    hasErrors={errors.sample}
                    setHasErrors={(value) => setErrors({...errors, sample: value})}
                    chemIDToMatch={null}
                />
            </Box>
        </div>
    </ModalTwoButtons>
}
export default AddSamples
