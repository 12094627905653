import React, { useState, useEffect } from "react";
import { UXDataTable } from "../../components/UXDataTable";
import { applyFiltersToArray} from "../../global";

 const MyStudyCols = [
    { field: 'blendStudy.id', headerName: 'ID', type: 'object'},
    { field: 'blendStudy.studyName', headerName: 'Study Name', type: 'object' },
    { field: 'blendStudy.studyPurpose', headerName: 'Description', type: 'object' },
    { field: 'blendStudy.ownerEmail', headerName: 'Owner', type: 'object' },
    { field: 'blendStudy.createdDate', headerName: 'Date Created', type: 'object-datetime' },
    { field: 'blendStudy.lastModifiedDate', headerName: 'Last Modified', type: 'object-datetime' },
    { field: 'blendStudy.blendStudyStatusName', headerName: 'Status', type: 'object' },
    { field: 'blendStudy.notes', headerName: 'Notes', type: 'object' },
  ];

const TableRecentlyAccessed = ({filters, myStudies, isDataLoading, userEmail}) => {
    const [filteredStudies, setFilteredStudies] = useState([])
    const noDataMessage = `No recent studies found for ${userEmail}`

    const menuItems = [
    {
      menuType: 'link-editStudyFromRecentStudies',
      redirectPath: '/blendstudy',
      text: 'Edit/View this Study',
      onClickFunction: null
    }]
  
    useEffect(() => {
      var filteredArray = applyFiltersToArray(filters, myStudies)
      setFilteredStudies(filteredArray)
    }, [filters, myStudies])

    return (
      <div>
        <UXDataTable tableWidth='100%' cols={MyStudyCols} rows={filteredStudies.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))} moreOptionsCell={true} enablePaging={true} 
                      noDataMessage={noDataMessage} menuProps={menuItems} defaultRowsPerPage={10} isDataLoading={isDataLoading} tableName={'myStudies'} enableSorting={true}>
        </UXDataTable>
      </div>
    );
};

export default TableRecentlyAccessed;