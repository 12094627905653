import API from "..";

export default class Container {
  /**
   * The Container dimension table for M3
   * @param TODO
   */
  constructor({
    id,
    sampleName,
    tests,
    containerNumber,
    containerTypeName,
    containerType,
    containerStatusName,
    containerStatus,
    cylinderSerialNumber,
    size,
    containerSizeUoM,
    uom,
    tareWeight,
    thisTareWeightUoM,
    tareWeightUoM,
    currentAmount,
    ownerEmail,
    createdDate,
    createdByEmail,
    lastModifiedDate,
    lastModifiedByEmail,
    subLocation,
    expirationDate,
    nextDispositionDate,
    discardOnDisposition,
    locationName,
    location,
    parentID,
    parentContainer,
    returnLocationName,
    returnLocation,
    sample,
    testsBackloggedOrPending,
    testsInProgress,
    changeLogs,
    distillationCuts,
    comments,
  }) {
    this.id = id;
    this.sampleName = sampleName;
    this.tests = tests;
    this.containerNumber = containerNumber;
    this.containerTypeName = containerTypeName;
    this.containerType = containerType;
    this.containerStatusName = containerStatusName;
    this.containerStatus = containerStatus;
    this.cylinderSerialNumber = cylinderSerialNumber;
    this.size = size;
    this.containerSizeUoM = containerSizeUoM;
    this.uom = uom;
    this.tareWeight = tareWeight;
    this.thisTareWeightUoM = thisTareWeightUoM;
    this.tareWeightUoM = tareWeightUoM;
    this.currentAmount = currentAmount;
    this.ownerEmail = ownerEmail;
    this.createdDate = createdDate;
    this.createdByEmail = createdByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.subLocation = subLocation;
    this.expirationDate = expirationDate;
    this.nextDispositionDate = nextDispositionDate;
    this.discardOnDisposition = discardOnDisposition;
    this.locationName = locationName;
    this.location = location;
    this.parentID = parentID;
    this.parentContainer = parentContainer;
    this.returnLocationName = returnLocationName;
    this.returnLocation = returnLocation;
    this.sample = sample;
    this.testsBackloggedOrPending = testsBackloggedOrPending;
    this.testsInProgress = testsInProgress;
    this.changeLogs = changeLogs;
    this.distillationCuts = distillationCuts;
    this.comments = comments;
  }

  static async getContainerBySampleAndNumber(midasNumber, containerNumber) {
    const api = await API();
    const { data } = await api.get(`container/${midasNumber}/${containerNumber}`);
    let returnValue = {};
    if (data.result) {
      returnValue = new Container(data.result);
    }
    return returnValue;
  }

  static async getByOwnerOrLocation(owner, location) {
    const api = await API();

    var ownerParam = `owner=${owner}`;
    var locationParam = `&location=${location}`;

    var method = `container/getContainerByOwnerOrLocation?`;

    if (owner) method += ownerParam;

    if (location) method += locationParam;

    const { data } = await api.get(method);

    let returnValue = [];
    if (data.result) {
      returnValue = data.result.map((d) => new Container(d));
    }
    return returnValue;
  }

  static async getByMidasNumber(midasNumber) {
    const api = await API();
    const { data } = await api.get(`container/getBySampleName?midasNumber=${midasNumber}`);
    let returnValue = [];
    if (data.result) {
      returnValue = data.result.map((d) => new Container(d));
    }
    return returnValue;
  }

  static async getAdvancedLocateContainers(request, abortSignal) {
    const api = await API();
    const { data } = await api.post(`container/advancedLocateContainers`, request, {
      signal: abortSignal,
    });
    let returnValue = [];
    if (data.result) {
      returnValue = data.result.map((d) => new Container(d));
    }
    return returnValue;
  }

  static async getByMidasNumberAndOrLocationAndOrSampleOwner(
    midasNumber,
    location,
    subLocation,
    sampleOwner,
    join1,
    join2,
  ) {
    const api = await API();
    const { data } = await api.get(
      `container/getBySampleNameLocationSampleOwner?midasNumber=${midasNumber}&location=${location}&subLocation=${subLocation}&sampleOwner=${sampleOwner}&join1=${join1}&join2=${join2}`,
    );
    let returnValue = [];
    if (data.result) {
      returnValue = data.result.map((d) => new Container(d));
    }
    return returnValue;
  }

  static async getContainersForDiscard(midasNumber, location, subLocation, owner, join1, join2) {
    const api = await API();
    const { data } = await api.get(
      `container/getContainersForDiscard?midasNumber=${midasNumber}&location=${location}&subLocation=${subLocation}&owner=${owner}&join1=${join1}&join2=${join2}`,
    );
    let returnValue = [];
    if (data.result) {
      returnValue = data.result.map((d) => new Container(d));
    }
    return returnValue;
  }

  static async getContainerById(containerID) {
    const api = await API();
    const { data } = await api.get(`/container/${containerID}`);
    return new Container(data.result);
  }

  static async getByChemIDAndSite(chemID, siteName) {
    const api = await API();
    const { data } = await api.get(
      `container/getByChemIDAndSite?chemID=${chemID}&site=${siteName}`,
    );
    let returnValue = [];
    if (data.result) {
      returnValue = data.result.map((d) => new Container(d));
    }
    return returnValue;
  }

  static async quickSearch(searchCriteria) {
    const api = await API();
    const { data } = await api.get(`container/quickSearch?searchCriteria=${searchCriteria}`);
    return data;
  }

  static async changeOwner(containers) {
    const api = await API();
    const { data } = await api.put(`/container/transferContainers`, containers);
    return data;
  }

  static async discardContainers(ids, newLocation, cancelTests, containerStatus) {
    const api = await API();
    const { data } = await api.put(
      `/container/discardContainers?newLocation=${newLocation}&cancelTests=${cancelTests}&containerStatus=${containerStatus}`,
      ids,
    );
    return data;
  }

  static async GetContainersToCreate(testingInfo) {
    const api = await API();
    const { data } = await api.post(`/container/getContainersToCreate`, testingInfo);
    return data;
  }

  static async CreateContainersWithTests(containersWithTests) {
    const api = await API();
    const { data } = await api.post(`/container/createContainersWithTests`, containersWithTests);
    return data;
  }

  static async sendReceive(containers) {
    const api = await API();
    const { data } = await api.put(`/container/receiveContainers`, containers);
    return data;
  }
}
